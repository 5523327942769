'use client';
import React from 'react';
import { Box, Button, Grid, IconButton } from '@mui/material';
import { OrganizationIdName, FormMode, Roles } from '@efacity/common';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UserProfile } from './UserProfile';
import { AddressForm } from './AddressForm';
import { UserFormValues, FormGroupDivider } from '@efacity/frontend-shared';
import { userSettingsFormValidationSchema, userSettingsFields } from './UserSettingsFormValues';
import { RoleSelector, OrganizationSelector, FormPasswordInput } from '@efacity/react-hook-form-mui';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import { AddCircleOutlined } from '@mui/icons-material';
import { FormCancelSubmitButtons } from '@efacity/frontend-next-shared/forms';

interface UserFormProps {
  initialFormValues: UserFormValues;
  allowHandleAccess?: boolean;
  allowChangeEmail?: boolean;
  allowHandleDOB?: boolean;
  allowChangePassword?: boolean;
  organizationOptions: OrganizationIdName[];
  isSubmitDisabled: boolean;
  handleSubmit: (
    values: UserFormValues,
    setError: (fieldName: keyof UserFormValues, error: { type: string; message: string }) => void
  ) => void;
  handleCancel?: () => void;
  showCancel?: boolean;
  formMode?: FormMode;
  userModelFields: string[];
}

export const UserSettingsForm = ({
  initialFormValues,
  allowHandleAccess = true,
  allowChangeEmail = true,
  allowChangePassword = false,
  organizationOptions,
  handleSubmit,
  handleCancel = () => null,
  showCancel = false,
  formMode = FormMode.Edit,
  userModelFields = userSettingsFields
}: UserFormProps) => {
  const methods = useForm<UserFormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(userSettingsFormValidationSchema.pick(userModelFields as any)),
    defaultValues: initialFormValues,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUseNativeValidation: false
  });
  const { isSubmitting, isValid, isDirty } = methods.formState;

  const submitForm = (values: UserFormValues) => {
    handleSubmit(values, methods.setError);
  };

  const { fields, append, remove } = useFieldArray({
    control: methods.control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'access' // unique name for your Field Array
  });

  return (
    <FormProvider {...methods}>
      <form noValidate autoComplete="off" onSubmit={methods.handleSubmit(submitForm)}>
        <UserProfile allowChangeEmail={allowChangeEmail} />
        <FormGroupDivider marginY={0} />
        <AddressForm />
        {allowHandleAccess && (
          <React.Fragment>
            <FormGroupDivider marginY={20} />
            <Box>
              <h4 style={{ marginTop: 10, marginBottom: 10 }}>Access</h4>
              <div key={`accessArrayKeyDiv`}>
                {fields.map((access, index) => {
                  return (
                    // https://react-hook-form.com/api/usefieldarray/
                    //The field.id (and not index) must be added as the component key to prevent re-renders breaking the fields:
                    <Grid container spacing={1} key={access.id} style={{ marginBottom: 10 }}>
                      <Grid item sm={5} xs={5} style={{ marginTop: 3 }}>
                        <RoleSelector
                          name={`access.${index}.role`}
                          dataTestId={`access.${index}.roleSelector`}
                          onSelect={(value) => methods.setValue(`access.${index}.role`, value as Roles)}
                          required={true}
                        />
                      </Grid>
                      <Grid item sm={6} xs={6} style={{ marginTop: 3 }}>
                        <OrganizationSelector
                          name={`access.${index}.organization`}
                          dataTestId={`access.${index}.organizationSelector`}
                          organizationOptions={
                            organizationOptions && organizationOptions.length > 0 ? organizationOptions : []
                          }
                          onSelect={(value) => methods.setValue(`access.${index}.organization`, value)}
                          required={false}
                          disabled={false}
                        />
                      </Grid>
                      <Grid item sm={1} xs={1} style={{ marginTop: 15 }}>
                        <IconButton
                          color="primary"
                          aria-label="delete access"
                          data-testid={`delete-access-btn${index}`}
                          component="span"
                          onClick={() => {
                            remove(index);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  );
                })}
                <Grid container spacing={1}>
                  <Grid item sm={12} style={{ textAlign: 'center', marginBottom: 0 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        append({
                          role: Roles.Customer,
                          isDefault: false,
                          organization: ''
                        })
                      }
                      style={{ fontSize: 12, textTransform: 'none' }}
                      data-testid="addAccessButton"
                      startIcon={<AddCircleOutlined />}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Box>
          </React.Fragment>
        )}
        {allowChangePassword && (
          <div>
            <FormGroupDivider marginY={20} />
            <Box style={{ marginTop: 20 }}>
              <h4 style={{ marginTop: 10, marginBottom: 10 }}>Password</h4>
              <FormPasswordInput label="New Password" />
            </Box>
          </div>
        )}
        <Box display="flex" justifyContent="center" marginTop={2}>
          <FormCancelSubmitButtons
            mode={formMode}
            onCancel={handleCancel}
            showCancel={showCancel}
            isDisabled={isSubmitting || !isDirty}
            isSubmitting={isSubmitting}
            isFormValid={!!isValid}
          />
        </Box>
      </form>
    </FormProvider>
  );
};
