import { Box, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormMode, Messages } from '@efacity/common';
import {
  WithLoading,
  apiService,
  initialTeacherFormValues,
  UserFormValues,
  getMergedFormValues
} from '@efacity/frontend-shared';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { addServerErrors } from '@efacity/react-hook-form-mui';
import { PATHS, toPath } from '@efacity/routing';
import { useNavigate } from 'react-router-dom';
import { CenteredFormContainer } from '@efacity/frontend-next-shared/forms/server';
import { IdOrgIdParamTypes } from '@efacity/frontend-next-shared/utils';
import { UserSettingsForm, userProfileFields } from '@efacity/frontend-next/site-admin/users';

export interface InstructorFormContainerProps {
  mode: FormMode;
}

const InstructorFormContainer: React.FC<InstructorFormContainerProps> = ({ mode }) => {
  const { orgId, id: userId } = useParams<IdOrgIdParamTypes>();
  const navigate = useNavigate();

  const [isUserLoading, setIsUserLoading] = useState(false);
  const [userFormValues, setUserFormValues] = useState(initialTeacherFormValues);

  const getUser = useCallback(async () => {
    try {
      setIsUserLoading(true);
      const { data } = await apiService.get<UserFormValues>(`/users/${orgId}/teacher/${userId}`);
      setUserFormValues(getMergedFormValues(initialTeacherFormValues, data));
      setIsUserLoading(false);
    } catch (error) {
      setIsUserLoading(false);
      showNotification(false, error.message || Messages.FailedGetInstructor);
    }
  }, [orgId, userId]);

  useEffect(() => {
    if (mode === FormMode.Edit) {
      getUser();
    } else {
      setUserFormValues({ ...initialTeacherFormValues, access: [] });
    }
  }, [getUser, mode]);

  const handleSubmit = async (
    formValues: UserFormValues,
    setError: (fieldName: keyof UserFormValues, error: { type: string; message: string }) => void,
    forceAddTeacher = false
  ) => {
    const instructorActionSuccessful = () => {
      navigate(toPath(PATHS.instructors, { orgId: orgId }));
    };

    const instructorActionFailure = (error) => {
      const { data: errorData } = error.response;
      if (errorData.validationErrors) {
        addServerErrors<UserFormValues>(errorData.validationErrors, setError);
      } else {
        showNotification(false, error.response?.data?.message || Messages.FailedUpdateInstructor, true);
      }
    };

    if (mode === FormMode.Edit) {
      return apiService
        .patch<{ message: string }>(`/users/${orgId}/teacher/${userId}`, formValues)
        .then(({ data }) => {
          instructorActionSuccessful();
        })
        .catch((error) => {
          instructorActionFailure(error);
        });
    } else {
      return apiService
        .post<{ message: string }>(`/users/${orgId}/teachers`, { ...formValues, forceAddTeacher })
        .then(({ data }) => {
          instructorActionSuccessful();
        })
        .catch((error) => {
          instructorActionFailure(error);
        });
    }
  };

  const handleCancel = () => {
    navigate(toPath(PATHS.instructors, { orgId: orgId }));
  };

  return (
    <div>
      <CenteredFormContainer>
        <Box marginBottom={4}>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            {mode === FormMode.Edit ? 'Edit Instructor' : 'Add Instructor'}
            <hr style={{ height: '2', width: 'auto', margin: 0 }} />
          </Typography>
        </Box>
        <WithLoading isLoading={isUserLoading} message={'Loading instruuctor settings...'} style={{ width: '100%' }}>
          <UserSettingsForm
            initialFormValues={userFormValues}
            organizationOptions={[]}
            allowHandleAccess={false}
            allowChangeEmail={mode === FormMode.Add}
            allowChangePassword={false}
            handleSubmit={handleSubmit}
            isSubmitDisabled={isUserLoading}
            handleCancel={handleCancel}
            showCancel={true}
            userModelFields={userProfileFields}
            formMode={mode}
          />
        </WithLoading>
      </CenteredFormContainer>
    </div>
  );
};

export default InstructorFormContainer;
