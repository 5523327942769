import { FormMode, Media, Messages, convertUtcToZonedTime } from '@efacity/common';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { apiService, getMergedFormValues } from '@efacity/frontend-shared';
import { useEffect, useState } from 'react';
import { useOrganizationRegionsOptions } from '../../hooks/useOrganizationRegionsOptions';
import { JobPostingBackendValues, jobPostingInitialFormValues } from './jobPostingFormValues';

export const useJobPostingLoader = (orgId: string, jobId: string, formMode: FormMode) => {
  const {
    organizationRegions: { defaultCurrency: currency, country, timeZone, isLoading }
  } = useOrganizationRegionsOptions(orgId);

  const [jobState, setJobState] = useState({
    jobFormValues: jobPostingInitialFormValues,
    isLoading: true
  });

  useEffect(() => {
    const getDefaultJobState = () => {
      setJobState((jobFormState) => ({
        jobFormValues: {
          ...jobPostingInitialFormValues,
          currency: currency,
          country: country,
          timeZone: timeZone
        },
        isLoading: isLoading
      }));
    };
    const getJobById = async (orgId: string) => {
      setJobState({
        jobFormValues: jobPostingInitialFormValues,
        isLoading: true
      });

      try {
        const { data } = await apiService.get<JobPostingBackendValues>(`/org/${orgId}/job/${jobId}`);
        const job = {
          ...data,
          description: JSON.parse(data.description),
          expiresOn: convertUtcToZonedTime(new Date(data.expiresOn), data.timeZone)
        };

        const media: Media[] = job?.media?.map((mediaUrl: string) => ({
          link: mediaUrl
        }));

        setJobState((jobFormState) => ({
          ...jobFormState,
          jobFormValues: {
            ...getMergedFormValues(jobPostingInitialFormValues, job),
            media: media
          },
          isLoading: false
        }));
      } catch (error) {
        showNotification(false, error.message || Messages.FailedGetJobPostings, true);
        setJobState({
          jobFormValues: jobPostingInitialFormValues,
          isLoading: false
        });
      }
    };

    if (formMode === FormMode.Add) {
      getDefaultJobState();
    } else {
      getJobById(orgId);
    }
  }, [orgId, jobId, formMode, isLoading, country, currency, timeZone]);

  return {
    jobState
  };
};
