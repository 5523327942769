import { Column } from '@tanstack/react-table';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { MouseEvent, useState } from 'react';
import { IconButton, InputAdornment } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { monthDayYearDateFormat } from '@efacity/common';
import { textFieldSizes } from './styles';

export const DateColumnFilter = <TableData,>({ column }: { column: Column<TableData, unknown> }) => {
  const defaultValue = (column.getFilterValue() as Date) ?? null;
  const [tempValue, setTempValue] = useState<Date | null>(defaultValue);

  const handleFilterAccepted = (newFilterValue: Date | null) => {
    column.setFilterValue(newFilterValue);
  };

  const handleClearDate = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setTempValue(null);
    column.setFilterValue(null);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileDatePicker
        value={tempValue}
        onChange={setTempValue}
        format={monthDayYearDateFormat}
        onAccept={handleFilterAccepted}
        slotProps={{
          textField: {
            variant: 'outlined',
            size: 'small',
            style: { fontSize: '14px', backgroundColor: 'white' },
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="clear date" size="small" onClick={handleClearDate} edge="end">
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
              sx: textFieldSizes
            }
          }
        }}
      />
    </LocalizationProvider>
  );
};
