import React, { ChangeEvent, FC } from 'react';
import { Button, ButtonProps, FormHelperText } from '@mui/material';

const uploadFileBtnStyles = {
  input: {
    display: 'none'
  },
  btn: {
    textTransform: 'none'
  } as React.CSSProperties,
  helperText: {
    padding: '6px 8px'
  }
};

export interface UploadFileButtonProps {
  id: string;
  onFilesSelected: (event: ChangeEvent<HTMLInputElement>) => void;
  buttonProps?: ButtonProps;
  style?: React.CSSProperties;
  acceptableMimeTypes?: string;
  sizeLimitInBytes?: number;
  disabled?: boolean;
  children: React.ReactNode;
  oneLineDisplay?: boolean;
}

export const UploadFileButton: FC<UploadFileButtonProps> = ({
  id,
  children,
  acceptableMimeTypes,
  onFilesSelected,
  style,
  sizeLimitInBytes,
  disabled,
  oneLineDisplay = false
}) => {
  return (
    <div style={oneLineDisplay ? { display: 'flex', alignItems: 'center' } : {}}>
      <input
        accept={acceptableMimeTypes || 'image/*'}
        id={id}
        type="file"
        onChange={onFilesSelected}
        style={uploadFileBtnStyles.input}
        disabled={disabled}
      />
      <label htmlFor={id}>
        <Button color="primary" component="span" style={{ ...uploadFileBtnStyles.btn, ...style }} disabled={disabled}>
          {children}
        </Button>
      </label>
      {sizeLimitInBytes && (
        <FormHelperText style={uploadFileBtnStyles.helperText}>
          (Allowed formats: .PNG, .JPG, .SVG; Up to {sizeLimitInBytes / Math.pow(2, 20)} mb)
        </FormHelperText>
      )}
    </div>
  );
};

export default UploadFileButton;
