import React, { useRef } from 'react';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import { useParams } from 'react-router-dom';
import { useMessages } from './useMessages';
import { PageTitle } from '@efacity/react-next-sc';
import { Table, TableRef } from '@efacity/table';
import { useMessagesColumns } from './useMessagesColumns';
import { Message } from '@efacity/frontend-shared';

const MessagesContainer: React.FC = () => {
  const { orgId } = useParams<OrgIdParamType>();
  const tableRef = useRef<TableRef<Message>>(null);

  const { messagesState, getMessages, resendMessage } = useMessages(orgId);

  const resendMessageAndReload = async (messageId: string) => {
    try {
      await resendMessage(messageId);
    } finally {
      tableRef.current?.refetchTableData();
    }
  };

  const columns = useMessagesColumns(
    orgId,
    messagesState['timeZone'],
    resendMessageAndReload,
    messagesState.processingMessageId
  );
  const dataUrl = `/org/${orgId}/messages`;

  return (
    <div>
      <PageTitle title="Messages" />
      <Table<Message>
        tableRef={tableRef}
        dataFetcherUrl={dataUrl}
        columnDefs={columns}
        dataFetcher={getMessages}
        noDataText="No Messages..."
        initialSortBy={[{ id: 'name', desc: false }]}
      />
    </div>
  );
};

export default MessagesContainer;
