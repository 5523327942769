import React, { useEffect } from 'react';
import { WithLoading, apiService, paymentPlatformFormValuesValidationSchema } from '@efacity/frontend-shared';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { FormPageContainer } from '@efacity/frontend-next-shared/forms/server';
import { FormCancelSubmitButtons } from '@efacity/frontend-next-shared/forms';
import { PATHS, toPath } from '@efacity/routing';
import { useNavigate } from 'react-router-dom';
import { Activity, FormMode, isEmptyObject, Messages, PaymentPlatformValues } from '@efacity/common';
import { useParams } from 'react-router-dom';
import { IdParamTypes } from '@efacity/frontend-next-shared/utils';
import { usePaymentPlatformFormValues } from '@efacity/frontend-next/site-admin/payment-platforms';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { addServerErrors, CurrencyInput, defaultRHFSetValueOptions, FormTextInput } from '@efacity/react-hook-form-mui';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { PageTitle } from '@efacity/react-next-sc';

export interface PaymentPlatformFormProps {
  mode: FormMode;
}

const PaymentPlatformForm: React.FC<PaymentPlatformFormProps> = ({ mode }) => {
  const navigate = useNavigate();

  const { id: stripeAccountId } = useParams<IdParamTypes>();
  const { paymentAccountFormState } = usePaymentPlatformFormValues(stripeAccountId, mode);

  const methods = useForm<PaymentPlatformValues>({
    resolver: yupResolver(paymentPlatformFormValuesValidationSchema),
    defaultValues: paymentAccountFormState.paymentAccountFormValue,
    mode: 'onBlur'
  });
  const { isValid, isDirty, isSubmitting } = methods.formState;

  useEffect(
    () => {
      methods.reset(paymentAccountFormState.paymentAccountFormValue);
    } /* eslint-disable-next-line react-hooks/exhaustive-deps */,
    [paymentAccountFormState.isLoading]
  );

  const handleCancelClick = () => {
    navigate(toPath(PATHS.paymentPlatforms));
  };

  const paymentAccountActionSuccessful = (message: string) => {
    showNotification(true, message);
    navigate(PATHS.paymentPlatforms);
  };

  const updatePaymentAccountAction = async (formValues: PaymentPlatformValues) => {
    return apiService
      .patch<{ activity: Activity; message: string }>(`/paymentPlatforms/account/${stripeAccountId}`, formValues)
      .then(({ data }) => {
        paymentAccountActionSuccessful(data.message || Messages.PaymentAccountUpdated);
      })
      .catch((error) => {
        const errorResponse = error.response.data;

        if (typeof errorResponse.validationErrors === 'object' && !isEmptyObject(errorResponse.validationErrors)) {
          addServerErrors<PaymentPlatformValues>(errorResponse.validationErrors, methods.setError);
        } else {
          showNotification(false, errorResponse.message || Messages.FailedUpdateStripeAccount, true);
        }
      });
  };

  const addStripeAccountAction = async (formValues: PaymentPlatformValues) => {
    return apiService
      .post<{ message: string }>(`/paymentPlatforms/account`, formValues)
      .then(({ data }) => {
        paymentAccountActionSuccessful(data.message || Messages.PaymentAccountCreated);
      })
      .catch((error) => {
        const errorResponse = error.response.data;

        if (typeof errorResponse.validationErrors === 'object' && !isEmptyObject(errorResponse.validationErrors)) {
          addServerErrors<PaymentPlatformValues>(errorResponse.validationErrors, methods.setError);
        } else {
          showNotification(false, errorResponse.message || Messages.FailedAddStripeAccount, true);
        }
      });
  };

  const handleSubmit = async (formValues: PaymentPlatformValues) => {
    if (mode === FormMode.Edit) {
      await updatePaymentAccountAction(formValues);
    } else {
      await addStripeAccountAction(formValues);
    }
  };

  return (
    <div>
      <PageTitle title={mode === FormMode.Edit ? 'Edit Payment Platform' : 'Add Payment Platform'} underlined />
      <FormPageContainer>
        <WithLoading isLoading={paymentAccountFormState.isLoading} message={'Loading payment platform details...'}>
          <FormProvider {...methods}>
            <form noValidate onSubmit={methods.handleSubmit(handleSubmit)}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <FormTextInput label="Account Name" name="name" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CurrencyInput label="Currency" name="stripePlatformAccount.currencyCode" />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={methods.watch('stripePlatformAccount.isEfacity')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          methods.setValue(
                            'stripePlatformAccount.isEfacity',
                            event.target.checked,
                            defaultRHFSetValueOptions
                          );
                        }}
                        name="stripePlatformAccount.isEfacity"
                      />
                    }
                    label="Efacity Default Account"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextInput
                    label="Stripe Secret Key"
                    name="stripePlatformAccount.stripeSecretKey"
                    type="password"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextInput label="Stripe Publishable Key" name="stripePlatformAccount.stripePublishableKey" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextInput label="Stripe Client Id" name="stripePlatformAccount.stripeClientId" />
                </Grid>
              </Grid>
              <FormCancelSubmitButtons
                isFormValid={isValid && isDirty}
                mode={mode}
                isSubmitting={isSubmitting}
                onCancel={handleCancelClick}
              />
            </form>
          </FormProvider>
        </WithLoading>
      </FormPageContainer>
    </div>
  );
};

export default PaymentPlatformForm;
