import { useCallback, useEffect, useState } from 'react';
import { apiService } from '@efacity/react-next-sc';
import {
  AddressForm,
  CountryCode,
  isEmptyObject,
  Messages,
  FormMode,
  generateShortAddress,
  Address
} from '@efacity/common';
import { AddressFormValues } from '../components/AddressForm';
import { addServerErrors } from '@efacity/react-hook-form-mui';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { getMergedFormValues } from '@efacity/frontend-shared';

const sortByAddressesByName = (address1: AddressForm, address2: AddressForm) => {
  return generateShortAddress(address1) > generateShortAddress(address2) ? 1 : -1;
};

export interface AddressesState {
  addresses: AddressForm[];
  defaultCountry?: CountryCode;
  isLoading: boolean;
}

export const initialAddressFormValues: AddressFormValues = {
  _id: '',
  country: CountryCode.USA,
  address: '',
  city: '',
  state: '',
  zip: '',
  mapLink: ''
};

export const useAddressesLoader = (url: string) => {
  const [addressesState, setAddressesState] = useState<AddressesState>({
    addresses: [],
    defaultCountry: CountryCode.USA,
    isLoading: true
  });

  const getAddresses = useCallback(() => {
    const setAddresses = (addresses: Address[], isLoading: boolean, defaultCountry: CountryCode = null) => {
      setAddressesState({
        addresses: addresses.sort(sortByAddressesByName),
        isLoading,
        defaultCountry
      });
    };

    const showErrorNotification = (message: string) => {
      setAddresses([], false);
      showNotification(false, message || 'Error', true);
    };

    apiService()
      .get<{ defaultCountry: CountryCode; data: Address[] }>(url)
      .then(
        (result) => {
          return setAddresses(result.data.data, false, result.data.defaultCountry);
        },
        (error) => {
          setAddressesState((addressesState) => ({
            ...addressesState,
            isLoading: false
          }));
          showErrorNotification(error.response?.data?.message || 'Failed to get addresses');
        }
      );
  }, [url]);

  const deleteAddress = async (addressId: string, reloadData: boolean = true) => {
    try {
      await apiService().delete<{ message: string }>(`${url}/${addressId}`);
      if (reloadData) getAddresses();
    } catch (error) {
      showNotification(false, error.response?.data?.message || Messages.FailedDeleteOrganizationAddress, true);
    }
  };

  const updateAddress = async (
    formValues: AddressFormValues,
    setError: (name, error, options?) => void,
    addressId: string,
    handleCloseModal: () => void,
    reloadData: boolean = true
  ) => {
    const address = { ...formValues };
    return apiService()
      .patch<{ message: string }, AddressFormValues>(`${url}/${addressId}`, address)
      .then(({ data }) => {
        if (reloadData) getAddresses();
        handleCloseModal();
      })
      .catch((error) => {
        const errorResponse = error.response.data;
        if (typeof errorResponse.validationErrors === 'object' && !isEmptyObject(errorResponse.validationErrors)) {
          addServerErrors<AddressFormValues>(errorResponse.validationErrors, setError);
        } else {
          showNotification(false, errorResponse.message || Messages.FailedUpdateOrganizationAddress, true);
        }
      });
  };

  const addAddress = async (
    formValues: AddressFormValues,
    setError: (name, error, options?) => void,
    handleCloseModal: () => void,
    reloadData: boolean = true
  ) => {
    const address = { ...formValues };
    return apiService()
      .post<{ data: AddressForm; message: string }>(url, address)
      .then(({ data }) => {
        const newAddress = data.data;
        if (reloadData) getAddresses();
        handleCloseModal();
        return newAddress;
      })
      .catch((error) => {
        const errorResponse = error.response.data;
        if (typeof errorResponse.validationErrors === 'object' && !isEmptyObject(errorResponse.validationErrors)) {
          addServerErrors<AddressFormValues>(errorResponse.validationErrors, setError);
        } else {
          showNotification(false, errorResponse.message || Messages.FailedCreateOrganizationAddress, true);
        }
      });
  };

  return [
    {
      addressesState,
      setAddressesState
    },
    { getAddresses, deleteAddress, updateAddress, addAddress }
  ];
};

export const useAddressFormValues = (formMode: FormMode, orgId: string = null, addressId: string = null) => {
  const [addressFormState, setAddressFormState] = useState({
    addressFormValues: initialAddressFormValues,
    isLoading: false
  });

  useEffect(() => {
    const getAddressById = async (orgId: string) => {
      setAddressFormState({
        addressFormValues: initialAddressFormValues,
        isLoading: true
      });

      try {
        const { data } = await apiService().get<AddressForm>(`/org/${orgId}/addresses/${addressId}`);
        const address = { ...data };

        setAddressFormState((addressFormState) => ({
          ...addressFormState,
          addressFormValues: getMergedFormValues(initialAddressFormValues, address),
          isLoading: false
        }));
      } catch (error) {
        showNotification(false, error?.message || 'Failed to get address info', true);
        setAddressFormState({
          addressFormValues: initialAddressFormValues,
          isLoading: true
        });
      }
    };

    if (formMode === FormMode.Add) {
      setAddressFormState((addressFormState) => ({
        ...addressFormState,
        addressFormValues: initialAddressFormValues
      }));
    } else {
      getAddressById(orgId);
    }
  }, [orgId, addressId, formMode]);

  return {
    addressFormState: addressFormState
  };
};
