import React, { useRef, useState } from 'react';
import { InstanceActivity, Messages, PaymentPlatform } from '@efacity/common';
import { apiService, ConfirmationModal } from '@efacity/frontend-shared';
import StartConnectModal from '../../../components/StartConnectModal/StartConnectModal';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { Table as TableNew, TableRef, usePaginatedLoaderWithAdditionalInfo } from '@efacity/table';
import { usePaymentPlatformsColumns } from './usePaymentPlatformsColumns';

export interface PaymentPlatformsTableProps {
  instanceActivity: InstanceActivity;
}

const PaymentPlatformsTable: React.FC<PaymentPlatformsTableProps> = ({ instanceActivity }) => {
  const tableRef = useRef<TableRef<PaymentPlatform>>(null);
  const dataFetchUrl = `/paymentPlatforms/${instanceActivity}`;
  const { paginatedDataFetcher } = usePaginatedLoaderWithAdditionalInfo<PaymentPlatform>();

  const defaultConfirmationModalState = {
    isOpen: false,
    isOperating: false,
    message: '',
    name: '',
    orgId: null,
    transferId: null,
    onConfirm: null
  };
  const [confirmationModalState, setConfirmationModalState] = useState(defaultConfirmationModalState);

  const defaultConnectModalState = {
    isOpen: false,
    isConnect: true,
    isOperating: false,
    id: null,
    stripeUserId: null,
    message: '',
    connectName: ''
  };
  const [connectModalState, setConnectModalState] = useState(defaultConnectModalState);

  // Connect between payment platforms temporary disabled
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onConnectCLick = (paymentPlatformId: string, name: string) => {
    setConnectModalState({
      isOpen: true,
      isConnect: true,
      isOperating: false,
      id: paymentPlatformId,
      stripeUserId: null,
      message: `You are about to Connect to ${name} Stripe Account.<br/>Please, login to Stripe account to be connected before click "Start" button.`,
      connectName: ''
    });
  };

  const onDisconnectCLick = (paymentPlatformId: string, stripeUserId: string) => {
    setConfirmationModalState({
      isOpen: true,
      isOperating: false,
      message:
        'You are about to Disconnect account from this Payment Platform.<br/>' +
        '<div style="display:flex;justify-content:center">Please, confirm.</div>',
      name: '',
      orgId: paymentPlatformId,
      transferId: stripeUserId,
      onConfirm: performStandartStripeDisconnect
    });
  };

  const performStandardStripeConnect = () => {
    localStorage.setItem('paymentPlatformId', connectModalState.id);
    localStorage.setItem('connectName', connectModalState.connectName);
    setConnectModalState({ ...connectModalState, isOperating: true });
    apiService.post(`/stripe/connect/forPlatform/${connectModalState.id}`, {}).then(
      (result: any) => {
        window.location.href = result.data;
      },
      (error) => {
        showNotification(false, error.response.data.message as string, true);
      }
    );
  };

  const performStandartStripeDisconnect = (currentConfirmationModalState) => {
    setConfirmationModalState({ ...currentConfirmationModalState, isOperating: true });
    apiService
      .post(
        `/stripe/connect/forPlatform/${currentConfirmationModalState.orgId}/disconnect/${currentConfirmationModalState.transferId}`,
        {}
      )
      .then(
        (result: any) => {
          setConfirmationModalState({ ...defaultConfirmationModalState });
          tableRef.current?.refetchTableData();
          showNotification(true, result.data.message as string, false);
        },
        (error) => {
          setConfirmationModalState({ ...currentConfirmationModalState, isOperating: false });
          showNotification(false, error.response.data.message as string, true);
        }
      );
  };

  const handleDeleteStripeAccountClicked = (accountId: string, name: string) => {
    setConfirmationModalState({
      isOpen: true,
      isOperating: false,
      message:
        `You are about to delete ${name} Payment Platform<br>` +
        '<div style="display:flex;justify-content:center">Please, confirm.</div>',
      name: '',
      orgId: accountId,
      transferId: null,
      onConfirm: handleDeleteStripeAccount
    });
  };

  const handleDeleteStripeAccount = (currentConfirmationModalState) => {
    setConfirmationModalState({ ...currentConfirmationModalState, isOperating: true });
    apiService.delete(`/paymentPlatforms/account/${currentConfirmationModalState.orgId}`).then(
      (result: any) => {
        setConfirmationModalState({ ...defaultConfirmationModalState });
        showNotification(true, (result.data.message as string) || Messages.PaymentAccountDeleted, false);
        tableRef.current?.refetchTableData();
      },
      (error) => {
        setConfirmationModalState({ ...currentConfirmationModalState, isOperating: false });
        showNotification(false, (error.response.data.message as string) || Messages.FailedDeletePaymentAccount, true);
      }
    );
  };

  const columnsNew = usePaymentPlatformsColumns(instanceActivity, onDisconnectCLick, handleDeleteStripeAccountClicked);

  return (
    <>
      <TableNew<PaymentPlatform>
        tableRef={tableRef}
        columnDefs={columnsNew}
        dataFetcherUrl={dataFetchUrl}
        dataFetcher={paginatedDataFetcher}
        noDataText={
          instanceActivity === InstanceActivity.active
            ? 'No current Stripe Accounts...'
            : 'No deleted Stripe Accounts...'
        }
        manualFiltering={false}
        manualSorting={true}
      />
      {connectModalState.isOpen && (
        <StartConnectModal
          open={connectModalState.isOpen}
          onClose={() => setConnectModalState({ ...defaultConnectModalState, isOpen: false })}
          onStart={performStandardStripeConnect}
          message={connectModalState.message}
          connectName={connectModalState.connectName}
          onNameChanged={(event) => setConnectModalState({ ...connectModalState, connectName: event.target.value })}
        />
      )}
      {confirmationModalState.isOpen && (
        <ConfirmationModal
          open={confirmationModalState.isOpen}
          isOperating={confirmationModalState.isOperating}
          message={confirmationModalState.message}
          onClose={() => setConfirmationModalState({ ...defaultConfirmationModalState })}
          onConfirm={() => confirmationModalState.onConfirm(confirmationModalState)}
        />
      )}
    </>
  );
};

export default PaymentPlatformsTable;
