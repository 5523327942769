import { Box } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { apiService, Center, ConfirmationModal } from '@efacity/frontend-shared';
import { PATHS, toPath } from '@efacity/routing';
import { IdOrgIdParamTypes } from '@efacity/frontend-next-shared/utils';
import StartConnectModal from '../../components/StartConnectModal/StartConnectModal';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { Table, TableRef, usePaginatedLoaderWithAdditionalInfo } from '@efacity/table';
import { useCentersColumns } from './useCentersColumns';
import { PageTitle } from '@efacity/react-next-sc';

export const CentersContainer: React.FC = () => {
  const { orgId } = useParams<IdOrgIdParamTypes>();
  const tableRef = useRef<TableRef<Center>>(null);
  const fetchDataUrl = `/org/${orgId}/schools/centers`;

  const { paginatedDataFetcher } = usePaginatedLoaderWithAdditionalInfo<Center>({});

  const defaultConnectModalState = {
    isOpen: false,
    isConnect: true,
    isOperating: false,
    id: null,
    name: '',
    message: '',
    connectName: ''
  };
  const [connectModalState, setConnectModalState] = useState(defaultConnectModalState);

  const onConnectCLick = (centerId: string, name: string) => {
    setConnectModalState({
      isOpen: true,
      isConnect: true,
      isOperating: false,
      id: centerId,
      name: name,
      message: `You are about to Connect ${name} to this school.<br/>Please, login to ${name} Stripe account before click "Start" button.`,
      connectName: ''
    });
  };

  const performStandardStripeConnect = () => {
    setConnectModalState({ ...connectModalState, isOperating: true });
    localStorage.setItem('connectOrgId', orgId);
    localStorage.setItem('centerId', connectModalState.id);
    localStorage.setItem('connectName', connectModalState.connectName);

    apiService
      .post(`/stripe/connect/fromSchool/${orgId}/${connectModalState.id}`, { name: connectModalState.connectName })
      .then(
        (result: any) => {
          window.location.href = result.data;
        },
        (error) => {
          showNotification(false, error.response.data.message as string, true);
        }
      );
  };

  const performStandartStripeDisconnect = (currentConfirmationModalState) => {
    apiService.post(`/stripe/connect/fromSchool/${orgId}/disconnect/${currentConfirmationModalState.orgId}`, {}).then(
      (result: any) => {
        setConfirmationModalState({ ...defaultConfirmationModalState });
        tableRef.current?.refetchTableData();
        showNotification(true, result.data.message as string, false);
      },
      (error) => {
        setConfirmationModalState({ ...currentConfirmationModalState, isOperating: false });
        showNotification(false, error.response.data.message as string, true);
      }
    );
  };

  const defaultConfirmationModalState = {
    isOpen: false,
    isOperating: false,
    message: '',
    name: '',
    orgId: null,
    transferId: null,
    onConfirm: null
  };
  const [confirmationModalState, setConfirmationModalState] = useState(defaultConfirmationModalState);

  const onDisconnectCLick = (centerId: string, name: string) => {
    setConfirmationModalState({
      isOpen: true,
      isOperating: false,
      message:
        'You are about to Disconnect Efacity from this school.<br/>' +
        '<div style="display:flex;justify-content:center">Please, confirm.</div>',
      name: '',
      orgId: centerId,
      transferId: null,
      onConfirm: performStandartStripeDisconnect
    });
  };

  const onDeleteCenterCLick = (centerId: string, name: string) => {
    setConfirmationModalState({
      isOpen: true,
      isOperating: false,
      message:
        `You are about to delete ${name} center.<br/>` +
        '<div style="display:flex;justify-content:center">Please, confirm.</div>',
      name: '',
      orgId: centerId,
      transferId: null,
      onConfirm: performDeleteCenter
    });
  };

  const performDeleteCenter = (currentConfirmationModalState) => {
    setConfirmationModalState({ ...currentConfirmationModalState, isOperating: true });
    apiService.delete(`/org/${orgId}/schools/centers/${currentConfirmationModalState.orgId}`).then(
      (result: any) => {
        setConfirmationModalState({ ...defaultConfirmationModalState });
        tableRef.current?.refetchTableData();
        showNotification(true, result.data.message as string, false);
      },
      (error) => {
        setConfirmationModalState({ ...currentConfirmationModalState, isOpen: false, isOperating: false });
        showNotification(false, error.response.data.message as string, true);
      }
    );
  };

  const addButtonLink = orgId ? toPath(PATHS.addCenter, { orgId: orgId }) : PATHS.addCenter;

  const centerColumns = useCentersColumns(
    orgId,
    onConnectCLick,
    onDisconnectCLick,
    onDeleteCenterCLick,
    connectModalState.isOperating
  );

  return (
    <Box>
      <PageTitle title="Centers" addButtonLink={addButtonLink} />
      <Table
        tableRef={tableRef}
        dataFetcherUrl={fetchDataUrl}
        columnDefs={centerColumns}
        dataFetcher={paginatedDataFetcher}
        noDataText="No Centers..."
        manualFiltering={false}
        manualSorting={false}
        initialSortBy={[{ id: 'name', desc: false }]}
      />
      {connectModalState.isOpen && (
        <StartConnectModal
          open={connectModalState.isOpen}
          onClose={() => setConnectModalState({ ...defaultConnectModalState, isOpen: false })}
          onStart={performStandardStripeConnect}
          message={connectModalState.message}
          connectName={connectModalState.connectName}
          onNameChanged={(event) => setConnectModalState({ ...connectModalState, connectName: event.target.value })}
        />
      )}
      {confirmationModalState.isOpen && (
        <ConfirmationModal
          open={confirmationModalState.isOpen}
          isOperating={confirmationModalState.isOperating}
          message={confirmationModalState.message}
          onClose={() => setConfirmationModalState({ ...defaultConfirmationModalState })}
          onConfirm={() => confirmationModalState.onConfirm(confirmationModalState)}
        />
      )}
    </Box>
  );
};
