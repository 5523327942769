import React, { useRef, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import { PATHS, toPath } from '@efacity/routing';
import { EnrollmentApplicationFrontend, ConfirmationModal } from '@efacity/frontend-shared';
import { PageTitle } from '@efacity/react-next-sc';
import { useEnrollmentsLoader } from './useEnrollmentsLoader';
import { IconButton, Link } from '@mui/material';
import {
  ApplicationStatus,
  ApplicationStatusConnector,
  convertUtcToZonedTime,
  joinNames,
  moneyWithCurrencyCodeFormatter,
  nowUTC,
  User,
  formatStringToMonthDayYearDate
} from '@efacity/common';
import { smallPublishButtonStyle } from '../../components/Buttons/SessionPublishButton';
import { isBefore } from 'date-fns';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import PageLinkWithCopier from '../../components/PageLinkWithCopier/PageLinkWithCopier';
import {
  Table as TableNew,
  TableRef,
  TextColumnFilter,
  usePaginatedLoaderWithAdditionalInfo,
  EnrollmentApplicationStatusFilter as EnrollmentApplicationStatusFilterNew
} from '@efacity/table';
import { createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper<EnrollmentApplicationWithUser>();

export interface EnrollmentApplicationWithUser extends EnrollmentApplicationFrontend {
  user: User;
}

const EnrollmentsContainer: React.FC = () => {
  const { orgId } = useParams<OrgIdParamType>();
  const tableRef = useRef<TableRef<EnrollmentApplicationWithUser>>(null);
  const dataFetcherUrl = `/org/${orgId}/enrollments`;
  const { dataState, paginatedDataFetcher } = usePaginatedLoaderWithAdditionalInfo<EnrollmentApplicationWithUser>({
    dateTimeFields: ['submittedOn', 'approvedAt']
  });
  const timeZone = dataState['timeZone'] || 'America/New_York';

  const { deleteEnrollmentApplication } = useEnrollmentsLoader(orgId);

  const defaultConfirmationModalState = {
    isOpen: false,
    isOperating: false,
    message: '',
    onConfirm: null
  };
  const [confirmationModalState, setConfirmationModalState] = useState(defaultConfirmationModalState);

  const onDeleteEnrollmentCLick = (enrollmentId: string, name: string) => {
    setConfirmationModalState({
      isOpen: true,
      isOperating: false,
      message:
        `You are about to delete Enrollment Application from ${name}.<br/>` +
        '<div style="display:flex;justify-content:center">Please, confirm.</div>',
      onConfirm: () => handleDeleteEnrollmentApplication(enrollmentId)
    });
  };

  const handleDeleteEnrollmentApplication = async (enrollmentId: string) => {
    setConfirmationModalState((state) => {
      return { ...state, isOperating: true };
    });
    await deleteEnrollmentApplication(enrollmentId);
    tableRef.current?.refetchTableData();
    setConfirmationModalState({ ...defaultConfirmationModalState });
  };

  const columns = [
    columnHelper.accessor('user.fullName', {
      header: 'User Name',
      id: 'user.fullName',
      enableSorting: true,
      enableColumnFilter: true,
      cell: ({ row }) => row.original.user.fullName,
      meta: {
        filter: (column) => <TextColumnFilter column={column} />
      }
    }),
    columnHelper.accessor('user.email', {
      header: 'User Email',
      id: 'user.email',
      enableSorting: true,
      enableColumnFilter: true,
      cell: ({ row }) => row.original.user.email,
      meta: {
        filter: (column) => <TextColumnFilter column={column} />
      }
    }),
    {
      header: 'Student Name',
      id: 'enrollmentForm.student.firstName',
      enableSorting: false,
      enableColumnFilter: false,
      cell: ({ row }) => (
        <Link
          component={NavLink}
          to={toPath(PATHS.studentEnrollment, {
            orgId: orgId,
            enrollmentId: row.original._id
          })}
          underline={'none'}
        >
          {row.original.enrollmentForm.student?.firstName?.length > 0 ||
          row.original.enrollmentForm.student?.lastName?.length > 0
            ? joinNames(row.original.enrollmentForm.student.firstName, row.original.enrollmentForm.student.lastName)
            : '[no - name]'}
        </Link>
      )
    },
    columnHelper.accessor('submittedOn', {
      header: 'Submitted On',
      enableSorting: false,
      enableColumnFilter: false,
      cell: ({ getValue }) =>
        getValue() ? formatStringToMonthDayYearDate(convertUtcToZonedTime(getValue(), timeZone).toISOString()) : '-'
    }),
    columnHelper.accessor('approvedAt', {
      header: 'Approved On',
      enableSorting: false,
      enableColumnFilter: false,
      cell: ({ getValue }) =>
        getValue() ? formatStringToMonthDayYearDate(convertUtcToZonedTime(getValue(), timeZone).toISOString()) : '-'
    }),
    {
      header: 'Price',
      id: 'price',
      accessor: 'price',
      enableSorting: false,
      enableColumnFilter: false,
      cell: ({ row }) => moneyWithCurrencyCodeFormatter(row.original.price, row.original.currencyCode),
      meta: {
        headerSx: { textAlign: 'right', paddingRight: '15px' },
        columnSx: { textAlign: 'right', width: '115px', maxWidth: '115px' }
      }
    },
    columnHelper.accessor('applicationStatus', {
      header: 'Status',
      enableSorting: true,
      enableColumnFilter: true,
      cell: ({ getValue }) => ApplicationStatusConnector[getValue()],
      meta: {
        filter: (column) => <EnrollmentApplicationStatusFilterNew column={column} />,
        headerSx: { width: '160px', maxWidth: '160px' },
        columnSx: { width: '160px', maxWidth: '160px' }
      }
    }),
    {
      header: '',
      id: 'actions-cell',
      enableSorting: false,
      enableColumnFilter: false,
      cell: ({ row }) =>
        row.original.applicationStatus !== ApplicationStatus.Approved ? (
          <IconButton
            color="primary"
            aria-label="delete application"
            component="span"
            style={smallPublishButtonStyle}
            disabled={isBefore(new Date(row.original.expiresOn), new Date(nowUTC()))}
            onClick={() =>
              onDeleteEnrollmentCLick(
                row.original._id,
                joinNames(row.original.enrollmentForm.customer.firstName, row.original.enrollmentForm.customer.lastName)
              )
            }
          >
            <DeleteIcon />
          </IconButton>
        ) : (
          <> </>
        ),
      meta: {
        headerSx: { width: '75px', maxWidth: '75px' },
        columnSx: { width: '75px', maxWidth: '75px' }
      }
    }
  ];

  return (
    <div>
      <PageTitle title="Enrollment Applications 111" />
      <PageLinkWithCopier
        name="Enrollment page"
        path={toPath(PATHS.enrollment, { orgId: orgId })}
        tooltipText="Copy Enrollment page link to share"
      />
      <TableNew<EnrollmentApplicationWithUser>
        tableRef={tableRef}
        dataFetcherUrl={dataFetcherUrl}
        columnDefs={columns}
        dataFetcher={paginatedDataFetcher}
        noDataText="No enrollments..."
        initialSortBy={[{ id: 'name', desc: false }]}
      />
      {confirmationModalState.isOpen && (
        <ConfirmationModal
          open={confirmationModalState.isOpen}
          isOperating={confirmationModalState.isOperating}
          message={confirmationModalState.message}
          onClose={() => setConfirmationModalState({ ...defaultConfirmationModalState })}
          onConfirm={() => confirmationModalState.onConfirm(confirmationModalState)}
        />
      )}
    </div>
  );
};

export default EnrollmentsContainer;
