import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { MailingServer, Messages, SesEmailVerificationStatus } from '@efacity/common';
import Email from '@mui/icons-material/Email';
import { apiService, HorizontalField, CircularProgressButton } from '@efacity/frontend-shared';
import { useFormContext } from 'react-hook-form';
import { defaultRHFSetValueOptions, FormPasswordInput, FormTextInput } from '@efacity/react-hook-form-mui';
import { Button, CircularProgress } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import SendFromEmailModal from './SendFromEmailModal/SendFromEmailModal';
import { showNotification } from '@efacity/frontend-next-shared/notifications';

interface OrganizationMailingFormProps {
  orgId: string;
}

export const OrganizationMailingForm: React.FC<OrganizationMailingFormProps> = ({ orgId }) => {
  const [sendingTestEmail, setSendingTestEmail] = useState(false);

  const [isSendFromModalOpen, setIsSendFromModalOpen] = useState(false);

  const onChangeSendFromEmailCLick = (email) => {
    setIsSendFromModalOpen(true);
  };

  const methods = useFormContext();

  const sendTestEmail = async () => {
    setSendingTestEmail(true);
    try {
      const smtpSettings = methods.getValues('smtpSettings');
      await apiService.post(`/organizations/${orgId}/test-email`, smtpSettings);
      showNotification(true, Messages.TestEmailSent, true, 'Ok');
    } catch (error) {
      const errorResponse = error.response.data;
      showNotification(false, errorResponse.message, true);
    } finally {
      setSendingTestEmail(false);
    }
  };

  const confirmNewSendFromEmail = async (newEmail: string) => {
    methods.setValue('sendFromEmail', newEmail, defaultRHFSetValueOptions);
    setIsSendFromModalOpen(false);
    await verifyEmailIfAny();
  };

  const verifyEmailIfAny = async () => {
    const sendFromEmail = methods.watch('sendFromEmail');
    const mailingServer = methods.watch('mailingServer');
    if (mailingServer === MailingServer.awsSes) {
      await onVerifyEmail(sendFromEmail);
    }
  };

  const [isVerifying, setIsVerifying] = React.useState(false);
  const onVerifyEmail = async (newEmail: string) => {
    try {
      setIsVerifying(true);
      const { data } = await apiService.post<{ status: string }>(`/org/${orgId}/sendfrom-verification`, {
        email: newEmail
      });
      if (data.status === SesEmailVerificationStatus.emailSent) {
        showNotification(true, Messages.EmailVerificationSentSes, true, 'Ok');
      }
    } catch {
      showNotification(false, Messages.FailedSendEmailVerificationSes, true);
    } finally {
      setIsVerifying(false);
    }
  };

  const handleMailingServerClicked = async () => {
    const oldValue = methods.getValues('mailingServer');
    const newValue = oldValue === MailingServer.smtp ? MailingServer.awsSes : MailingServer.smtp;
    methods.setValue('mailingServer', newValue, defaultRHFSetValueOptions);
    if (newValue) {
      await methods.trigger('smtpSettings.server');
      await methods.trigger('smtpSettings.port');
      await methods.trigger('smtpSettings.user');
      await methods.trigger('smtpSettings.password');
    }

    await verifyEmailIfAny();
  };

  const handleSslClicked = () => {
    methods.setValue('smtpSettings.ssl', !methods.getValues('smtpSettings.ssl'), defaultRHFSetValueOptions);
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <HorizontalField>
            <FormTextInput label="Send From E-mail" name="sendFromEmail" type="email" disabled={true} />
            <Button
              variant="contained"
              onClick={() => onChangeSendFromEmailCLick(methods.watch('sendFromEmail'))}
              startIcon={<AutorenewIcon style={{ marginRight: 10 }} />}
              style={{ minWidth: 185 }}
              name="change-send-from-email"
            >
              <div style={{ paddingRight: 10 }}>Change</div>
            </Button>
          </HorizontalField>
        </Grid>
        <Grid item xs={12}>
          <div style={{ height: 20, display: 'flex', justifyContent: 'center' }}>
            {isVerifying && (
              <>
                <CircularProgress variant="indeterminate" size={17} style={{ marginTop: 2 }} />
                <Typography color="primary" style={{ margin: 0, marginLeft: 20, padding: 0 }}>
                  Verifying email...
                </Typography>
              </>
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">Mail Server</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={methods.getValues('mailingServer') === MailingServer.smtp}
                name="mailingServer"
                onChange={handleMailingServerClicked}
                color="primary"
              />
            }
            label="Use own SMTP server"
          />
        </Grid>
        {methods.watch('mailingServer') === MailingServer.smtp && (
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <FormTextInput label="Server *" name="smtpSettings.server" />
              </Grid>
              <Grid item xs={4}>
                <FormTextInput label="Port *" name="smtpSettings.port" type="number" />
              </Grid>
              <Grid item xs={4} alignSelf="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={methods.watch('smtpSettings.ssl')}
                      name="smtpSettings.ssl"
                      onChange={handleSslClicked}
                      color="primary"
                    />
                  }
                  label="Use SSL"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextInput label="User *" name="smtpSettings.user" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormPasswordInput label="Password *" name="smtpSettings.password" />
              </Grid>
              <Grid item xs={12}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgressButton
                    type="button"
                    data-testid="add-note-btn"
                    progress={sendingTestEmail}
                    onClick={sendTestEmail}
                    variant="contained"
                    color="primary"
                    startIcon={<Email />}
                    size="small"
                    style={{ minWidth: 100, height: '100%' }}
                  >
                    Send Test Email
                  </CircularProgressButton>
                </div>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      {isSendFromModalOpen && (
        <SendFromEmailModal
          open={isSendFromModalOpen}
          email={methods.watch('sendFromEmail')}
          onClose={() => setIsSendFromModalOpen(false)}
          onConfirmEmail={confirmNewSendFromEmail}
        />
      )}
    </>
  );
};
