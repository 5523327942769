import React, { useMemo } from 'react';
import { adminSectionTableFontSize, LoginAudit } from '@efacity/frontend-shared';
import { loginStatusViewConnector } from '@efacity/common';
import DateCell from '../../components/DateCell';
import { createColumnHelper } from '@tanstack/react-table';
import { TextColumnFilter, DateColumnFilter, LoginAuditStatusFilter } from '@efacity/table';

const columnHelper = createColumnHelper<LoginAudit>();

export const useLoginAuditColumns = () => {
  return useMemo(() => {
    const columns = [
      columnHelper.accessor('login', {
        header: 'Login',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ getValue }) => <div>{getValue()}</div>,
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      }),
      columnHelper.accessor('loginTimestamp', {
        header: 'Time',
        id: 'loginTimestamp',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ getValue }) => (
          <div>
            <DateCell
              dateTime={new Date(getValue())}
              yearOnNewLine={false}
              wrap={true}
              style={{ ...adminSectionTableFontSize }}
            />
          </div>
        ),
        meta: {
          filter: (column) => <DateColumnFilter column={column} />,
          headerSx: { width: '120px', maxWidth: '120px' },
          columnSx: { width: '120px', maxWidth: '120px' }
        }
      }),
      columnHelper.accessor('fromIpAddress', {
        header: 'IP Address',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ getValue }) => <div>{getValue()}</div>,
        meta: {
          filter: (column) => <TextColumnFilter column={column} />,
          headerSx: { width: '120px', maxWidth: '120px' },
          columnSx: { width: '120px', maxWidth: '120px' }
        }
      }),
      columnHelper.accessor('status', {
        header: 'Status',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ getValue }) => <div>{loginStatusViewConnector[getValue()]}</div>,
        meta: {
          filter: (column) => <LoginAuditStatusFilter column={column} />,
          headerSx: { width: '120px', maxWidth: '120px' },
          columnSx: { width: '120px', maxWidth: '120px' }
        }
      }),
      columnHelper.accessor('failureReason', {
        header: 'Failure Reason',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ getValue }) => <div>{getValue()}</div>,
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      })
    ];

    return columns;
  }, []);
};
