import { addMinutes, isDate } from 'date-fns';
import { SortingState, ColumnFiltersState } from '@tanstack/react-table';

const isDateValue = (maybeDate: Date | unknown): maybeDate is Date => {
  return isDate(maybeDate);
};
export const removeDateOffsetFromFilters = (allFilters: ColumnFiltersState, dateFilterIds: string[]) => {
  return allFilters.map((filter) => {
    if (dateFilterIds.includes(filter.id) && isDateValue(filter.value)) {
      const dateOffset = filter.value.getTimezoneOffset();
      return {
        ...filter,
        value: addMinutes(filter.value, -dateOffset)
      };
    }

    return filter;
  });
};

export const getFiltersQueryParameters = (filters: ColumnFiltersState) => {
  return filters.reduce<Record<string, string>>((acc, { id, value }) => {
    acc[id] = value as string;
    return acc;
  }, {});
};

export const replaceUnderlinesInFilterStates = (filterState: ColumnFiltersState) => {
  return filterState.map((filter) => {
    return {
      ...filter,
      id: filter.id.replace(/_/g, '.')
    };
  });
};

export const replaceUnderlinesInSortingState = (sortingState: SortingState) => {
  return sortingState.map((sort) => {
    return {
      ...sort,
      id: sort.id.replace(/_/g, '.')
    };
  });
};
