import { Dialog, DialogContent } from '@mui/material';
import { FC, useState } from 'react';
import { apiService, Teacher } from '@efacity/frontend-shared';
import { ModalDialogTitle } from '@efacity/react-next-sc';
import { addServerErrors, defaultRHFSetValueOptions } from '@efacity/react-hook-form-mui';
import InstructorForm, { InstructorFormValues } from './InstructorForm';
import { Messages } from '@efacity/common';
import { SessionMainInfoFormValues } from '../../pages/SessionsContainer/SessionMainInfoForm/SessionFormValues';
import { showNotification } from '@efacity/frontend-next-shared/notifications';

export interface AddInstructorModalProps {
  open: boolean;
  toggleModalVisible: (state: boolean) => void;
  instructorOptions: Teacher[];
  orgId: string;
  setTeacherFieldValue: (fieldName: keyof SessionMainInfoFormValues, value: any, options?: any) => void | null;
  addTeacherOption: (teacher: Teacher) => void;
  fieldName: keyof SessionMainInfoFormValues;
}

const AddInstructorModal: FC<AddInstructorModalProps> = ({
  open,
  toggleModalVisible,
  orgId,
  instructorOptions,
  addTeacherOption,
  setTeacherFieldValue,
  fieldName
}) => {
  const [addingTeacher, setAddingTeacher] = useState(false);

  const handleClose = () => {
    if (!addingTeacher) {
      toggleModalVisible(false);
    }
  };

  const onSubmitTeacherForm = async (
    values: InstructorFormValues,
    setValue: (fieldName: keyof InstructorFormValues, value: any) => void,
    setError: (fieldName: keyof InstructorFormValues, value: any) => void
  ) => {
    setAddingTeacher(true);

    try {
      const request = await apiService.post<{ user: Teacher; message: string }>(`/users/${orgId}/teachers`, values);
      const { data } = request;
      setAddingTeacher(false);
      addTeacherOption(data.user);
      if (setTeacherFieldValue) {
        await setTeacherFieldValue(fieldName, data.user._id, defaultRHFSetValueOptions);
      }
      toggleModalVisible(false);
      if (request.status === 202) {
        showNotification(false, Messages.FailedPasswordEmailDueServerContactUs, true);
      }
    } catch (error) {
      setAddingTeacher(false);
      if (error?.validationErrors) {
        addServerErrors(error.validationErrors, setError);
      } else {
        if (error?.actionRequired) {
          setValue('forceAddTeacher', true);
        } else {
          showNotification(false, error?.response?.data?.message || Messages.FailedAddTeacher, true, 'Ok');
        }
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="add teacher modal"
      maxWidth="xs"
      data-testid="add-instructor-modal"
    >
      <ModalDialogTitle text="Add Instructor" />
      <DialogContent>
        <InstructorForm
          onSubmit={onSubmitTeacherForm}
          onClose={handleClose}
          instructorOptions={instructorOptions}
          addingTeacher={addingTeacher}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AddInstructorModal;
