import React, { useEffect, useRef, useState } from 'react';
import {
  Activity,
  ActivityTypes,
  CurrencyCode,
  defaultImageHeight,
  defaultImageWidth,
  DurationType,
  FormMode,
  getNestedValue,
  ValidationErrors
} from '@efacity/common';
import {
  ActivityFormValues,
  AdditionalSessionOptionIdName,
  CreatableAutocomplete,
  ImageCropper,
  IOption,
  RichTextEditor,
  RichTextEditorLabel
} from '@efacity/frontend-shared';
import { ActivityState } from '../../../hooks/useActivityOptions';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  defaultRHFSetValueOptions,
  DurationTypeSelector,
  FormCurrencyInput,
  FormTextInput
} from '@efacity/react-hook-form-mui';
import { Box, Checkbox, FormControlLabel, FormHelperText, Grid, Paper, TextField } from '@mui/material';
import { FormCancelSubmitButtons } from '@efacity/frontend-next-shared/forms';
import { ActivityTypeSelector, ActivityLevelSelector, StudentLevelSelector } from '@efacity/react-hook-form-mui';
import { useActivityInitialFormValues } from './useActivityInitialFormValues';
import { activityFormValidationSchema } from './ActivityFormValidationSchema';
import { Descendant } from 'slate';
import { blankActivityFormValues } from './activityFormValues';
import { OrgAdminPaymentLink } from '@efacity/frontend-shared';

const activityFormSx = {
  formField: { marginTop: 15 },
  imageUploader: (theme) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    marginRight: theme.spacing(2)
  }),
  paper: {
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '10px',
    paddingBottom: '10px',
    maxHeight: '230px',
    overflowY: 'scroll'
  }
};

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface StateWithLoading<T = {}> {
  data: T;
  isLoading: boolean;
}

export interface ActivityFormProps {
  mode: FormMode;
  orgId: string;
  activityId?: string;
  allowHandleActivity: boolean;
  currency: CurrencyCode;
  additionalOptionsState: StateWithLoading<AdditionalSessionOptionIdName[]>;
  activityCategoriesState: StateWithLoading<IOption[]>;
  activityTagsState: StateWithLoading<IOption[]>;
  activityOptionsState: ActivityState;
  isCloning: boolean;
  activity: Activity;
  handleCancel: () => void;
  handleSubmit(
    formValues: ActivityFormValues,
    setError: (fieldName: keyof ActivityFormValues, error: { type: string; message: string }) => void,
    activityImage: Blob
  ): void;
  priceAllowed: boolean;
  isUserIndependentTeacher: boolean;
}

const ActivityForm: React.FC<ActivityFormProps> = ({
  mode,
  orgId,
  activityId = '',
  currency,
  allowHandleActivity,
  activity,
  isCloning,
  additionalOptionsState,
  activityCategoriesState,
  activityTagsState,
  activityOptionsState,
  handleSubmit,
  handleCancel,
  priceAllowed,
  isUserIndependentTeacher
}) => {
  const [activityFormInitialValues] = useActivityInitialFormValues(activity, mode, currency, isCloning, priceAllowed);
  const activityNameInputRef = useRef(null);
  const [additionalOptionsSearchQuery, setAdditionalOptionsSearchQuery] = useState('');
  const [filteredAdditionalOptions, setFilteredAdditionalOptions] = useState([]);

  useEffect(() => {
    if (additionalOptionsSearchQuery?.length && additionalOptionsState?.data?.length) {
      const loweredSearchQuery = additionalOptionsSearchQuery.toLowerCase();

      setFilteredAdditionalOptions(
        additionalOptionsState.data.filter((additionalOption: AdditionalSessionOptionIdName) =>
          additionalOption.name.toLowerCase().includes(loweredSearchQuery)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalOptionsSearchQuery]);

  useEffect(() => {
    if (additionalOptionsState?.data) {
      setFilteredAdditionalOptions(additionalOptionsState?.data);
    }
  }, [additionalOptionsState]);

  useEffect(() => {
    if (isCloning && activityNameInputRef?.current) {
      activityNameInputRef.current.focus();
    }
  }, [isCloning]);

  const methods = useForm<ActivityFormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(activityFormValidationSchema(orgId, activityId)),
    defaultValues: activityFormInitialValues,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUseNativeValidation: false
  });
  const { errors, isSubmitting, isValid, isDirty } = methods.formState;

  useEffect(() => {
    methods.reset(activityFormInitialValues);
  }, [mode, activityId, activityFormInitialValues, methods]);

  useEffect(() => {
    methods.register('defaultSessionCurrency');
    methods.setValue('defaultSessionCurrency', activityFormInitialValues.defaultSessionCurrency);
  }, [methods, activityFormInitialValues]);

  const submitForm = (values) => {
    handleSubmit(values, methods.setError, activityImg);
  };

  const [cropError, setCropError] = useState(null);
  const [activityImg, setActivityImg] = useState(null);

  const onCropFinished = (image: Blob) => {
    const imagePreviewUrl = URL.createObjectURL(image);
    methods.setValue('imageFullUrl', imagePreviewUrl, { shouldDirty: true });
    setCropError(null);
    setActivityImg(image);
  };
  const onImageCropError = (errorMessage: string) => {
    setCropError(errorMessage);
    methods.setValue('imageFullUrl', '');
  };

  const toggleAdditionalOption = (_id) => {
    const chosenOptionsArray = methods.getValues('additionalSessionOptions');
    const index = chosenOptionsArray.indexOf(_id);
    if (index > -1) {
      //remove an option if it already exists in the array
      chosenOptionsArray.splice(index, 1);
      methods.setValue('additionalSessionOptions', chosenOptionsArray, { shouldDirty: true });
    } else {
      methods.setValue('additionalSessionOptions', [...chosenOptionsArray, _id], { shouldDirty: true });
    }
  };

  const teacherInstructionsMarginTop = methods.watch('type') === ActivityTypes.Enrichment ? 15 : 10;
  const longDescriptionLabel =
    methods.getValues('type') !== ActivityTypes.Enrichment
      ? 'Description displayed on the public page of the session'
      : 'Description of the Session on Registration Page';

  const additionalOptionsAvailable = additionalOptionsState.data.length > 0;

  return (
    <FormProvider {...methods}>
      <form noValidate autoComplete="off" onSubmit={methods.handleSubmit(submitForm)}>
        <fieldset disabled={!allowHandleActivity} style={{ border: 'none', padding: 0 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={5}>
              <Box sx={activityFormSx.imageUploader}>
                <ImageCropper
                  id={'activity-img'}
                  onCropFinished={(croppedImage) => {
                    onCropFinished(croppedImage);
                  }}
                  onError={(errorMessage) => onImageCropError(errorMessage)}
                  imageSrc={methods.watch('imageFullUrl')}
                  imageHeight={defaultImageHeight}
                  imageWidth={defaultImageWidth}
                />
                {cropError && (
                  <FormHelperText error style={{ alignSelf: 'flex-start' }}>
                    {cropError}
                  </FormHelperText>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={7}>
              {isUserIndependentTeacher ? null : (
                <ActivityTypeSelector
                  name="type"
                  includeCertification={false}
                  data-testid="activity.form.type"
                  disabled={activityOptionsState.loading || mode === FormMode.Edit || !allowHandleActivity}
                />
              )}

              <FormTextInput
                name="name"
                label={isUserIndependentTeacher ? 'Course name' : 'Activity name'}
                data-testid="activity.form.name"
                required
                disabled={!allowHandleActivity}
                helperText="Should be unique"
                style={activityFormSx.formField}
              />

              <DurationTypeSelector
                name="durationType"
                data-testid="activity.form.duration.type"
                disabled={!allowHandleActivity}
              />

              <ActivityLevelSelector
                name="level"
                data-testid="activity.form.level"
                label="Default session level"
                disabled={!allowHandleActivity}
                style={activityFormSx.formField}
              />

              <div data-testid="activity.form.categories">
                <CreatableAutocomplete
                  label={isUserIndependentTeacher ? 'Course categories' : 'Activity categories'}
                  name="categories"
                  errorMessage={getNestedValue(errors, 'categories')?.message || undefined}
                  isLoadingOptions={activityCategoriesState.isLoading}
                  initialOptions={activityCategoriesState.data}
                  value={methods.watch('categories')}
                  onChange={(value) => {
                    methods.setValue('categories', value, defaultRHFSetValueOptions);
                  }}
                  variant="outlined"
                  size="small"
                  disabled={!allowHandleActivity}
                  inputStyle={{ marginTop: 15, marginBottom: 13 }}
                />
              </div>

              <div data-testid="activity.form.tags">
                <CreatableAutocomplete
                  label="Default session tags"
                  name="tags"
                  errorMessage={getNestedValue(errors, 'tags')?.message || undefined}
                  data-testid="activity.form.tags"
                  isLoadingOptions={activityTagsState.isLoading}
                  initialOptions={activityTagsState.data}
                  value={methods.watch('tags')}
                  onChange={(value) => {
                    methods.setValue('tags', value, defaultRHFSetValueOptions);
                  }}
                  variant="outlined"
                  margin="dense"
                  disabled={!allowHandleActivity}
                  inputStyle={{ marginTop: 15, marginBottom: 13 }}
                />
              </div>
            </Grid>
          </Grid>

          {methods.watch('type') !== ActivityTypes.Enrichment && (
            <FormTextInput
              label="Description on card on session listing"
              name="shortDescription"
              required
              data-testid="activity.form.shortDescription"
              multiline
              minRows={2}
              style={activityFormSx.formField}
              disabled={!allowHandleActivity}
            />
          )}

          <div data-testid="activity.form.longDescription" style={{ marginTop: 13, marginBottom: 13 }}>
            <RichTextEditorLabel>{longDescriptionLabel} *</RichTextEditorLabel>
            <RichTextEditor
              value={activityFormInitialValues.longDescription || blankActivityFormValues.longDescription}
              onChange={(value: Descendant[]) => {
                methods.setValue('longDescription', value, defaultRHFSetValueOptions);
              }}
              editorHeight={200}
              placeholder={longDescriptionLabel}
              readOnly={!allowHandleActivity}
            />
          </div>
          {methods.watch('type') === ActivityTypes.Enrichment && (
            <div style={{ marginTop: 15, marginBottom: 13 }}>
              <RichTextEditorLabel>Message for parents before choosing location</RichTextEditorLabel>
              <RichTextEditor
                value={
                  activityFormInitialValues.descriptionOnSessionSelection ||
                  blankActivityFormValues.descriptionOnSessionSelection
                }
                onChange={(value: Descendant[]) => {
                  methods.setValue('descriptionOnSessionSelection', value, defaultRHFSetValueOptions);
                }}
                editorHeight={200}
                placeholder={'Enter description for customer on public school selection page...'}
                readOnly={!allowHandleActivity}
              />
            </div>
          )}

          <FormTextInput
            label="Teacher Instructions"
            name="teacherInstructions"
            data-testid="activity.form.teacherInstructions"
            multiline
            minRows={4}
            style={{ marginTop: teacherInstructionsMarginTop }}
            disabled={!allowHandleActivity}
          />

          <Grid container spacing={1}>
            {methods.watch('durationType') !== DurationType.selfPaced && (
              <Grid item lg={3} xs={12} sm={12} md={3}>
                <FormTextInput
                  type="number"
                  name="numberOfInstances"
                  data-testid="activity.form.numberOfInstances"
                  label="Number of Instances"
                  required
                  style={activityFormSx.formField}
                  disabled={!allowHandleActivity}
                />
              </Grid>
            )}
            <Grid item lg={9} xs={12} sm={12} md={9} sx={{ marginTop: { xs: '-5px', md: '3px' } }}>
              <StudentLevelSelector namePrefix="studentLevel" disabled={!allowHandleActivity} />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item lg={6} xs={12} sm={12} md={6}>
              <>
                <FormCurrencyInput
                  name="defaultSessionPrice"
                  label="Suggested Session Price *"
                  data-testid="activity.form.defaultSessionPrice"
                  value={methods.getValues('defaultSessionPrice') / 100}
                  currency={methods.getValues('defaultSessionCurrency') as CurrencyCode}
                  style={activityFormSx.formField}
                  disabled={!priceAllowed || !allowHandleActivity}
                  forceErrorMessage={priceAllowed ? null : ValidationErrors.ConnectRequired}
                />
                {!priceAllowed && <OrgAdminPaymentLink orgId={orgId} />}
              </>
            </Grid>
            <Grid item lg={6} xs={12} sm={12} md={6}>
              <FormTextInput
                type="number"
                name="fee"
                label="Fee (%) *"
                data-testid="activity.form.fee"
                disabled={!allowHandleActivity}
                sx={{ marginTop: { xs: '7px', md: '15px' } }}
              />
            </Grid>
          </Grid>

          {additionalOptionsAvailable && (
            <div style={{ marginTop: 20, marginBottom: 30 }}>
              <div style={{ fontSize: 18, marginBottom: 10 }}>Additional Options:</div>
              <Paper sx={activityFormSx.paper}>
                <TextField
                  label="Search options"
                  variant="outlined"
                  size="small"
                  value={additionalOptionsSearchQuery}
                  onChange={(e) => setAdditionalOptionsSearchQuery(e.target.value || '')}
                  style={{ marginBottom: 6, marginTop: 8 }}
                  fullWidth
                  disabled={!allowHandleActivity}
                />
                {filteredAdditionalOptions?.length ? (
                  filteredAdditionalOptions.map((additionalOption: AdditionalSessionOptionIdName, index: number) => (
                    <div key={`additionalSessionOption${index}`}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={`${additionalOption._id}`}
                            checked={methods.watch('additionalSessionOptions').indexOf(additionalOption._id) > -1}
                            onClick={(_) => {
                              toggleAdditionalOption(additionalOption._id);
                            }}
                            name={`sharedWith${additionalOption._id}`}
                            color="primary"
                            disabled={!allowHandleActivity}
                          />
                        }
                        label={additionalOption.name}
                      />
                    </div>
                  ))
                ) : (
                  <div style={{ marginBottom: 6, marginTop: 8 }}>Nothing found</div>
                )}
              </Paper>
            </div>
          )}

          <FormCancelSubmitButtons
            isFormValid={isValid && isDirty && !cropError}
            mode={mode}
            isSubmitting={isSubmitting}
            onCancel={handleCancel}
            isDisabled={!allowHandleActivity || additionalOptionsState.isLoading}
          />
        </fieldset>
      </form>
    </FormProvider>
  );
};

export default ActivityForm;
