import { InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { Column } from '@tanstack/react-table';
import { textFieldSizes } from './styles';

interface SelectColumnFilterProps<TableData> {
  column: Column<TableData>;
  options: { value: string; label: string }[];
  emptyValueText?: string;
}

export const SelectColumnFilter = <TableData,>({
  column,
  options,
  emptyValueText = 'Not selected...'
}: SelectColumnFilterProps<TableData>) => {
  const value = (column.getFilterValue() as string) ?? '';
  const handleChange = (e: SelectChangeEvent) => {
    column.setFilterValue(e?.target?.value ?? undefined);
  };
  const headerLabel = column.columnDef.header;

  return (
    <>
      <InputLabel id="label" sx={visuallyHidden}>
        Filter by {typeof headerLabel === 'string' ? headerLabel : ''}
      </InputLabel>
      <Select
        value={value}
        onChange={handleChange}
        labelId="label"
        id="select"
        variant="outlined"
        size="small"
        margin="dense"
        sx={{
          ...textFieldSizes,
          '.MuiSelect-select': {
            p: 0.5
          },
          backgroundColor: 'white'
        }}
        displayEmpty
      >
        <MenuItem value="">{emptyValueText}</MenuItem>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};
