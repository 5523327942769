import React, { useRef } from 'react';
import { apiService, Product } from '@efacity/frontend-shared';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import { useParams } from 'react-router-dom';
import { nonPaginatedDataFetcher, Table, TableRef } from '@efacity/table';
import { Messages } from '@efacity/common';
import { PATHS, toPath } from '@efacity/routing';
import ProductsLinkWithCopier from '../../components/ProductsLinkWithCopier/ProductsLinkWiithCopier';
import { PageTitle } from '@efacity/react-next-sc';
import { useProductsTableColumnDefs } from './useProductsColumns';

const ProductsContainer: React.FC = () => {
  const { orgId } = useParams<OrgIdParamType>();
  const tableRef = useRef<TableRef<Product>>(null);
  const dataFetcherUrl = `/org/${orgId}/products`;

  const handleDeleteKit = async (kitId: string) => {
    try {
      await apiService.delete(`/org/${orgId}/product/${kitId}`);
      showNotification(true, Messages.KitDeleted);
      tableRef.current?.refetchTableData();
    } catch (error) {
      showNotification(false, error.response.data.message || Messages.FailedToDeleteKit, true);
    }
  };

  const productsTableColumnDefs = useProductsTableColumnDefs(orgId, handleDeleteKit);

  return (
    <>
      <PageTitle
        title="Products"
        addButtonLink={toPath(PATHS.addProduct, { orgId: orgId })}
        button={
          <div style={{ marginTop: 5, marginRight: 15 }}>
            <ProductsLinkWithCopier />
          </div>
        }
      />
      <Table<Product>
        tableRef={tableRef}
        dataFetcherUrl={dataFetcherUrl}
        columnDefs={productsTableColumnDefs}
        dataFetcher={nonPaginatedDataFetcher<Product>}
        initialSortBy={[{ id: 'name', desc: false }]}
        manualFiltering={false}
        manualSorting={false}
        noDataText={'No products...'}
      />
    </>
  );
};

export default ProductsContainer;
