import { addMinutes, set, parseISO, format, isSameDay } from 'date-fns';
import { format as formatTz, getTimezoneOffset, utcToZonedTime } from 'date-fns-tz';
import { enUS } from 'date-fns/locale';
import { ScheduleInstance } from './Sessions';
import {
  getSessionEndDate,
  getWeekDayNames,
  mapInstancesToTimeStamps,
  mapInstancesToTimeStampsForTimeZone
} from '../utils/getSessionDuration';

export const endOfComputerEra = new Date('2037-12-31');
export const INVALID_DATE = 'Invalid Date';

export const monthDayDateFormat = 'MMM d';
export const time12HourFormat = 'h:mm a';
export const time24HourFormat = 'HH:mm';
export const dateTimeIn12HourClockFormat = 'MMM d, h:mm a';
export const monthDayYearDateFormat = 'MMM d, yyyy';
export const dateMonthDayYearWithUnderscoresFormat = 'MMM_d_yyyy';
export const dateFormatter = 'yyyy-MM-dd';
export const monthDayYearDateFormatHoursFormat = 'MMM d, yyyy, hh:mm a';
export const monthTimeFormat = `${monthDayYearDateFormat} (hh:mm a)`;
export const timeFormat = 'hh:mm a';

export const getDateFormatted = (date: Date, dateFormat: string) => {
  return format(date, dateFormat).replace(' ', '\xa0');
};

export const formatDateToTime = (date: Date | string) => {
  return format(new Date(date), time12HourFormat);
};

export const getDateFormattedForUserTimeZone = (date: Date) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return getDateFormatted(convertUtcToZonedTime(date, timeZone), monthDayYearDateFormat);
};

export function formatDateToMonthDayYearDate(date: Date | string, locale = enUS) {
  try {
    return format(new Date(date), monthDayYearDateFormat, {
      locale
    });
  } catch {
    return '';
  }
}

export const formatTimeToHhMmNonBreakableString = (date: Date | string) => {
  try {
    const parsedDate = new Date(date);
    return format(parsedDate, "hh:mm'\xa0'a");
  } catch {
    return '';
  }
};

export function formatDateInBrowserTimeZone(date: Date | string | undefined, dateFormat: string) {
  let timeZone = '';
  if (isIntlSupported) {
    timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  return format(new Date(convertUtcToZonedTime(date, timeZone)), dateFormat); // yyyy-MM-dd
}

export function formatDateWithFormatter(date: Date | string | undefined, dateFormat: string) {
  if (date === undefined || date === '') {
    return '-';
  }
  try {
    return format(new Date(date), dateFormat);
  } catch {
    return '';
  }
}

export function formatDateWithLocal(date: Date | string | undefined, dateFormat: string, locale = enUS) {
  if (date === undefined || date === '') {
    return '-';
  }
  try {
    return format(new Date(date), dateFormat, {
      locale
    });
  } catch {
    return '';
  }
}

export const formatDateToMonthDayInLocale = (date, locale = enUS) => {
  return formatDateWithLocal(date, monthDayDateFormat, locale);
};

export function formatDateToMonthDayYear(date: Date | string | undefined, locale = enUS) {
  return formatDateWithLocal(date, monthDayYearDateFormat, locale);
}

export function formatStringToMonthDayYearDate(dateString: string) {
  return format(parseISO(dateString), monthDayYearDateFormat);
}

export function formatDateToMonthDayYearInLocale(date: Date | string | undefined, locale = enUS) {
  return formatDateWithLocal(date, monthDayYearDateFormat, locale);
}

export function formatDateToMonthDayYearTimeDate(date: Date) {
  return format(date, monthDayYearDateFormatHoursFormat);
}

export const formatTimeToHhMm = (date: Date | string) => {
  try {
    const parsedDate = new Date(date);
    return format(parsedDate, time12HourFormat);
  } catch {
    return '';
  }
};

export const formatTimeToHhMmWithTimeZone = (date: Date, timeZone: string) => {
  return `${formatTimeToHhMm(date)} (${timeZone})`;
};

export const formatSessionWeekDays = (weekDays: string[]) => {
  return weekDays ? weekDays.join(', ') : '-';
};

export const isValidDate = (date: Date | string) => {
  return date instanceof Date && !isNaN(date.valueOf());
};

export const getMonthOptions = () => {
  const monthLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const options = [];
  for (let i = 0; i < 12; i++) {
    options.push({ label: monthLabels[i], value: i });
  }
  return options;
};

export const getMonthDays = () => {
  const maxDays = 31;

  const options = [];
  for (let i = 0; i < maxDays; i++) {
    options.push(i);
  }
  return options;
};

export const getStudentBirthYearOptions = () => {
  const preKAge = 4;
  const twelfthGradeAge = 18;

  const maxAvailableYear = new Date().getFullYear() - preKAge;
  const minAvailableYear = new Date().getFullYear() - twelfthGradeAge;

  const options = [];
  for (let i = minAvailableYear; i <= maxAvailableYear; i++) {
    options.push(i);
  }
  return options;
};

export function getDate() {
  return new Date();
}

export function getDateAfternoon() {
  return set(new Date(), { hours: 14, minutes: 0, seconds: 0, milliseconds: 0 });
}

export const convertDaysToMilliseconds = (days: number): number => {
  return days * 24 * 60 * 60 * 1000;
};

export const nowUTC = (): Date => {
  const date = new Date();
  const nowUtc = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );

  return new Date(nowUtc);
};

export const isDate = function (date: any) {
  const parsedDate = Date.parse(date);

  // You want to check again for !isNaN(parsedDate) here because Dates can be converted
  // to numbers, but a failed Date parse will not.
  return isNaN(date) && !isNaN(parsedDate);
};

export const convertUtcToBrowserTimeZone = (date: Date | string | undefined) => {
  if (date === undefined) {
    return date;
  }
  let timeZone = '';
  if (isIntlSupported) {
    timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  return utcToZonedTime(new Date(date).toISOString(), timeZone);
};

export const isIntlSupported = typeof window !== 'undefined' && window?.Intl && typeof window?.Intl === 'object';

export const getDateWithTimeZoneName = (
  date: Date,
  dateFormat: string,
  customTimeZone: string | null,
  displayTimeZone = true
) => {
  if (customTimeZone) {
    return displayTimeZone
      ? format(date, `${dateFormat}`).concat(` (${customTimeZone})`).replace(' ', '\xa0')
      : format(date, `${dateFormat}`).replace(' ', '\xa0');
  }
  let timeZone = '';
  if (isIntlSupported) {
    timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  if (timeZone && timeZone.length > 0) {
    return formatTz(date, `${dateFormat} (zzz)`, { timeZone }).replace(' ', '\xa0');
  } else {
    return format(date, `${dateFormat}`).replace(' ', '\xa0');
  }
};

export function getEndTime(duration: number, startTime: Date | string) {
  if (!duration || !isValidDate(new Date(startTime))) {
    return '';
  }
  return format(addMinutes(new Date(startTime), duration), time12HourFormat);
}

export const getTimeDiapasonWithStartDateAndZone = (
  startDateTime: Date,
  durationInMinutes: number,
  timeZone: string,
  displayTimeZone: boolean
) => {
  const startDate = getDateFormatted(convertUtcToZonedTime(startDateTime, timeZone), dateTimeIn12HourClockFormat);
  const endDateWithTimeZone = getDateWithTimeZoneName(
    addMinutes(convertUtcToZonedTime(startDateTime, timeZone), durationInMinutes),
    time12HourFormat,
    timeZone,
    displayTimeZone
  );

  return `${startDate}\xa0- ${endDateWithTimeZone}`;
};

export const getTimeDiapasonWithZone = (
  startDateTime: Date,
  durationInMinutes = 0,
  timeZone: string,
  displayTimeZone = true
) => {
  const startDate = getDateFormatted(convertUtcToZonedTime(startDateTime, timeZone), time12HourFormat);

  const endDateWithTimeZone = getDateWithTimeZoneName(
    addMinutes(convertUtcToZonedTime(startDateTime, timeZone), durationInMinutes),
    time12HourFormat,
    timeZone,
    displayTimeZone
  );

  return `${startDate}\xa0- ${endDateWithTimeZone}`;
};

export const getTimeDiapasonWithoutTimeZone = (startDateTime: Date, durationInMinutes: number, timeZone: string) => {
  const startDate = getDateFormatted(convertUtcToZonedTime(startDateTime, timeZone), time12HourFormat);
  const endDate = getDateFormatted(
    addMinutes(convertUtcToZonedTime(startDateTime, timeZone), durationInMinutes),
    time12HourFormat
  );

  return `${startDate}\xa0- ${endDate}`;
};

export const getSessionDatesDiapasonInLocalTimeZone = (startDate: Date, endDate: Date) => {
  let timeZone = '';
  if (isIntlSupported) {
    timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  const startDateInLocalTimeZone = convertUtcToZonedTime(startDate, timeZone);
  const endDateInLocalTimeZone = convertUtcToZonedTime(endDate, timeZone);
  return isSameDay(startDateInLocalTimeZone, endDateInLocalTimeZone)
    ? getDateInLocalTimeZone(startDateInLocalTimeZone)
    : `${getDateInLocalTimeZone(startDateInLocalTimeZone)} - ${getDateInLocalTimeZone(endDateInLocalTimeZone)}`;
};

export function formatStartToEndDateInTimeZone(
  startDate: Date | string | undefined,
  endDate: Date | string | undefined,
  timeZone: string,
  locale = enUS
) {
  if (startDate === endDate) {
    return formatDateToMonthDayYearInLocale(convertUtcToZonedTime(new Date(startDate), timeZone), locale);
  }
  return (
    formatDateToMonthDayInLocale(convertUtcToZonedTime(new Date(startDate), timeZone), locale) +
    ' - ' +
    formatDateToMonthDayYearInLocale(convertUtcToZonedTime(new Date(endDate), timeZone), locale)
  );
}

export const convertUtcToZonedTime = (date: Date | string, timeZone: string): Date => {
  return utcToZonedTime(new Date(date).toISOString(), timeZone);
};

export const startOfTheDayInUTCForTimeZone = (date: Date | string, timeZone: string): Date => {
  return new Date(startOfTheDay(date).getTime() - getTimezoneOffset(timeZone, new Date(date)));
};

export const endOfTheDayInUTCForTimeZone = (date: Date | string, timeZone: string): Date => {
  return new Date(endOfTheDay(date).getTime() - getTimezoneOffset(timeZone, new Date(date)));
};

export const startOfCurrentDayInUTC = () => {
  return nowUTC().setHours(0, 0, 0, 0);
};

export const endOfDayAsDate = (date: Date | string): Date => {
  return new Date(endOfTheDay(date));
};

export const nowBeforeEndOfDayOfDate = (date: Date | string): boolean => {
  return nowUTC() < endOfDayAsDate(date);
};

export const startOfTheDay = (date: Date | string): Date => {
  return new Date(new Date(date).setHours(0, 0, 0, 0));
};

export const endOfTheDay = (date: Date | string): Date => {
  return new Date(new Date(date).setHours(23, 59, 59, 999));
};

export const getDateInLocalTimeZone = (sessionsStartDate: Date | string) => {
  let timeZone = '';
  if (isIntlSupported) {
    timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  return format(convertUtcToZonedTime(sessionsStartDate, timeZone), 'MMMM do'); // yyyy-MM-dd
};

export const getTimeDiapasonWithLocalTimeZone = (startDateTime: Date, durationInMinutes: number) => {
  let timeZone = '';
  if (isIntlSupported) {
    timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  return getTimeDiapasonWithZone(startDateTime, durationInMinutes, timeZone);
};

export const getStartOfDayInUTC = (date: Date) => {
  return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
};

export const getEndOfDayInUTC = (date: Date) => {
  return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() + 1));
};

export const convertHoursMinutesToMinutes = ({ hours, minutes }: Duration) => {
  if (hours === undefined) {
    hours = 0;
  }
  if (minutes === undefined) {
    minutes = 0;
  }
  return hours * 60 + minutes;
};

export const convertMinutesToDuration = (durationInMinutes: number): Duration => {
  if (durationInMinutes === null || durationInMinutes === undefined) {
    return {
      hours: 0,
      minutes: 0
    };
  }
  const fullHours = Math.floor(durationInMinutes / 60);
  const minutes = durationInMinutes % 60;
  return {
    hours: fullHours,
    minutes: minutes
  };
};

export const mapSessionInstancesToTimestampsInTimeZone = (instancesList: ScheduleInstance[], timeZone: string) => {
  return instancesList.map((instance) => convertUtcToZonedTime(new Date(instance.startDateTime), timeZone).getTime());
};

export const getInstancesEndDateInTimeZone = (instances: ScheduleInstance[] = [], timeZone: string) => {
  if (instances?.length > 0) {
    const sessionEndDate = getSessionEndDate(mapSessionInstancesToTimestampsInTimeZone(instances, timeZone));
    return format(sessionEndDate, monthDayYearDateFormat);
  }
  return '';
};

export const getDaysOfWeekForTimeZoneForInstances = (instances: any, timeZone: string) => {
  return instances?.length > 0
    ? getWeekDayNames(mapInstancesToTimeStampsForTimeZone(instances, timeZone)).join(', ')
    : '-';
};

export const getDaysOfWeekInBrowserTimeZoneForInstances = (instances: any) => {
  return instances?.length > 0 ? getWeekDayNames(mapInstancesToTimeStamps(instances)).join(', ') : '-';
};
