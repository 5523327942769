import React, { useState, useRef } from 'react';
import { Button } from '@mui/material';
import { Messages, ReviewInfo, ReviewStatus } from '@efacity/common';
import { PageTitle } from '@efacity/react-next-sc';
import { Table, TableRef, usePaginatedLoaderWithAdditionalInfo } from '@efacity/table';
import useSiteAdminReviewsColumns from './useSiteAdminReviewsColumns';
import { apiService } from '@efacity/frontend-shared';
import { showNotification } from '@efacity/frontend-next-shared/notifications';

const SiteAdminReviewsContainer: React.FC = () => {
  const tableRef = useRef<TableRef<ReviewInfo>>(null);
  const dataFetchUrl = '/reviews/sessions';
  const { paginatedDataFetcher } = usePaginatedLoaderWithAdditionalInfo<ReviewInfo>({
    dateTimeFields: ['reviewedOn']
  });
  const [processingReviewId, setProcessingReviewId] = useState<string | null>(null);
  const [processingApprovePending, setProcessingApprovePending] = useState<boolean>(false);

  const changeReviewStatus = async (reviewId: string, newStatus: ReviewStatus) => {
    try {
      setProcessingReviewId(reviewId);
      await apiService.patch<{ status: ReviewStatus }>(`/reviews/${reviewId}/status`, { status: newStatus });
      tableRef.current?.refetchTableData();
    } catch (errorResponse) {
      showNotification(false, errorResponse.message || Messages.FailedUpdateReviewStatus, true);
    } finally {
      setProcessingReviewId(null);
    }
  };

  const approveAllPendingReviews = async () => {
    setProcessingApprovePending(true);
    try {
      await apiService.patch(`/reviews/approve`, {});
      tableRef.current?.refetchTableData();
    } catch (errorResponse) {
      showNotification(false, errorResponse.message || Messages.FailedUpdateReviewStatus, true);
    } finally {
      setProcessingApprovePending(false);
    }
  };

  const columns = useSiteAdminReviewsColumns(changeReviewStatus, processingReviewId, processingApprovePending);

  const approveAllPendingButton = (
    <Button variant="contained" onClick={approveAllPendingReviews}>
      Approve all pending
    </Button>
  );

  return (
    <div>
      <PageTitle title="Reviews" button={approveAllPendingButton} />
      <Table<ReviewInfo>
        tableRef={tableRef}
        dataFetcherUrl={dataFetchUrl}
        columnDefs={columns}
        dataFetcher={paginatedDataFetcher}
        initialSortBy={[{ id: 'stars', desc: false }]}
        initialFilters={[{ id: 'status', value: ReviewStatus.Pending }]}
        noDataText="No Reviews..."
        manualFiltering={true}
        manualSorting={true}
      />
    </div>
  );
};

export default SiteAdminReviewsContainer;
