import React, { useMemo } from 'react';
import { Link, Typography } from '@mui/material';
import { CircularProgressButton } from '@efacity/frontend-shared';
import { nowUTC, Roles, User } from '@efacity/common';
import { IconButton } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { TextColumnFilter } from '@efacity/table';
import { NavLink } from 'react-router-dom';
import { PATHS, toPath } from '@efacity/routing';
import { LightTooltip, PhoneNumber } from '@efacity/react-next-sc';
import EmailAddress from '../../components/EmailAddress/EmailAddress';
import { smallPublishButtonStyle } from '../../components/Buttons/SessionPublishButton';
import { isBefore } from 'date-fns';
import DeleteIcon from '@mui/icons-material/DeleteForever';

const columnHelper = createColumnHelper<User>();

export const useInstructorsColumns = (
  orgId: string,
  isActivating: boolean,
  activateInstructor: (userId: string) => void,
  onDeleteCLick: (userId: string) => void
) => {
  return useMemo(() => {
    return [
      columnHelper.accessor('fullName', {
        header: 'Full Name',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ row }) => {
          return (
            <Link
              component={NavLink}
              to={toPath(PATHS.editInstructor, { orgId: orgId, id: row.original._id })}
              underline={'none'}
            >
              {row.original.fullName}
            </Link>
          );
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />,
          columnSx: { verticalAlign: 'top', paddingTop: '10px' }
        }
      }),
      columnHelper.accessor('defaultPhone', {
        header: 'Phone',
        enableColumnFilter: true,
        enableSorting: true,
        cell: ({ getValue }) => {
          return <PhoneNumber phone={getValue()} />;
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />,
          columnSx: { verticalAlign: 'top', paddingTop: '10px' }
        }
      }),
      columnHelper.accessor('email', {
        header: 'Email',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ getValue }) => {
          return <EmailAddress email={getValue()} />;
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />,
          columnSx: { verticalAlign: 'top', paddingTop: '10px' }
        }
      }),
      {
        header: '',
        id: 'actions-cell',
        cell: ({ row }) => {
          const organizationAccess = row.original?.access?.find(
            (item) =>
              item.organization === orgId && (item.role === Roles.SchoolTeacher || item.role === Roles.CenterTeacher)
          );
          return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }} data-testid="actions-cell">
              {!organizationAccess?.isActive && (
                <LightTooltip title={<Typography>Activate Instructor</Typography>}>
                  <span>
                    <div style={{ display: 'flex', marginRight: 5 }} data-testid="registration-link-with-copier">
                      <CircularProgressButton
                        progress={isActivating}
                        color="primary"
                        onClick={() => activateInstructor(row.original._id)}
                        style={{ ...smallPublishButtonStyle, width: 80 }}
                        data-testid="activate-instructor-btn"
                      >
                        Activate
                      </CircularProgressButton>
                    </div>
                  </span>
                </LightTooltip>
              )}
              <IconButton
                color="primary"
                aria-label="delete-instructor"
                data-testid="delete-instructor-btn"
                component="span"
                style={smallPublishButtonStyle}
                disabled={isBefore(new Date(row.original.expiresOn), new Date(nowUTC()))}
                onClick={() => onDeleteCLick(row.original._id)}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          );
        },
        meta: {
          headerSx: { width: '80px', maxWidth: '80px' },
          columnSx: { width: '80px', maxWidth: '80px' }
        }
      }
    ];
  }, [orgId, isActivating, activateInstructor, onDeleteCLick]);
};
