import {
  CurrencyCode,
  DurationType,
  moneyWithCurrencyCodeFormatter,
  ShoppingCartProduct,
  ShoppingCartRegistration
} from '@efacity/common';
import { LinearProgress, Table, TableBody, TableCell, TableRow, TableContainer, TableHead } from '@mui/material';
import React, { ChangeEvent, FC } from 'react';
import {
  cellSx,
  strippedTableRowSx,
  tableContainerSx,
  tableHeadCellSx,
  tableHeadDividerSx,
  tableHeadSx,
  totalCellSx
} from '@efacity/frontend-shared';
import ShoppingCartRegistrationAsTableRows from './ShoppingCartRegistrationAsTableRows';
import CouponDiscountInputAsRow from './CouponDiscountInputAsRow';
import DeleteIconButtonWithSpinner from '../../../components/DeleteIconButtonWithSpinner/DeleteIconButtonWithSpinner';

export interface ShoppingCartTableProps {
  shoppingCartRegistrations: ShoppingCartRegistration[];
  shoppingCartProducts: ShoppingCartProduct[];
  shoppingCartAmount: number;
  shoppingCartTaxAmount: number;
  shoppingCartAmountWithTax: number;
  credit: number;
  due: number;
  currencyCode: CurrencyCode;
  timeZone: string;
  taxDescription: string;
  loading: boolean;
  handleDeleteShoppingCartItem: (studentId: string, sessionId: string) => void;
  handleDiscountCodeChanged: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  handleDeleteAdditionalOption: (studentId: string, sessionAd: string, additionalOptionId: string) => void;
  handleDeleteProduct: (productId: string) => void;
  isUpdating: boolean;
}

const ShoppingCartAsTable: FC<ShoppingCartTableProps> = ({
  shoppingCartRegistrations = [],
  shoppingCartProducts = [],
  shoppingCartAmount,
  shoppingCartTaxAmount,
  shoppingCartAmountWithTax,
  credit,
  due,
  currencyCode,
  timeZone,
  loading,
  handleDeleteShoppingCartItem,
  handleDiscountCodeChanged,
  handleDeleteAdditionalOption,
  handleDeleteProduct,
  taxDescription,
  isUpdating
}) => {
  const showScheduleRelatedColumns = !!shoppingCartRegistrations.find(
    (reg) => reg.session.durationType !== DurationType.selfPaced
  );

  return (
    <TableContainer sx={tableContainerSx}>
      {loading ? (
        <TableRow>
          <TableCell align="center" colSpan={8}>
            <LinearProgress variant="indeterminate" color="primary" data-testid="loadingIndicator" />
          </TableCell>
        </TableRow>
      ) : shoppingCartRegistrations?.length === 0 && shoppingCartProducts?.length === 0 ? (
        <TableRow>
          <TableCell align="center" colSpan={8}>
            Empty
          </TableCell>
        </TableRow>
      ) : (
        <>
          {shoppingCartRegistrations?.length > 0 && (
            <Table size="small" aria-label="shopping cart">
              <TableHead sx={tableHeadSx}>
                <TableRow>
                  <TableCell sx={tableHeadCellSx} align="left">
                    Session
                  </TableCell>
                  <TableCell sx={tableHeadCellSx} align="left">
                    Student
                  </TableCell>
                  {showScheduleRelatedColumns ? (
                    <>
                      <TableCell sx={{ ...tableHeadCellSx, width: '200px' }} align="left">
                        Dates
                      </TableCell>
                      <TableCell sx={{ ...tableHeadCellSx, width: '137px' }} align="left">
                        Days
                      </TableCell>
                      <TableCell sx={{ ...tableHeadCellSx, width: '137px' }} align="left">
                        Time
                      </TableCell>
                    </>
                  ) : (
                    <TableCell colSpan={3} />
                  )}
                  <TableCell sx={{ ...tableHeadCellSx, width: '98px' }} align="left">
                    Duration
                  </TableCell>
                  <TableCell sx={{ ...tableHeadCellSx, width: '120px' }} align="right">
                    Amount
                  </TableCell>
                  <TableCell sx={{ ...tableHeadCellSx, width: '80px' }} align="center">
                    Delete
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {shoppingCartRegistrations?.map((shoppingCartRegistration: ShoppingCartRegistration, index: number) => (
                  <ShoppingCartRegistrationAsTableRows
                    key={`student${
                      shoppingCartRegistration?.student?._id ? shoppingCartRegistration.student._id : 'NoStudentId'
                    }ToSession${shoppingCartRegistration.session._id}ShoppingCartRow${index}`}
                    session={shoppingCartRegistration.session}
                    timeZone={timeZone}
                    student={shoppingCartRegistration?.student ? shoppingCartRegistration.student : null}
                    additionalOptions={
                      shoppingCartRegistration.additionalSessionOptions &&
                      shoppingCartRegistration.additionalSessionOptions.length > 0
                        ? shoppingCartRegistration.additionalSessionOptions
                        : null
                    }
                    discounts={shoppingCartRegistration.discounts}
                    handleDeleteShoppingCartItem={handleDeleteShoppingCartItem}
                    handleDeleteAdditionalOption={handleDeleteAdditionalOption}
                    isUpdating={isUpdating}
                  />
                ))}
              </TableBody>
            </Table>
          )}

          {shoppingCartProducts?.length > 0 && (
            <Table size="small" aria-label="a simple dense table">
              <TableHead
                sx={{
                  ...tableHeadSx,
                  borderTop: shoppingCartRegistrations?.length ? `1px solid` : `0px`,
                  borderColor: 'primary.dark'
                }}
              >
                <TableRow>
                  <TableCell sx={tableHeadCellSx} align="left">
                    Kit
                  </TableCell>
                  <TableCell sx={{ ...tableHeadCellSx, width: '120px' }} align="right">
                    Amount
                  </TableCell>
                  <TableCell sx={{ ...tableHeadCellSx, width: '80px' }} align="center">
                    Delete
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {shoppingCartProducts?.map((shoppingCartProduct: ShoppingCartProduct, index: number) => (
                  <TableRow sx={strippedTableRowSx} data-testid="shopping-cart-item-row" key={`$product-row${index}`}>
                    <TableCell sx={cellSx} align="left">
                      {shoppingCartProduct.product.name}
                    </TableCell>
                    <TableCell sx={{ ...cellSx, whiteSpace: 'nowrap', width: '120px' }} align="right" scope="row">
                      {moneyWithCurrencyCodeFormatter(
                        shoppingCartProduct.product.price,
                        shoppingCartProduct.product.currencyCode
                      )}
                    </TableCell>
                    <TableCell sx={{ ...cellSx, width: '80px' }} align="center" component="th" scope="row">
                      <DeleteIconButtonWithSpinner
                        onClick={() => handleDeleteProduct(shoppingCartProduct.product._id.toString())}
                        isUpdating={isUpdating}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          <Table size="small" aria-label="a simple dense table">
            <TableHead sx={tableHeadDividerSx}>
              <TableRow>
                <TableCell sx={{ ...tableHeadCellSx, borderBottom: '0px solid' }} align="left" />
                <TableCell sx={{ ...tableHeadCellSx, borderBottom: '0px solid' }} align="left" />
                <TableCell sx={{ ...tableHeadCellSx, width: '200px', borderBottom: '0px solid' }} align="left" />
                <TableCell sx={{ ...tableHeadCellSx, width: '137px', borderBottom: '0px solid' }} align="left" />
                <TableCell sx={{ ...tableHeadCellSx, width: '137px', borderBottom: '0px solid' }} align="left" />
                <TableCell sx={{ ...tableHeadCellSx, width: '98px', borderBottom: '0px solid' }} align="left" />
                <TableCell sx={{ ...tableHeadCellSx, width: '120px', borderBottom: '0px solid' }} align="right" />
                <TableCell sx={{ ...tableHeadCellSx, width: '80px', borderBottom: '0px solid' }} align="center" />
              </TableRow>
            </TableHead>
            <TableBody>
              <CouponDiscountInputAsRow handleDiscountCodeChanged={handleDiscountCodeChanged} disabled={false} />
              {shoppingCartTaxAmount > 0 ? (
                <>
                  <TableRow>
                    <TableCell sx={totalCellSx} colSpan={6} align="right">
                      Sub Total:
                    </TableCell>
                    <TableCell sx={totalCellSx} align="right">
                      {moneyWithCurrencyCodeFormatter(shoppingCartAmount, currencyCode)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={totalCellSx} colSpan={6} align="right">
                      {taxDescription}
                    </TableCell>
                    <TableCell sx={totalCellSx} align="right">
                      {moneyWithCurrencyCodeFormatter(shoppingCartTaxAmount, currencyCode)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={totalCellSx} colSpan={6} align="right">
                      Total :
                    </TableCell>
                    <TableCell sx={totalCellSx} align="right">
                      {moneyWithCurrencyCodeFormatter(shoppingCartAmountWithTax, currencyCode)}
                    </TableCell>
                  </TableRow>
                </>
              ) : (
                <TableRow key={'shopping-cart-total'}>
                  <TableCell sx={totalCellSx} colSpan={6} align="right">
                    Total:
                  </TableCell>
                  <TableCell sx={totalCellSx} align="right">
                    {moneyWithCurrencyCodeFormatter(shoppingCartAmount, currencyCode)}
                  </TableCell>
                </TableRow>
              )}
              {credit > 0 && (
                <TableRow key={'shopping-cart-credit'}>
                  <TableCell sx={totalCellSx} colSpan={6} align="right">
                    Credit :
                  </TableCell>
                  <TableCell sx={totalCellSx} align="right">
                    {moneyWithCurrencyCodeFormatter(-credit, currencyCode)}
                  </TableCell>
                </TableRow>
              )}
              <TableRow key={'shopping-cart-due'}>
                <TableCell sx={totalCellSx} colSpan={6} align="right">
                  Due :
                </TableCell>
                <TableCell sx={totalCellSx} align="right">
                  {moneyWithCurrencyCodeFormatter(due, currencyCode)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </>
      )}
    </TableContainer>
  );
};

export default ShoppingCartAsTable;
