import React, { useRef } from 'react';
import { apiService } from '@efacity/frontend-shared';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import { useParams } from 'react-router-dom';
import { Messages, JobPostingFrontend } from '@efacity/common';
import JobPostingsLinkWithCopier from '../../components/JobPostingsLinkWithCopier/JobPostingsLinkWithCopier';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { PageTitle } from '@efacity/react-next-sc';
import { Table, TableRef, usePaginatedLoaderWithAdditionalInfo } from '@efacity/table';
import { useJobPostingColumns } from './useJobPostingColumns';

const JobPostingsContainer: React.FC = () => {
  const { orgId } = useParams<OrgIdParamType>();
  const tableRef = useRef<TableRef<JobPostingFrontend>>(null);
  const dataFetcherUrl = `/org/${orgId}/jobs`;
  const { paginatedDataFetcher } = usePaginatedLoaderWithAdditionalInfo<JobPostingFrontend>({
    dateTimeFields: ['expiresOn', 'createdOn'],
    usePagination: true,
    useAdditionalInfo: false
  });

  const handleDeleteJob = async (jobId: string) => {
    try {
      await apiService.delete(`/org/${orgId}/job/${jobId}`);
      showNotification(true, Messages.JobDeleted);
      tableRef.current?.refetchTableData();
    } catch (error) {
      showNotification(false, error.response.data.message || Messages.FailedToDeleteJob, true);
    }
  };

  const columns = useJobPostingColumns(orgId, handleDeleteJob);

  return (
    <div>
      <PageTitle
        title="Job Posting"
        addButtonLink={`/admin/${orgId}/job`}
        button={
          <div style={{ marginTop: 5, marginRight: 15 }}>
            <JobPostingsLinkWithCopier orgId={orgId} />
          </div>
        }
      />
      <Table<JobPostingFrontend>
        tableRef={tableRef}
        dataFetcherUrl={dataFetcherUrl}
        columnDefs={columns}
        dataFetcher={paginatedDataFetcher}
      />
    </div>
  );
};

export default JobPostingsContainer;
