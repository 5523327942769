import React, { useMemo, useState } from 'react';
import EmailAddress from '../../components/EmailAddress/EmailAddress';
import { CreditWithCustomer, moneyWithCurrencyCodeFormatter } from '@efacity/common';
import { Collapse, IconButton } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CreditsToRefundTable from './CreditsToRefundTable';
import { createColumnHelper } from '@tanstack/react-table';
import { TextColumnFilter } from '@efacity/table';

const columnHelper = createColumnHelper<CreditWithCustomer>();

export const useOrganizationCreditsColumns = (orgId: string) => {
  return useMemo(() => {
    return [
      columnHelper.accessor('creditFor.fullName', {
        header: 'Full Name',
        enableColumnFilter: true,
        enableSorting: false,
        cell: ({ row }) => {
          return row.original.creditFor.fullName;
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      }),
      columnHelper.accessor('creditFor.email', {
        header: 'Email',
        enableColumnFilter: true,
        enableSorting: false,
        cell: ({ row }) => {
          return <EmailAddress email={row.original.creditFor.email} />;
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      }),
      {
        header: 'Credit',
        accessor: (data) => moneyWithCurrencyCodeFormatter(data.amount, data.currencyCode),
        cell: ({ row }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const [open, setOpen] = useState(false);
          return (
            <>
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <div style={{ paddingTop: 5, paddingRight: row?.original?.creditFromPayments?.length > 0 ? 10 : 40 }}>
                  {moneyWithCurrencyCodeFormatter(row.original.amount, row.original.currencyCode)}
                </div>
                {row?.original?.creditFromPayments?.length > 0 && (
                  <div>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => setOpen(!open)}
                      data-testid="collapseButton"
                    >
                      {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </div>
                )}
              </div>
              <Collapse in={open} style={{ display: 'block' }}>
                <CreditsToRefundTable
                  orgId={orgId}
                  creditId={row.original?._id}
                  creditFromPayments={row?.original?.creditFromPayments || []}
                  currencyCode={row.original.currencyCode}
                  timeZone={row.original.timeZone}
                  allowRefund={true}
                />
              </Collapse>
            </>
          );
        },
        meta: {
          headerSx: { textAlign: 'right', paddingRight: '50px' },
          columnSx: { textAlign: 'right' }
        }
      }
    ];
  }, [orgId]);
};
