import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CustomerWithStudents } from '@efacity/common';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import EditCustomerModal from '../../components/EditCustomerModal/EditCustomerModal';
import { PageTitle } from '@efacity/react-next-sc';
import { Table, TableRef, usePaginatedLoaderWithAdditionalInfo } from '@efacity/table';
import useCustomersTableColumns from './useCustomersTableColumns';

const OrganizationCustomersTable: React.FC = () => {
  const { orgId } = useParams<OrgIdParamType>();
  const tableRef = useRef<TableRef<CustomerWithStudents>>(null);
  const dataFetchUrl = `/org/${orgId}/users/customers`;
  const { paginatedDataFetcher } = usePaginatedLoaderWithAdditionalInfo<CustomerWithStudents>({});

  const [customerUnderEdit, setCustomerUnderEdit] = useState<string>('');

  const onEditCustomerInfo = (customerId: string) => {
    setCustomerUnderEdit(customerId);
  };
  const handleCustomerModalClose = async (reloadData: boolean) => {
    if (reloadData) {
      tableRef.current?.refetchTableData();
    }
    setCustomerUnderEdit('');
  };
  const columns = useCustomersTableColumns(onEditCustomerInfo);

  return (
    <div>
      <PageTitle title="Customers" addButtonLink={undefined} />
      <Table<CustomerWithStudents>
        tableRef={tableRef}
        dataFetcherUrl={dataFetchUrl}
        columnDefs={columns}
        dataFetcher={paginatedDataFetcher}
        noDataText="No customers..."
        initialSortBy={[{ id: 'fullName', desc: false }]}
      />
      {customerUnderEdit && (
        <EditCustomerModal orgId={orgId} customerId={customerUnderEdit} onClose={handleCustomerModalClose} />
      )}
    </div>
  );
};

export default OrganizationCustomersTable;
