import { apiService, Discount, getMergedFormValues } from '@efacity/frontend-shared';
import { useEffect, useState } from 'react';
import { initialDiscountFormValues } from '../pages/Discounts/initialDiscountFormValues';
import { convertUtcToZonedTime, CurrencyCode, DiscountType, FormMode, randomString } from '@efacity/common';
import { showNotification } from '@efacity/frontend-next-shared/notifications';

export const useDiscountFormValues = (
  orgId: string,
  discountId: string,
  currency: CurrencyCode,
  formMode: FormMode
) => {
  const [discountFormState, setDiscountFormState] = useState({
    discountFormValue: { ...initialDiscountFormValues, currencyCode: currency },
    isLoading: false
  });

  useEffect(() => {
    const getDiscountById = async (orgId: string) => {
      setDiscountFormState({
        discountFormValue: { ...initialDiscountFormValues, currencyCode: currency },
        isLoading: true
      });

      try {
        const { data } = await apiService.get<Discount>(`/org/${orgId}/discount/${discountId}`);
        const discount = { ...data };
        delete discount._id;
        delete discount.createdOn;
        if (discount.typeDefinition.discountType === DiscountType.Percentage) {
          discount.typeDefinition.discountAmount = 0;
        } else if (discount.typeDefinition.discountType === DiscountType.Amount) {
          discount.typeDefinition.discountPercentage = 0;
        }
        discount.expiresOn = convertUtcToZonedTime(new Date(discount.expiresOn), (discount as any).timeZone);

        setDiscountFormState((discountFormState) => ({
          ...discountFormState,
          discountFormValue: getMergedFormValues({ ...initialDiscountFormValues, currencyCode: currency }, discount),
          isLoading: false
        }));
      } catch (error) {
        showNotification(false, error.message || 'Failed to get Discount info.', true);
        setDiscountFormState({
          discountFormValue: { ...initialDiscountFormValues, currencyCode: currency },
          isLoading: true
        });
      }
    };
    if (formMode === FormMode.Add) {
      setDiscountFormState((discountFormState) => ({
        ...discountFormState,
        discountFormValue: {
          ...initialDiscountFormValues,
          currencyCode: currency,
          code: `Coupon_${randomString(10)}`
        }
      }));
    } else {
      getDiscountById(orgId);
    }
  }, [orgId, discountId, formMode, currency]);

  return {
    discountFormState
  };
};
