import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { apiService } from '@efacity/frontend-shared';
import { Messages } from '@efacity/common';
import { PATHS } from '@efacity/routing';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { ConfirmConnectPrompt } from './ConfirmConnectPrompt';

export const StandardConnectOrganizationToEfacityConfirmation: React.FC = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const { code } = queryString.parse(search);

  const orgId = localStorage.getItem('orgId');
  localStorage.removeItem('orgId');
  const connectName = localStorage.getItem('connectName');
  localStorage.removeItem('connectName');

  useEffect(() => {
    const returnToSiteAdminSection = () => {
      const redirectPath = PATHS.schools;
      navigate(redirectPath);
    };

    if (code && connectName && orgId) {
      const connectData = {
        code: code,
        orgId: orgId,
        name: connectName
      };

      apiService.post(`/stripe/callbackForEfacity`, connectData).then(
        (result: any) => {
          showNotification(true, result.data.message as string, false);
          returnToSiteAdminSection();
        },
        (error) => {
          showNotification(false, error.response.data.message as string, true);
          returnToSiteAdminSection();
        }
      );
    } else {
      showNotification(false, Messages.FailedStripeStandardConnect, true);
      returnToSiteAdminSection();
    }
  });

  return <ConfirmConnectPrompt />;
};
