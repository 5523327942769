import React, { useRef } from 'react';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import { useParams } from 'react-router-dom';
import { InstanceTemporality, Tag } from '@efacity/common';
import { Table, TableRef, usePaginatedLoaderWithAdditionalInfo } from '@efacity/table';
import useTagsTableColumns from './useTagsTableColumns';

export interface TagsTableProps {
  temporality: InstanceTemporality;
}

const TagsTable: React.FC<TagsTableProps> = ({ temporality }) => {
  const { orgId } = useParams<OrgIdParamType>();
  const tableRef = useRef<TableRef<Tag>>(null);
  const dataFetcherUrl = `/org/${orgId}/tags/${temporality}`;
  const { paginatedDataFetcher } = usePaginatedLoaderWithAdditionalInfo<Tag>();

  const columns = useTagsTableColumns(orgId);

  return (
    <Table<Tag>
      tableRef={tableRef}
      dataFetcherUrl={dataFetcherUrl}
      columnDefs={columns}
      dataFetcher={paginatedDataFetcher}
      noDataText={`No ${temporality} tags...`}
      manualFiltering={false}
      manualSorting={false}
    />
  );
};

export default TagsTable;
