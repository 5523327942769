import {
  adminAndIndependentRoles,
  adminsTeachersAndIndependentRoles,
  customerStudentRoles,
  FormMode,
  InstanceActivity,
  InstanceTemporality,
  Roles
} from '@efacity/common';
import React, { Suspense } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ConfirmationPage, EfacityActionLogo } from '@efacity/frontend-shared';
import { NotFound } from '@efacity/react-next-sc';
import { PATHS } from '@efacity/routing';
import AdditionalSessionOptionsContainer from '../../pages/AdditionalSessionOptionsContainer/AdditionalSessionOptionsContainer';
import AdditionalSessionOptionForm from '../../pages/AdditionalSessionOptionsContainer/AdditionalSessionOptionsForm';
import AttendanceContainer from '../../pages/Attendance/AttendanceContainer';
import { AuthPage } from '../../pages/Auth/AuthPage';
import { CenterSettingsContainer } from '../../pages/CentersContainer/CenterSettingsContainer';
import { CentersContainer } from '../../pages/CentersContainer/CentersContainer';
import CustomerStudentsContainer from '../../pages/CustomerStudents/CustomerStudentsContainer';
import CustomerRegistrationsContainer from '../../pages/CustomerRegistrations/CustomerRegistrationsContainer';
import DiscountsForm from '../../pages/Discounts/DiscountForm/DiscountForm';
import DiscountsContainer from '../../pages/Discounts/DiscountsContainer';
import InvoicePaymentByAdminContainer from '../../pages/PaymentContainer/InvoicePaymentByAdminContainer';
import InvoicePaymentByCustomerContainer from '../../pages/PaymentContainer/InvoicePaymentByCustomerContainer';
import { OrganizationsContainer } from '../../pages/SiteAdminOrganizationsContainer/OrganizationsContainer';
import { SiteAdminOrganizationFormContainer } from '../../pages/SiteAdminOrganizationsContainer/OrganizationForm/SiteAdminOrganizationFormContainer';
import SelectAdditionalOptionsByAdministrator from '../../pages/SelectAdditionalSessionOptions/SelectAdditionalOptionsByAdministrator';
import SelectAdditionalOptionsByCustomer from '../../pages/SelectAdditionalSessionOptions/SelectAdditionalOptionsByCustomer';
import SelectStudentsForSessions from '../../pages/SelectStudentsForSessions/SelectStudentsForSessions';
import SessionsListContainer from '../../pages/SessionsContainer/SessionsListContainer';
import { OrganizationSettingsContainer } from '../../pages/OrganizationSettings/OrganizationSettingsContainer';
import ShoppingCartContainer from '../../pages/ShoppingCartContainer/ShoppingCartContainer';
import StudentRegistrationsContainer from '../../pages/Student/StudentRegistrationsContainer';
import SiteAdminUsers from '../../pages/SiteAdminUsers/SiteAdminUsers';
import MainLayout from '../MainLayout/MainLayout';
import NextPageRedirect from '../../pages/NextPageRedirect';
import SiteAdminEditUser from '../../pages/SiteAdminUsers/SiteAdminEditUserContainer';
import UserSettings from '../../pages/UserSettings/UserSettings';
import OrganizationCredits from '../../pages/OrganizationCredits/OrganizationCredits';
import OrganizationRegistrationsContainer from '../../pages/OrganizationRegistrations/OrganizationRegistrationsContainer';
import TagsContainer from '../../pages/Tags/TagsContainer';
import TagForm from '../../pages/Tags/TagForm';
import CustomerStudentContainer from '../../pages/CustomerStudents/CustomerStudentContainer';
import PaymentPlatformsContainer from '../../pages/PaymentPlatforms/PaymentPlatformsContainer';
import PaymentPlatformForm from '../../pages/PaymentPlatforms/PaymentPlatformForm';
import { PaymentConfirmation } from '../../pages/PaymentConfirmation/PaymentConfirmation';
import InstructorsContainer from '../../pages/Instructors/InstructorsContainer';
import InstructorFormContainer from '../../pages/Instructors/InstructorFormContainer';
import CustomerCreditsContainer from '../../pages/CustomerCredits/CustomerCreditsContainer';
import { InstructorRegistration } from '../../pages/InstructorRegistration/InstructorRegistration';
import AuthChecker from './AuthChecker';
import { AuthStatusChecker } from '../AuthStatusChecker/AuthStatusChecker';
import OrganizationRegistrationsTable from '../../pages/OrganizationRegistrations/OrganizationRegistrationsTable';
import SessionsTable from '../../pages/SessionsContainer/SessionsTable';
import DiscountsTable from '../../pages/Discounts/DiscountsTable';
import AdditionalSessionOptionsTable from '../../pages/AdditionalSessionOptionsContainer/AdditionalSessionOptionsTable/AdditionalSessionOptionsTable';
import TagsTable from '../../pages/Tags/TagsTable';
import PaymentPlatformsTable from '../../pages/PaymentPlatforms/PaymentPlatformsTable/PaymentPlatformsTable';
import ActivityWithCertificatesContainer from '../../pages/ActivitiesContainer/Certificates/ActivityWithCertificatesContainer';
import OrganizationCertificatesContainer from '../../pages/OrganizationCertificates/OrganizationCertificatesContainer';
import ProductsContainer from '../../pages/Products/ProductsContainer';
import ProductForm from '../../pages/Products/ProductForm';
import OrganizationCustomersTable from '../../pages/OrganizationCustomers/OrrganizationCustomersTable';
import OrganizationStudentsTable from '../../pages/OrganizationStudents/OrganizationStudentsTable';
import { StandardConnectConfirmationForCenter } from '../../pages/ConnectConfirmation/StandardConnectConfirmationForCenter';
import JobPostingsContainer from '../../pages/JobPosting/JobPostingsContainer';
import JobPostingFormContainer from '../../pages/JobPosting/JobPostingFormContainer';
import MessagesContainer from '../../pages/Messages/MessagesContainer';
import LoginAuditContainer from '../../pages/LoginAudit/LoginAuditContainer';
import UserCertificates from '../../pages/UserCertificates/UserCertificates';
import SiteAdminReviewsContainer from '../../pages/SiteAdminReviewsContainer/SiteAdminReviewsContainer';
import ProductPurchasesContainer from '../../pages/ProductPurchases/ProductPurchasesContainer';
import CustomerProductPurchasesContainer from '../../pages/CustomerProductPurchases/CustomerProductPurchasesContainer';
import PaymentsContainer from '../../pages/OrganizationPayments/PaymentsContainer';
import UserMainSettingsContainer from '../../pages/UserSettings/UserMainSettingsContainer';
import UserPublicInfoForm from '../../pages/UserSettings/UserPublicInfoForm';
import { StandardConnectConfirmationForPaymentPlatform } from '../../pages/ConnectConfirmation/StandardConnectConfirmationForPaymentPlatform';
import { StandardConnectOrganizationToEfacityConfirmation } from '../../pages/ConnectConfirmation/StandardConnectOrganizationToEfacityConfirmation';
import EnrollmentsContainer from '../../pages/Enrollments/EnrollmentsContainer';
import UserEnrollmentApplications from '../../pages/Enrollments/UserEnrollmentApplications';
import EnrollmentViewContainerForAdmin from '../../pages/Enrollments/EnrollmentViewContainerForAdmin';

const SessionContainer = React.lazy(() => import('../../pages/SessionsContainer/SessionContainer'));
const ActivitiesListContainer = React.lazy(() => import('../../pages/ActivitiesContainer/ActivitiesListContainer'));

export const Routing: React.FC = () => {
  const { pathname, search } = useLocation();

  return (
    <Suspense fallback={<EfacityActionLogo message={'Loading...'} />}>
      <Routes>
        <Route element={<AuthStatusChecker />}>
          <Route path={PATHS.landing} element={<NextPageRedirect />} />
          <Route path={PATHS.about} element={<NextPageRedirect />} />
          <Route path={PATHS.enrollment} element={<NextPageRedirect path={`${pathname}${search}`} />} />
          <Route path={`${PATHS.auth}/*`} element={<AuthPage />} />
          <Route path={PATHS.checkEmail} element={<ConfirmationPage />} />
          <Route path={PATHS.instructorRegistration} element={<InstructorRegistration />} />
          <Route path={PATHS.paymentConfirmation} element={<PaymentConfirmation />} />
          <Route path={PATHS.connectConfirmation} element={<StandardConnectConfirmationForCenter />} />
          <Route
            path={PATHS.connectSchoolToEfacityConfirmation}
            element={<StandardConnectOrganizationToEfacityConfirmation />}
          />
          <Route
            path={PATHS.connectToPaymentPlatformConfirmation}
            element={<StandardConnectConfirmationForPaymentPlatform />}
          />
          <Route element={<MainLayout />}>
            <Route
              path={PATHS.userSettings}
              element={
                <AuthChecker
                  acceptableRoles={[...customerStudentRoles, ...adminsTeachersAndIndependentRoles, Roles.SiteAdmin]}
                >
                  <UserSettings />
                </AuthChecker>
              }
            >
              <Route path="main" element={<UserMainSettingsContainer />} />
              <Route path="public" element={<UserPublicInfoForm />} />
              <Route index element={<Navigate replace to="main" />} />
            </Route>
            {/* Admin and independent teacher */}
            <Route
              path={`${PATHS.registrationsForAdmin}`}
              element={
                <AuthChecker acceptableRoles={adminAndIndependentRoles}>
                  <OrganizationRegistrationsContainer />
                </AuthChecker>
              }
            >
              <Route
                path={InstanceTemporality.Current}
                element={<OrganizationRegistrationsTable registrationType={InstanceTemporality.Current} />}
              />
              <Route
                path={InstanceTemporality.Historic}
                element={<OrganizationRegistrationsTable registrationType={InstanceTemporality.Historic} />}
              />
              <Route
                path={InstanceTemporality.Canceled}
                element={<OrganizationRegistrationsTable registrationType={InstanceTemporality.Canceled} />}
              />
              <Route index element={<Navigate to={InstanceTemporality.Current} />} />
            </Route>
            <Route
              path={PATHS.sessions}
              element={
                <AuthChecker acceptableRoles={adminsTeachersAndIndependentRoles}>
                  <SessionsListContainer />
                </AuthChecker>
              }
            >
              <Route path="current" element={<SessionsTable sessionType={InstanceTemporality.Current} />} />
              <Route path="completed" element={<SessionsTable sessionType={InstanceTemporality.Completed} />} />
              <Route path="canceled" element={<SessionsTable sessionType={InstanceTemporality.Canceled} />} />
              <Route
                path="tags"
                element={
                  <AuthChecker acceptableRoles={adminAndIndependentRoles}>
                    <TagsContainer />
                  </AuthChecker>
                }
              >
                <Route path="current" element={<TagsTable temporality={InstanceTemporality.Current} />} />
                <Route path="expired" element={<TagsTable temporality={InstanceTemporality.Expired} />} />
                <Route index element={<Navigate to="current" />} />
              </Route>
              <Route
                path="additionalOptions"
                element={
                  <AuthChecker acceptableRoles={adminAndIndependentRoles}>
                    <AdditionalSessionOptionsContainer />
                  </AuthChecker>
                }
              >
                <Route path="current" element={<AdditionalSessionOptionsTable />} />
                <Route
                  path="expired"
                  element={<AdditionalSessionOptionsTable temporality={InstanceTemporality.Expired} />}
                />
                <Route index element={<Navigate replace to="current" />} />
              </Route>
              <Route index element={<Navigate to="current" />} />
            </Route>
            <Route
              path={`${PATHS.addSession}/*`}
              element={
                <AuthChecker acceptableRoles={adminsTeachersAndIndependentRoles}>
                  <SessionContainer />
                </AuthChecker>
              }
            />
            <Route
              path={`${PATHS.session}/*`}
              element={
                <AuthChecker acceptableRoles={adminsTeachersAndIndependentRoles}>
                  <SessionContainer />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.attendance}
              element={
                <AuthChecker acceptableRoles={adminsTeachersAndIndependentRoles}>
                  <AttendanceContainer />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.discounts}
              element={
                <AuthChecker acceptableRoles={adminAndIndependentRoles}>
                  <DiscountsContainer />
                </AuthChecker>
              }
            >
              <Route path="current" element={<DiscountsTable temporality={InstanceTemporality.Current} />} />
              <Route path="expired" element={<DiscountsTable temporality={InstanceTemporality.Expired} />} />
              <Route index element={<Navigate to="current" />} />
            </Route>
            <Route
              path={PATHS.editDiscount}
              element={
                <AuthChecker acceptableRoles={adminAndIndependentRoles}>
                  <DiscountsForm mode={FormMode.Edit} />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.addDiscount}
              element={
                <AuthChecker acceptableRoles={adminAndIndependentRoles}>
                  <DiscountsForm mode={FormMode.Add} />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.editAdditionalOption}
              element={
                <AuthChecker acceptableRoles={adminAndIndependentRoles}>
                  <AdditionalSessionOptionForm mode={FormMode.Edit} />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.addAdditionalOption}
              element={
                <AuthChecker acceptableRoles={adminAndIndependentRoles}>
                  <AdditionalSessionOptionForm mode={FormMode.Add} />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.messages}
              element={
                <AuthChecker acceptableRoles={adminAndIndependentRoles}>
                  <MessagesContainer />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.products}
              element={
                <AuthChecker acceptableRoles={adminAndIndependentRoles}>
                  <ProductsContainer />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.editProduct}
              element={
                <AuthChecker acceptableRoles={adminAndIndependentRoles}>
                  <ProductForm mode={FormMode.Edit} />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.addProduct}
              element={
                <AuthChecker acceptableRoles={adminAndIndependentRoles}>
                  <ProductForm mode={FormMode.Add} />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.productPurchases}
              element={
                <AuthChecker acceptableRoles={adminAndIndependentRoles}>
                  <ProductPurchasesContainer />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.jobs}
              element={
                <AuthChecker acceptableRoles={adminAndIndependentRoles}>
                  <JobPostingsContainer />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.editJob}
              element={
                <AuthChecker acceptableRoles={adminAndIndependentRoles}>
                  <JobPostingFormContainer mode={FormMode.Edit} />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.addJob}
              element={
                <AuthChecker acceptableRoles={adminAndIndependentRoles}>
                  <JobPostingFormContainer mode={FormMode.Add} />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.certificates}
              element={
                <AuthChecker acceptableRoles={adminsTeachersAndIndependentRoles}>
                  <OrganizationCertificatesContainer />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.userCertificates}
              element={
                <AuthChecker acceptableRoles={adminsTeachersAndIndependentRoles}>
                  <UserCertificates />
                </AuthChecker>
              }
            />

            <Route
              path={PATHS.editTag}
              element={
                <AuthChecker acceptableRoles={adminAndIndependentRoles}>
                  <TagForm mode={FormMode.Edit} />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.addTag}
              element={
                <AuthChecker acceptableRoles={adminAndIndependentRoles}>
                  <TagForm mode={FormMode.Add} />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.payments}
              element={
                <AuthChecker acceptableRoles={adminsTeachersAndIndependentRoles}>
                  <PaymentsContainer />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.organizationCredits}
              element={
                <AuthChecker acceptableRoles={adminAndIndependentRoles}>
                  <OrganizationCredits />
                </AuthChecker>
              }
            />
            <Route
              path={`${PATHS.addActivity}/*`}
              element={
                <AuthChecker acceptableRoles={adminsTeachersAndIndependentRoles}>
                  <ActivityWithCertificatesContainer mode={FormMode.Add} />
                </AuthChecker>
              }
            />
            <Route
              path={`${PATHS.editActivity}/*`}
              element={
                <AuthChecker acceptableRoles={adminsTeachersAndIndependentRoles}>
                  <ActivityWithCertificatesContainer mode={FormMode.Edit} />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.activities}
              element={
                <AuthChecker acceptableRoles={adminsTeachersAndIndependentRoles}>
                  <ActivitiesListContainer />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.courses}
              element={
                <AuthChecker acceptableRoles={adminsTeachersAndIndependentRoles}>
                  <ActivitiesListContainer />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.instructors}
              element={
                <AuthChecker acceptableRoles={adminsTeachersAndIndependentRoles}>
                  <InstructorsContainer />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.editInstructor}
              element={
                <AuthChecker acceptableRoles={adminsTeachersAndIndependentRoles}>
                  <InstructorFormContainer mode={FormMode.Edit} />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.addInstructor}
              element={
                <AuthChecker acceptableRoles={adminsTeachersAndIndependentRoles}>
                  <InstructorFormContainer mode={FormMode.Add} />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.centers}
              element={
                <AuthChecker acceptableRoles={[Roles.SchoolAdmin]}>
                  <CentersContainer />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.addCenter}
              element={
                <AuthChecker acceptableRoles={[Roles.SchoolAdmin]}>
                  <CenterSettingsContainer mode={FormMode.Add} />
                </AuthChecker>
              }
            />
            <Route
              path={`${PATHS.editCenter}/*`}
              element={
                <AuthChecker acceptableRoles={[Roles.SchoolAdmin]}>
                  <CenterSettingsContainer mode={FormMode.Edit} />
                </AuthChecker>
              }
            />
            <Route
              path={`${PATHS.organizationStudents}`}
              element={
                <AuthChecker acceptableRoles={adminAndIndependentRoles}>
                  <OrganizationStudentsTable />
                </AuthChecker>
              }
            />
            <Route
              path={`${PATHS.studentEnrollments}`}
              element={
                <AuthChecker acceptableRoles={adminAndIndependentRoles}>
                  <EnrollmentsContainer />
                </AuthChecker>
              }
            />
            <Route
              path={`${PATHS.studentEnrollment}`}
              element={
                <AuthChecker acceptableRoles={adminAndIndependentRoles}>
                  <EnrollmentViewContainerForAdmin />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.usersEnrollmentApplications}
              element={
                <AuthChecker>
                  <UserEnrollmentApplications />
                </AuthChecker>
              }
            />
            <Route
              path={`${PATHS.organizationCustomers}`}
              element={
                <AuthChecker acceptableRoles={adminAndIndependentRoles}>
                  <OrganizationCustomersTable />
                </AuthChecker>
              }
            />
            <Route
              path={`${PATHS.organizationSettings}/*`}
              element={
                <AuthChecker acceptableRoles={adminAndIndependentRoles}>
                  <OrganizationSettingsContainer />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.invoiceAdditionalOptions}
              element={
                <AuthChecker acceptableRoles={adminAndIndependentRoles}>
                  <SelectAdditionalOptionsByAdministrator />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.invoice}
              element={
                <AuthChecker acceptableRoles={adminAndIndependentRoles}>
                  <InvoicePaymentByAdminContainer />
                </AuthChecker>
              }
            />
            {/* Site admin */}
            <Route
              path={PATHS.schools}
              element={
                <AuthChecker acceptableRoles={[Roles.SiteAdmin]}>
                  <OrganizationsContainer />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.siteUsers}
              element={
                <AuthChecker acceptableRoles={[Roles.SiteAdmin]}>
                  <SiteAdminUsers />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.siteAdminAddUser}
              element={
                <AuthChecker acceptableRoles={[Roles.SiteAdmin]}>
                  <SiteAdminEditUser mode={FormMode.Add} />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.siteAdminEditUser}
              element={
                <AuthChecker acceptableRoles={[Roles.SiteAdmin]}>
                  <SiteAdminEditUser mode={FormMode.Edit} />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.addSchool}
              element={
                <AuthChecker acceptableRoles={[Roles.SiteAdmin]}>
                  <SiteAdminOrganizationFormContainer mode={FormMode.Add} />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.loginAudit}
              element={
                <AuthChecker acceptableRoles={[Roles.SiteAdmin]}>
                  <LoginAuditContainer />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.reviews}
              element={
                <AuthChecker acceptableRoles={[Roles.SiteAdmin]}>
                  <SiteAdminReviewsContainer />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.paymentPlatforms}
              element={
                <AuthChecker acceptableRoles={[Roles.SiteAdmin]}>
                  <PaymentPlatformsContainer />
                </AuthChecker>
              }
            >
              <Route
                path={InstanceActivity.active}
                element={<PaymentPlatformsTable instanceActivity={InstanceActivity.active} />}
              />
              <Route
                path={InstanceActivity.deleted}
                element={<PaymentPlatformsTable instanceActivity={InstanceActivity.deleted} />}
              />
              <Route index element={<Navigate to={InstanceActivity.active} />} />
            </Route>
            <Route
              path={PATHS.addPaymentPlatform}
              element={
                <AuthChecker acceptableRoles={[Roles.SiteAdmin]}>
                  <PaymentPlatformForm mode={FormMode.Add} />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.editPaymentPlatform}
              element={
                <AuthChecker acceptableRoles={[Roles.SiteAdmin]}>
                  <PaymentPlatformForm mode={FormMode.Edit} />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.editSchool}
              element={
                <AuthChecker acceptableRoles={[Roles.SiteAdmin, Roles.SchoolAdmin]}>
                  <SiteAdminOrganizationFormContainer mode={FormMode.Edit} />
                </AuthChecker>
              }
            />
            {/* Customer and student */}
            <Route
              path={PATHS.selectStudentsForSessions}
              element={
                <AuthChecker acceptableRoles={customerStudentRoles}>
                  <SelectStudentsForSessions />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.selectAdditionalOptionsByCustomer}
              element={
                <AuthChecker acceptableRoles={customerStudentRoles}>
                  <SelectAdditionalOptionsByCustomer />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.cart}
              element={
                <AuthChecker acceptableRoles={customerStudentRoles}>
                  <ShoppingCartContainer />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.payment}
              element={
                <AuthChecker acceptableRoles={customerStudentRoles}>
                  <InvoicePaymentByCustomerContainer />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.registrations}
              element={
                <AuthChecker acceptableRoles={customerStudentRoles}>
                  <CustomerRegistrationsContainer />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.customerPurchases}
              element={
                <AuthChecker acceptableRoles={customerStudentRoles}>
                  <CustomerProductPurchasesContainer />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.customerCredits}
              element={
                <AuthChecker acceptableRoles={customerStudentRoles}>
                  <CustomerCreditsContainer />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.students}
              element={
                <AuthChecker acceptableRoles={customerStudentRoles}>
                  <CustomerStudentsContainer />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.addStudent}
              element={
                <AuthChecker acceptableRoles={customerStudentRoles}>
                  <CustomerStudentContainer mode={FormMode.Add} />
                </AuthChecker>
              }
            />
            <Route
              path={PATHS.editStudent}
              element={
                <AuthChecker acceptableRoles={customerStudentRoles}>
                  <CustomerStudentContainer mode={FormMode.Edit} />
                </AuthChecker>
              }
            />
            {/* all roles */}
            <Route
              path={PATHS.studentSessions}
              element={
                <AuthChecker acceptableRoles={[]}>
                  <StudentRegistrationsContainer />
                </AuthChecker>
              }
            />
          </Route>

          <Route path={PATHS.sessionRegistration} element={<NextPageRedirect path={pathname} />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};
