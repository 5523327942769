import { Box, Grid, Tooltip, Typography } from '@mui/material';
import React, { FC, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import { apiService } from '@efacity/frontend-shared';
import { PATHS, toPath } from '@efacity/routing';
import { InstanceTemporality, Messages, RegistrationForOrganizationAdmin } from '@efacity/common';
import CancellationModal, { CancellationModalProps } from '../SessionsContainer/CancellationModal';
import SelectSessionModal from './SelectSessionModal';
import { handleSaveFile } from '../../utils/handleSaveFileFromResponse';
import { ExportButton } from '../../components/Buttons/ExportButton';
import NotesModal from '../../components/NotesModal/NotesModal';
import EditCustomerModal from '../../components/EditCustomerModal/EditCustomerModal';
import EditStudentWithCustomerInfoModal, {
  StudentWithCustomerInfoTabs
} from '../../components/EditStudentWithCustomerInfoModal/EditStudentWithCustomerInfoModal';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import {
  removeDateOffsetFromFilters,
  Table as TableNew,
  TableRef,
  usePaginatedLoaderWithAdditionalInfo,
  getFiltersQueryParameters,
  mapReactTableSortToApiSort
} from '@efacity/table';
import { useOrganizationRegistrationsColumns } from './useOrganizationRegistrationsColumns';

const emptyRegistrationToCancel = {
  registrationId: null,
  switchRegistration: false,
  cancellationAmount: '',
  studentId: '',
  sessionName: ''
};

interface AdminRegistrationsTableProps {
  registrationType: InstanceTemporality;
}

const emptyStudentUnderEdit = {
  studentId: '',
  studentName: '',
  tab: StudentWithCustomerInfoTabs.MAIN
};
const emptyCustomerUnderEdit = {
  customerId: ''
};

const emptyCustomerModalState = { orgId: '', customerId: '', name: '', url: '' };
const dateTimeFields = [
  'efacitySessionId.schedule.startDateTime',
  'efacitySessionId.schedule.endDateTime',
  'registeredAt'
];

const OrganizationRegistrationsTable: FC<AdminRegistrationsTableProps> = ({ registrationType }) => {
  const { orgId } = useParams<OrgIdParamType>();
  const navigate = useNavigate();
  const tableRef = useRef<TableRef<RegistrationForOrganizationAdmin>>(null);
  const dataFetcherUrl = `/org/${orgId}/registrations?registrationType=${registrationType}`;

  const [isRegistering, setIsRegistering] = useState(false);
  const [registrationCancelling, setRegistrationCancelling] = useState(false);
  const [selectSessionModalOpen, setSelectSessionModalOpen] = useState(false);
  const [switchRegistrationCustomer, setSwitchRegistrationCustomer] = useState(null);
  const [registrationToCancel, setRegistrationToCancel] = useState<{
    registrationId: string;
    switchRegistration: boolean;
    cancellationAmount: string;
    studentId: string;
    sessionName: string;
  }>(emptyRegistrationToCancel);

  const [notesModalState, setNotesModalState] = useState(emptyCustomerModalState);
  const onOpenNotesModal = (customerId: string, name: string, url: string) => {
    setNotesModalState({ orgId: orgId, customerId: customerId, name: name, url: url });
  };
  const onCloseNotesModal = () => {
    setNotesModalState(emptyCustomerModalState);
  };

  const handleOpenCancellationModal = async (
    registrationId: string,
    studentId: string,
    sessionName: string,
    switchRegistration: boolean,
    cancellationAmount: string
  ) => {
    setRegistrationToCancel({
      registrationId,
      switchRegistration,
      cancellationAmount,
      studentId,
      sessionName
    });
  };

  const [studentUnderEdit, setStudentUnderEdit] = useState(emptyStudentUnderEdit);
  const [customerUnderEdit, setCustomerUnderEdit] = useState(emptyCustomerUnderEdit);

  const columnsNew = useOrganizationRegistrationsColumns(
    orgId,
    handleOpenCancellationModal,
    onOpenNotesModal,
    registrationCancelling || isRegistering,
    setStudentUnderEdit,
    setCustomerUnderEdit
  );
  const { dataState, paginatedDataFetcher } = usePaginatedLoaderWithAdditionalInfo<RegistrationForOrganizationAdmin>({
    dateTimeFields: dateTimeFields
  });

  const handleCancelRegistration: CancellationModalProps['submitCallback'] = (cancellationReason, submitActions) => {
    setRegistrationCancelling(true);
    const { registrationId, switchRegistration } = registrationToCancel;

    apiService.patch(`/org/${orgId}/registrations/${registrationId}/cancel`, { cancellationReason }).then(
      async (result: any) => {
        setRegistrationCancelling(false);
        if (result.status === 202) {
          showNotification(true, result.data.message as string, true, 'Ok');
        }
        const { customerId } = result.data;
        setSwitchRegistrationCustomer(customerId);
        setRegistrationToCancel({
          ...registrationToCancel,
          registrationId: null
        });
        tableRef.current?.refetchTableData();
        if (switchRegistration) {
          setSelectSessionModalOpen(true);
        }
      },
      (error) => {
        setRegistrationCancelling(false);
        setSwitchRegistrationCustomer(null);
        if (error.response?.data?.validationErrors) {
          return submitActions.setValidationError(error.response?.data?.validationErrors.cancellationReason);
        }
        showNotification(false, (error.response.data.message as string) || Messages.FailedCancelRegistration, true);
      }
    );
  };

  const handleSessionToRegistrationSelected = async (sessionId: string) => {
    const { studentId } = registrationToCancel;
    setIsRegistering(true);
    apiService
      .post(`/org/${orgId}/registrations/${sessionId}/student/${studentId}/customer/${switchRegistrationCustomer}`, {})
      .then(
        async (result: any) => {
          setIsRegistering(false);
          setSelectSessionModalOpen(false);
          if (result.status === 202) {
            showNotification(false, result.data.message as string, true, 'Ok');
          }
          setRegistrationToCancel(emptyRegistrationToCancel);
          if (result.data?.invoiceId) {
            navigate(toPath(PATHS.invoiceAdditionalOptions, { orgId: orgId, invoiceId: result.data?.invoiceId }));
          }
        },
        (error) => {
          setIsRegistering(false);
          setSelectSessionModalOpen(false);
          setRegistrationToCancel(emptyRegistrationToCancel);
          showNotification(false, (error.response.data.message as string) || Messages.FailedRegisteredOnSession, true);
        }
      );
  };

  const handleCancellationModalClose = () => {
    setRegistrationToCancel(emptyRegistrationToCancel);
  };
  const handleSelectSessionModalClose = () => {
    setSelectSessionModalOpen(false);
  };

  const [isExporting, setIsExporting] = useState(false);
  const exportOrgRegistrations = () => {
    setIsExporting(true);
    const { dataFetcherArgs } = dataState;
    const { sorting, pagination, columnFilters } = dataFetcherArgs;

    apiService
      .get(
        `/org/${orgId}/registrations/export`,
        {
          page: pagination.pageIndex,
          perPage: pagination.pageSize,
          ...getFiltersQueryParameters(removeDateOffsetFromFilters(columnFilters, dateTimeFields)),
          sortBy: mapReactTableSortToApiSort(sorting || []),
          registrationType: registrationType
        },
        {
          'content-disposition': 'attachment'
        }
      )
      .then(
        async (result: any) => {
          setIsExporting(false);
          const contentTypeHeader = result.headers['content-type'];
          if (
            contentTypeHeader?.indexOf('text/csv') !== -1 ||
            contentTypeHeader?.indexOf('application/pdf') !== -1 ||
            contentTypeHeader?.indexOf('application/octet-stream') !== -1 ||
            contentTypeHeader?.indexOf('text/html') !== -1
          ) {
            handleSaveFile(result);
          } else {
            showNotification(false, Messages.UnsupportedFileType, true);
          }
        },
        (error) => {
          setIsExporting(false);
          showNotification(false, (error.response.data.message as string) || Messages.FailedExportRegistrations, true);
        }
      );
  };

  const handleCloseStudentEditModal = async (reloadData: boolean) => {
    setStudentUnderEdit(emptyStudentUnderEdit);
    if (reloadData) {
      await tableRef.current?.refetchTableData();
    }
  };
  const handleCustomerModalClose = async (reloadData: boolean) => {
    setCustomerUnderEdit(emptyCustomerUnderEdit);
    if (reloadData) {
      await tableRef.current?.refetchTableData();
    }
  };

  return (
    <Box>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Tooltip title="Only 1000 first records will be exported">
          <div>
            <ExportButton isExporting={isExporting} color={'primary'} onClick={exportOrgRegistrations} />
          </div>
        </Tooltip>
      </div>
      <Box paddingY={2}>
        <TableNew<RegistrationForOrganizationAdmin>
          tableRef={tableRef}
          dataFetcherUrl={dataFetcherUrl}
          columnDefs={columnsNew}
          dataFetcher={paginatedDataFetcher}
          noDataText="No registrations..."
        />
        {registrationToCancel?.registrationId && (
          <CancellationModal
            titleText={
              registrationToCancel.switchRegistration ? 'Switch registration' : 'Enter registration cancellation reason'
            }
            message={
              registrationToCancel.switchRegistration ? (
                <div>
                  <Grid container spacing={1} style={{ marginTop: 15 }}>
                    <Grid item xs={4} style={{ textAlign: 'right' }}>
                      Cancelled Session:
                    </Grid>
                    <Grid item xs={8}>
                      <Typography color={'primary'} style={{ fontWeight: 700 }}>
                        {registrationToCancel.sessionName}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: 'right' }}>
                      Credit to be added:
                    </Grid>
                    <Grid item xs={8}>
                      <Typography color={'primary'} style={{ fontWeight: 700 }}>
                        {registrationToCancel.cancellationAmount}
                      </Typography>
                    </Grid>
                  </Grid>
                  <br />
                  Enter cancellation reason.
                </div>
              ) : null
            }
            open={!!registrationToCancel.registrationId}
            isLoading={registrationCancelling}
            submitCallback={handleCancelRegistration}
            onClose={handleCancellationModalClose}
          />
        )}
        {selectSessionModalOpen && (
          <SelectSessionModal
            open={selectSessionModalOpen}
            orgId={orgId}
            studentId={registrationToCancel.studentId}
            onClose={handleSelectSessionModalClose}
            onSessionSelected={handleSessionToRegistrationSelected}
            isRegistering={isRegistering}
          />
        )}
        {studentUnderEdit.studentId && (
          <EditStudentWithCustomerInfoModal
            orgId={orgId}
            studentId={studentUnderEdit.studentId}
            studentName={studentUnderEdit.studentName}
            onClose={handleCloseStudentEditModal}
            open={!!studentUnderEdit.studentId}
            tab={studentUnderEdit.tab}
            setTab={(newTab: StudentWithCustomerInfoTabs) =>
              setStudentUnderEdit((state) => ({ ...state, tab: newTab }))
            }
          />
        )}
        {customerUnderEdit.customerId && (
          <EditCustomerModal
            orgId={orgId}
            customerId={customerUnderEdit.customerId}
            onClose={handleCustomerModalClose}
          />
        )}
      </Box>
      {notesModalState.customerId && (
        <NotesModal
          orgId={notesModalState.orgId}
          customerId={notesModalState.customerId}
          name={notesModalState.name}
          url={notesModalState.url}
          onClose={onCloseNotesModal}
        />
      )}
    </Box>
  );
};

export default OrganizationRegistrationsTable;
