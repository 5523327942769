import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import MustAgreeCheckedIcon from './MustAgreeCheckedIcon';
import MustAgreeUnCheckedIcon from './MustAgreeUnCheckedIcon';
import { adminSectionTableFontSize } from '@efacity/frontend-shared';
import CancelIconButton from '../../components/CancelIconButton';
import { Agreement, formatDateToMonthDayYearInLocale } from '@efacity/common';
import { createColumnHelper } from '@tanstack/react-table';
import { TextColumnFilter } from '@efacity/table';

const agreementNameStyle = {
  marginLeft: 6,
  cursor: 'pointer'
};

const columnHelper = createColumnHelper<Agreement>();

const useCustomerAgreementsTableColumns = (
  onSelectAgreement: (agreementId: string) => void,
  handleDeleteAgreement: (agreementId: string) => void
) => {
  return useMemo(() => {
    const columns = [
      columnHelper.accessor('agreementName', {
        header: 'Name',
        enableColumnFilter: true,
        enableSorting: true,
        cell: ({ row }) => {
          return (
            <div style={{ display: 'flex' }}>
              {row.original.customerMustSignAgreement ? (
                <MustAgreeCheckedIcon color="primary" />
              ) : (
                <MustAgreeUnCheckedIcon color="primary" />
              )}
              <Typography
                color="primary"
                style={agreementNameStyle}
                onClick={() => onSelectAgreement(row.original._id)}
              >
                {row.original.agreementName}
              </Typography>
            </div>
          );
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      }),
      columnHelper.accessor('createdAt', {
        header: 'Created on',
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ row }) => <>{formatDateToMonthDayYearInLocale(row.original.createdAt)}</>,
        meta: {
          headerSx: { width: '110px', maxWidth: '110px' },
          columnSx: { ...adminSectionTableFontSize, width: '110px', maxWidth: '110px' }
        }
      }),
      columnHelper.accessor('validFrom', {
        header: 'Valid From',
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => <>{formatDateToMonthDayYearInLocale(row.original.validFrom)}</>,
        meta: {
          headerSx: { width: '105px', maxWidth: '105px' },
          columnSx: { ...adminSectionTableFontSize, width: '105px', maxWidth: '105px' }
        }
      }),
      columnHelper.accessor('validTo', {
        header: 'Valid To',
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => <>{formatDateToMonthDayYearInLocale(row.original.validTo)}</>,
        meta: {
          headerSx: { width: '105px', maxWidth: '105px' },
          columnSx: { ...adminSectionTableFontSize, width: '105px', maxWidth: '105px' }
        }
      }),
      {
        header: '',
        id: 'deleteAction',
        cell: ({ row }) => {
          return (
            <CancelIconButton onClick={() => handleDeleteAgreement(row.original._id)} data-testid="delete-agreement" />
          );
        },
        meta: {
          headerSx: { width: '50px', maxWidth: '50px' },
          columnSx: { width: '50px', maxWidth: '50px' }
        }
      }
    ];

    return columns;
  }, [handleDeleteAgreement, onSelectAgreement]);
};

export default useCustomerAgreementsTableColumns;
