import { Box, FormHelperText, TextField } from '@mui/material';
import React, { ReactNode } from 'react';
import { moneyWithCurrencyCodeFormatter } from '@efacity/common';
import { CurrencyFormat } from '@efacity/react-hook-form-mui';
import { Column } from '@tanstack/react-table';

// started with @tanstack/react-table doc for range filter
// https://tanstack.com/table/latest/docs/framework/react/examples/filters-faceted
export const MoneyRangeColumnFilter = <TableData,>({ column }: { column: Column<TableData, unknown> }): ReactNode => {
  const { setFilterValue, getFacetedMinMaxValues } = column;

  let min = Number(getFacetedMinMaxValues()?.[0] ?? '');
  let max = Number(getFacetedMinMaxValues()?.[1] ?? '');

  const minValue = min ? min / 100 : '';
  const maxValue = max ? max / 100 : '';

  const handleMinValueChange = (e) => {
    const { value } = e.target;
    setFilterValue((columnFilter = []) => [value ? value : undefined, columnFilter[1]]);
  };

  const handleMaxValueChange = (e) => {
    const { value } = e.target;
    setFilterValue((columnFilter = []) => [columnFilter[0], value ? value : undefined]);
  };

  return (
    <Box display="flex" justifyContent="flex-end">
      <Box>
        <TextField
          name="minValue"
          value={minValue}
          size="small"
          variant="outlined"
          onChange={handleMinValueChange}
          inputProps={{
            style: { fontSize: 14, margin: 0, padding: 5 }
          }}
          InputProps={{
            inputComponent: CurrencyFormat
          }}
          placeholder="Min"
          style={{
            width: '75px',
            marginRight: '0.5rem',
            backgroundColor: 'white'
          }}
        />
        <FormHelperText>{moneyWithCurrencyCodeFormatter(min)}</FormHelperText>
      </Box>

      <Box>
        <TextField
          name="maxValue"
          value={maxValue}
          size="small"
          variant="outlined"
          onChange={handleMaxValueChange}
          placeholder="Max"
          inputProps={{
            style: { fontSize: 14, margin: 0, padding: 5 }
          }}
          InputProps={{
            inputComponent: CurrencyFormat
          }}
          style={{
            width: '75px',
            backgroundColor: 'white'
          }}
        />
        <FormHelperText>{moneyWithCurrencyCodeFormatter(max)}</FormHelperText>
      </Box>
    </Box>
  );
};

export default MoneyRangeColumnFilter;
