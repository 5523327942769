import { useState } from 'react';
import { apiService } from '@efacity/react-next-sc';
import { DataFetcherArgs, initialDataFetcherArgs } from '../types';
import { mapReactTableSortToApiSort } from './sortUtils';
import { getFiltersQueryParameters, removeDateOffsetFromFilters } from './filtersUtils';

export interface AdditionalData {
  [key: string]: any;
}
export interface DataWithAdditionalInfo<T> extends AdditionalData {
  data: T[];
  total: number;
  isLoading: boolean;
  dataFetcherArgs: DataFetcherArgs;
}

export interface PaginatedDataFetcherArgs {
  dateTimeFields?: string[];
  additionalFields?: string[];
  usePagination?: boolean;
  useAdditionalInfo?: boolean;
}
export const usePaginatedLoaderWithAdditionalInfo = <T>({
  dateTimeFields = [],
  usePagination = true
}: PaginatedDataFetcherArgs = {}) => {
  const [dataState, setDataState] = useState<DataWithAdditionalInfo<T>>({
    data: [],
    total: 0,
    isLoading: false,
    dataFetcherArgs: initialDataFetcherArgs
  });

  const paginatedDataFetcher = async (dataFetcherArgs: DataFetcherArgs) => {
    try {
      const { pagination, sorting, columnFilters, url } = dataFetcherArgs;

      if (usePagination) {
        const result = await apiService().get<{ data: T[]; total?: number } & AdditionalData>(url, {
          page: pagination.pageIndex,
          perPage: pagination.pageSize,
          sortBy: mapReactTableSortToApiSort(sorting ?? []),
          ...getFiltersQueryParameters(removeDateOffsetFromFilters(columnFilters, dateTimeFields ?? []))
        });
        const { data, total, ...additionalInfo } = result.data;
        const calculatedTotal = total ?? data?.length ?? 0;
        setDataState({
          data: data,
          total: calculatedTotal,
          isLoading: false,
          dataFetcherArgs: dataFetcherArgs,
          ...additionalInfo
        });

        return { data: data, total: calculatedTotal };
      } else {
        const result = await apiService().get<T[]>(url);
        const data = result.data;
        const total = (result?.data?.length ?? 0) as number;
        setDataState({
          data: data,
          total: total,
          isLoading: false,
          dataFetcherArgs: dataFetcherArgs
        });

        return { data: data, total: total };
      }
    } catch {
      setDataState((dataState) => ({
        ...dataState,
        dataFetcherArgs: dataFetcherArgs,
        isLoading: false
      }));

      return { data: [], total: 0 };
    }
  };

  return { dataState: dataState, paginatedDataFetcher: paginatedDataFetcher };
};
