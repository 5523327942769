import { AddressForm } from '../lib/AddressForm';

// TODO: This should be refactored to receive Address, convert it to complete address
export const generateGoogleMapsLinkForAddress = (address: string): string => {
  return `https://www.google.com/maps/place/${address.replace(/ /g, '+')}`;
};

export const generateCompleteAddressWithDivider = (address: Omit<AddressForm, '_id'> | undefined): string => {
  if (address) {
    let completeAddress = `${address.address}, ${address.city}`;
    if (address.state) {
      completeAddress = completeAddress + `, ${address.state}`;
    }
    if (address.zip) {
      completeAddress = completeAddress + `, ${address.zip}`;
    }
    completeAddress = completeAddress + `, ${address.country}`;
    return completeAddress;
  }
  return '[no address]';
};

export const generateCompleteAddress = (address: Omit<AddressForm, '_id'>): string => {
  if (address) {
    let completeAddress = `${address.address} ${address.city}`;
    if (address.state) {
      completeAddress = completeAddress + ` ${address.state}`;
    }
    if (address.zip) {
      completeAddress = completeAddress + ` ${address.zip}`;
    }
    completeAddress = completeAddress + ` ${address.country}`;
    return completeAddress;
  }
  return '[no address]';
};

export const generateShortAddress = (address: Omit<AddressForm, '_id'>): string => {
  return address ? `${address.address}, ${address.city}` : '[no address]';
};

export const locationNameWithShortAddress = (name: string, address: Omit<AddressForm, '_id'>): string => {
  return address ? `${name} (${address.address}, ${address.city})` : `${name} ([no address])`;
};
