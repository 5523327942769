import { FC, useState } from 'react';
import { apiService, WithLoading, ConfirmationModal } from '@efacity/frontend-shared';
import { AdminAccess, FormMode, Roles, getEmailsArray } from '@efacity/common';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { addServerErrors } from '@efacity/react-hook-form-mui';
import { useAuth } from '@efacity/react-next-sc';
import { PATHS, toPath } from '@efacity/routing';
import { useNavigate, useParams } from 'react-router-dom';
import { IdOrgIdParamTypes } from '@efacity/frontend-next-shared/utils';
import { CenterFormValues, useCenterFormValues } from '../useCenterFormValues';
import { CenterForm } from './CenterForm';

interface CenterFormContainerProps {
  mode: FormMode;
  paramCenterId?: string;
  handleCancelClick?: any;
}

export const CenterFormContainer: FC<CenterFormContainerProps> = ({ mode, paramCenterId, handleCancelClick }) => {
  const { orgId, id: centerId = paramCenterId } = useParams<IdOrgIdParamTypes>();
  const [{ centerFormValues, allActivities, isLoading }] = useCenterFormValues(orgId, centerId, mode);
  const [deletingAdmin, setDeletingAdmin] = useState(false);
  const {
    authState: { user },
    addAdminAccess
  } = useAuth();
  const navigate = useNavigate();

  const defaultConfirmationModalState = {
    isOpen: false,
    isOperating: false,
    message: '',
    onConfirm: null
  };
  const [confirmationModalState, setConfirmationModalState] = useState(defaultConfirmationModalState);

  const onDeleteCLick = (adminId) => {
    setConfirmationModalState({
      isOpen: true,
      isOperating: false,
      message:
        '<div style="display:flex;justify-content:center">You are about to delete an Admin.</div><br/>' +
        '<div style="display:flex;justify-content:center">Please, confirm this action.</div>',
      onConfirm: () => handleDeleteAdmin(adminId)
    });
  };

  const handleDeleteAdmin = async (adminId: string) => {
    try {
      setDeletingAdmin(true);
      await apiService.delete(`/org/${orgId}/centers/${centerId}/admins/${adminId}`);
      showNotification(true, 'Successfully deleted center administrator');
      setConfirmationModalState(defaultConfirmationModalState);
    } catch (error) {
      showNotification(false, error?.response?.data?.message || 'Cannot delete center administrator');
      setConfirmationModalState(defaultConfirmationModalState);
    } finally {
      setDeletingAdmin(false);
    }
  };

  const onCenterFormSubmit = async (
    formValues: CenterFormValues,
    setError: (name, error, options?) => void,
    logoImage: Blob,
    mobileLogoImage: Blob
  ) => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append(
        'centerSettings',
        JSON.stringify({
          ...formValues.centerSettings,
          additionalNotificationEmails: getEmailsArray(formValues?.centerSettings?.additionalNotificationEmails || ''),
          additionalNotificationEmailsToPrimarySchool: getEmailsArray(
            formValues?.centerSettings?.additionalNotificationEmailsToPrimarySchool || ''
          )
        })
      );
      formDataToSend.append('administrators', JSON.stringify(formValues.administrators));
      formDataToSend.append('allowedActivities', JSON.stringify(formValues.allowedActivities));

      if (logoImage) {
        formDataToSend.append('logoImage', logoImage);
      }

      if (mobileLogoImage) {
        formDataToSend.append('mobileLogoImage', mobileLogoImage);
      }

      const response =
        mode === FormMode.Add
          ? await apiService.post<any>(`/org/${orgId}/schools/centers`, formDataToSend)
          : await apiService.patch<any>(`/org/${orgId}/schools/centers/${centerId}`, formDataToSend);
      showNotification(true, response?.data?.message);

      if (mode === FormMode.Add) {
        const newAdminAccess: AdminAccess = {
          role: Roles.CenterAdmin,
          orgId: response.data?.data?.centerSettings?._id,
          orgName: formValues.centerSettings.name
        };
        addAdminAccess(newAdminAccess);
      }

      return navigate(toPath(PATHS.centers, { orgId: orgId }));
    } catch (error) {
      if (error.response?.data?.message) {
        showNotification(false, error.response?.data?.message);
      } else if (error.response?.data?.validationErrors) {
        addServerErrors<CenterFormValues>(error.response.data.validationErrors, setError);
      }
    }
  };

  return (
    <div>
      <WithLoading isLoading={isLoading} message={'Loading center data...'} style={{ width: '25%' }}>
        <CenterForm
          mode={mode}
          orgId={centerId}
          centerFormValues={{ ...centerFormValues, administrators: [] }}
          allActivities={allActivities}
          authenticatedUser={user}
          administrators={centerFormValues.administrators}
          handleSubmit={onCenterFormSubmit}
          onCancel={handleCancelClick}
          onDeleteOrgAdmin={onDeleteCLick}
          loading={isLoading}
          deletingOrgAdmin={deletingAdmin}
        />
      </WithLoading>
      {confirmationModalState.isOpen && (
        <ConfirmationModal
          open={confirmationModalState.isOpen}
          isOperating={confirmationModalState.isOperating}
          message={confirmationModalState.message}
          onClose={() => setConfirmationModalState(defaultConfirmationModalState)}
          onConfirm={() => confirmationModalState.onConfirm(confirmationModalState)}
        />
      )}
    </div>
  );
};
