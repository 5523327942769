import React, { useMemo } from 'react';
import { IconButton, Link } from '@mui/material';
import { Center, CircularProgressButton } from '@efacity/frontend-shared';
import { createColumnHelper } from '@tanstack/react-table';
import { TextColumnFilter } from '@efacity/table';
import { NavLink } from 'react-router-dom';
import { PATHS, toPath } from '@efacity/routing';
import { PhoneNumber } from '@efacity/react-next-sc';
import { ConnectStatus, connectStatusViews } from '@efacity/common';
import { smallPublishButtonStyle } from '../../components/Buttons/SessionPublishButton';
import DeleteIcon from '@mui/icons-material/DeleteForever';

const hiddenSm = {
  display: {
    xs: 'none',
    sm: 'table-cell'
  }
};

const columnHelper = createColumnHelper<Center>();

export const useCentersColumns = (
  orgId: string,
  onConnectCLick: (centerId: string, centerName: string) => void,
  onDisconnectCLick: (centerId: string, centerName: string) => void,
  onDeleteCenterCLick: (centerId: string, centerName: string) => void,
  connectModalOperating: boolean
) => {
  return useMemo(() => {
    const columns = [
      columnHelper.accessor('name', {
        header: 'Name',
        enableColumnFilter: true,
        enableSorting: true,
        cell: ({ row, getValue }) => (
          <Link
            component={NavLink}
            to={toPath(PATHS.editCenter, { orgId: orgId, id: row.original._id })}
            underline={'none'}
          >
            {getValue()}
          </Link>
        ),
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      }),
      columnHelper.accessor('phone', {
        header: 'Phone',
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ getValue }) => <PhoneNumber phone={getValue()} />,
        meta: {
          headerSx: { ...hiddenSm },
          columnSx: { ...hiddenSm }
        }
      }),
      columnHelper.accessor('connectStatus', {
        header: 'Connect Status',
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ getValue }) => <div>{connectStatusViews[getValue()]}</div>
      }),
      {
        header: '',
        id: 'center-actions',
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => (
          <div style={{ display: 'flex', justifyItems: 'center' }}>
            <div style={{ display: 'flex', justifyItems: 'center' }}>
              <CircularProgressButton
                progress={false}
                color="primary"
                onClick={() => {
                  row.original.connectStatus === ConnectStatus.ConnectedStandard
                    ? onDisconnectCLick(row.original._id, row.original.name)
                    : onConnectCLick(row.original._id, row.original.name);
                }}
                style={{ ...smallPublishButtonStyle, width: 100 }}
                data-testid={`connectButtonForCenter${row.original._id}`}
                disabled={connectModalOperating}
              >
                {row.original.connectStatus === ConnectStatus.ConnectedStandard ? 'Disconnect' : 'Connect'}
              </CircularProgressButton>
            </div>
            <IconButton
              color="primary"
              aria-label="delete center"
              data-testid="delete-center-btn"
              component="span"
              style={{ ...smallPublishButtonStyle, width: 45 }}
              onClick={() => onDeleteCenterCLick(row.original._id, row.original.name)}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        ),
        meta: {
          headerSx: { width: '180px', maxWidth: '180px' },
          columnSx: { width: '180px', maxWidth: '180px' }
        }
      }
    ];

    return columns;
  }, [orgId, onConnectCLick, onDisconnectCLick, onDeleteCenterCLick, connectModalOperating]);
};
