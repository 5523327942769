import { FormMode } from '@efacity/common';
import { PageTitle, useAuth } from '@efacity/react-next-sc';
import { PATHS, toPath } from '@efacity/routing';
import React, { FC, useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { IdOrgIdParamTypes } from '@efacity/frontend-next-shared/utils';
import { FormPageContainer } from '@efacity/frontend-next-shared/forms/server';
import PageNavigationTabs, { Tab } from '../../components/PageNavigatonTabs/PageNavigationTabs';
import { OrganizationAddressesContainer } from '../OrganizationSettings/Addresses/OrganizationAddressesContainer';
import { CenterFormContainer } from './CenterForm/CenterFormContainer';
import OrganizationAdminsContainer from '../OrganizationSettings/OrganizationAdmins/OrganizationAdminsContainer';

interface CenterFormContainerProps {
  mode: FormMode;
  paramCenterId?: string;
}

export const CenterSettingsContainer: FC<CenterFormContainerProps> = ({ mode, paramCenterId }) => {
  const { orgId, id: centerId = paramCenterId } = useParams<IdOrgIdParamTypes>();
  const navigate = useNavigate();
  const [tabsList, setTabsList] = useState<Tab[]>([
    {
      label: 'Center',
      link: '',
      disabled: false,
      end: true
    }
  ]);

  const {
    authState: { user }
  } = useAuth();

  useEffect(() => {
    if (mode === FormMode.Edit) {
      setTabsList([
        ...tabsList,
        { label: 'Addresses', link: 'addresses', disabled: false },
        { label: 'Admins', link: 'admins', disabled: false }
      ]);
    }
    // eslint-disable-next-line
  }, []);

  const handleCancelClick = () => {
    navigate(toPath(PATHS.centers, { orgId: orgId }));
  };

  return (
    <FormPageContainer>
      <PageTitle title={mode === FormMode.Add ? 'Add Center' : 'Edit Center'} underlined />
      {mode === FormMode.Edit && <PageNavigationTabs tabs={tabsList} />}
      <Routes>
        <Route
          path="/"
          element={<CenterFormContainer mode={mode} paramCenterId={orgId} handleCancelClick={handleCancelClick} />}
        />
        {mode === FormMode.Edit && (
          <>
            <Route path="addresses" element={<OrganizationAddressesContainer orgId={centerId} />} />
            <Route
              path="admins"
              element={
                <OrganizationAdminsContainer
                  authenticatedUserId={user._id}
                  orgId={centerId}
                  hasSchoolAdminAccess={false}
                  isCenterAdminsPage={true}
                />
              }
            />
          </>
        )}
      </Routes>
    </FormPageContainer>
  );
};
