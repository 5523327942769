import { createColumnHelper, TableOptions } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { adminSectionTableFontSize, Product } from '@efacity/frontend-shared';
import { TextColumnFilter } from '@efacity/table';
import { PATHS, toPath } from '@efacity/routing';
import { Box, IconButton, Link } from '@mui/material';
import { NavLink } from 'react-router-dom';
import ProductDescriptionLinkWithCopier from '../../components/ProductDescriptionLinkWithCopier/ProductDescriptionLinkWithCopier';
import { moneyWithCurrencyCodeFormatter } from '@efacity/common';
import { smallPublishButtonStyle } from '../../components/Buttons/SessionPublishButton';
import DeleteIcon from '@mui/icons-material/DeleteForever';

// Documentation propose as preferable way to create column definitions via typed helper
// https://tanstack.com/table/v8/docs/guide/migrating
const columnHelper = createColumnHelper<Product>();

export const useProductsTableColumnDefs = (
  orgId: string,
  handleDeleteKit: (kitId: string) => void
): TableOptions<Product>['columns'] => {
  return useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Name',
        enableColumnFilter: true,
        filterFn: 'includesString',
        enableSorting: true,
        sortingFn: 'alphanumeric', // use built-in sorting function by name
        cell: ({ row, getValue }) => {
          return (
            <Box display="flex" alignItems="center" data-testid="product-name-copier-cell">
              <Link
                component={NavLink}
                to={toPath(PATHS.editProduct, {
                  orgId: orgId,
                  productId: row.original._id
                })}
                underline={'none'}
              >
                {getValue()}
              </Link>
              &nbsp;&nbsp;
              <ProductDescriptionLinkWithCopier orgId={orgId} productId={row.original._id} />
            </Box>
          );
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      }),
      columnHelper.accessor('inventoryQuantity', {
        header: 'In Stock',
        enableColumnFilter: true,
        filterFn: 'includesString',
        enableSorting: true,
        sortingFn: 'alphanumeric', // use built-in sorting function by name
        cell: ({ getValue }) => <div>{getValue()}</div>,
        meta: {
          filter: (column) => <TextColumnFilter column={column} />,
          headerSx: { width: 150, maxWidth: 150 },
          columnSx: { width: 150, maxWidth: 150 }
        }
      }),
      columnHelper.accessor('price', {
        header: 'Price',
        enableSorting: true,
        sortingFn: 'basic', // use built-in sorting function by name
        filterFn: (row, columnId, filterValue) => {
          return (row.original.price * 100).toString().includes(filterValue);
        },
        cell: ({ row, getValue }) => {
          return <>{moneyWithCurrencyCodeFormatter(getValue(), row.original.currencyCode)}</>;
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />,
          headerSx: { textAlign: 'right', width: 135, maxWidth: 135 },
          columnSx: { textAlign: 'right', width: 135, maxWidth: 135 }
        }
      }),
      {
        header: '',
        id: 'actions-cell',
        enableSorting: false,
        cell: ({ row }) => {
          return (
            <IconButton
              color="primary"
              aria-label="delete product"
              data-testid="delete-product-btn"
              component="span"
              style={smallPublishButtonStyle}
              onClick={() => handleDeleteKit(row.original._id)}
            >
              <DeleteIcon />
            </IconButton>
          );
        },
        meta: {
          headerSx: { width: 75, maxWidth: 75 },
          columnSx: { ...adminSectionTableFontSize, width: 75, maxWidth: 75 }
        }
      }
    ],
    [orgId, handleDeleteKit]
  );
};
