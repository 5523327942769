import React, { useRef } from 'react';
import { Certificate } from '@efacity/frontend-shared';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import { useParams } from 'react-router-dom';
import { PageTitle } from '@efacity/react-next-sc';
import { Table as TableNew, TableRef, usePaginatedLoaderWithAdditionalInfo } from '@efacity/table';
import { useOrganizationCertificatesColumns } from './useOrganizationCertificatesColumns';

const OrganizationCertificatesContainer: React.FC = () => {
  const { orgId } = useParams<OrgIdParamType>();
  const tableRef = useRef<TableRef<Certificate>>(null);
  const fetchDataUrl = `/org/${orgId}/certificates`;
  const { paginatedDataFetcher } = usePaginatedLoaderWithAdditionalInfo<Certificate>({});
  const columns = useOrganizationCertificatesColumns(orgId);

  return (
    <div>
      <PageTitle title="Certificates" />
      <TableNew<Certificate>
        tableRef={tableRef}
        dataFetcherUrl={fetchDataUrl}
        columnDefs={columns}
        dataFetcher={paginatedDataFetcher}
        noDataText="No Certificates..."
        initialSortBy={[{ id: 'name', desc: false }]}
      />
    </div>
  );
};

export default OrganizationCertificatesContainer;
