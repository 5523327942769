import React, { useMemo, useState } from 'react';
import { Box, IconButton, Link, Tooltip } from '@mui/material';
import {
  getInvoiceFileName,
  getPaymentReceiptFileName,
  moneyWithCurrencyCodeFormatter,
  ProductPurchaseWithOrganization,
  PurchaseStatus,
  purchaseStatusViewConnector
} from '@efacity/common';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ColoredPurchaseStatusDate from './ColoredPurchaseStatusDate';
import ProductDescriptionLinkWithCopier from '../../components/ProductDescriptionLinkWithCopier/ProductDescriptionLinkWithCopier';
import { createColumnHelper } from '@tanstack/react-table';
import { TextColumnFilter, DateColumnFilter, PurchaseStatusFilter as PurchaseStatusFilterNew } from '@efacity/table';

const columnHelper = createColumnHelper<ProductPurchaseWithOrganization>();

export const useProductPurchasesColumns = (orgId: string, timeZone: string) => {
  return useMemo(() => {
    const columns = [
      columnHelper.accessor('productName', {
        header: 'Product Name',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ row, getValue }) => (
          <Box display="flex" alignItems="center" data-testid="product-name-copier-cell">
            {getValue()}&nbsp;&nbsp;
            <ProductDescriptionLinkWithCopier
              orgId={row.original.orgId as string}
              productId={row.original.productId as string}
            />
          </Box>
        ),
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      }),
      columnHelper.accessor('customerName', {
        header: 'Customer Name',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ getValue }) => <div>{getValue()}</div>,
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      }),
      columnHelper.accessor('status', {
        header: 'Status',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ getValue, row }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const [isExpanded, setIsExpanded] = useState(false);
          return (
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              {isExpanded ? (
                <div style={{ width: '100%' }}>
                  {row.original?.orderedAt && (
                    <ColoredPurchaseStatusDate
                      dateTime={row.original?.orderedAt}
                      timeZone={timeZone}
                      purchaseStatus={PurchaseStatus.Ordered}
                    />
                  )}
                  {row.original?.paidAt && (
                    <ColoredPurchaseStatusDate
                      dateTime={row.original?.paidAt}
                      timeZone={timeZone}
                      purchaseStatus={PurchaseStatus.Paid}
                    />
                  )}
                  {row.original?.shippedAt && (
                    <ColoredPurchaseStatusDate
                      dateTime={row.original?.shippedAt}
                      timeZone={timeZone}
                      purchaseStatus={PurchaseStatus.Shipped}
                    />
                  )}
                  {(row.original?.deliveryConfirmedByCustomerAt || row.original?.deliveryConfirmedByAdminAt) && (
                    <ColoredPurchaseStatusDate
                      dateTime={row.original?.deliveryConfirmedByCustomerAt || row.original?.deliveryConfirmedByAdminAt}
                      timeZone={timeZone}
                      purchaseStatus={PurchaseStatus.Delivered}
                    />
                  )}
                  {row.original?.cancelledAt && (
                    <ColoredPurchaseStatusDate
                      dateTime={row.original?.cancelledAt}
                      timeZone={timeZone}
                      purchaseStatus={PurchaseStatus.Cancelled}
                    />
                  )}
                </div>
              ) : (
                <div style={{ width: '100%' }}>{purchaseStatusViewConnector[getValue()]}</div>
              )}
              <div>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setIsExpanded(!isExpanded)}
                  data-testid="collapseButton"
                  style={{ marginTop: -10 }}
                >
                  {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </div>
            </div>
          );
        },
        meta: {
          filter: (column) => <PurchaseStatusFilterNew column={column} />,
          headerSx: { width: 280, maxWidth: 280 },
          columnSx: { width: 280, maxWidth: 280 }
        }
      }),
      columnHelper.accessor('updatedAt', {
        header: 'Status Updated',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ getValue, row }) => (
          <div>
            <ColoredPurchaseStatusDate
              dateTime={getValue()}
              timeZone={timeZone}
              purchaseStatus={row.original.status}
              showStatus={false}
            />
          </div>
        ),
        meta: {
          filter: (column) => <DateColumnFilter column={column} />,
          headerSx: { width: 190, maxWidth: 190 },
          columnSx: { width: 190, maxWidth: 190 }
        }
      }),
      {
        header: 'Balance',
        id: 'balance-cell',
        enableSorting: true,
        enableColumnFilter: true,
        canExpand: true,
        cell: ({ row }) => {
          return (
            <div>
              {moneyWithCurrencyCodeFormatter(
                row.original.invoiceBalance?.balance ? row.original.invoiceBalance?.balance : 0,
                row.original?.invoiceBalance?.currencyCode
              )}
              {/* documentations says to use getToggleExpandedHandler())
               but because we don't provide getSubRows() function, it's not working because check
               getCanExpand() depended on getSubRows() function.
               So, skip this check and manually call toggleExpanded() function.
               // https://github.com/TanStack/table/discussions/4115
              */}
              <span onClick={() => row.toggleExpanded()}>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  data-testid="collapseButton"
                  style={{ marginTop: -10 }}
                >
                  {row.getIsExpanded() ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </span>
            </div>
          );
        },
        meta: {
          headerSx: { textAlign: 'right', paddingRight: '35px', width: 115, maxWidth: 115 },
          columnSx: { textAlign: 'right', width: 115, maxWidth: 115 }
        }
      },
      {
        header: 'Invoice',
        id: 'invoice-cell',
        enableColumnFilter: false,
        cell: ({ row }) => {
          return (
            <div>
              {row.original.invoiceBalance ? (
                row.original.invoiceBalance?.isPaid ? (
                  <Tooltip title="Download Payment Receipt">
                    <Link href={getPaymentReceiptFileName(row.original.invoiceBalance?._id)}>Receipt</Link>
                  </Tooltip>
                ) : (
                  <Tooltip title="Download Invoice">
                    <Link href={getInvoiceFileName(row.original.invoiceBalance?._id)}>Invoice</Link>
                  </Tooltip>
                )
              ) : (
                ''
              )}
            </div>
          );
        },
        meta: {
          headerSx: { width: 65, maxWidth: 65 },
          columnSx: { width: 65, maxWidth: 65 }
        }
      }
    ];

    return columns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, timeZone]);
};
