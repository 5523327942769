import React, { useMemo } from 'react';
import { Link } from '@mui/material';
import { Certificate } from '@efacity/frontend-shared';
import { certificateTypesMapper, formatStringToMonthDayYearDate } from '@efacity/common';
import { createColumnHelper } from '@tanstack/react-table';
import { TextColumnFilter } from '@efacity/table';
import EmailAddress from '../../components/EmailAddress/EmailAddress';
import { NavLink } from 'react-router-dom';
import { PATHS, toPath } from '@efacity/routing';

const columnHelper = createColumnHelper<Certificate>();

export const useOrganizationCertificatesColumns = (orgId: string) => {
  return useMemo(() => {
    return [
      columnHelper.accessor('user.fullName', {
        header: 'Instructor Name',
        id: 'user.fullName',
        enableColumnFilter: true,
        enableSorting: true,
        cell: ({ getValue }) => {
          return getValue() || '[n/a]';
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      }),
      columnHelper.accessor('user.email', {
        header: 'Email',
        id: 'user.email',
        enableColumnFilter: true,
        enableSorting: true,
        cell: ({ getValue }) => {
          return getValue() ? <EmailAddress email={getValue()} /> : <>[n/a]</>;
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      }),
      columnHelper.accessor('type', {
        header: 'Certificate Type',
        enableSorting: true,
        enableColumnFilter: false,
        cell: ({ getValue }) => {
          return certificateTypesMapper[getValue()];
        }
      }),
      columnHelper.accessor('certificationForActivity.name', {
        header: 'Activity Name',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ row }) => {
          return (
            <Link
              component={NavLink}
              to={`${toPath(PATHS.editActivity, {
                orgId: orgId,
                id: row.original.certificationForActivity._id
              })}/certificates`}
              underline={'none'}
            >
              {row.original.certificationForActivity?.name || '[n/a]'}
            </Link>
          );
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      }),
      columnHelper.accessor('expiresOn', {
        header: 'Expires On',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ getValue }) => <>{formatStringToMonthDayYearDate(getValue())}</>
      })
    ];
  }, [orgId]);
};
