import { useCallback, useEffect, useState } from 'react';
import { apiService, getMergedFormValues } from '@efacity/frontend-shared';
import { isEmptyObject, Messages } from '@efacity/common';
import { addServerErrors } from '@efacity/react-hook-form-mui';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import {
  initialOrganizationEnrollmentsFormValues,
  OrganizationEnrollmentsFormValues
} from './organizationEnrollmentFormValues';

export interface OrganizationEnrollmentSettingsState {
  organizationEnrollments: OrganizationEnrollmentsFormValues;
  isLoading: boolean;
  isSubmitting: boolean;
}
export const initialOrganizationEnrollmentSettingsState: OrganizationEnrollmentSettingsState = {
  organizationEnrollments: initialOrganizationEnrollmentsFormValues,
  isLoading: true,
  isSubmitting: false
};

export const useOrganizationEnrollmentSettingsLoader = (orgId: string) => {
  const [organizationEnrollmentSettingsState, setOrganizationEnrollmentSettingsState] =
    useState<OrganizationEnrollmentSettingsState>(initialOrganizationEnrollmentSettingsState);

  const getOrgEnrollmentSettings = useCallback(async () => {
    setOrganizationEnrollmentSettingsState((state) => ({ ...state, isLoading: true }));
    try {
      const { data } = await apiService.get<OrganizationEnrollmentsFormValues>(`/org/${orgId}/enrollment-settings`);

      setOrganizationEnrollmentSettingsState((state) => ({
        ...state,
        organizationEnrollments: getMergedFormValues<OrganizationEnrollmentsFormValues>(
          initialOrganizationEnrollmentsFormValues,
          data
        ),
        isLoading: false
      }));
    } catch (error) {
      showNotification(false, error.message || 'Failed to get organization info.', true);
      setOrganizationEnrollmentSettingsState((state) => ({ ...state, isLoading: false }));
    }
  }, [orgId]);

  useEffect(() => {
    if (orgId) {
      getOrgEnrollmentSettings();
    }
  }, [orgId, getOrgEnrollmentSettings]);

  const updateOrganizationEnrollmentSettings = async (
    formValues: OrganizationEnrollmentsFormValues,
    setError: (name, error, options?) => void
  ): Promise<boolean> => {
    try {
      setOrganizationEnrollmentSettingsState((state) => ({ ...state, isSubmitting: true }));
      const { data } = await apiService.patch<{ message: string }>(`/org/${orgId}/enrollment-settings`, formValues);
      showNotification(true, data.message);
      return true;
    } catch (error) {
      const validationErrors = error.response?.data?.validationErrors;
      if (typeof validationErrors === 'object' && !isEmptyObject(validationErrors)) {
        addServerErrors<OrganizationEnrollmentsFormValues>(validationErrors, setError);
      } else {
        showNotification(false, error.response?.data?.message || Messages.FailedToUpdateOrganization, true);
      }
      return false;
    } finally {
      setOrganizationEnrollmentSettingsState((state) => ({ ...state, isSubmitting: false }));
    }
  };

  return { organizationEnrollmentSettingsState, updateOrganizationEnrollmentSettings };
};
