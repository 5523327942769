import React, { useEffect, useState } from 'react';
import { apiService, DiscountFormValues, ConfirmationModal } from '@efacity/frontend-shared';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import {
  Activity,
  CurrencyCode,
  DiscountClass,
  DiscountType,
  FormMode,
  isEmptyObject,
  Messages
} from '@efacity/common';
import { PATHS, toPath } from '@efacity/routing';
import { Box, Grid } from '@mui/material';
import { FormCancelSubmitButtons } from '@efacity/frontend-next-shared/forms';
import { useNavigate } from 'react-router-dom';
import { discountFormValuesValidationSchema } from '../DiscountFormValuesValidationSchema';
import { addServerErrors } from '@efacity/react-hook-form-mui';
import { useParams } from 'react-router-dom';
import { useDiscountFormValues } from '../../../hooks/useDiscountFormValues';
import { IdOrgIdParamTypes } from '@efacity/frontend-next-shared/utils';
import { useOrganizationRegionsOptions } from '../../../hooks/useOrganizationRegionsOptions';
import { addMinutes } from 'date-fns';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { DiscountBadgePreview } from '../DiscountBadgePreview';
import DiscountFormFields from './DiscountFormFields';
import { PageTitle } from '@efacity/react-next-sc';

export interface DiscountFormProps {
  mode: FormMode;
}

const DiscountForm: React.FC<DiscountFormProps> = ({ mode }) => {
  const navigate = useNavigate();

  const { id: discountId, orgId } = useParams<IdOrgIdParamTypes>();
  const {
    organizationRegions: { defaultCurrency: currency }
  } = useOrganizationRegionsOptions(orgId);
  const { discountFormState } = useDiscountFormValues(orgId, discountId, currency as CurrencyCode, mode);
  const [discountConflictModalOpen, setDiscountConflictModalOpen] = useState(false);

  const methods = useForm<DiscountFormValues>({
    resolver: yupResolver(discountFormValuesValidationSchema),
    defaultValues: discountFormState.discountFormValue,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUseNativeValidation: false
  });
  const { isSubmitting, isValid, isDirty } = methods.formState;

  const handleCancelClick = () => {
    navigate(toPath(PATHS.discounts, { orgId: orgId }));
  };

  const discountActionSuccessful = () => {
    navigate(toPath(PATHS.discounts, { orgId: orgId }));
  };

  const moveExpiresOnToUtc = (formValues: DiscountFormValues) => {
    const myZoneOffset = new Date(formValues.expiresOn).getTimezoneOffset();

    return {
      ...formValues,
      expiresOn: addMinutes(new Date(formValues.expiresOn), -myZoneOffset)
    };
  };

  const updateDiscountAction = async (formValues: DiscountFormValues, forceTheCreation: boolean) => {
    const discount = {
      ...moveExpiresOnToUtc(formValues),
      forceTheCreation: forceTheCreation
    };
    return apiService
      .patch<{ activity: Activity; message: string }>(`/org/${orgId}/discount/${discountId}`, discount)
      .then(() => {
        discountActionSuccessful();
      })
      .catch((error) => {
        const errorResponse = error.response.data;
        if (error.response.status === 409) {
          setDiscountConflictModalOpen(true);
        } else {
          if (typeof errorResponse.validationErrors === 'object' && !isEmptyObject(errorResponse.validationErrors)) {
            addServerErrors<DiscountFormValues>(errorResponse.validationErrors, methods.setError);
          } else {
            showNotification(false, errorResponse?.message || Messages.FailedUpdateDiscount, true);
          }
        }
      });
  };

  const addDiscountAction = async (formValues: DiscountFormValues, forceTheCreation: boolean) => {
    const discount = {
      ...moveExpiresOnToUtc(formValues),
      forceTheCreation: forceTheCreation
    };
    return apiService
      .post<{ message: string }>(`/org/${orgId}/discount`, discount)
      .then(() => {
        discountActionSuccessful();
      })
      .catch((error) => {
        const errorResponse = error.response.data;
        if (error.response.status === 409) {
          setDiscountConflictModalOpen(true);
        } else {
          if (typeof errorResponse.validationErrors === 'object' && !isEmptyObject(errorResponse.validationErrors)) {
            addServerErrors<DiscountFormValues>(errorResponse.validationErrors, methods.setError);
          } else {
            showNotification(false, errorResponse.message || Messages.FailedCreateDiscount, true);
          }
        }
      });
  };

  const closeModal = () => {
    setDiscountConflictModalOpen(false);
  };

  const handleForceSubmit = async (formValues: DiscountFormValues) => {
    setDiscountConflictModalOpen(false);
    if (mode === FormMode.Edit) {
      await updateDiscountAction(formValues, true);
    } else {
      await addDiscountAction(formValues, true);
    }
  };

  const handleSubmit: SubmitHandler<DiscountFormValues> = async (formValues: DiscountFormValues) => {
    if (mode === FormMode.Edit) {
      await updateDiscountAction(formValues, false);
    } else {
      await addDiscountAction(formValues, false);
    }
  };

  const getHelp = (discountClassValue) => {
    switch (discountClassValue) {
      case DiscountClass.FullDay:
        return (
          <Grid container spacing={1} style={{ marginTop: '30' }}>
            <Grid item xs={12}>
              <hr style={{ height: '2', width: 'auto', margin: 0 }} />
            </Grid>
            <Grid item xs={12}>
              {'Full day discount is applied only for Camp Activity/Session types.'}
            </Grid>
          </Grid>
        );
      default:
        return null;
    }
  };

  // methods.getValues() and watch() provide number input values as strings
  // so this function is used before manually submitting data
  // (in case of sibling discount conflict)
  const convertPercentageToNumber = (formData) => {
    formData.typeDefinition.discountPercentage = parseInt(formData.typeDefinition.discountPercentage);
    return {
      ...formData,
      typeDefinition: {
        ...formData.typeDefinition,
        discountPercentage: parseInt(formData.typeDefinition.discountPercentage)
      }
    };
  };

  useEffect(
    () => {
      methods.reset(discountFormState.discountFormValue);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [discountFormState.isLoading, JSON.stringify(discountFormState.discountFormValue)]
  );

  const shouldShowPreview =
    methods.getValues('classDefinition.discountClass') === DiscountClass.Sibling ||
    methods.getValues('classDefinition.discountClass') === DiscountClass.FullDay;

  const discountPreview = {
    descriptionForCustomer: methods.watch('descriptionForCustomer'),
    typeDefinition: {
      discountType: methods.watch('typeDefinition.discountType') as DiscountType,
      discountAmount: methods.getValues('typeDefinition.discountAmount'),
      //a trick to ignore the leading zeroes and not crash
      discountPercentage: parseInt(methods.watch('typeDefinition.discountPercentage').toString() || '0')
    },
    currencyCode: methods.getValues('currencyCode') as CurrencyCode,
    classDefinition: { discountClass: methods.getValues('classDefinition.discountClass') as DiscountClass }
  };

  return (
    <div>
      <PageTitle title={mode === FormMode.Edit ? 'Edit Discount' : 'Add Discount'} underlined />

      <FormProvider {...methods}>
        <form noValidate onSubmit={methods.handleSubmit(handleSubmit)}>
          <Box
            sx={{
              display: 'grid',
              gridTemplateAreas: {
                xs: `"fields"
                     "preview"
                     "buttons"`,
                md: `"fields preview"
                     "buttons ."`
              },
              gridTemplateColumns: { xs: '1fr', md: '65% 35%' }
            }}
          >
            <div style={{ gridArea: 'fields' }}>
              <DiscountFormFields />
              {getHelp(methods.watch('classDefinition.discountClass'))}
            </div>

            <div style={{ gridArea: 'buttons' }}>
              <FormCancelSubmitButtons
                isFormValid={isValid && isDirty}
                mode={mode}
                isSubmitting={isSubmitting}
                onCancel={handleCancelClick}
              />
            </div>
            {discountConflictModalOpen && (
              <ConfirmationModal
                open={discountConflictModalOpen}
                isOperating={false}
                message={
                  '<div style="display:flex;justify-content:center">There is another valid Sibling Discount. Multiple Sibling Discounts not allowed.</div><br />' +
                  '<div style="display:flex;justify-content:center">Invalidate previous Sibling Discount before store actual one?</div>'
                }
                onClose={closeModal}
                onConfirm={() => handleForceSubmit(convertPercentageToNumber(methods.getValues()))}
              />
            )}

            {shouldShowPreview && <DiscountBadgePreview discount={discountPreview} />}
          </Box>
        </form>
      </FormProvider>
    </div>
  );
};

export default DiscountForm;
