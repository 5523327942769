import { PageTitle } from '@efacity/react-next-sc';
import { EnrollmentApplicationCommon, Messages } from '@efacity/common';
import { useRef, useState } from 'react';
import { apiService, ConfirmationModal } from '@efacity/frontend-shared';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { Table, TableRef, usePaginatedLoaderWithAdditionalInfo } from '@efacity/table';
import { useEnrollmentApplicationsColumns } from './useEnrollmentApplicationsColumns';

export interface EnrollmentApplicationWithOrgName extends EnrollmentApplicationCommon {
  _id: string;
  orgName: string;
}

const UserEnrollmentApplications = () => {
  const tableRef = useRef<TableRef<EnrollmentApplicationWithOrgName>>(null);
  const dataFetchUrl = `/enrollments`;
  const { paginatedDataFetcher } = usePaginatedLoaderWithAdditionalInfo<EnrollmentApplicationWithOrgName>({
    usePagination: true,
    dateTimeFields: ['submittedOn', 'approvedAt']
  });

  const defaultConfirmationModalState = {
    isOpen: false,
    isOperating: false,
    message: '',
    onConfirm: null
  };
  const [confirmationModalState, setConfirmationModalState] = useState(defaultConfirmationModalState);

  const onDeleteEnrollmentCLick = (enrollmentId, name: string) => {
    setConfirmationModalState({
      isOpen: true,
      isOperating: false,
      message:
        `You are about to delete Enrollment Application for ${name}.<br/>` +
        '<div style="display:flex;justify-content:center">Please, confirm.</div>',
      onConfirm: () => handleDeleteEnrollmentApplication(enrollmentId)
    });
  };
  const handleDeleteEnrollmentApplication = async (enrollmentId: string) => {
    setConfirmationModalState((state) => {
      return { ...state, isOperating: true };
    });
    try {
      await apiService.delete(`enrollments/enrollment/${enrollmentId}`);
    } catch (error) {
      showNotification(false, (error.response?.data?.message as string) || Messages.FailedDeleteEnrollment, true);
    } finally {
      setConfirmationModalState({ ...defaultConfirmationModalState });
    }
    tableRef.current?.refetchTableData();
  };

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const columns = useEnrollmentApplicationsColumns(timeZone, onDeleteEnrollmentCLick);

  return (
    <div>
      <PageTitle title="Enrollment applications" />
      <Table<EnrollmentApplicationWithOrgName>
        tableRef={tableRef}
        columnDefs={columns}
        dataFetcherUrl={dataFetchUrl}
        dataFetcher={paginatedDataFetcher}
        noDataText="You have no enrollment applications"
        manualFiltering={false}
        manualSorting={false}
      />
      {confirmationModalState.isOpen && (
        <ConfirmationModal
          open={confirmationModalState.isOpen}
          isOperating={confirmationModalState.isOperating}
          message={confirmationModalState.message}
          onClose={() => setConfirmationModalState({ ...defaultConfirmationModalState })}
          onConfirm={() => confirmationModalState.onConfirm(confirmationModalState)}
        />
      )}
    </div>
  );
};

export default UserEnrollmentApplications;
