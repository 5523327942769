import { Box, List, ListItemButton, ListItemText } from '@mui/material';
import React from 'react';
import { navigationItemActiveStyle, navigationItemStyle } from '@efacity/frontend-shared';
import { NavLink } from 'react-router-dom';

interface NavigationItemProps {
  label: string;
  link: string;
  onMenuItemClick?: any;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const NavigationItem: React.FC<NavigationItemProps> = ({ label, link, onMenuItemClick = () => {} }) => {
  return (
    <List component="nav" style={{ padding: 0 }}>
      <Box display="flex" key={'ActivitiesNavigationLinkKey'}>
        <ListItemButton
          onClick={onMenuItemClick}
          component={NavLink}
          to={link}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          style={({ isActive }) =>
            isActive ? { ...navigationItemActiveStyle, ...navigationItemStyle } : navigationItemStyle
          }
        >
          <ListItemText primary={label} />
        </ListItemButton>
      </Box>
    </List>
  );
};

export default NavigationItem;
