import { Messages } from '@efacity/common';
import { useNavigate } from 'react-router-dom';
import { CenteredFormContainer } from '@efacity/frontend-next-shared/forms/server';
import {
  apiService,
  initialUserFormValues,
  UserFormValues,
  getMergedFormValues,
  WithLoading
} from '@efacity/frontend-shared';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { addServerErrors } from '@efacity/react-hook-form-mui';
import { useAuth } from '@efacity/react-next-sc';
import { PATHS, toPath } from '@efacity/routing';
import { UserSettingsForm, userProfileFields } from '@efacity/frontend-next/site-admin/users';
import { useCallback, useEffect, useState } from 'react';

const UserMainSettingsContainer: React.FC = () => {
  const navigate = useNavigate();
  const {
    authState: {
      user: { _id }
    }
  } = useAuth();
  const [isUserLoading, setIsUserLoading] = useState(true);
  const [userFormValues, setUserFormValues] = useState(initialUserFormValues);

  const getUser = useCallback(async () => {
    try {
      setIsUserLoading(true);
      const { data } = await apiService.get<UserFormValues>(`/users/${_id}`);
      setUserFormValues(getMergedFormValues(initialUserFormValues, data));
      setIsUserLoading(false);
    } catch (error) {
      setIsUserLoading(false);
      showNotification(false, error.message || Messages.FailedToGetUser);
    }
  }, [_id]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  const handleSubmit = async (
    formValues: UserFormValues,
    setError: (fieldName: keyof UserFormValues, error: { type: string; message: string }) => void
  ) => {
    return apiService
      .patch<{ message: string }>(`/users/${_id}`, formValues)
      .then(({ data }) => {
        showNotification(true, data.message || Messages.UserUpdatedSuccessfully);
      })
      .catch((error) => {
        const { data: errorData } = error.response;
        if (errorData.validationErrors) {
          addServerErrors<UserFormValues>(errorData.validationErrors, setError);
        } else {
          showNotification(false, (error.response.data.message as string) || Messages.FailedToUpdateUser, true);
        }
      });
  };

  const handleCancel = () => {
    navigate(toPath(PATHS.registrations));
  };

  return (
    <CenteredFormContainer>
      <WithLoading isLoading={isUserLoading} message={'Loading user settings...'} style={{ width: '50%' }}>
        <UserSettingsForm
          initialFormValues={userFormValues}
          organizationOptions={[]}
          allowHandleAccess={false}
          allowChangeEmail={false}
          allowChangePassword={false}
          handleSubmit={handleSubmit}
          isSubmitDisabled={isUserLoading}
          handleCancel={handleCancel}
          showCancel={false}
          userModelFields={userProfileFields}
        />
      </WithLoading>
    </CenteredFormContainer>
  );
};

export default UserMainSettingsContainer;
