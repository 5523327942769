import React from 'react';
import { NumericFormat, NumericFormatProps, NumberFormatValues } from 'react-number-format';

interface CurrencyFormatProps extends NumericFormatProps {
  ref: (instance: NumericFormatProps<any> | null) => void;
}

export const CurrencyFormat = React.forwardRef(function NumberFormatCustom(props: CurrencyFormatProps, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values: NumberFormatValues) => {
        onChange({
          target: {
            name: props.name,
            value: (values.floatValue || 0) * 100
          }
        } as any);
      }}
      decimalScale={2}
      thousandSeparator
      valueIsNumericString
    />
  );
});
