import type { DataFetcherArgs } from '../types';
import { apiService } from '@efacity/react-next-sc';

export const nonPaginatedDataFetcher = async <T>({
  url
}: Pick<DataFetcherArgs, 'url'>): Promise<{ data: T[]; total: number }> => {
  try {
    const data = await apiService().get<T[]>(url, {});
    return {
      data: data.data,
      total: (data?.data?.length ?? 0) as number
    };
  } catch {
    return {
      data: [],
      total: 0
    };
  }
};
