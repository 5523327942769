import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { ShoppingCartTableProps } from './ShoppingCartAsTable';
import { moneyWithCurrencyCodeFormatter, ShoppingCartProduct, ShoppingCartRegistration } from '@efacity/common';
import ShoppingCartRegistrationMobileTableRows from './ShoppingCartRegistrationMobileTableRows';
import CouponDiscountMobileInput from './CouponDiscountMobileInput';
import ShoppingCartProductMobileTableRows from './ShoppingCartProductMobileTableRow';

const shoppingCartMobileStyles = {
  total: {
    fontSize: 18,
    fontWeight: 'bold'
  }
};

const ShoppingCartMobileTable: FC<ShoppingCartTableProps> = ({
  shoppingCartRegistrations,
  shoppingCartProducts,
  shoppingCartAmount,
  shoppingCartTaxAmount,
  shoppingCartAmountWithTax,
  credit,
  due,
  currencyCode,
  timeZone,
  taxDescription,
  loading,
  handleDeleteShoppingCartItem,
  handleDiscountCodeChanged,
  handleDeleteAdditionalOption,
  handleDeleteProduct,
  isUpdating
}) => {
  return shoppingCartRegistrations?.length > 0 || shoppingCartProducts?.length > 0 ? (
    <Box marginBottom={2}>
      {shoppingCartRegistrations?.map((shoppingCarRegistration: ShoppingCartRegistration, index: number) =>
        shoppingCarRegistration && shoppingCarRegistration.student ? (
          <ShoppingCartRegistrationMobileTableRows
            key={`student${shoppingCarRegistration.student._id}ToSession${shoppingCarRegistration.session._id}ShoppingCartRow${index}`}
            session={shoppingCarRegistration.session}
            timeZone={timeZone}
            student={shoppingCarRegistration.student}
            additionalOptions={
              shoppingCarRegistration.additionalSessionOptions &&
              shoppingCarRegistration.additionalSessionOptions.length > 0
                ? shoppingCarRegistration.additionalSessionOptions
                : null
            }
            discounts={shoppingCarRegistration.discounts}
            handleDeleteShoppingCartItem={handleDeleteShoppingCartItem}
            handleDeleteAdditionalOption={handleDeleteAdditionalOption}
            isUpdating={isUpdating}
          />
        ) : (
          <ShoppingCartRegistrationMobileTableRows
            key={`noStudentToSession${shoppingCarRegistration.session._id}ShoppingCartRow${index}`}
            session={shoppingCarRegistration.session}
            timeZone={timeZone}
            student={null}
            additionalOptions={
              shoppingCarRegistration.additionalSessionOptions &&
              shoppingCarRegistration.additionalSessionOptions.length > 0
                ? shoppingCarRegistration.additionalSessionOptions
                : null
            }
            discounts={shoppingCarRegistration.discounts}
            handleDeleteShoppingCartItem={handleDeleteShoppingCartItem}
            handleDeleteAdditionalOption={handleDeleteAdditionalOption}
            isUpdating={isUpdating}
          />
        )
      )}
      {shoppingCartProducts?.map((shoppingCartProduct: ShoppingCartProduct, j) => (
        <ShoppingCartProductMobileTableRows
          shoppingCartProduct={shoppingCartProduct}
          timeZone={timeZone}
          handleDeleteShoppingCartProduct={handleDeleteProduct}
          isUpdating={isUpdating}
          key={`product${shoppingCartProduct.product._id}-shopping-cart-row-${j}`}
        />
      ))}
      <CouponDiscountMobileInput handleDiscountCodeChanged={handleDiscountCodeChanged} disabled={false} />
      {shoppingCartTaxAmount > 0 ? (
        <>
          <Box my={2}>
            <Typography color="primary" align="right" style={shoppingCartMobileStyles.total}>
              Sub Total : {moneyWithCurrencyCodeFormatter(shoppingCartAmount, currencyCode)}
            </Typography>
          </Box>
          <Box my={2}>
            <Typography color="primary" align="right" style={shoppingCartMobileStyles.total}>
              {taxDescription} {moneyWithCurrencyCodeFormatter(shoppingCartTaxAmount, currencyCode)}
            </Typography>
          </Box>
          <Box my={2}>
            <Typography color="primary" align="right" style={shoppingCartMobileStyles.total}>
              Total : {moneyWithCurrencyCodeFormatter(shoppingCartAmountWithTax, currencyCode)}
            </Typography>
          </Box>
        </>
      ) : (
        <Box my={2}>
          <Typography color="primary" align="right" style={shoppingCartMobileStyles.total}>
            Total : {moneyWithCurrencyCodeFormatter(shoppingCartAmount, currencyCode)}
          </Typography>
        </Box>
      )}
      {credit > 0 && (
        <Box my={2}>
          <Typography color="primary" align="right" style={shoppingCartMobileStyles.total}>
            Credit: {moneyWithCurrencyCodeFormatter(-credit, currencyCode)}
          </Typography>
        </Box>
      )}
      <Box my={2}>
        <Typography color="primary" align="right" style={shoppingCartMobileStyles.total}>
          Due : {moneyWithCurrencyCodeFormatter(due, currencyCode)}
        </Typography>
      </Box>
    </Box>
  ) : (
    <Box textAlign="center">
      <Typography style={{ fontSize: 18 }}>Empty</Typography>
    </Box>
  );
};

export default ShoppingCartMobileTable;
