import React, { useRef } from 'react';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import { useParams } from 'react-router-dom';
import { Row } from 'react-table';
import { Box } from '@mui/material';
import SmallInvoiceDataTable from '../../components/SmallInvoiceDataTable/SmallInvoiceDataTable';
import { InvoiceBalance } from '@efacity/common';
import SmallInvoicePaymentsTable from '../../components/SmallInvoicePaymentsTable/SmallInvoicePaymentsTable';
import { PageTitle } from '@efacity/react-next-sc';
import { Table, TableRef, usePaginatedLoaderWithAdditionalInfo } from '@efacity/table';
import { usePaymentsColumns } from './usePaymentsColumns';

const tableDescriptionStyle = {
  color: 'primary.dark',
  fontWeight: 'bold',
  fontSize: 14,
  marginBottom: '-10px'
};
const PaymentsContainer: React.FC = () => {
  const { orgId } = useParams<OrgIdParamType>();
  const tableRef = useRef<TableRef<InvoiceBalance>>(null);
  const dataFetcherUrl = `/org/${orgId}/invoices`;

  const { dataState, paginatedDataFetcher } = usePaginatedLoaderWithAdditionalInfo<InvoiceBalance>({
    dateTimeFields: ['invoiceDate'],
    additionalFields: ['timezone']
  });
  const columns = usePaymentsColumns(orgId, dataState['timeZone']);

  const renderInvoiceBalanceTable = (row: Row<InvoiceBalance>) => {
    return (
      <td colSpan={columns.length - 1}>
        <Box margin={1}>
          <Box sx={tableDescriptionStyle}>Invoice Balance</Box>
          <SmallInvoiceDataTable invoiceBalance={row.original} />
        </Box>

        <Box margin={1}>
          <Box sx={tableDescriptionStyle}>Payout Balance</Box>
          <SmallInvoicePaymentsTable orgId={orgId} invoiceBalance={row.original} timeZone={dataState['timeZone']} />
        </Box>
      </td>
    );
  };

  return (
    <>
      <PageTitle title="Payments" />
      <Table<InvoiceBalance>
        tableRef={tableRef}
        dataFetcherUrl={dataFetcherUrl}
        columnDefs={columns}
        dataFetcher={paginatedDataFetcher}
        manualFiltering={true}
        manualSorting={true}
        renderRowSubComponent={renderInvoiceBalanceTable}
      />
    </>
  );
};

export default PaymentsContainer;
