import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from '@mui/material';
import {
  AdditionalSessionOption,
  moneyWithCurrencyCodeFormatter,
  formatStringToMonthDayYearDate
} from '@efacity/common';
import { PATHS, toPath } from '@efacity/routing';
import { createColumnHelper } from '@tanstack/react-table';
import { TextColumnFilter, MoneyRangeColumnFilter } from '@efacity/table';

const columnHelper = createColumnHelper<AdditionalSessionOption>();

export const useSessionOptionsTableColumns = (orgId: string, additionalColumns) => {
  return useMemo(() => {
    const columns = [
      columnHelper.accessor('name', {
        header: 'Name',
        enableColumnFilter: true,
        enableSorting: true,
        cell: ({ row, getValue }) => {
          return (
            <Link
              component={NavLink}
              to={toPath(PATHS.editAdditionalOption, {
                orgId: orgId,
                additionalOptionId: row.original._id
              })}
              underline={'none'}
            >
              {getValue()}
            </Link>
          );
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      }),
      columnHelper.accessor('description', {
        header: 'Description',
        enableColumnFilter: true,
        enableSorting: true,
        cell: ({ getValue }) => <>{getValue()}</>,
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      }),
      columnHelper.accessor('expiresOn', {
        header: 'Available until',
        enableColumnFilter: true,
        enableSorting: true,
        cell: ({ getValue }) => <>{formatStringToMonthDayYearDate(new Date(getValue()).toISOString())}</>
      }),
      columnHelper.accessor('price', {
        header: 'Price',
        enableColumnFilter: true,
        enableSorting: true,
        cell: ({ row, getValue }) => <>{moneyWithCurrencyCodeFormatter(getValue(), row.original.currencyCode)}</>,
        meta: {
          filter: (column) => <MoneyRangeColumnFilter column={column} />,
          filterVariant: 'range',
          headerSx: { textAlign: 'right' },
          columnSx: { textAlign: 'right' }
        }
      })
    ];

    if (additionalColumns) return [...columns, ...additionalColumns];
    return columns;
  }, [orgId, additionalColumns]);
};
