import { PATHS, toPath } from '@efacity/routing';
import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Link } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { InstanceActivity, PaymentPlatform } from '@efacity/common';
import { smallPublishButtonStyle } from '../../../components/Buttons/SessionPublishButton';
import { CircularProgressButton } from '@efacity/frontend-shared';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/DeleteForever';

const columnHelper = createColumnHelper<PaymentPlatform>();

export const usePaymentPlatformsColumns = (
  instanceActivity: InstanceActivity,
  onDisconnectCLick: (paymentPlatformId: string, stripeUserId: string) => void,
  handleDeleteStripeAccountClicked: (paymentPlatformId: string, name: string) => void
) => {
  return useMemo(() => {
    const columns = [
      columnHelper.accessor('name', {
        header: 'Name',
        id: 'name',
        enableSorting: true,
        enableColumnFilter: false,
        cell: ({ row, getValue }) => {
          return (
            <Link
              component={NavLink}
              to={toPath(PATHS.editPaymentPlatform, { id: row.original._id })}
              underline={'none'}
            >
              {getValue()}
            </Link>
          );
        }
      }),
      {
        header: 'Currency',
        id: 'currency',
        accessor: 'stripePlatformAccount.currencyCode',
        enableSorting: true,
        enableColumnFilter: false,
        cell: ({ row }) => {
          return row.original.stripePlatformAccount.currencyCode;
        }
      },
      {
        header: 'Efacity Default',
        id: 'isEfacity',
        accessor: 'stripePlatformAccount.isEfacity',
        enableSorting: true,
        enableColumnFilter: false,
        cell: ({ row }) => {
          return row.original?.stripePlatformAccount?.isEfacity ? 'Yes' : '';
        }
      }
    ];

    if (instanceActivity === InstanceActivity.active) {
      const additionalColumns = [
        {
          header: 'Connected Accounts',
          id: 'connectedAccounts',
          accessor: 'stripePlatformAccount',
          enableSorting: false,
          enableColumnFilter: false,
          cell: ({ row }) => {
            return row.original?.stripePlatformAccount?.connectedAccounts?.length > 0
              ? row.original.stripePlatformAccount.connectedAccounts.map((connectedAccount, index) => {
                  return (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      style={{ marginTop: 2, marginBottom: 2 }}
                      key={`connectedAccountsLine${index}`}
                    >
                      <div>{connectedAccount.name}</div>
                      <div>
                        <CircularProgressButton
                          progress={false}
                          color="primary"
                          onClick={() => onDisconnectCLick(row.original._id, connectedAccount.stripeUserId)}
                          style={{ ...smallPublishButtonStyle, width: 100 }}
                          data-testid={`disconnectButtonForPaymentPlatform${row.original._id}`}
                        >
                          Disconnect
                        </CircularProgressButton>
                      </div>
                    </Box>
                  );
                })
              : '-';
          }
        },
        {
          header: '',
          id: 'actions-cell',
          accessor: 'actions',
          enableSorting: false,
          enableColumnFilter: false,
          cell: ({ state, row }) => {
            return (
              <IconButton
                color="primary"
                aria-label="delete discount"
                data-testid="delete-discount-btn"
                component="span"
                style={smallPublishButtonStyle}
                onClick={() => handleDeleteStripeAccountClicked(row.original._id, row.original.name)}
              >
                <DeleteIcon />
              </IconButton>
            );
          },
          meta: {
            headerSx: { width: '75px', maxWidth: '75px' },
            columnSx: { width: '75px', maxWidth: '75px' }
          }
        }
      ];
      columns.push(...additionalColumns);
    }

    return columns;
  }, [instanceActivity, onDisconnectCLick, handleDeleteStripeAccountClicked]);
};
