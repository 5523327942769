import React, { useMemo } from 'react';
import SubjectWithMessageCell from './SubjectWithMessageCell';
import SendIcon from '@mui/icons-material/Send';
import { CircularProgress, useTheme } from '@mui/material';
import { adminSectionTableFontSize, Message } from '@efacity/frontend-shared';
import DateCell from '../../components/DateCell';
import { convertUtcToZonedTime, SendStatuses, sendStatusViewConnector } from '@efacity/common';
import { IconButton } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { TextColumnFilter, DateColumnFilter, MessageStatusFilter } from '@efacity/table';

const columnHelper = createColumnHelper<Message>();

export const useMessagesColumns = (
  orgId: string,
  timeZone: string,
  resendMessage: (messageId: string) => void,
  processingMessageId: string | null
) => {
  const theme = useTheme();

  const messageStatusColor = (status: SendStatuses) => {
    return status === SendStatuses.Sent
      ? theme.palette.primary.main
      : status === SendStatuses.Failed
      ? theme.palette.error.main
      : '';
  };

  return useMemo(() => {
    const columns = [
      columnHelper.accessor('recipientEmail', {
        header: 'To',
        enableColumnFilter: true,
        enableSorting: true,
        cell: ({ getValue }) => <div>{getValue()}</div>,
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      }),
      columnHelper.accessor('subject', {
        header: 'Subject / Text',
        enableColumnFilter: true,
        enableSorting: true,
        cell: ({ getValue, row }) => <SubjectWithMessageCell subject={getValue()} body={row.original.body} />,
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      }),
      columnHelper.accessor('createdOn', {
        header: 'Created On',
        enableColumnFilter: true,
        enableSorting: true,
        cell: ({ getValue }) => (
          <div>
            {getValue() ? (
              <DateCell
                dateTime={convertUtcToZonedTime(getValue(), timeZone)}
                yearOnNewLine={false}
                wrap={true}
                style={{ ...adminSectionTableFontSize }}
              />
            ) : (
              '[n/a]'
            )}
          </div>
        ),
        meta: {
          filter: (column) => <DateColumnFilter column={column} />,
          headerSx: { maxWidth: 120, width: 120 },
          columnSx: { maxWidth: 120, width: 120 }
        }
      }),
      columnHelper.accessor('sentOn', {
        header: 'Sent On',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ getValue }) => (
          <div>
            {getValue() ? (
              <DateCell
                dateTime={convertUtcToZonedTime(getValue(), timeZone)}
                yearOnNewLine={false}
                wrap={true}
                style={{ ...adminSectionTableFontSize }}
              />
            ) : (
              '[n/a]'
            )}
          </div>
        ),
        meta: {
          filter: (column) => <DateColumnFilter column={column} />,
          headerSx: { maxWidth: 120, width: 120 },
          columnSx: { maxWidth: 120, width: 120 }
        }
      }),
      columnHelper.accessor('status', {
        header: 'Status',
        enableColumnFilter: true,
        enableSorting: true,
        cell: ({ getValue }) => (
          <div style={{ color: `${messageStatusColor(getValue())}` }}> {sendStatusViewConnector[getValue()]}</div>
        ),
        meta: {
          filter: (column) => <MessageStatusFilter column={column} />,
          headerSx: { width: 76, maxWidth: 76 },
          columnSx: { width: 76, maxWidth: 76 }
        }
      }),
      {
        header: '',
        id: 'registration-actions-option',
        accessorFn: (row) => row,
        enableColumnFilter: true,
        enableSorting: true,
        cell: ({ row }) =>
          row.original?.status === SendStatuses.Failed &&
          row.original._id && (
            <IconButton
              color="primary"
              aria-label="copy session link"
              component="span"
              disabled={!!processingMessageId}
              onClick={() => resendMessage(row.original._id)}
            >
              {processingMessageId === row.original._id ? (
                <CircularProgress variant="indeterminate" size={20} color="primary" data-testid="progress-in-button" />
              ) : (
                <SendIcon fontSize="small" />
              )}
            </IconButton>
          ),
        meta: {
          headerSx: { width: 40, maxWidth: 40 },
          columnSx: { width: 40, maxWidth: 40 }
        }
      }
    ];

    return columns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, theme, timeZone, processingMessageId]);
};
