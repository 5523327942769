import { OwnerType } from './OwnerInterface';
import { AddressForm } from './AddressForm';
import { CountryCode } from './Countries';
import { TaxTypes } from './Taxes';
import { Access } from './Access';
import { CurrencyCode } from './Currencies';
import { PaymentAcceptanceMethod } from './Stripe';

export interface OrganizationIdName {
  _id: string;
  name: string;
}
export interface OrganizationIdNameTimezone {
  _id: string;
  name: string;
  timeZone: string;
}

export enum MailingServer {
  awsSes = 'awSes',
  smtp = 'smtp'
}

export class SMTPSettings {
  server: string;
  port: number;
  ssl: boolean;
  user: string;
  password: string;
}

export enum SesEmailVerificationStatus {
  alreadyVerified = 'alreadyVerified',
  emailSent = 'emailSent',
  emailSendingFailed = 'emailSendingFailed'
}

export interface Organization {
  orgType: OwnerType;
  status?: OrganizationStatus;
  name: string;
  description: string;
  timeZone: string;
  logoFullUrl?: string;
  mobileLogoFullUrl?: string;
  ein: string;
  contactEmail: string;
  website: string;
  phone: string;
  phoneCountry: CountryCode;
  addresses: AddressForm[];
  defaultAddress: AddressForm;
  country: CountryCode;
  defaultCurrency: string;
  customerLogoutUrl: string;
  schoolContactEmail: string;
  hideNumberOfRegistrations: boolean;
  validateUserOnRegistration: boolean;
  requireStudentAge: boolean;
  efacityFee: number;
  paymentAcceptanceMethod: string;
  connectModel: string;
  taxType: TaxTypes;
  taxPercentage: number;
  schoolFee: number;
  allowedCreateActivity: boolean;
  additionalNotificationEmails?: string;
  additionalNotificationEmailsToPrimarySchool?: string;
  enrollmentRequired: boolean;
}

export interface OrganizationEmail {
  sendFromEmail: string;
  mailingServer: MailingServer;
  smtpSettings?: SMTPSettings;
}

export enum OrganizationStatus {
  Active = 'active',
  Inactive = 'inactive',
  Pending = 'pending'
}
export const organizationStatusViewConnector = {
  [OrganizationStatus.Active]: 'Active',
  [OrganizationStatus.Inactive]: 'Inactive',
  [OrganizationStatus.Pending]: 'Pending'
};
export const organizationStatusSelectOptions = Object.entries(organizationStatusViewConnector).map(([value, label]) => {
  return {
    value,
    label
  };
});

export interface AdministratorInfo {
  fullName: string;
  email: string;
  access: Access[];
  _id: string;
}
export const emptyAdministratorInfo = {
  fullName: '',
  email: '',
  access: [],
  _id: ''
};

export interface OrganizationRegions {
  country: CountryCode;
  timeZone: string;
  defaultCurrency: CurrencyCode;
  isPaymentsAllowed: boolean;
  paymentAcceptanceMethod: PaymentAcceptanceMethod;
  enrollmentRequired: boolean;
  logoFullUrl: string;
  mobileLogoFullUrl: string;
}

export interface OrganizationWithAdmins {
  organizationSettings: Organization;
  administrators: AdministratorInfo[];
}

export interface OrganizationLogosResponse {
  logoFullUrl?: string;
  mobileLogoFullUrl?: string;
  contactEmail?: string;
  phone?: string;
  website?: string;
  sendFromEmail?: string;
  hideNumberOfRegistrations?: boolean;
  isPaymentsAllowed: boolean;
}
