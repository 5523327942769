import React, { useEffect, useState } from 'react';
import { apiService } from '@efacity/frontend-shared';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { FormControlLabel, Grid } from '@mui/material';
import { FormPageContainer } from '@efacity/frontend-next-shared/forms/server';
import { FormCancelSubmitButtons } from '@efacity/frontend-next-shared/forms';
import { FormMode, isEmptyObject, Messages, SessionWithNameAndSelection, Tag, TagFormValue } from '@efacity/common';
import { PATHS, toPath } from '@efacity/routing';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { OrgIdTagIdParamType } from '@efacity/frontend-next-shared/utils';
import { useTagFormValues } from './useTagFormValues';
import * as Yup from 'yup';
import Checkbox from '@mui/material/Checkbox';
import { SessionsInTagPeriodModal } from './SessionsInTagPeriodModal';
import { addMinutes } from 'date-fns';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ActivityTypeChecker, addServerErrors, FormDatePicker, FormTextInput } from '@efacity/react-hook-form-mui';
import { PageTitle } from '@efacity/react-next-sc';

const tagFormValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  activityTypes: Yup.array().of(Yup.string()).min(1, 'Please select at least one activity type'),
  isSessionPeriod: Yup.boolean(),
  validFrom: Yup.mixed().when('isSessionPeriod', {
    is: (isSessionPeriod) => isSessionPeriod === true,
    then: Yup.date().required('Valid From is Required')
  }),
  validTo: Yup.mixed().when('isSessionPeriod', {
    is: (isSessionPeriod) => isSessionPeriod === true,
    then: Yup.date().required('Valid To is Required')
  })
});

export interface TagFormProps {
  mode: FormMode;
}

const TagForm: React.FC<TagFormProps> = ({ mode }) => {
  const navigate = useNavigate();

  const { tagId, orgId } = useParams<OrgIdTagIdParamType>();
  const { tagFormState } = useTagFormValues(orgId, tagId, mode);
  const [sessionsInTagPeriod, setSessionsInTagPeriod] = useState([]);
  const [workingTagId, setWorkingTagId] = useState(tagId);
  const [isSubmittingSessions, setIsSubmittingSessions] = useState(false);

  const methods = useForm<TagFormValue>({
    resolver: yupResolver(tagFormValidationSchema),
    defaultValues: tagFormState.tagFormValue,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUseNativeValidation: false,
    mode: 'onTouched'
  });
  const { isSubmitting, isValid, isDirty } = methods.formState;

  const onChangeSessionsTagStatus = (sessionsWithNewTagStatus: SessionWithNameAndSelection[], newStatus: boolean) => {
    const sessionsWithNewStatus = [...sessionsInTagPeriod];
    sessionsWithNewStatus.forEach((sessionWithOldStatus) => {
      const sessionToBeUpdated = sessionsWithNewTagStatus.find((session) => session._id === sessionWithOldStatus._id);
      if (sessionToBeUpdated) sessionToBeUpdated.isSelected = newStatus;
    });
    setSessionsInTagPeriod(sessionsWithNewStatus);
  };

  const closeSessionsInTagPeriodModal = () => {
    setSessionsInTagPeriod([]);
  };

  const gotoTagsPage = () => {
    navigate(toPath(PATHS.tags, { orgId: orgId }));
  };

  const submitTaggingSessionsWithSessionPeriod = () => {
    setIsSubmittingSessions(true);
    return apiService
      .post<{ message: string }>(`/org/${orgId}/tags/${workingTagId}/sessions`, sessionsInTagPeriod)
      .then(({ data }) => {
        setIsSubmittingSessions(false);
        gotoTagsPage();
      })
      .catch((error) => {
        setIsSubmittingSessions(false);
        showNotification(false, error.response?.data?.message || Messages.FailedTaggingSessions, true);
      });
  };

  const tagActionSuccessful = (sessions: SessionWithNameAndSelection[], tagId?: string) => {
    if (sessions?.length > 0) {
      if (tagId) setWorkingTagId(tagId);
      setSessionsInTagPeriod(sessions);
    } else {
      setSessionsInTagPeriod([]);
      navigate(toPath(PATHS.tags, { orgId: orgId }));
    }
  };

  const updateTagAction = async (formValues: TagFormValue) => {
    return apiService
      .patch<{ sessions: SessionWithNameAndSelection[] }>(
        `/org/${orgId}/tags/${tagId}`,
        moveValidFieldsToUtc(formValues)
      )
      .then(({ data }) => {
        tagActionSuccessful(data.sessions);
      })
      .catch((error) => {
        const errorResponse = error.response.data;
        if (typeof errorResponse.validationErrors === 'object' && !isEmptyObject(errorResponse.validationErrors)) {
          addServerErrors<TagFormValue>(errorResponse.validationErrors, methods.setError);
        } else {
          showNotification(false, error.response?.data?.message || Messages.FailedUpdateDiscount, true);
        }
      });
  };

  const moveValidFieldsToUtc = (formValues: TagFormValue) => {
    const validFromZoneOffset = new Date(formValues.validFrom).getTimezoneOffset();
    const validToZoneOffset = new Date(formValues.validTo).getTimezoneOffset();

    return {
      ...formValues,
      validFrom: formValues?.validFrom ? addMinutes(new Date(formValues.validFrom), -validFromZoneOffset) : null,
      validTo: formValues?.validTo ? addMinutes(new Date(formValues.validTo), -validToZoneOffset) : null
    };
  };

  const addTagAction = async (formValues: TagFormValue) => {
    return apiService
      .post<{ data: Tag; sessions: SessionWithNameAndSelection[] }>(
        `/org/${orgId}/tags`,
        moveValidFieldsToUtc(formValues)
      )
      .then(({ data }) => {
        tagActionSuccessful(data.sessions, data.data._id);
      })
      .catch((error) => {
        const errorResponse = error.response.data;
        if (typeof errorResponse.validationErrors === 'object' && !isEmptyObject(errorResponse.validationErrors)) {
          addServerErrors<TagFormValue>(errorResponse.validationErrors, methods.setError);
        } else {
          showNotification(false, error.response?.data?.message || Messages.FailedCreateDiscount, true);
        }
      });
  };

  const handleCreateUpdateTagSubmit = async (formValues: TagFormValue) => {
    const tag = { ...formValues };
    if (!tag.isSessionPeriod) {
      delete tag.validFrom;
      delete tag.validTo;
    }
    if (mode === FormMode.Edit) {
      await updateTagAction(tag);
    } else {
      await addTagAction(tag);
    }
  };

  useEffect(
    () => {
      methods.reset(tagFormState.tagFormValue);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [tagFormState.isLoading]
  );

  return (
    <div>
      <PageTitle title={mode === FormMode.Edit ? 'Edit Tag' : 'Add Tag'} underlined />
      <FormPageContainer>
        <form noValidate onSubmit={methods.handleSubmit(handleCreateUpdateTagSubmit)}>
          <FormProvider {...methods}>
            <FormTextInput label="Name *" name="name" data-testid="tag.name" />
            <Controller
              name="isSessionPeriod"
              control={methods.control}
              render={({ field: { onChange, value, ref, ...field } }) => (
                <FormControlLabel
                  label={<div style={{ fontSize: 18 }}>Is Session Period</div>}
                  control={
                    <Checkbox
                      {...field}
                      onChange={() => onChange(!methods.watch('isSessionPeriod'))}
                      color="primary"
                      checked={methods.watch('isSessionPeriod')}
                      inputProps={{ 'aria-label': 'is-session-period-checkbox' }}
                    />
                  }
                />
              )}
            />

            {methods.watch('isSessionPeriod') && (
              <Grid container spacing={1}>
                <Grid item sm={6} xs={12} style={{ marginBottom: 20 }}>
                  <FormDatePicker name="validFrom" label="Valid From" data-testid="tag.validFrom" required />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormDatePicker name="validTo" label="Valid To" data-testid="tag.validTo" required />
                </Grid>
              </Grid>
            )}
            <hr />
            <ActivityTypeChecker message={'This tag will only be applied to Sessions of the following types:'} />
            <FormCancelSubmitButtons
              isFormValid={isValid && isDirty}
              mode={mode}
              isSubmitting={isSubmitting}
              onCancel={gotoTagsPage}
            />
          </FormProvider>
        </form>
      </FormPageContainer>
      <SessionsInTagPeriodModal
        isOpen={sessionsInTagPeriod?.length > 0}
        isSubmitting={isSubmittingSessions}
        sessions={sessionsInTagPeriod}
        onCancel={closeSessionsInTagPeriodModal}
        onSubmit={submitTaggingSessionsWithSessionPeriod}
        onUpdate={onChangeSessionsTagStatus}
      />
    </div>
  );
};

export default TagForm;
