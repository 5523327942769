import React, { FC } from 'react';
import { StudentContactInfoResponse } from '@efacity/common';
import UserInfoContainer from './UserInfoContainer';
import { Grid, List, ListItem, ListItemText } from '@mui/material';
import contactInfoModalSx from './contactInfoModalStyles';
import { PhoneNumber } from '@efacity/react-next-sc';
import EmailAddress from '../../../components/EmailAddress/EmailAddress';

export interface StudentContactInfoProps {
  studentWithCustomers: StudentContactInfoResponse;
}

const StudentContactInfo: FC<StudentContactInfoProps> = ({ studentWithCustomers }) => {
  return (
    <>
      {studentWithCustomers.customers?.length &&
        studentWithCustomers.customers.map((customer, index) => {
          return (
            <UserInfoContainer title="Customer Information" key={`attendanceStudentCustomer${index}Section`}>
              <List dense sx={contactInfoModalSx.list}>
                <ListItem>
                  <ListItemText primary="Name" secondary={customer.fullName || '-'} />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Email"
                    secondary={customer?.email ? <EmailAddress email={customer.email} /> : '-'}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Phone" secondary={<PhoneNumber phone={customer.phone} />} />
                </ListItem>
              </List>
            </UserInfoContainer>
          );
        })}
      <UserInfoContainer title="Attendee Information">
        <List dense sx={contactInfoModalSx.list}>
          <ListItem>
            <ListItemText primary="Name" secondary={studentWithCustomers.student.fullName || '-'} />
          </ListItem>
          <ListItem>
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <ListItemText
                  primary="Email"
                  secondary={
                    studentWithCustomers.student?.email ? (
                      <EmailAddress email={studentWithCustomers.student.email} />
                    ) : (
                      '-'
                    )
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <ListItemText primary="Phone" secondary={<PhoneNumber phone={studentWithCustomers.student.phone} />} />
              </Grid>
            </Grid>
          </ListItem>
          <ListItem>
            <ListItemText primary="Medical Info" secondary={studentWithCustomers.student.medicalInformation || '-'} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Emergency Instructions"
              secondary={studentWithCustomers.student.emergencyInstructions || '-'}
            />
          </ListItem>
        </List>
      </UserInfoContainer>
    </>
  );
};

export default StudentContactInfo;
