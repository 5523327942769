import React, { ChangeEvent, ReactNode } from 'react';
import { ActivityTypesOptions, DisplayOption } from '@efacity/common';
import { MenuItem, TextField } from '@mui/material';
import { Column } from '@tanstack/react-table';

export const ActivityTypeFilter = <TableData,>({ column }: { column: Column<TableData, unknown> }): ReactNode => {
  const { setFilterValue } = column;
  const value = (column.getFilterValue() as string) ?? '';

  const handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (setFilterValue) setFilterValue(() => (value ? value : undefined));
  };

  return (
    <TextField
      select
      type="text"
      variant="outlined"
      color="primary"
      margin="dense"
      fullWidth
      onChange={handleValueChange}
      value={value || ''}
      sx={{ m: 0, backgroundColor: 'white' }}
      inputProps={{ sx: { padding: '0' } }}
      SelectProps={{ sx: { padding: 0.62, fontSize: '14px' } }}
    >
      <MenuItem key={'none'} value="">
        Any
      </MenuItem>
      {Object.values(ActivityTypesOptions).map((activityTypeOption: DisplayOption) => (
        <MenuItem key={activityTypeOption.value} value={activityTypeOption.value}>
          {activityTypeOption.displayText}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default ActivityTypeFilter;
