import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { IconButton, Link } from '@mui/material';
import { AccessorKeyColumnDefBase, createColumnHelper } from '@tanstack/react-table';
import { Address } from '@efacity/common';
import { smallPublishButtonStyle } from '../../../components/Buttons/SessionPublishButton';
import DeleteIcon from '@mui/icons-material/DeleteForever';

const columnHelper = createColumnHelper<Address>();

const useAddressesTableColumns = (
  handleEditAddress: (addressId: string) => void,
  allowActions = false,
  handleDeleteAddress: (addressId: string) => void
) => {
  return useMemo((): AccessorKeyColumnDefBase<Address, string>[] => {
    const columns = [
      columnHelper.accessor('address', {
        header: 'Address',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ row, getValue }) => {
          return (
            <Link component={NavLink} to={'#'} onClick={() => handleEditAddress(row.original._id)} underline={'none'}>
              {getValue()}
            </Link>
          );
        }
      }),
      columnHelper.accessor('country', {
        header: 'Country',
        enableColumnFilter: true,
        enableSorting: true,
        cell: ({ getValue }) => {
          return getValue();
        }
      }),
      columnHelper.accessor('city', {
        header: 'City',
        enableColumnFilter: true,
        enableSorting: true,
        cell: ({ getValue }) => {
          return getValue();
        }
      }),
      columnHelper.accessor('state', {
        header: 'State',
        enableColumnFilter: true,
        enableSorting: true,
        cell: ({ getValue }) => {
          return getValue() || '';
        }
      }),
      columnHelper.accessor('zip', {
        header: 'Zip',
        enableColumnFilter: true,
        enableSorting: true,
        cell: ({ getValue }) => {
          return getValue();
        }
      })
    ];

    if (allowActions) {
      const actionColumn = {
        header: '',
        id: 'actions-cell',
        accessorKey: 'actions-cell',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => {
          return (
            <IconButton
              color="primary"
              aria-label="delete address"
              data-testid="delete-address-btn"
              component="span"
              style={smallPublishButtonStyle}
              onClick={() => handleDeleteAddress(row.original._id)}
            >
              <DeleteIcon />
            </IconButton>
          );
        },
        meta: {
          headerSx: { width: '75px', maxWidth: '75px' },
          columnSx: { width: '75px', maxWidth: '75px' }
        }
      };
      columns.push(actionColumn);
    }

    return columns;
  }, [handleEditAddress, allowActions, handleDeleteAddress]);
};

export default useAddressesTableColumns;
