import {
  LocationType,
  convertUtcToZonedTime,
  generateShortAddress,
  Attendance,
  getTimeDiapasonWithoutTimeZone
} from '@efacity/common';
import { adminSectionTableFontSize } from '@efacity/frontend-shared';
import { LightTooltip } from '@efacity/react-next-sc';
import React, { useMemo } from 'react';
import DateCell from '../../components/DateCell';
import AttendanceActionsCell from './AttendanceActionsCell';
import { NameLinkWithNoteIcon } from '../../components/NameLinkWithNoteIcon/NameLinkWithNoteIcon';
import { SignatureWithTooltip } from './SignatureWithTooltip';
import MustAgreeUnCheckedIcon from '../CustomerAgreements/MustAgreeUnCheckedIcon';
import { IconButton } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { TextColumnFilter } from '@efacity/table';

const columnHelper = createColumnHelper<Attendance>();

const useAttendanceTableColumns = ({
  onChangeStudentAttendanceStatus,
  onShowStudentContactInfo,
  onOpenNotesModal,
  onOpenAgreementsModal,
  timeZone
}) => {
  const getInstructorsName = (firstInstructorName: string | null, secondInstructorName: string | null) => {
    return firstInstructorName
      ? secondInstructorName
        ? `${firstInstructorName}, ${secondInstructorName}`
        : firstInstructorName
      : secondInstructorName
      ? secondInstructorName
      : '[n/a]';
  };
  return useMemo(() => {
    return [
      columnHelper.accessor('instance.startDateTime', {
        header: 'Date',
        id: 'instance.startDateTime',
        enableSorting: true,
        enableColumnFilter: false,
        cell: ({ row, getValue }) => {
          return (
            <DateCell
              dateTime={convertUtcToZonedTime(getValue(), row.original?.timeZone)}
              yearOnNewLine={false}
              wrap={true}
              showTime={false}
              style={{ ...adminSectionTableFontSize }}
            />
          );
        },
        meta: {
          headerSx: { verticalAlign: 'top' },
          columnSx: { whiteSpace: 'nowrap' }
        }
      }),
      columnHelper.accessor('instance.startDateTime', {
        header: () => {
          return (
            <span>
              Time,
              <br />
              {timeZone}
            </span>
          );
        },
        id: 'instance-time-range',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row, getValue }) => (
          <>
            {getTimeDiapasonWithoutTimeZone(
              new Date(getValue()),
              row.original.instance.durationInMinutes,
              row.original.timeZone
            )}
          </>
        ),
        meta: {
          headerSx: { verticalAlign: 'top' },
          columnSx: { whiteSpace: 'nowrap' }
        }
      }),
      columnHelper.accessor('location.name', {
        header: 'Location',
        id: 'location.name',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ row }) => {
          const addressLink =
            row.original?.location?.type === LocationType.InPerson ||
            row.original?.location?.type === LocationType.PublicSchool
              ? row.original?.location?.address?.mapLink
              : row.original?.location?.virtualMeeting?.link;

          return (
            <LightTooltip
              title={
                row.original?.location?.address
                  ? generateShortAddress(row.original?.location?.address)
                  : row.original?.location?.name
              }
            >
              {addressLink ? (
                <a href={addressLink} target="_blank" rel="noreferrer">
                  {row.original?.location?.name}
                </a>
              ) : (
                <div>{row.original?.location?.name}</div>
              )}
            </LightTooltip>
          );
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />,
          headerSx: { verticalAlign: 'top' }
        }
      }),
      columnHelper.accessor('sessionName', {
        header: 'Session',
        id: 'sessionName',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ getValue }) => {
          return <>{getValue()}</>;
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />,
          headerSx: { verticalAlign: 'top' }
        }
      }),
      columnHelper.accessor('instructorFullName', {
        header: 'Instructor',
        id: 'instructorFullName',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ row }) => {
          return <>{getInstructorsName(row.original?.instructorFullName, row.original?.secondaryInstructorFullName)}</>;
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />,
          headerSx: { verticalAlign: 'top' }
        }
      }),
      columnHelper.accessor('studentFullName', {
        header: 'Student',
        id: 'studentFullName',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ row, getValue }) => {
          return (
            <NameLinkWithNoteIcon
              name={getValue()}
              onNameClick={() => onShowStudentContactInfo(row.original?.studentId)}
              onNoteClick={() => onOpenNotesModal(row.original?.studentId, getValue())}
            />
          );
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />,
          headerSx: { verticalAlign: 'top' }
        }
      }),
      {
        header: 'Agreements',
        id: 'agreements',
        accessor: '',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => {
          return (
            <IconButton
              onClick={() => {
                onOpenAgreementsModal(row.original?.studentId, row.original.studentFullName);
              }}
            >
              <MustAgreeUnCheckedIcon color="primary" />
            </IconButton>
          );
        }
      },
      {
        header: '',
        id: 'signatures',
        accessor: '',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => {
          return <SignatureWithTooltip signatureUrl={row.original?.signInSignature} />;
        },
        meta: {
          columnSx: { width: 30, maxWidth: 30 }
        }
      },
      {
        header: '',
        id: 'action-buttons',
        accessor: '',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => {
          return (
            <div style={{ display: 'flex' }}>
              <AttendanceActionsCell
                attendanceId={row.original._id}
                attendanceStatus={row.original.status}
                onChangeStudentAttendanceStatus={onChangeStudentAttendanceStatus}
              />
            </div>
          );
        },
        meta: {
          columnSx: { width: 115, maxWidth: 115 },
          headerSx: { verticalAlign: 'top' }
        }
      }
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeZone]);
};

export default useAttendanceTableColumns;
