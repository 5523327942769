import { AddLinkMediumButton, apiService, ConfirmationModal } from '@efacity/frontend-shared';
import { PageTitle } from '@efacity/react-next-sc';
import React, { useRef, useState } from 'react';
import { Messages, User } from '@efacity/common';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { PATHS, toPath } from '@efacity/routing';
import { useParams } from 'react-router-dom';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import AddInstructorLinkCopier from './AddInstructorLinkCopier';
import { Table, TableRef, usePaginatedLoaderWithAdditionalInfo } from '@efacity/table';
import { useInstructorsColumns } from './useInstructorsColumns';

const InstructorsContainer: React.FC = () => {
  const { orgId } = useParams<OrgIdParamType>();
  const tableRef = useRef<TableRef<User>>(null);
  const dataUrl = `/org/${orgId}/users/teachers`;
  const { paginatedDataFetcher } = usePaginatedLoaderWithAdditionalInfo<User>({});

  const defaultConfirmationModalState = {
    isOpen: false,
    isOperating: false,
    message: '',
    onConfirm: null
  };
  const [confirmationModalState, setConfirmationModalState] = useState(defaultConfirmationModalState);
  const [isActivating, setIsActivating] = useState(false);

  const onDeleteCLick = (userId: string) => {
    setConfirmationModalState({
      isOpen: true,
      isOperating: false,
      message:
        '<div style="display:flex;justify-content:center">You are about to delete Instructor.</div><br/>' +
        '<div style="display:flex;justify-content:center">Please, confirm.</div>',
      onConfirm: () => deleteInstructor(userId)
    });
  };

  const activateInstructor = async (userId: string) => {
    setIsActivating(true);

    try {
      await apiService.patch<{ message: string }>(`/users/${orgId}/teacher/${userId}/activate`, {});
      setIsActivating(false);
      tableRef.current?.refetchTableData();
      showNotification(true, Messages.InstructorActivated, false);
      setConfirmationModalState(defaultConfirmationModalState);
    } catch {
      setIsActivating(false);
      showNotification(false, Messages.FailedActivateInstructor, true);
      setConfirmationModalState(defaultConfirmationModalState);
    }
  };

  const deleteInstructor = async (userId: string) => {
    setConfirmationModalState((state) => {
      return { ...state, isOperating: true };
    });
    try {
      await apiService.delete<{ message: string }>(`/users/${orgId}/teacher/${userId}`);
      tableRef.current?.refetchTableData();
      showNotification(true, Messages.InstructorDeleted, false);
      setConfirmationModalState(defaultConfirmationModalState);
    } catch {
      showNotification(false, Messages.FailedDeleteInstructor, true);
      setConfirmationModalState(defaultConfirmationModalState);
    }
  };

  const columns = useInstructorsColumns(orgId, isActivating, activateInstructor, onDeleteCLick);

  return (
    <div>
      <PageTitle
        title="Instructors"
        addButtonLink={null}
        button={
          <div style={{ display: 'flex' }}>
            <AddInstructorLinkCopier orgId={orgId} />
            <AddLinkMediumButton link={toPath(PATHS.addInstructor, { orgId: orgId })} data-testid="pageTitleBtn" />
          </div>
        }
      />
      <Table<User>
        tableRef={tableRef}
        dataFetcherUrl={dataUrl}
        columnDefs={columns}
        dataFetcher={paginatedDataFetcher}
        noDataText="No Instructors..."
        initialSortBy={[{ id: 'name', desc: false }]}
      />
      {confirmationModalState.isOpen && (
        <ConfirmationModal
          open={confirmationModalState.isOpen}
          isOperating={confirmationModalState.isOperating}
          message={confirmationModalState.message}
          onClose={() => setConfirmationModalState(defaultConfirmationModalState)}
          onConfirm={() => confirmationModalState.onConfirm(confirmationModalState)}
        />
      )}
    </div>
  );
};

export default InstructorsContainer;
