import { adminSectionTableFontSize, Discount } from '@efacity/frontend-shared';
import {
  ActivityTypes,
  activityTypesMapper,
  convertUtcToZonedTime,
  DiscountClass,
  DiscountType,
  moneyWithCurrencyCodeFormatter,
  formatStringToMonthDayYearDate
} from '@efacity/common';
import { PATHS, toPath } from '@efacity/routing';
import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Link } from '@mui/material';
import copy from 'copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { createColumnHelper } from '@tanstack/react-table';

const getActivityTypesList = (activityTypes: ActivityTypes[]) => {
  if (!(activityTypes?.length > 0)) return 'None';
  if (Object.values(ActivityTypes).every((type) => activityTypes.includes(type))) return 'All';

  return activityTypes.map((type) => activityTypesMapper[type]).join(', ');
};

const columnHelper = createColumnHelper<Discount>();

const useDiscountsTableColumns = (accessId, timeZone: string, additionalColumns = []) => {
  return useMemo(() => {
    const columns = [
      columnHelper.accessor('description', {
        header: 'Description in the invoice line',
        enableSorting: true,
        enableColumnFilter: false,
        cell: ({ row, getValue }) => {
          return (
            <Link
              component={NavLink}
              to={toPath(PATHS.editDiscount, { orgId: accessId, id: row.original._id })}
              underline={'none'}
            >
              {getValue()}
            </Link>
          );
        },
        meta: {
          columnSx: { ...adminSectionTableFontSize }
        }
      }),
      columnHelper.accessor('typeDefinition', {
        header: () => <div>Discount</div>,
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row, getValue }) => {
          return (
            <>
              {getValue().discountType === DiscountType.Amount &&
                moneyWithCurrencyCodeFormatter(getValue().discountAmount, row.original.currencyCode)}
              {getValue().discountType === DiscountType.Percentage && `${getValue().discountPercentage} %`}
            </>
          );
        },
        meta: {
          headerSx: { textAlign: 'right' },
          columnSx: { textAlign: 'right' }
        }
      }),
      columnHelper.accessor('code', {
        header: 'Code',
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row, getValue }) => {
          if (row.original.classDefinition.discountClass !== DiscountClass.Coupon) return null;
          return <div>{getValue()}</div>;
        },
        meta: {
          headerSx: { textAlign: 'right' },
          columnSx: { textAlign: 'right', whiteSpace: 'nowrap' }
        }
      }),
      {
        header: ' ',
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => {
          if (row.original.classDefinition.discountClass !== DiscountClass.Coupon) return null;
          return (
            <Button
              onClick={() => copy(row.original.code)}
              color="primary"
              style={{ paddingLeft: 0, margin: 0, minWidth: 0, marginRight: '10px' }}
            >
              <ContentCopyIcon />
            </Button>
          );
        },
        meta: {
          columnSx: { textAlign: 'left' }
        }
      },
      columnHelper.accessor('activityTypes', {
        header: 'Applied to',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ getValue }) => <span>{getActivityTypesList(getValue())} </span>,
        meta: {
          headerSx: { maxWidth: 120, width: 120 }
        }
      }),
      columnHelper.accessor('expiresOn', {
        header: 'Expires On',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ getValue }) => (
          <>{formatStringToMonthDayYearDate(convertUtcToZonedTime(getValue(), timeZone).toISOString())}</>
        ),
        meta: {
          headerSx: { maxWidth: 120, width: 120 }
        }
      }),

      ...additionalColumns
    ];

    return columns;
  }, [accessId, timeZone, additionalColumns]);
};

export default useDiscountsTableColumns;
