import React, { useRef } from 'react';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import { IconButton } from '@mui/material';
import { useParams } from 'react-router-dom';
import { nowUTC } from '@efacity/common';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import { apiService, adminSectionTableFontSize, Discount } from '@efacity/frontend-shared';
import { isBefore } from 'date-fns';
import { smallPublishButtonStyle } from '../../components/Buttons/SessionPublishButton';
import { InstanceTemporality } from '@efacity/common';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { Table, usePaginatedLoaderWithAdditionalInfo, TableRef } from '@efacity/table';
import useDiscountsTableColumns from './useDiscountsTableColumns';

export interface DiscountsTableProps {
  temporality: InstanceTemporality;
}

const DiscountsTable: React.FC<DiscountsTableProps> = ({ temporality }) => {
  const { orgId } = useParams<OrgIdParamType>();
  const tableRef = useRef<TableRef<Discount>>(null);
  const dataFetcherUrl = `/org/${orgId}/discounts/${temporality}`;

  const { dataState, paginatedDataFetcher } = usePaginatedLoaderWithAdditionalInfo<Discount>({
    dateTimeFields: ['createdOn', 'expiresOn'],
    additionalFields: ['timezone']
  });

  function handleDeleteDiscount(discountId: string) {
    apiService.delete(`/org/${orgId}/discounts/${discountId}`).then(
      (result: any) => {
        showNotification(true, result.data.message as string, false);
        tableRef.current?.refetchTableData();
      },
      (error) => {
        showNotification(false, error.response.data.message as string, true);
      }
    );
  }

  const columnsNew = useDiscountsTableColumns(
    orgId,
    dataState['timeZone'],
    temporality === InstanceTemporality.Current
      ? [
          {
            header: '',
            id: 'actions-cell',
            enableColumnFilter: false,
            enableSorting: false,
            cell: ({ row }) => {
              return (
                <IconButton
                  color="primary"
                  aria-label="delete discount"
                  data-testid="delete-discount-btn"
                  component="span"
                  style={smallPublishButtonStyle}
                  disabled={isBefore(new Date(row.original.expiresOn), new Date(nowUTC()))}
                  onClick={() => handleDeleteDiscount(row.original._id)}
                >
                  <DeleteIcon />
                </IconButton>
              );
            },
            meta: {
              columnSx: { ...adminSectionTableFontSize, width: 75, maxWidth: 75 }
            }
          }
        ]
      : []
  );

  return (
    <Table<Discount>
      tableRef={tableRef}
      dataFetcherUrl={dataFetcherUrl}
      columnDefs={columnsNew}
      dataFetcher={paginatedDataFetcher}
      manualFiltering={false}
      manualSorting={false}
      noDataText={'No Discounts...'}
    />
  );
};

export default DiscountsTable;
