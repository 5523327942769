import React from 'react';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createRoot } from 'react-dom/client';
import { gtag } from './gtag';
import { GoogleAnalytics } from '@efacity/google-analytics';
import { initSentryReplayIntegration } from '@efacity/frontend-next/sentry';
import { AppEnv } from '@efacity/common';

const REACT_APP_ENVIRONMENT = process.env['REACT_APP_ENVIRONMENT'] || 'dev';

if (REACT_APP_ENVIRONMENT === 'production' || REACT_APP_ENVIRONMENT === 'test') {
  initSentryReplayIntegration(REACT_APP_ENVIRONMENT as AppEnv, process.env['REACT_APP_SENTRY_DSN']);
  GoogleAnalytics.init(gtag);
} else {
  // eslint-disable-next-line no-console
  console.log('Logging is disabled');
}

const container = document.getElementById('app');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
