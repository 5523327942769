import { Box, Typography } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { FormMode, Messages, OrganizationIdName } from '@efacity/common';
import {
  apiService,
  initialUserFormValues,
  useFetch,
  UserFormValues,
  getMergedFormValues,
  WithLoading
} from '@efacity/frontend-shared';
import { addServerErrors } from '@efacity/react-hook-form-mui';
import { CenteredFormContainer } from '@efacity/frontend-next-shared/forms/server';
import { PATHS, toPath } from '@efacity/routing';
import { useNavigate } from 'react-router-dom';
import { UserSettingsForm, userSettingsFields } from '@efacity/frontend-next/site-admin/users';
import { showNotification } from '@efacity/frontend-next-shared/notifications';

export interface UserFormProps {
  mode: FormMode;
}

type ParamTypes = Partial<{
  userId: string;
}>;

const SiteAdminEditUserContainer: React.FC<UserFormProps> = ({ mode }) => {
  const { userId } = useParams<ParamTypes>();
  const navigate = useNavigate();

  const [{ data: userFormValues, isLoading: isUserLoading }] = useFetch<UserFormValues>(`/users/${userId}`, {
    initialDataState: initialUserFormValues,
    shouldFetch: !!userId
  });
  const [{ data: organizationOptions, isLoading: isOrganizationLoading }] = useFetch<OrganizationIdName[]>(
    `/org/organizations`,
    {
      initialDataState: []
    }
  );

  const handleSubmit = async (
    formValues: UserFormValues,
    setError: (fieldName: keyof UserFormValues, error: { type: string; message: string }) => void
  ) => {
    const userActionSuccessful = (message: string) => {
      showNotification(true, message);
      navigate(toPath(PATHS.siteUsers));
    };

    const userActionFailure = (error) => {
      const { data: errorData } = error.response;
      if (errorData.validationErrors) {
        addServerErrors<UserFormValues>(errorData.validationErrors, setError);
      } else {
        showNotification(false, error.response?.data?.message || Messages.FailedUpdateInstructor, true);
      }
    };

    if (mode === FormMode.Edit) {
      return apiService
        .patch<{ message: string }>(`/users/${userId}`, formValues)
        .then(({ data }) => {
          userActionSuccessful(data.message);
        })
        .catch((error) => {
          userActionFailure(error);
        });
    } else {
      return apiService
        .post<{ message: string }>('/users', formValues)
        .then(({ data }) => {
          userActionSuccessful(data.message as string);
        })
        .catch((error) => {
          userActionFailure(error);
        });
    }
  };

  const handleCancel = () => {
    navigate(toPath(PATHS.siteUsers));
  };

  return (
    <div>
      <CenteredFormContainer>
        <Box marginBottom={4}>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            {mode === FormMode.Edit ? 'Edit User' : 'Add User'}
            <hr style={{ height: '2', width: 'auto', margin: 0 }} />
          </Typography>
        </Box>
        <WithLoading
          isLoading={isUserLoading || isOrganizationLoading}
          message={'Loading user settings...'}
          style={{ width: '50%' }}
        >
          <UserSettingsForm
            initialFormValues={getMergedFormValues(initialUserFormValues, userFormValues)}
            organizationOptions={organizationOptions}
            allowHandleAccess={true}
            allowChangeEmail={true}
            allowChangePassword={true}
            handleSubmit={handleSubmit}
            isSubmitDisabled={isUserLoading}
            handleCancel={handleCancel}
            showCancel={true}
            userModelFields={userSettingsFields}
            formMode={mode}
          />
        </WithLoading>
      </CenteredFormContainer>
    </div>
  );
};

export default SiteAdminEditUserContainer;
