import React, { useMemo } from 'react';
import { Link, Typography } from '@mui/material';
import { adminSectionTableFontSize } from '@efacity/frontend-shared';
import { PhoneNumber } from '@efacity/react-next-sc';
import EmailAddress from '../../components/EmailAddress/EmailAddress';
import { createColumnHelper } from '@tanstack/react-table';
import { CustomerWithStudents } from '@efacity/common';
import { TextColumnFilter } from '@efacity/table';

const columnHelper = createColumnHelper<CustomerWithStudents>();

const useCustomersTableColumns = (onEditCustomerInfo: (customerId: string) => void) => {
  return useMemo(() => {
    const columns = [
      columnHelper.accessor('fullName', {
        header: 'Full Name',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ getValue, row }) => {
          return (
            <Link
              component="button"
              align={'left'}
              aria-label="edit-customer-info"
              onClick={() => onEditCustomerInfo(row.original._id)}
              underline={'none'}
            >
              {getValue()}
            </Link>
          );
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      }),
      columnHelper.accessor('email', {
        header: 'Email',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ getValue }) => {
          return <EmailAddress email={getValue()} />;
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      }),
      columnHelper.accessor('phone', {
        header: 'Phone',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ getValue }) => {
          return <PhoneNumber phone={getValue()} />;
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      }),
      {
        header: 'Student(s)',
        id: 'students',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => {
          return row.original.students.map((child, index) => (
            <div key={index}>
              <Typography style={{ ...adminSectionTableFontSize }}>{child.fullName}</Typography>
              {child.email && (
                <Typography style={{ ...adminSectionTableFontSize }}>
                  (<EmailAddress email={child.email} />)
                </Typography>
              )}
            </div>
          ));
        }
      }
    ];

    return columns;
  }, [onEditCustomerInfo]);
};

export default useCustomersTableColumns;
