import { apiService } from '@efacity/frontend-shared';
import { Dispatch, useCallback, useState } from 'react';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { Attendance, AttendanceExtended, Messages } from '@efacity/common';
import { addMinutes } from 'date-fns';
import {
  DataFetcherArgs,
  initialDataFetcherArgs,
  removeDateOffsetFromFilters,
  getFiltersQueryParameters,
  mapReactTableSortToApiSort
} from '@efacity/table';

export interface AttendanceState {
  isLoadingForDate: boolean;
  attendanceForDate: Attendance[];
  validationErrors: Record<'fromDate' | 'toDate', string> | null;
}

export interface FetchOptionsWithFromToDatesState {
  fromDate: Date;
  toDate: Date;
  dataFetcherArgs: DataFetcherArgs;
}

export const useAttendanceLoader = (
  url: string,
  attendanceFilters: FetchOptionsWithFromToDatesState,
  dateTimeFields: string[],
  setTimezone: Dispatch<string>
) => {
  const [attendanceState, setAttendanceState] = useState<AttendanceState>({
    isLoadingForDate: false,
    validationErrors: null,
    attendanceForDate: []
  });

  const getAttendance = async (dataFetcherArgs: DataFetcherArgs): Promise<{ data: Attendance[]; total: number }> => {
    // Attendance table uses client side pagination/sorting/filtering
    // so, send only dates to backend to get all data
    const { fromDate, toDate } = attendanceFilters;
    const attendanceFiltersWithOnlyDated = {
      fromDate: fromDate,
      toDate: toDate,
      dataFetcherArgs: initialDataFetcherArgs
    };

    try {
      const { data } = await apiService.get<Attendance[]>(
        url,
        getQueryParamsForAttendanceFilters(attendanceFiltersWithOnlyDated, [])
      );
      if (data?.length > 0 && data[0]?.timeZone) setTimezone(data[0].timeZone);
      return { data: data, total: data.length };
    } catch (error) {
      const validationErrors = error?.response?.data?.validationErrors;

      setAttendanceState((attendanceState) => ({
        ...attendanceState,
        validationErrors: validationErrors
      }));
      if (!validationErrors) {
        showNotification(false, (error.response?.data?.message as string) || Messages.FailedGetAttendance, true);
      }
      return { data: [], total: 0 };
    }
  };

  const getAttendanceForDate = useCallback(
    async (date: Date) => {
      const dateOffset = new Date(date).getTimezoneOffset();

      setAttendanceState((state) => ({ ...state, isLoadingForDate: true }));
      try {
        const { data } = await apiService.get<AttendanceExtended[]>(url, {
          fromDate: addMinutes(date, -dateOffset),
          toDate: addMinutes(date, -dateOffset)
        });

        setAttendanceState((state) => ({
          ...state,
          isLoadingForDate: false,
          attendanceForDate: data
        }));
      } catch {
        setAttendanceState((state) => ({
          ...state,
          isLoadingForDate: false,
          attendanceForDate: []
        }));
        showNotification(false, Messages.FailedGetAttendance, true);
      }
    },
    [url]
  );

  return {
    attendanceState: attendanceState,
    setAttendanceState: setAttendanceState,
    getAttendance: getAttendance,
    getAttendanceForDate
  };
};

export const getQueryParamsForAttendanceFilters = (
  attendanceFilters: FetchOptionsWithFromToDatesState,
  dateTimeFields: string[]
) => {
  const { fromDate, toDate, dataFetcherArgs } = attendanceFilters;
  const { pagination, sorting, columnFilters } = dataFetcherArgs;

  const fromZoneOffset = new Date(fromDate).getTimezoneOffset();
  const toZoneOffset = new Date(toDate).getTimezoneOffset();

  return {
    fromDate: addMinutes(fromDate, -fromZoneOffset),
    toDate: addMinutes(toDate, -toZoneOffset),
    page: pagination.pageIndex,
    perPage: pagination.pageSize,
    sortBy: mapReactTableSortToApiSort(sorting ?? []),
    ...getFiltersQueryParameters(removeDateOffsetFromFilters(columnFilters, dateTimeFields ?? []))
  };
};
