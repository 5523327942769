import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import { adminSectionTableFontSize } from '@efacity/frontend-shared';
import EmailAddress from '../../components/EmailAddress/EmailAddress';
import { NameLinkWithNoteIcon } from '../../components/NameLinkWithNoteIcon/NameLinkWithNoteIcon';
import { StudentWithCustomers } from '@efacity/common';
import { createColumnHelper } from '@tanstack/react-table';
import { TextColumnFilter } from '@efacity/table';

const columnHelper = createColumnHelper<StudentWithCustomers>();

const useStudentsTableColumns = (
  onEditStudentInfo: ({ studentId, studentName }: { studentId: string; studentName: string }) => void,
  onOpenNotesModal: (studentId: string, studentName: string) => void
) => {
  return useMemo(() => {
    const columns = [
      columnHelper.accessor('fullName', {
        header: 'Full Name',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ getValue, row }) => {
          return (
            <NameLinkWithNoteIcon
              name={row.original.fullName}
              onNameClick={() =>
                onEditStudentInfo({
                  studentId: row.original._id,
                  studentName: getValue()
                })
              }
              onNoteClick={() => onOpenNotesModal(row.original._id, row.original.fullName)}
            />
          );
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      }),
      columnHelper.accessor('email', {
        header: 'Student Email',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ getValue }) => {
          return <EmailAddress email={getValue()} />;
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      }),
      columnHelper.accessor('customers.fullName', {
        header: 'Customer',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => {
          return row.original?.customers?.length > 0 ? (
            row.original.customers.map((customer, index) => {
              return (
                <div key={`customerForStudent${row.original._id}Customer${customer._id}Index${index}Info`}>
                  <Typography style={{ ...adminSectionTableFontSize }}>{customer.fullName}</Typography>

                  {customer.email && (
                    <Typography style={{ ...adminSectionTableFontSize }}>
                      (<EmailAddress email={customer.email} />)
                    </Typography>
                  )}
                </div>
              );
            })
          ) : (
            <Typography>-</Typography>
          );
        }
      })
    ];

    return columns;
  }, [onEditStudentInfo, onOpenNotesModal]);
};

export default useStudentsTableColumns;
