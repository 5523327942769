import { PageTitle, useAuth } from '@efacity/react-next-sc';
import { Box } from '@mui/material';
import React, { useRef } from 'react';
import { Row } from 'react-table';
import SmallInvoiceDataTable from '../../components/SmallInvoiceDataTable/SmallInvoiceDataTable';
import { Messages, ProductPurchaseWithOrganization } from '@efacity/common';
import { Table, TableRef, usePaginatedLoaderWithAdditionalInfo } from '@efacity/table';
import { useCustomerProductPurchasesColumns } from './useCustomerProductPurchasesColumns';

const CustomerProductPurchasesContainer: React.FC = () => {
  const {
    authState: {
      user: { _id }
    }
  } = useAuth();
  const tableRef = useRef<TableRef<ProductPurchaseWithOrganization>>(null);
  const dataFetchUrl = `/users/${_id}/product-purchases`;
  const { paginatedDataFetcher } = usePaginatedLoaderWithAdditionalInfo<ProductPurchaseWithOrganization>({
    dateTimeFields: ['updatedAt']
  });

  const columns = useCustomerProductPurchasesColumns();

  const renderInvoiceBalanceTable = (row: Row<ProductPurchaseWithOrganization>) => {
    return (
      <td colSpan={columns.length - 1}>
        <Box margin={1}>
          <SmallInvoiceDataTable
            invoiceBalance={row.original?.invoiceBalance}
            message={Messages.NoInvoiceForPurchase}
          />
        </Box>
      </td>
    );
  };

  return (
    <div>
      <PageTitle title="Purchases" addButtonLink={undefined} />
      <Table<ProductPurchaseWithOrganization>
        tableRef={tableRef}
        dataFetcherUrl={dataFetchUrl}
        dataFetcher={paginatedDataFetcher}
        columnDefs={columns}
        noDataText="No purchased products..."
        renderRowSubComponent={renderInvoiceBalanceTable}
      />
    </div>
  );
};

export default CustomerProductPurchasesContainer;
