import React, { useRef, useState } from 'react';
import { Address, FormMode } from '@efacity/common';
import { Box } from '@mui/material';
import {
  NewButton,
  AddressModal,
  useAddressesLoader,
  AddressFormValues
} from '@efacity/frontend-next-shared/org-settings';
import { Table, TableRef, usePaginatedLoaderWithAdditionalInfo } from '@efacity/table';
import useAddressesTableColumns from './useAddressesTableColumns';

const addAddressButtonContainerStyle = {
  marginBottom: 15,
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end'
};

export const defaultAddressModalState = {
  isOpen: false,
  addressId: null,
  mode: FormMode.Add
};

export interface SchoolAddressesContainerProps {
  orgId: string;
}

export const OrganizationAddressesContainer: React.FC<SchoolAddressesContainerProps> = ({ orgId }) => {
  const tableRef = useRef<TableRef<Address>>(null);
  const [addressModalState, setAddressModalState] = useState(defaultAddressModalState);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, { deleteAddress, updateAddress, addAddress }] = useAddressesLoader(`/org/${orgId}/addresses`);
  const { dataState, paginatedDataFetcher } = usePaginatedLoaderWithAdditionalInfo<Address>({});

  function handleStartEditAddress(addressId: string) {
    setAddressModalState({ isOpen: true, addressId, mode: FormMode.Edit });
  }
  async function handleAddAddress(
    formValues: AddressFormValues,
    setError: (name, error, options?) => void,
    handleCloseModal: () => void
  ) {
    await addAddress(formValues, setError, handleCloseModal, false);
    tableRef.current?.refetchTableData();
  }
  async function handleUpdateAddress(
    formValues: AddressFormValues,
    setError: (name, error, options?) => void,
    addressId: string,
    handleCloseModal: () => void
  ) {
    await updateAddress(formValues, setError, addressId, handleCloseModal, false);
    tableRef.current?.refetchTableData();
  }

  async function handleDeleteAddress(addressId: string) {
    await deleteAddress(addressId, false);
    tableRef.current?.refetchTableData();
  }

  function handleStartAddAddress() {
    setAddressModalState({ isOpen: true, addressId: null, mode: FormMode.Add });
  }

  function handleCloseModal() {
    setAddressModalState(defaultAddressModalState);
  }

  const columns = useAddressesTableColumns(handleStartEditAddress, true, handleDeleteAddress);

  return (
    <div style={{ marginTop: '15px' }}>
      <Box style={addAddressButtonContainerStyle}>
        <NewButton data-testid="addBtn" onClick={() => handleStartAddAddress()}>
          Add address
        </NewButton>
      </Box>
      {addressModalState.isOpen && (
        <AddressModal
          open={addressModalState.isOpen}
          addressId={addressModalState.addressId}
          mode={addressModalState.mode}
          orgId={orgId}
          orgCountry={dataState['defaultCountry']}
          handleClose={handleCloseModal}
          onAddAddress={handleAddAddress}
          updateAddress={handleUpdateAddress}
        />
      )}
      <Table<Address>
        tableRef={tableRef}
        dataFetcherUrl={`/org/${orgId}/addresses`}
        columnDefs={columns}
        dataFetcher={paginatedDataFetcher}
        noDataText="No addresses..."
        initialSortBy={[{ id: 'name', desc: false }]}
        manualFiltering={false}
        manualSorting={false}
      />
    </div>
  );
};

export default OrganizationAddressesContainer;
