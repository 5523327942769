import {
  InstanceTemporality,
  Messages,
  Roles,
  SessionExportOption,
  adminAndIndependentRoles,
  nowUTC,
  SessionStatus
} from '@efacity/common';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { apiService } from '@efacity/frontend-shared';
import { hasFrontendAccessWithRoleForOrganization, Session as EfacitySession, useAuth } from '@efacity/react-next-sc';
import { PATHS, toPath } from '@efacity/routing';
import { Box, Tooltip, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { addDays } from 'date-fns';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import { ExportButton } from '../../components/Buttons/ExportButton';
import StartEndDatesFilter from '../../components/StartEndDatesFilter/StartEndDatesFilter';
import { handleSaveFile } from '../../utils/handleSaveFileFromResponse';
import CancellationModal from './CancellationModal';
import StudentExportOptionsModal from './StudentExportOptionsModal';
import { useSessionTabActions } from './useSessionTabActions';
import {
  getFiltersQueryParameters,
  mapReactTableSortToApiSort,
  Table as TableNew,
  TableRef,
  usePaginatedLoaderWithAdditionalInfo
} from '@efacity/table';
import useSessionsTableColumns from './useSessionsTableColumns';

export interface FromToSessionsDatesState {
  fromDate: Date;
  toDate: Date;
}

interface SessionsTableProps {
  sessionType: InstanceTemporality;
}

const SessionsTable: React.FC<SessionsTableProps> = ({ sessionType }) => {
  const { orgId } = useParams<OrgIdParamType>();
  const navigate = useNavigate();
  const {
    authState: { user }
  } = useAuth();
  const isUserIndependentTeacher = hasFrontendAccessWithRoleForOrganization(
    orgId,
    [Roles.IndependentTeacher],
    user.adminAccesses || []
  );

  const isUserAllowedToPerformSessionActions = useMemo<boolean>(() => {
    return hasFrontendAccessWithRoleForOrganization(orgId, adminAndIndependentRoles, user?.adminAccesses || []);
  }, [orgId, user]);

  const tableRef = useRef<TableRef<EfacitySession>>(null);
  const dataFetcherUrl = `/org/${orgId}/sessions/${sessionType}/list`;
  const { dataState, paginatedDataFetcher } = usePaginatedLoaderWithAdditionalInfo<EfacitySession>();

  const [
    {
      sessionActionsState: { isUpdating, sessionIdUnderUpdate },
      cancellationModalOpened,
      modalCallbackAdditionalProps
    },
    {
      onChangeSessionStatus,
      onCancelSession,
      onRestoreSession,
      onOpenCancellationModal,
      onCloseCancellationModal,
      setModalCallbackAdditionalProps
    }
  ] = useSessionTabActions(orgId);

  const now = nowUTC();
  const [fetchDataOptionsWithDates, setFetchDataOptionsWithDates] = useState<FromToSessionsDatesState>({
    fromDate: addDays(now, -30),
    toDate: now
  });

  const handleDatesChanged = useCallback(
    (newFromDate: Date, newToDate: Date) => {
      const newFetchOptionsWithDates = {
        ...fetchDataOptionsWithDates,
        fromDate: newFromDate,
        toDate: newToDate
      };
      setFetchDataOptionsWithDates(newFetchOptionsWithDates);
      tableRef.current?.refetchTableData();
    },
    [fetchDataOptionsWithDates]
  );

  const timeHeader = dataState.data?.length > 0 ? `Time (${dataState.data[0].timeZone})` : 'Time';
  const theme = useTheme();
  const widthMd = useMediaQuery(theme.breakpoints.up('md'));
  const widthSm = useMediaQuery(theme.breakpoints.up('sm'));
  const width = widthMd ? 'md' : widthSm ? 'sm' : 'xs';

  const handleRestoreSession = async (sessionId: string) => {
    await onRestoreSession(sessionId);
    tableRef.current?.refetchTableData();
  };

  const onSessionStatusChanged = async (sessionId: string, sessionStatus: SessionStatus) => {
    await onChangeSessionStatus(sessionId, sessionStatus);
    tableRef.current?.refetchTableData();
  };

  const columns = useSessionsTableColumns(
    orgId,
    isUserAllowedToPerformSessionActions,
    isUserIndependentTeacher,
    {
      onCloneSession,
      handleRestoreSession,
      onShowCancellationModal,
      onSessionStatusChanged
    },
    width,
    timeHeader,
    sessionIdUnderUpdate
  );
  async function onCloneSession(session) {
    navigate(toPath(PATHS.addSession, { orgId: orgId }), {
      state: { isCloning: true, sessionId: session._id }
    });
  }

  async function handleCancelSession(cancellationReason, cancelSessionActions) {
    await onCancelSession(modalCallbackAdditionalProps.sessionId, cancellationReason, cancelSessionActions);
    tableRef.current?.refetchTableData();
  }

  function onShowCancellationModal(sessionId: string) {
    onOpenCancellationModal();
    setModalCallbackAdditionalProps({ sessionId });
  }

  const [exportOptionsOpen, setExportOptionsOpen] = useState(false);
  const askStudentsExportOptions = () => {
    setExportOptionsOpen(true);
  };

  const [isExporting, setIsExporting] = useState(false);
  const exportSessionsList = (sessionExportOption: SessionExportOption) => {
    setIsExporting(true);
    const { fromDate, toDate } = fetchDataOptionsWithDates;
    const { dataFetcherArgs } = dataState;
    const { pagination, columnFilters, sorting } = dataFetcherArgs;
    const sessionsQueryParams = {
      page: pagination.pageIndex,
      perPage: pagination.pageSize,
      ...getFiltersQueryParameters(columnFilters),
      sortBy: mapReactTableSortToApiSort(sorting || []),
      sessionExportOption: sessionExportOption
    };
    if (sessionType === InstanceTemporality.Completed) {
      sessionsQueryParams['fromDate'] = fromDate;
      sessionsQueryParams['toDate'] = toDate;
    }

    apiService
      .get(`/org/${orgId}/sessions/${sessionType}/export`, sessionsQueryParams, {
        'content-disposition': 'attachment'
      })
      .then(
        async (result: any) => {
          setIsExporting(false);
          setExportOptionsOpen(false);
          const contentTypeHeader = result.headers['content-type'];
          if (
            contentTypeHeader?.indexOf('text/csv') !== -1 ||
            contentTypeHeader?.indexOf('application/pdf') !== -1 ||
            contentTypeHeader?.indexOf('application/octet-stream') !== -1 ||
            contentTypeHeader?.indexOf('text/html') !== -1
          ) {
            handleSaveFile(result);
          } else {
            showNotification(false, Messages.UnsupportedFileType, true);
          }
        },
        (error) => {
          setIsExporting(false);
          setExportOptionsOpen(false);
          showNotification(false, (error.response.data.message as string) || Messages.FailedExportRegistrations, true);
        }
      );
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" marginY={2}>
        <div>
          {sessionType === InstanceTemporality.Completed && (
            <StartEndDatesFilter
              fromDate={fetchDataOptionsWithDates.fromDate}
              toDate={fetchDataOptionsWithDates.toDate}
              handleDatesChanged={handleDatesChanged}
              validationErrors={{ fromDate: null, toDate: null }}
              adjustDatesInterval={false}
            />
          )}
        </div>
        <Tooltip title="Only 500 first records will be exported">
          <div>
            <ExportButton isExporting={isExporting} color={'primary'} onClick={askStudentsExportOptions} />
          </div>
        </Tooltip>
      </Box>
      <TableNew<EfacitySession>
        tableRef={tableRef}
        dataFetcherUrl={dataFetcherUrl}
        columnDefs={columns}
        dataFetcher={paginatedDataFetcher}
        noDataText="No Sessions..."
        manualSorting={false}
      />
      <CancellationModal
        titleText="Enter session cancellation reason"
        open={cancellationModalOpened}
        onClose={onCloseCancellationModal}
        submitCallback={handleCancelSession}
        isLoading={isUpdating}
      />
      <StudentExportOptionsModal
        open={exportOptionsOpen}
        handleSubmit={exportSessionsList}
        onClose={() => setExportOptionsOpen(false)}
        progress={isExporting}
      />
    </>
  );
};

export default SessionsTable;
