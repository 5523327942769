import React, { FC, useEffect } from 'react';
import { Messages } from '@efacity/common';
import { Dialog, DialogContent, Typography } from '@mui/material';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import NonEditableAddress from '../NonEditableAddress/NonEditableAddress';
import { apiService } from '@efacity/frontend-shared';
import { FormGroupTitle } from '@efacity/frontend-next-shared/forms/server';
import { SubmitMediumButton } from '@efacity/frontend-shared';
import { useCustomerFormValues } from './useCustomerFormState';
import { CustomerFormValues, editCustomerValidationSchema } from './customerFormValues';
import EmailAddress from '../EmailAddress/EmailAddress';
import NotesTableWithAddForm from '../NotesTableWithAddForm/NotesTableWithAddForm';
import { ModalDialogTitle } from '@efacity/react-next-sc';
import { useForm, FormProvider } from 'react-hook-form';
import { FormTextInput, FormPhoneInput, addServerErrors } from '@efacity/react-hook-form-mui';
import { yupResolver } from '@hookform/resolvers/yup';

export interface EditCustomerModalProps {
  orgId: string;
  customerId: string;
  onClose: (reloadData: boolean) => void;
  allowEditCustomer?: boolean;
}

const EditCustomerModal: FC<EditCustomerModalProps> = ({ orgId, customerId, onClose, allowEditCustomer = true }) => {
  const { customerFormState } = useCustomerFormValues(orgId, customerId);

  const methods = useForm<CustomerFormValues>({
    resolver: yupResolver(editCustomerValidationSchema),
    defaultValues: customerFormState.customerFormValues
  });
  const { isValid, isDirty, isSubmitting } = methods.formState;

  const handleSubmit = async (formValues: CustomerFormValues) => {
    try {
      await apiService.patch<string>(`/org/${orgId}/users/customers/${customerId}`, formValues);
      onClose(true);
    } catch (errorResponse) {
      if (errorResponse.validationErrors) {
        addServerErrors<CustomerFormValues>(errorResponse.validationErrors, methods.setError);
      } else {
        showNotification(false, errorResponse.message || Messages.FailedUpdateCustomer, true);
      }
    }
  };

  const handleCancel = () => {
    onClose(false);
  };

  useEffect(
    () => {
      methods.reset(customerFormState.customerFormValues);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(customerFormState.customerFormValues)]
  );

  return (
    <Dialog open={!!customerId} onClose={onClose} maxWidth="sm">
      <ModalDialogTitle text="Customer" onClose={handleCancel} />
      <DialogContent>
        <FormProvider {...methods}>
          <form noValidate onSubmit={methods.handleSubmit(handleSubmit)}>
            <Typography gutterBottom>
              <EmailAddress email={customerFormState.customerFormValues.email} />
            </Typography>
            <FormTextInput required name="fullName" label="Full Name" disabled={isSubmitting || !allowEditCustomer} />
            <FormPhoneInput name="phone" disabled={isSubmitting || !allowEditCustomer} />
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}>
              <SubmitMediumButton disabled={!isDirty || !isValid} isSubmitting={isSubmitting}>
                Save
              </SubmitMediumButton>
            </div>
          </form>
        </FormProvider>
        {customerFormState.customerFormValues?.address?.address?.length > 0 && (
          <NonEditableAddress
            title="Address"
            address={customerFormState.customerFormValues.address}
            hint={
              <Typography variant="body2" style={{ fontSize: '0.8rem' }} gutterBottom>
                * You cannot edit customer information directly. The information is managed by the customer. Ask the
                customer to update their information.
              </Typography>
            }
          />
        )}
        <FormGroupTitle>Notes</FormGroupTitle>
        <NotesTableWithAddForm url={`/org/${orgId}/users/customers/${customerId}/notes`} />
      </DialogContent>
    </Dialog>
  );
};

export default EditCustomerModal;
