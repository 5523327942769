import { CustomerWithCredits, moneyWithCurrencyCodeFormatter } from '@efacity/common';
import { PageTitle, useAuth } from '@efacity/react-next-sc';
import { PATHS, toPath } from '@efacity/routing';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Collapse, IconButton, Link } from '@mui/material';
import React, { useMemo, useRef, useState } from 'react';
import CreditsToRefundTable from '../OrganizationCredits/CreditsToRefundTable';
import { nonPaginatedDataFetcher, Table, TableRef } from '@efacity/table';

const CustomerCreditsContainer: React.FC = () => {
  const {
    authState: {
      user: { _id }
    }
  } = useAuth();
  const tableRef = useRef<TableRef<CustomerWithCredits>>(null);

  const tableColumns = useMemo(() => {
    return [
      {
        header: 'Organization',
        id: 'organization',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => {
          return (
            <Link href={toPath(PATHS.orgLanding, { orgId: row.original.organization._id })} underline={'none'}>
              {row.original.organization.name}
            </Link>
          );
        }
      },
      {
        header: 'Credit',
        id: 'credit',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const [open, setOpen] = useState(false);
          return (
            <>
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <div style={{ paddingTop: 5, paddingRight: row?.original?.creditFromPayments?.length > 0 ? 10 : 40 }}>
                  {moneyWithCurrencyCodeFormatter(row.original.credit.amount, row.original.credit.currencyCode)}
                </div>
                <div>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                    data-testid="collapseButton"
                  >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </div>
              </div>
              <Collapse in={open} style={{ display: 'block' }}>
                <CreditsToRefundTable
                  orgId={row.original.organization._id}
                  creditId={row.original?.credit?._id}
                  creditFromPayments={row?.original?.credit?.creditFromPayments || []}
                  currencyCode={row.original.credit?.currencyCode}
                  timeZone={row.original.organization.timeZone}
                  allowRefund={false}
                />
              </Collapse>
            </>
          );
        },
        meta: {
          headerSx: { textAlign: 'right', paddingRight: '75px' }
        }
      }
    ];
  }, []);

  return (
    <>
      <PageTitle title="Credits" addButtonLink={undefined} underlined />
      <Table<CustomerWithCredits>
        tableRef={tableRef}
        dataFetcherUrl={`/users/${_id}/credits`}
        columnDefs={tableColumns}
        dataFetcher={nonPaginatedDataFetcher<CustomerWithCredits>}
        manualFiltering={false}
        manualSorting={false}
        noDataText="No Credits..."
      />
    </>
  );
};

export default CustomerCreditsContainer;
