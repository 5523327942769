import { Dialog, DialogContent } from '@mui/material';
import React, { FC } from 'react';
import { AddressFormValues } from './AddressForm';
import { useAddressFormValues } from '../hooks/useAddressesLoader';
import { CountryCode, getCountryByCode, getCountryNameByCode, FormMode } from '@efacity/common';
import { AddressFormContainer } from './AddressFormContainer';
import { ModalDialogTitle } from '@efacity/react-next-sc';

export interface AddressModalProps {
  open: boolean;
  mode: FormMode;
  addressId: string;
  orgId: string;
  orgCountry?: CountryCode;
  handleClose: () => void;
  onAddAddress?: (formValues: AddressFormValues, setError: any, handleCloseModal: () => void) => void;
  updateAddress?: (
    formValues: AddressFormValues,
    setError: (name, error, options?) => void,
    addressId: string,
    handleCloseModal: () => void
  ) => void;
}

export const AddressModal: FC<AddressModalProps> = ({
  open,
  addressId,
  orgId,
  orgCountry,
  mode,
  handleClose,
  onAddAddress,
  updateAddress
}) => {
  const { addressFormState } = useAddressFormValues(mode, orgId, addressId);

  const handleSubmitAddressForm = async (values: AddressFormValues, setError) => {
    if (mode === FormMode.Edit) {
      await updateAddress(values, setError, addressId, handleClose);
    } else {
      await onAddAddress(values, setError, handleClose);
    }
  };

  const countryAdjective = getCountryByCode(orgCountry).formTitles['adjective'];
  const countyNameAdjective = orgCountry
    ? countryAdjective
      ? countryAdjective
      : getCountryNameByCode(orgCountry)
    : '';

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="Add address modal" maxWidth="xs" fullWidth>
      <ModalDialogTitle
        text={mode === FormMode.Edit ? `Edit ${countyNameAdjective} Address` : `Add ${countyNameAdjective} Address`}
      />

      <DialogContent>
        <AddressFormContainer
          onSubmit={handleSubmitAddressForm}
          onCancel={handleClose}
          mode={mode}
          country={orgCountry}
          initialFormValues={
            orgCountry
              ? { ...addressFormState.addressFormValues, country: orgCountry }
              : addressFormState.addressFormValues
          }
        />
      </DialogContent>
    </Dialog>
  );
};
