import { apiService, getMergedFormValues } from '@efacity/frontend-shared';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { convertUtcToZonedTime, FormMode, Tag, TagFormValue } from '@efacity/common';
import { useEffect, useState } from 'react';
import { addMinutes } from 'date-fns';

export const initialTagFormValues: TagFormValue = {
  name: '',
  activityTypes: [],
  isSessionPeriod: false,
  validFrom: new Date(),
  validTo: new Date()
};

export const useTagFormValues = (orgId: string, tagId: string, formMode: FormMode) => {
  const [tagFormState, setTagFormState] = useState({
    tagFormValue: initialTagFormValues,
    isLoading: false
  });

  useEffect(() => {
    const getTagById = async (orgId: string, tagId: string) => {
      setTagFormState({
        tagFormValue: initialTagFormValues,
        isLoading: true
      });

      try {
        const { data } = await apiService.get<Tag>(`/org/${orgId}/tags/${tagId}`);
        const tag = { ...data };
        delete tag._id;
        delete tag.owner;
        delete tag.deletedAt;

        const isIncomingTagSessionPeriod = !!(tag.validFrom || tag.validTo);

        const mergedValues = getMergedFormValues(initialTagFormValues, tag);
        const mergedTagValues: TagFormValue = {
          ...mergedValues,
          isSessionPeriod: isIncomingTagSessionPeriod,
          validFrom: isIncomingTagSessionPeriod
            ? addMinutes(
                convertUtcToZonedTime(new Date(tag.validFrom), tag.timeZone),
                0 //dstOffsetAtDate(new Date(tag.validFrom)) / 60000
              )
            : null,
          validTo: isIncomingTagSessionPeriod
            ? addMinutes(
                convertUtcToZonedTime(new Date(tag.validTo), tag.timeZone),
                0 //dstOffsetAtDate(new Date(tag.validTo)) / 60000
              )
            : null
        };

        setTagFormState((tagFormState) => ({
          ...tagFormState,
          tagFormValue: mergedTagValues,
          isLoading: false
        }));
      } catch (error) {
        showNotification(false, error.response?.data?.message || 'Failed to get Tag info.', true);
        setTagFormState({
          tagFormValue: initialTagFormValues,
          isLoading: true
        });
      }
    };

    if (formMode === FormMode.Add) {
      setTagFormState((tagFormState) => ({
        ...tagFormState,
        tagFormValue: initialTagFormValues
      }));
    } else {
      getTagById(orgId, tagId);
    }
  }, [orgId, tagId, formMode]);

  return {
    tagFormState
  };
};
