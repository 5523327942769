import { LightTooltip } from '@efacity/react-next-sc';
import CropIcon from '@mui/icons-material/Crop';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogProps, IconButton } from '@mui/material';
import React, { useCallback, useState } from 'react';
import Cropper, { Area } from 'react-easy-crop';
import { getCroppedImg } from '../ImageCropper/helpers';

const MIN_ZOOM = 0.4;
const MAX_ZOOM = 3;

const zoomControlsContainerStyles = {
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  bottom: 10,
  right: 10,
  '& button': {
    color: '#fff'
  }
};

export interface CropImageModalProps extends DialogProps {
  open: boolean;
  imageHeight: number;
  imageWidth: number;
  cropperPreview: string;
  onCropFinished: (croppedImage: Blob) => void;
  onClose: () => void;
  onCropError: (errorMessage: string) => void;
}

const CropImageModal: React.FC<CropImageModalProps> = ({
  open,
  imageHeight,
  imageWidth,
  cropperPreview,
  onCropFinished,
  onClose,
  onCropError
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onCropImage = async () => {
    try {
      setIsLoading(true);
      const croppedImage = await getCroppedImg(cropperPreview, croppedAreaPixels, imageWidth, imageHeight);
      setIsLoading(false);
      onCropFinished(croppedImage);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      onCropError('Failed to crop image.');
    }
  };

  const handleZoomChange = (zoomValue: number) => {
    setZoom(zoomValue);
  };
  const onZoomIn = () => {
    setZoom((prevZoom) => prevZoom + 0.05);
  };
  const onZoomOut = () => {
    setZoom((prevZoom) => prevZoom - 0.05);
  };

  const minZoomReached = (zoomValue: number) => {
    return Number(zoomValue.toFixed(1)) <= MIN_ZOOM;
  };
  const maxZoomReached = (zoomValue: number) => zoomValue >= MAX_ZOOM;

  return (
    <Dialog onClose={onClose} open={open} maxWidth="md" fullWidth>
      <div style={{ position: 'relative', height: 600 }}>
        <Cropper
          image={cropperPreview}
          crop={crop}
          zoom={zoom}
          minZoom={MIN_ZOOM}
          maxZoom={MAX_ZOOM}
          aspect={4 / 3}
          cropSize={{ height: imageHeight, width: imageWidth }}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={handleZoomChange}
          zoomWithScroll
          zoomSpeed={0.05}
          restrictPosition={false}
        />
        <Box sx={zoomControlsContainerStyles}>
          <LightTooltip title="Zoom in" placement="left">
            <IconButton onClick={onZoomIn} disabled={maxZoomReached(zoom)}>
              <ZoomInIcon />
            </IconButton>
          </LightTooltip>

          <LightTooltip title="Zoom out" placement="left">
            <IconButton onClick={onZoomOut} disabled={minZoomReached(zoom)}>
              <ZoomOutIcon />
            </IconButton>
          </LightTooltip>
        </Box>
      </div>
      <DialogActions>
        <Button onClick={onCropImage} color="primary" variant="contained" disabled={isLoading}>
          {isLoading ? (
            <CircularProgress
              variant="indeterminate"
              data-testid="loader"
              size={20}
              style={{ marginRight: 20, color: 'white' }}
            />
          ) : (
            <CropIcon style={{ marginRight: 20 }} />
          )}
          <span>Crop</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CropImageModal;
