import React, { useCallback, useEffect } from 'react';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { useNavigate } from 'react-router-dom';
import { Messages } from '@efacity/common';
import { RegistrationInfoModal } from './RegistrationInfoModal/RegistrationInfoModal';
import { User } from '@efacity/common';
import SelectStudentsToSessionByIndex from './SelectStudentsToSessionByIndex';
import { Session as EfacitySession } from '@efacity/react-next-sc';
import { WithLoading } from '@efacity/frontend-shared';
import { PATHS } from '@efacity/routing';
import { setNeedReturnToRegistration } from '../CustomerStudents/useCustomerStudentsLoader';
import { useShoppingCartPayment } from '../ShoppingCartContainer/useShoppingCartPayment';

const SelectStudentsForSessions = () => {
  const [{ registrationState }, { registrationActions }] = useShoppingCartPayment();
  const { startRegistrationURL, isLoadingSessions, sessionsSelectedForRegistration, students, registrationInfoModal } =
    registrationState;
  const { setRegistrationState, querySessionsToRegisterOn } = registrationActions;
  const navigate = useNavigate();

  const closeModalAndGotoRegistrations = useCallback((storedRedirectUrl: string) => {
    setRegistrationState((state) => ({
      ...state,
      registrationInfoModal: {
        ...state.registrationInfoModal,
        isOpen: false
      }
    }));
    const redirectPath = storedRedirectUrl ? storedRedirectUrl : PATHS.landing;
    window.location.href = redirectPath;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gotoStartRegistrationsIfAny = useCallback(() => {
    const redirectPath = startRegistrationURL ? startRegistrationURL : PATHS.landing;
    window.location.href = redirectPath;
  }, [startRegistrationURL]);

  useEffect(() => {
    const loadSessionsAndValidate = async () => {
      const toggleNeedReturnAndGotoAddStudent = (requireStudentAge) => {
        setNeedReturnToRegistration(requireStudentAge);
        navigate(PATHS.addStudent);
      };
      const validateIncomingSessionsAndStudent = (
        sessions: EfacitySession[],
        startRegistrationURL: string,
        students: User[],
        requireStudentAge: boolean
      ) => {
        if (!(sessions && sessions.length > 0)) {
          setRegistrationState((state) => ({
            ...state,
            registrationInfoModal: {
              ...state.registrationInfoModal,
              isOpen: true,
              isError: false,
              message: Messages.ToStartNeedRegistrations,
              onClose: closeModalAndGotoRegistrations
            }
          }));
        } else if (!(students && students.length > 0)) {
          toggleNeedReturnAndGotoAddStudent(requireStudentAge);
        }
      };

      const { isError, sessions, startRegistrationURL, students, requireStudentAge, message } =
        await querySessionsToRegisterOn();
      if (isError) {
        showNotification(false, message, true, 'Ok');
        gotoStartRegistrationsIfAny();
      } else {
        validateIncomingSessionsAndStudent(sessions, startRegistrationURL, students, requireStudentAge);
      }
    };
    loadSessionsAndValidate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const readyToSelect =
    students && students.length > 0 && sessionsSelectedForRegistration && sessionsSelectedForRegistration.length > 0;

  return (
    <div>
      <WithLoading isLoading={isLoadingSessions} message={'Loading data to start registrations...'}>
        {readyToSelect && (
          <SelectStudentsToSessionByIndex
            registrationState={registrationState}
            registrationActions={registrationActions}
          />
        )}
      </WithLoading>
      <RegistrationInfoModal registrationInfoModal={registrationInfoModal} />
    </div>
  );
};

export default SelectStudentsForSessions;
