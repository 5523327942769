import { PATHS, toPath } from '@efacity/routing';
import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Link } from '@mui/material';
import { activityTypesMapper, formatStartToEndDateInTimeZone, Tag } from '@efacity/common';
import SessionsWithTagLinkCopier from './SessionsWithTagLinkCopier';
import { createColumnHelper } from '@tanstack/react-table';
import { TextColumnFilter } from '@efacity/table';

const columnHelper = createColumnHelper<Tag>();

const useTagsTableColumns = (orgId: string) => {
  return useMemo(() => {
    const columns = [
      columnHelper.accessor('name', {
        header: 'Name',
        cell: ({ row, getValue }) => {
          return (
            <Box display="flex" alignItems="center" data-testid="session-tag-copier-cell" style={{ marginRight: 5 }}>
              <Link
                component={NavLink}
                to={toPath(PATHS.editTag, { orgId: orgId, tagId: row.original._id })}
                underline={'none'}
              >
                {getValue()?.trim()?.length > 0 ? getValue() : '[no name]'}
              </Link>
              &nbsp;
              <SessionsWithTagLinkCopier isEnabled={true} orgId={orgId} tagId={row.original._id} />
            </Box>
          );
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      }),
      {
        header: 'Activity Types',
        accessor: 'activityTypes',
        cell: ({ row }) => {
          return row.original?.activityTypes && row.original?.activityTypes?.length > 0
            ? row.original.activityTypes.map((activityType, index) =>
                index === 0 ? activityTypesMapper[activityType] : `, ${activityTypesMapper[activityType]}`
              )
            : '-';
        }
      },
      {
        header: 'Session Period',
        id: 'session-period',
        cell: ({ row }) => {
          return (
            <div>
              {row.original?.validFrom && row.original?.validTo && row.original?.timeZone
                ? `${formatStartToEndDateInTimeZone(
                    row.original.validFrom,
                    row.original.validTo,
                    row.original.timeZone
                  )}`
                : ''}
            </div>
          );
        }
      },
      {
        header: 'Tagged Sessions',
        id: 'tagged-sessions',
        cell: ({ row }) => {
          return <div color="primary">{row.original?.sessions ? row.original?.sessions?.length : '-'}</div>;
        }
      }
    ];

    return columns;
  }, [orgId]);
};

export default useTagsTableColumns;
