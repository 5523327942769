import { FormMode, Roles } from '@efacity/common';
import { hasFrontendAccessWithRoleForOrganization, PageTitle, useAuth } from '@efacity/react-next-sc';
import React from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import PageNavigationTabs from '../../components/PageNavigatonTabs/PageNavigationTabs';
import CustomerAgreementsContainer from '../CustomerAgreements/CustomerAgreementsContainer';
import LocationFormContainer from '../Locations/LocationFormContainer';
import LocationsContainer from '../Locations/LocationsContainer';
import { OrganizationAddressesContainer } from './Addresses/OrganizationAddressesContainer';
import { EmailSettingsContainer } from './EmailSettings/EmailSettingsContainer';
import { OrganizationSettingsFormContainer } from './Organization/OrganizationSettingsFormContainer';
import { OrganizationPaymentsContainer } from './OrganizationPayments/OrganizationPaymentsContainer';
import { OrganizationEnrollmentSettingsContainer } from './OrganizatttionEnrollments/OrganizationEnrollmentSettingsContainer';
import OrganizationAdminsContainer from './OrganizationAdmins/OrganizationAdminsContainer';

export const OrganizationSettingsContainer: React.FC = () => {
  const { orgId } = useParams<OrgIdParamType>();
  const {
    authState: { user }
  } = useAuth();

  const hasSchoolAdminAccess = !!user?.access.find(
    (accessItem) => accessItem.organization === orgId && accessItem.role === Roles.SchoolAdmin
  );
  const tabsList = [];
  const schoolAccess = hasFrontendAccessWithRoleForOrganization(orgId, [Roles.SchoolAdmin], user.adminAccesses);
  const centerAccess = hasFrontendAccessWithRoleForOrganization(orgId, [Roles.CenterAdmin], user.adminAccesses);
  const independentTeacherAccess = hasFrontendAccessWithRoleForOrganization(
    orgId,
    [Roles.IndependentTeacher],
    user.adminAccesses
  );
  if (schoolAccess || centerAccess || independentTeacherAccess) {
    tabsList.push({ label: 'Organization', link: 'organization', disabled: false });
    tabsList.push({ label: 'Addresses', link: 'addresses', disabled: false });
    tabsList.push({ label: 'Locations', link: 'locations', disabled: false });
    tabsList.push({ label: 'Agreements', link: 'agreements', disabled: false });
    tabsList.push({ label: 'Enrollment', link: 'enrollment', disabled: false });
    if (hasSchoolAdminAccess) {
      tabsList.push({ label: 'School Admins', link: 'school-admins', disabled: false });
    }
    if (schoolAccess || centerAccess) {
      tabsList.push({ label: 'Center Admins', link: 'center-admins', disabled: false });
    }
    if (hasSchoolAdminAccess || independentTeacherAccess) {
      tabsList.push({ label: 'Payments', link: 'payments', disabled: false });
    }
    if (schoolAccess || independentTeacherAccess) {
      tabsList.push({ label: 'Emails', link: 'emails', disabled: false });
    }
  }

  const centerAdminsTable = (
    <OrganizationAdminsContainer
      authenticatedUserId={user._id}
      orgId={orgId}
      hasSchoolAdminAccess={hasSchoolAdminAccess}
      isCenterAdminsPage={true}
    />
  );

  return (
    <div>
      <PageTitle title="Settings" />
      <PageNavigationTabs tabs={tabsList} />

      <Routes>
        <Route path="organization" element={<OrganizationSettingsFormContainer />} />
        <Route path="addresses" element={<OrganizationAddressesContainer orgId={orgId} />} />
        <Route path="location" element={<LocationFormContainer mode={FormMode.Add} />} />
        <Route path="locations" element={<LocationsContainer />} />
        <Route path="location/:locationId" element={<LocationFormContainer mode={FormMode.Edit} />} />
        <Route path="agreements" element={<CustomerAgreementsContainer />} />
        <Route path="enrollment" element={<OrganizationEnrollmentSettingsContainer orgId={orgId} />} />
        <Route
          path="school-admins"
          element={
            <OrganizationAdminsContainer
              authenticatedUserId={user._id}
              orgId={orgId}
              hasSchoolAdminAccess={hasSchoolAdminAccess}
              isCenterAdminsPage={false}
            />
          }
        />
        <Route path="center-admins" element={centerAdminsTable} />
        <Route path="payments" element={<OrganizationPaymentsContainer />} />
        <Route path="emails" element={<EmailSettingsContainer />} />
        <Route index element={<Navigate to="organization" />} />
      </Routes>
    </div>
  );
};
