import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { PATHS, toPath } from '@efacity/routing';
import {
  generateShortAddress,
  getSessionLocationLabelByValue,
  LocationFrontend,
  LocationType,
  nowUTC
} from '@efacity/common';
import { IconButton, Link } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { LocationTypeFilter, TextColumnFilter } from '@efacity/table';
import { LocationMarkerIcon, ZoomIcon } from '@efacity/frontend-shared';
import { smallPublishButtonStyle } from '../../components/Buttons/SessionPublishButton';
import { isBefore } from 'date-fns';
import DeleteIcon from '@mui/icons-material/DeleteForever';

const columnHelper = createColumnHelper<LocationFrontend>();

const useLocationsTableColumns = (orgId: string, handleDeleteLocation: (locationId: string) => void) => {
  return useMemo(() => {
    const columns = [
      columnHelper.accessor('name', {
        header: 'Name',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ row, getValue }) => {
          return (
            <Link
              component={NavLink}
              to={toPath(PATHS.editLocation, { orgId: orgId, locationId: row.original._id })}
              underline={'none'}
            >
              {getValue()}
            </Link>
          );
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      }),
      columnHelper.accessor('type', {
        header: 'Type',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ getValue }) => <>{getSessionLocationLabelByValue(getValue())}</>,
        meta: {
          filter: (column) => <LocationTypeFilter column={column} />,
          headerSx: { whiteSpace: 'nowrap', width: '120px', maxWidth: '120px' },
          columnSx: { whiteSpace: 'nowrap', width: '120px', maxWidth: '120px' }
        }
      }),
      {
        header: 'Capacity',
        id: 'capacity',
        accessorFn: (row) => (row?.capacity || 0).toString(),
        enableColumnFilter: true,
        filterFn: (row, _, filterValue) => {
          return (row.original?.capacity || 0).toString().includes(filterValue.toString());
        },
        enableSorting: true,
        cell: ({ getValue }) => {
          return getValue();
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />,
          headerSx: { whiteSpace: 'nowrap', width: '120px', maxWidth: '120px' },
          columnSx: { whiteSpace: 'nowrap', width: '120px', maxWidth: '120px' }
        }
      },
      {
        header: 'Address',
        id: 'address',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => <div>{row.original?.address ? generateShortAddress(row.original.address) : 'Virtual'}</div>
      },
      {
        header: 'Link',
        id: 'link',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => {
          const link =
            row.original.type === LocationType.InPerson || row.original.type === LocationType.PublicSchool
              ? row.original?.address?.mapLink
              : row.original?.virtualMeeting?.link;

          if (!link) return <div></div>;

          return (
            <a href={link} target="_blank" rel="noreferrer">
              {row.original.type === LocationType.InPerson || row.original.type === LocationType.PublicSchool ? (
                <LocationMarkerIcon />
              ) : (
                <ZoomIcon />
              )}
            </a>
          );
        }
      },
      {
        header: '',
        id: 'actions-cell',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => {
          return (
            <IconButton
              color="primary"
              aria-label="delete location"
              data-testid="delete-location-btn"
              component="span"
              style={smallPublishButtonStyle}
              disabled={isBefore(new Date(row.original.expiresOn), new Date(nowUTC()))}
              onClick={() => handleDeleteLocation(row.original._id)}
            >
              <DeleteIcon />
            </IconButton>
          );
        },
        meta: {
          headerSx: { width: 75, maxWidth: 75 },
          columnSx: { width: 75, maxWidth: 75 }
        }
      }
    ];

    return columns;
  }, [orgId, handleDeleteLocation]);
};

export default useLocationsTableColumns;
