import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { WithLoading, apiService, useFetch } from '@efacity/frontend-shared';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { ConnectModel, ConnectStatus, getMessageAndActionByConnectStatus, Messages } from '@efacity/common';
import { PATHS, toPath } from '@efacity/routing';
import { Button, Typography } from '@mui/material';
import queryString from 'query-string';

export const OrganizationPaymentsContainer: React.FC = () => {
  const { orgId } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { confirm } = queryString.parse(search);
  const [confirmingExpressStatus, setConfirmingExpressStatus] = useState<boolean>(false);
  const [redirectingToStripe, setRedirectingToStripe] = useState<boolean>(false);

  const confirmExpressConnect = () => {
    setConfirmingExpressStatus(true);

    return apiService
      .patch(`/paymentPlatforms/org/${orgId}/status/connected`, {})
      .then(({ data }) => {
        showNotification(true, Messages.BankAccountConnected);
        navigate(`${toPath(PATHS.organizationSettings, { orgId: orgId })}/payments`);
        recheckPaymentPlatformStatus();
      })
      .catch((error) => {
        showNotification(false, Messages.FailedConnectBankAccount);
      })
      .finally(() => setConfirmingExpressStatus(false));
  };

  const recheckPaymentPlatformStatus = () => {
    fetchPaymentPlatformStatus(`/paymentPlatforms/org/${orgId}/status`);
  };

  const [{ data: paymentPlatformStatus, isLoading: isStatusLoading }, { fetchData: fetchPaymentPlatformStatus }] =
    useFetch<any>(`/paymentPlatforms/org/${orgId}/status`, { shouldFetch: false });

  useEffect(() => {
    if (confirm === ConnectStatus.ConnectedExpress) {
      confirmExpressConnect();
    } else {
      fetchPaymentPlatformStatus(`/paymentPlatforms/org/${orgId}/status`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStartConnect = async () => {
    setRedirectingToStripe(true);

    return apiService
      .get<any>(`/paymentPlatforms/org/${orgId}/stripe-express-redirect-url`)
      .then(({ data }) => {
        window.location.href = data.url;
      })
      .catch((error) => {
        setRedirectingToStripe(false);
        showNotification(false, error.response?.data?.message || Messages.FailedConnectBankAccount);
      });
  };

  const { message, buttonMessage, bottomMessage } = getMessageAndActionByConnectStatus(paymentPlatformStatus);

  return (
    <div>
      {confirmingExpressStatus ? (
        <WithLoading isLoading={confirmingExpressStatus} message={'Confirming Bank Account connected...'} />
      ) : redirectingToStripe ? (
        <WithLoading isLoading={redirectingToStripe} message={'Redirecting to Stripe...'} />
      ) : (
        <WithLoading isLoading={isStatusLoading} message={'Loading Bank Account status...'}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              textAlign: 'center',
              marginTop: '15px'
            }}
          >
            {paymentPlatformStatus?.connectModel === ConnectModel.ConnectExpress ? (
              <>
                <div>
                  <Typography style={{ fontSize: 18, fontWeight: 400 }}>{message}</Typography>
                </div>
                <div style={{ marginTop: 30 }}>
                  {buttonMessage && (
                    <div>
                      <Button
                        variant="contained"
                        onClick={
                          paymentPlatformStatus?.connectStatus === ConnectStatus.ConnectingExpressPending
                            ? recheckPaymentPlatformStatus
                            : handleStartConnect
                        }
                        style={{ width: 270, marginBottom: 15 }}
                        disabled={isStatusLoading}
                      >
                        {buttonMessage}
                      </Button>
                      {bottomMessage?.length > 0 && (
                        <Typography style={{ fontSize: 16, fontWeight: 400 }}>{bottomMessage}</Typography>
                      )}
                    </div>
                  )}
                </div>
              </>
            ) : (
              <Typography style={{ fontSize: 18, fontWeight: 400 }}>
                Allowed only for Stripe Express Connect model
              </Typography>
            )}
          </div>
        </WithLoading>
      )}
    </div>
  );
};
