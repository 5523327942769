import React, { useMemo } from 'react';
import {
  ConnectModel,
  connectModelViewConnector,
  ConnectStatus,
  OrganizationStatus,
  organizationStatusViewConnector,
  OrganizationTransfer,
  paymentPlatformTypeViews
} from '@efacity/common';
import { PATHS, toPath } from '@efacity/routing';
import { Box, IconButton, Link } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { ConnectedAccount, Organization, CircularProgressButton } from '@efacity/frontend-shared';
import { groupBy } from 'lodash';
import { smallPublishButtonStyle } from '../../components/Buttons/SessionPublishButton';
import EditIcon from '@mui/icons-material/Edit';
import { PhoneNumber } from '@efacity/react-next-sc';
import { createColumnHelper } from '@tanstack/react-table';
import { TextColumnFilter } from '@efacity/table';

const hiddenSmStyles = {
  display: {
    xs: 'none',
    sm: 'table-cell'
  }
};

const isActiveTransfer = (transferStripeUserId: string, stripePlatformAccounts: ConnectedAccount[]) => {
  return !!stripePlatformAccounts.find((account) => account.stripeUserId === transferStripeUserId);
};

const columnHelper = createColumnHelper<Organization>();

export const useOrganizationColumns = (
  handleStartAccountModal: (accountId: string, schoolId: string, schoolName: string) => void,
  startOrganizationTransferModal: (school: Organization) => void,
  onConnectCLick: (schoolId: string) => void,
  onDisconnectCLick: (schoolId: string) => void,
  connectButtonDisabled: boolean
) => {
  return useMemo(() => {
    const columns = [
      columnHelper.accessor('name', {
        header: 'Name',
        //accessor: 'name',
        cell: ({ row }) => (
          <Link component={NavLink} to={toPath(PATHS.editSchool, { id: row.original._id })} underline={'none'}>
            {row.original.name}
          </Link>
        ),
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      }),
      columnHelper.accessor('status', {
        header: 'Status',
        cell: ({ row }) => {
          return (
            <div>
              {row.original?.status
                ? organizationStatusViewConnector[row.original?.status]
                : organizationStatusViewConnector[OrganizationStatus.Active]}
            </div>
          );
        },
        meta: {
          headerSx: hiddenSmStyles,
          columnSx: hiddenSmStyles
        }
      }),
      {
        header: 'Admins',
        id: 'administrators',
        accessor: 'administrators',
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => (
          <div>
            {row.original?.administrators.map((admin, index) => (
              <div key={admin._id}>
                {admin.fullName}
                {index + 1 < row.original.administrators.length ? ',' : ''}
              </div>
            ))}
          </div>
        ),
        meta: {
          headerSx: hiddenSmStyles,
          columnSx: hiddenSmStyles
        }
      },
      columnHelper.accessor('phone', {
        header: 'Phone',
        id: 'phone',
        filterFn: (row, id, filterValue) => {
          return row.original?.phone ? row.original.phone.toString().includes(filterValue) : false;
        },
        cell: ({ row }) => <PhoneNumber phone={row.original.phone} />,
        meta: {
          filter: (column) => <TextColumnFilter column={column} />,
          headerSx: hiddenSmStyles,
          columnSx: hiddenSmStyles
        }
      }),
      {
        header: 'Payment Platform',
        id: 'paymentAccountId',
        accessor: 'paymentAccountId',
        cell: ({ row }) => (
          <Link
            component={NavLink}
            to="#"
            onClick={() =>
              handleStartAccountModal(row.original?.paymentAccountId?._id, row.original._id, row.original.name)
            }
            underline={'none'}
          >
            {row.original?.paymentAccountId
              ? `${paymentPlatformTypeViews[row.original.paymentAccountId.paymentPlatformType]}: ${
                  row.original.paymentAccountId.name
                }`
              : 'Add Account'}
          </Link>
        ),
        meta: {
          headerSx: hiddenSmStyles,
          columnSx: hiddenSmStyles
        }
      },
      {
        header: 'Transfers',
        id: 'organizationTransfers',
        accessor: 'organizationTransfers',
        cell: ({ row }) => (
          <div>
            {row.original?.organizationTransfers?.length > 0
              ? Object.values(groupBy(row.original.organizationTransfers, 'paymentPlatformAccountId')).map(
                  (group, index) => {
                    return (
                      <div key={`organizationTransferCell${index}`}>
                        {index > 0 && <hr style={{ height: 2, marginBottom: 5, marginTop: 5 }} />}
                        {group.map((transfer: OrganizationTransfer) => {
                          return (
                            <div key={`organizationTransferCell${transfer._id}`}>
                              {`${transfer.name}, ${transfer.transferPercentage}%`}
                              {row.original?.connectStatus === ConnectStatus.ConnectedExpress ||
                              isActiveTransfer(
                                transfer.stripeUserId,
                                row.original?.paymentAccountId?.stripePlatformAccount?.connectedAccounts || []
                              ) ? (
                                <div style={{ color: '#2F75C1', display: 'inline' }}> (active)</div>
                              ) : (
                                <div style={{ color: 'red', display: 'inline' }}> (inactive)</div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    );
                  }
                )
              : ''}
            {row.original?.paymentAccountId?.stripePlatformAccount?.connectedAccounts?.length > 0 && (
              <Box display="flex" justifyContent="center" style={{ marginTop: 2, marginBottom: 2 }}>
                <IconButton
                  color="primary"
                  component="span"
                  style={smallPublishButtonStyle}
                  onClick={() => startOrganizationTransferModal(row.original)}
                >
                  <EditIcon />
                </IconButton>
              </Box>
            )}
          </div>
        ),
        meta: {
          headerSx: hiddenSmStyles,
          columnSx: hiddenSmStyles
        }
      },
      {
        header: 'Connect Model',
        id: 'connectModel',
        accessor: 'connectModel',
        cell: ({ row }) => (
          <div>{row.original?.connectModel ? connectModelViewConnector[row.original?.connectModel] : '[n/a]'}</div>
        ),
        meta: {
          headerSx: hiddenSmStyles,
          columnSx: hiddenSmStyles
        }
      },
      columnHelper.accessor('connectStatus', {
        header: 'Efacity Standard Connect',
        id: 'connectStatus',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => (
          <div style={{ textAlign: 'center' }}>
            {!row.original.parentOrgId && row.original?.connectModel === ConnectModel.ConnectStandard && (
              <CircularProgressButton
                progress={false}
                color="primary"
                onClick={() => {
                  row.original.connectStatus === ConnectStatus.ConnectedStandard
                    ? onDisconnectCLick(row.original._id)
                    : onConnectCLick(row.original._id);
                }}
                style={{ ...smallPublishButtonStyle, width: 100 }}
                data-testid={`connectButtonForEfacity${row.original._id}`}
                disabled={connectButtonDisabled}
              >
                {row.original.connectStatus === ConnectStatus.ConnectedStandard ? 'Disconnect' : 'Connect'}
              </CircularProgressButton>
            )}
          </div>
        ),
        meta: {
          headerSx: hiddenSmStyles,
          columnSx: hiddenSmStyles
        }
      })
    ];
    return columns;
  }, [
    handleStartAccountModal,
    startOrganizationTransferModal,
    onConnectCLick,
    onDisconnectCLick,
    connectButtonDisabled
  ]);
};
