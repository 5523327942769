import React, { useMemo } from 'react';
import { Box, IconButton, Link } from '@mui/material';
import {
  convertUtcToZonedTime,
  formatDateToMonthDayYearInLocale,
  jobLocationTypeViewConnector,
  JobPostingFrontend,
  jobTypesViewConnector,
  nowUTC
} from '@efacity/common';
import { createColumnHelper } from '@tanstack/react-table';
import { NavLink } from 'react-router-dom';
import { PATHS, toPath } from '@efacity/routing';
import JobPostingLinkWithCopier from '../../components/JobPostingLinkWithCopier/JobPostingLinkWithCopier';
import { DateColumnFilter, TextColumnFilter } from '@efacity/table';
import { smallPublishButtonStyle } from '../../components/Buttons/SessionPublishButton';
import { isBefore } from 'date-fns';
import DeleteIcon from '@mui/icons-material/DeleteForever';

const columnHelper = createColumnHelper<JobPostingFrontend>();

export const useJobPostingColumns = (orgId: string, handleDeleteJob: (jobId: string) => void) => {
  return useMemo(() => {
    const columns = [
      columnHelper.accessor('title', {
        header: 'Title',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ row, getValue }) => {
          return (
            <Box display="flex" alignItems="center" data-testid="job-posting-name-copier-cell">
              <Link
                component={NavLink}
                to={toPath(PATHS.editJob, {
                  orgId: orgId,
                  jobId: row.original._id
                })}
                underline={'none'}
              >
                {getValue()}
              </Link>
              &nbsp;&nbsp;
              <JobPostingLinkWithCopier orgId={orgId} jobPostingId={row.original._id} />
            </Box>
          );
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      }),
      columnHelper.accessor('locationType', {
        header: 'Location Type',
        enableSorting: true,
        enableColumnFilter: false,
        cell: ({ getValue }) => jobLocationTypeViewConnector[getValue()],
        meta: {
          headerSx: { width: '130px', maxWidth: '130px' },
          columnSx: { width: '130px', maxWidth: '130px' }
        }
      }),
      columnHelper.accessor('jobType', {
        header: 'Type',
        enableSorting: true,
        enableColumnFilter: false,
        cell: ({ getValue }) => jobTypesViewConnector[getValue()],
        meta: {
          headerSx: { width: '90px', maxWidth: '90px' },
          columnSx: { width: '90px', maxWidth: '90px' }
        }
      }),
      columnHelper.accessor('expiresOn', {
        header: 'Expires On',
        enableColumnFilter: true,
        enableSorting: true,
        cell: ({ getValue, row }) => (
          <>{formatDateToMonthDayYearInLocale(convertUtcToZonedTime(new Date(getValue()), row.original.timeZone))}</>
        ),
        meta: {
          filter: (column) => <DateColumnFilter column={column} />,
          headerSx: { width: '140px', maxWidth: '140px' },
          columnSx: { width: '140px', maxWidth: '140px' }
        }
      }),
      {
        header: '',
        id: 'actions-cell',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => {
          return (
            <IconButton
              color="primary"
              aria-label="delete product"
              data-testid="delete-product-btn"
              component="span"
              style={smallPublishButtonStyle}
              disabled={isBefore(new Date(row.original.expiresOn), new Date(nowUTC()))}
              onClick={() => handleDeleteJob(row.original._id)}
            >
              <DeleteIcon />
            </IconButton>
          );
        },
        meta: {
          headerSx: { width: 75, maxWidth: 75 },
          columnSx: { width: 75, maxWidth: 75 }
        }
      }
    ];

    return columns;
  }, [orgId, handleDeleteJob]);
};
