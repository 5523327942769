import React, { useMemo } from 'react';
import {
  convertUtcToZonedTime,
  getInvoiceFileName,
  getPaymentReceiptFileName,
  InvoiceBalance,
  moneyWithCurrencyCodeFormatter
} from '@efacity/common';
import DateCell from '../../components/DateCell';
import { adminSectionTableFontSize } from '@efacity/frontend-shared';
import { IconButton, Link, Tooltip, useTheme } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { createColumnHelper } from '@tanstack/react-table';
import { TextColumnFilter, DateColumnFilter } from '@efacity/table';

export const usePaymentsColumns = (orgId: string, timeZone: string) => {
  const theme = useTheme();

  const paymentStatusColor = (isPaid: boolean) => {
    return isPaid ? theme.palette.primary.main : theme.palette.error.main;
  };
  const payoutColor = (amount: number) => {
    return amount < 0 ? theme.palette.secondary.dark : '';
  };

  const columnHelper = createColumnHelper<InvoiceBalance>();

  return useMemo(() => {
    const columns = [
      columnHelper.accessor('invoiceNo', {
        header: 'Invoice #',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ getValue }) => <div>{getValue()}</div>,
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      }),
      columnHelper.accessor('customer.fullName', {
        header: 'Customer',
        id: 'customer.fullName',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ getValue }) => <div>{getValue()}</div>,
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      }),
      {
        header: 'Date',
        id: 'invoiceDate',
        accessor: 'invoiceDate',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ row }) => (
          <div>
            {row.original.invoiceDate ? (
              <DateCell
                dateTime={convertUtcToZonedTime(row.original.invoiceDate, timeZone)}
                yearOnNewLine={false}
                wrap={true}
                style={{ ...adminSectionTableFontSize }}
              />
            ) : (
              '[n/a]'
            )}
          </div>
        ),
        meta: {
          filter: (column) => <DateColumnFilter column={column} />
        }
      },
      {
        header: 'Balance',
        id: 'balance-cell',
        accessor: 'balance',
        cell: ({ row }) => {
          return (
            <div style={{ color: `${paymentStatusColor(row.original.isPaid)}` }}>
              {moneyWithCurrencyCodeFormatter(row.original?.balance || 0, row.original?.currencyCode)}
              <span onClick={() => row.toggleExpanded()}>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  data-testid="collapseButton"
                  style={{ marginTop: -10 }}
                >
                  {row.getIsExpanded() ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </span>
            </div>
          );
        },
        meta: {
          headerSx: { textAlign: 'right', paddingRight: '15px' },
          columnSx: { textAlign: 'right', width: '115px', maxWidth: '115px' }
        }
      },
      {
        header: 'Payout',
        id: 'payoutsAmount',
        accessor: 'payoutsAmount',
        cell: ({ row }) => {
          return (
            <div style={{ color: payoutColor(-row?.original?.ownerPayoutsAmount || 0) }}>
              {moneyWithCurrencyCodeFormatter(row?.original?.ownerPayoutsAmount || 0, row.original?.currencyCode)}
              <span onClick={() => row.toggleExpanded()}>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  data-testid="collapseButton"
                  style={{ marginTop: -10 }}
                >
                  {row.getIsExpanded() ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </span>
            </div>
          );
        },
        meta: {
          headerSx: { textAlign: 'right', paddingRight: '30px' },
          columnSx: { textAlign: 'right', width: '115px', maxWidth: '115px' }
        }
      },
      {
        header: 'Invoice',
        id: 'invoice-column',
        cell: ({ row }) => {
          return (
            <div>
              {row.original ? (
                row.original?.isPaid ? (
                  <Tooltip title="Download Payment Receipt">
                    <Link href={getPaymentReceiptFileName(row.original?._id)}>Receipt</Link>
                  </Tooltip>
                ) : (
                  <Tooltip title="Download Invoice">
                    <Link href={getInvoiceFileName(row.original?._id)}>Invoice</Link>
                  </Tooltip>
                )
              ) : (
                ''
              )}
            </div>
          );
        },
        meta: {
          headerSx: { width: '65px', maxWidth: '65px' },
          columnSx: { width: '65px', maxWidth: '65px' }
        }
      }
    ];

    return columns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, timeZone]);
};
