import { Roles } from '@efacity/common';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { addServerErrors } from '@efacity/react-hook-form-mui';
import { SignUpFormValues, getShoppingProgress, useAuth } from '@efacity/react-next-sc';
import { PATHS } from '@efacity/routing';
import { FC } from 'react';
import { ErrorOption, FieldPath } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { SignUpForm, ButtonWithIcon } from '@efacity/frontend-shared';

interface SignUpPageProps {
  signInUrl: string;
}

export const SignUpPage: FC<SignUpPageProps> = ({ signInUrl }) => {
  const navigate = useNavigate();

  const { signUp } = useAuth();
  document.title = 'Sign Up | Efacity';

  const onSignUp = async (
    credentialsWithRecaptchaToken: SignUpFormValues,
    setError: (name: FieldPath<SignUpFormValues>, error: ErrorOption, options?: { shouldFocus: boolean }) => void
  ) => {
    /*
      When customer is coming from registrations list page
      we remember his role and not redirect to roles screen
    */
    const { sessionRegistrationInProgress, role, kitPurchaseInProgress } = getShoppingProgress();

    const credentialsToSend = role
      ? { ...credentialsWithRecaptchaToken, access: [{ role: role as Roles }] }
      : credentialsWithRecaptchaToken;
    const { signUpSuccess, error } = await signUp(credentialsToSend);

    if (error && error?.response?.data?.message) {
      if (error.response.status === 303) {
        return navigate(`${PATHS.checkEmail}/?messageId=1`);
      }

      showNotification(true, error.response.data?.message, true, 'Ok');

      if (error.response.status === 409) {
        if (error.response.data?.googleSigninUrl) {
          window.location.href = error.response.data.googleSigninUrl;
        } else {
          navigate(`${PATHS.auth}/${PATHS.signIn}?email=${credentialsWithRecaptchaToken.email}`);
        }
      }

      return;
    }

    if (error && error?.response?.data?.validationErrors) {
      return addServerErrors<SignUpFormValues>(error.response.data.validationErrors, setError);
    }

    if (signUpSuccess) {
      showNotification(true, 'You have successfully signed up');
    }
    // TODO: Restore it when select role screen is restored
    /*if (!role) {
      return navigate( PATHS.selectRole);
    }*/

    if (sessionRegistrationInProgress) {
      return navigate(PATHS.selectStudentsForSessions);
    }

    if (kitPurchaseInProgress) {
      return navigate(PATHS.cart);
    }

    navigate(`${PATHS.checkEmail}/?messageId=1`);
    // TODO: restore this when not only customer can sign up
    // navigate( getLoggedInUserHomePagePathIfAny(user.access));
  };

  return (
    <>
      <br />
      <ButtonWithIcon
        href={signInUrl}
        icon={<img src="/assets/images/GoogleLogo_30_30.svg" alt="Google logo icon" />}
        fullWidth
      >
        Sign up with Google
      </ButtonWithIcon>
      <hr style={{ width: '100%', marginTop: 20 }} />
      <div style={{ textAlign: 'center' }}>Or</div>
      <SignUpForm onSignUp={onSignUp} />
    </>
  );
};
