import React, { useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IdOrgIdParamTypes } from '@efacity/frontend-next-shared/utils';
import { DateColumnFilter, nonPaginatedDataFetcher, Table, TableRef, TextColumnFilter } from '@efacity/table';
import { Certificate } from '@efacity/frontend-shared';
import { Box, Button } from '@mui/material';
import AddCircleOutlined from '@mui/icons-material/AddCircleOutlined';
import CertificateInstructorModal from './CertificateInstructorModal';
import { certificateTypesMapper, formatStringToMonthDayYearDate } from '@efacity/common';
import EmailAddress from '../../../components/EmailAddress/EmailAddress';
import { createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper<Certificate>();

export interface ActivityCertificatesTableProps {
  activityName: string;
}

const ActivityCertificatesTable: React.FC<ActivityCertificatesTableProps> = ({ activityName }) => {
  const { orgId, id } = useParams<IdOrgIdParamTypes>();
  const tableRef = useRef<TableRef<Certificate>>(null);
  const [certificateInstructorModalShown, setCertificateInstructorModalShown] = useState(false);

  const closeCertificationModal = (reload: boolean) => {
    setCertificateInstructorModalShown(false);
    if (reload) tableRef.current?.refetchTableData();
  };

  const columns = useMemo(() => {
    const columns = [
      columnHelper.accessor('user.fullName', {
        header: 'Instructor Name',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ getValue }) => {
          return getValue() || '[n/a]';
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      }),
      columnHelper.accessor('user.email', {
        header: 'Email',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ getValue }) => {
          return getValue() ? <EmailAddress email={getValue()} /> : '[n/a]';
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      }),
      columnHelper.accessor('type', {
        header: 'Certificate Type',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ getValue }) => {
          return certificateTypesMapper[getValue()];
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      }),
      columnHelper.accessor('expiresOn', {
        header: 'Expires On',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ getValue }) => <>{formatStringToMonthDayYearDate(getValue())}</>,
        meta: {
          filter: (column) => <DateColumnFilter column={column} />
        }
      })
    ];

    return columns;
  }, []);

  const onsStartAddCertificateModal = () => {
    setCertificateInstructorModalShown(true);
  };

  return (
    <div>
      <Box display="flex" justifyContent="end" marginY={2}>
        <Button
          onClick={onsStartAddCertificateModal}
          color="primary"
          variant="contained"
          data-testid="addBtn"
          startIcon={<AddCircleOutlined style={{ color: 'white', marginRight: 15 }} />}
        >
          New Certificate
        </Button>
      </Box>
      <Table<Certificate>
        tableRef={tableRef}
        dataFetcherUrl={`/org/${orgId}/activities/${id}/certificates`}
        columnDefs={columns}
        dataFetcher={nonPaginatedDataFetcher<Certificate>}
        manualSorting={false}
        manualFiltering={false}
      />
      {certificateInstructorModalShown && (
        <CertificateInstructorModal
          open={certificateInstructorModalShown}
          orgId={orgId}
          activityId={id}
          activityName={activityName}
          closeCertificationModal={closeCertificationModal}
        />
      )}
    </div>
  );
};

export default ActivityCertificatesTable;
