import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { StudentWithCustomers } from '@efacity/common';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import EditStudentWithCustomerInfoModal, {
  StudentWithCustomerInfoTabs
} from '../../components/EditStudentWithCustomerInfoModal/EditStudentWithCustomerInfoModal';
import NotesModal from '../../components/NotesModal/NotesModal';
import { PageTitle } from '@efacity/react-next-sc';
import { Table, TableRef, usePaginatedLoaderWithAdditionalInfo } from '@efacity/table';
import useStudentsTableColumns from './useStudentsTableColumns';

const emptyStudentUnderEdit = {
  studentId: '',
  studentName: '',
  tab: StudentWithCustomerInfoTabs.MAIN
};

const emptyStudentNoteModalState = { orgId: '', studentId: '', name: '' };

const OrganizationStudentsTable: React.FC = () => {
  const { orgId } = useParams<OrgIdParamType>();
  const tableRef = useRef<TableRef<StudentWithCustomers>>(null);
  const dataUrl = `/org/${orgId}/users/students`;
  const { paginatedDataFetcher } = usePaginatedLoaderWithAdditionalInfo<StudentWithCustomers>({
    useAdditionalInfo: false
  });

  const [notesModalState, setNotesModalState] = useState(emptyStudentNoteModalState);
  const onOpenNotesModal = (studentId: string, name: string) => {
    setNotesModalState({ orgId: orgId, studentId: studentId, name: name });
  };
  const onCloseNotesModal = () => {
    setNotesModalState(emptyStudentNoteModalState);
  };

  const [studentUnderEdit, setStudentUnderEdit] = useState(emptyStudentUnderEdit);
  const handleEditStudentInfo = ({ studentId, studentName }: { studentId: string; studentName: string }) => {
    setStudentUnderEdit({
      studentId: studentId,
      studentName: studentName,
      tab: StudentWithCustomerInfoTabs.MAIN
    });
  };
  const handleStudentModalClose = async (reloadData: boolean) => {
    setStudentUnderEdit(emptyStudentUnderEdit);
    if (reloadData) {
      tableRef.current?.refetchTableData();
    }
  };

  const columns = useStudentsTableColumns(handleEditStudentInfo, onOpenNotesModal);

  return (
    <div>
      <PageTitle title="Students" addButtonLink={undefined} />
      <Table<StudentWithCustomers>
        tableRef={tableRef}
        dataFetcherUrl={dataUrl}
        columnDefs={columns}
        dataFetcher={paginatedDataFetcher}
        noDataText="No Students..."
        initialSortBy={[{ id: 'fullName', desc: false }]}
      />
      {studentUnderEdit.studentId && (
        <EditStudentWithCustomerInfoModal
          open={!!studentUnderEdit.studentId}
          orgId={orgId}
          studentId={studentUnderEdit.studentId}
          studentName={studentUnderEdit.studentName}
          onClose={handleStudentModalClose}
          tab={studentUnderEdit.tab}
          setTab={(newTab: StudentWithCustomerInfoTabs) => setStudentUnderEdit((state) => ({ ...state, tab: newTab }))}
        />
      )}
      {notesModalState.studentId && (
        <NotesModal
          orgId={notesModalState.orgId}
          customerId={notesModalState.studentId}
          name={notesModalState.name}
          url={`/org/${orgId}/users/students/${notesModalState.studentId}/notes`}
          onClose={onCloseNotesModal}
        />
      )}
    </div>
  );
};

export default OrganizationStudentsTable;
