import { UserWithId } from '@efacity/common';
import { PageTitle, PhoneNumber, useAuth } from '@efacity/react-next-sc';
import { PATHS, toPath } from '@efacity/routing';
import { Link } from '@mui/material';
import React, { useMemo, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import EmailAddress from '../../components/EmailAddress/EmailAddress';
import { createColumnHelper } from '@tanstack/react-table';
import { Table as TableNew, TextColumnFilter, usePaginatedLoaderWithAdditionalInfo, TableRef } from '@efacity/table';

const columnHelper = createColumnHelper<UserWithId>();

const CustomerStudentsContainer: React.FC = () => {
  const {
    authState: {
      user: { _id: customerId }
    }
  } = useAuth();
  const tableRef = useRef<TableRef<UserWithId>>(null);
  const dataFetcherUrl = `/customers/${customerId}/students`;
  const { paginatedDataFetcher } = usePaginatedLoaderWithAdditionalInfo<UserWithId>({ usePagination: false });

  const tableColumns = useMemo(() => {
    const childrenTableColumns = [
      columnHelper.accessor('fullName', {
        header: 'Full Name',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ row, getValue }) => {
          return (
            <Link component={NavLink} to={toPath(PATHS.editStudent, { studentId: row.original._id })}>
              {getValue()}
            </Link>
          );
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      }),
      columnHelper.accessor('email', {
        header: 'Email',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ getValue }) => {
          return getValue() ? <EmailAddress email={getValue()} /> : <>-</>;
        }
      }),
      columnHelper.accessor('defaultPhone', {
        header: 'Phone',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ getValue }) => {
          return <PhoneNumber phone={getValue()} />;
        }
      })
    ];

    return childrenTableColumns;
  }, []);

  return (
    <div>
      <PageTitle title="Students" addButtonLink={undefined} />
      <TableNew<UserWithId>
        tableRef={tableRef}
        dataFetcherUrl={dataFetcherUrl}
        columnDefs={tableColumns}
        dataFetcher={paginatedDataFetcher}
        noDataText="No Students..."
        manualFiltering={false}
        manualSorting={false}
      />
    </div>
  );
};

export default CustomerStudentsContainer;
