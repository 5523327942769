'use client';
import { CountryCode, getFieldIsRequiredMessage, getStateOptionsDependingOnCountry, Messages } from '@efacity/common';
import * as yup from 'yup';
import { passwordValidator } from '@efacity/frontend-shared';
import { requiredPhoneValidator } from '@efacity/frontend-shared';

export const userProfileFields = ['email', 'fullName', 'defaultPhone', 'defaultPhoneCountry', 'address'];
export const userSettingsFields = [...userProfileFields, 'password']; //'dateOfBirth'

export const userSettingsFormValidationSchema = yup.object({
  email: yup.string().email(Messages.InvalidEmail).required(Messages.EmailRequired),
  fullName: yup.string().required(Messages.NameRequired),
  defaultPhone: requiredPhoneValidator('defaultPhone'),
  defaultPhoneCountry: yup.string().required(),
  dateOfBirth: yup.object().shape({
    year: yup.string(),
    month: yup.string(),
    day: yup.string()
  }),
  address: yup.object().shape({
    country: yup.mixed().test('address', null, function (this: yup.TestContext, fieldValue: any): any {
      const allValuesEmpty = Object.values(this.parent).every((value) => !value);
      if (allValuesEmpty) {
        return true;
      }
      if (!fieldValue) {
        return this.createError({ path: 'address.country', message: Messages.CountryRequired });
      }
      return true;
    }),
    address: yup.mixed().test('address', null, function (this: yup.TestContext, fieldValue: any): any {
      const allValuesEmpty = Object.values(this.parent).every((value) => !value);
      if (allValuesEmpty) {
        return true;
      }
      if (!fieldValue) {
        return this.createError({ path: 'address.address', message: Messages.AddressRequired });
      }
      return true;
    }),
    city: yup.mixed().test('address', null, function (this: yup.TestContext, fieldValue: any): any {
      const allValuesEmpty = Object.values(this.parent).every((value) => !value);
      if (allValuesEmpty) {
        return true;
      }
      if (!fieldValue) {
        return this.createError({ path: 'address.city', message: Messages.CityRequired });
      }
      return true;
    }),
    zip: yup.mixed().test('address', null, function (this: yup.TestContext, fieldValue: any): any {
      const allValuesEmpty = Object.values(this.parent).every((value) => !value);
      if (allValuesEmpty) {
        return true;
      }
      if (!fieldValue) {
        return this.createError({ path: 'address.zip', message: getFieldIsRequiredMessage('Zip') });
      }
      return true;
    }),
    state: yup.mixed().test('address', function (this: yup.TestContext, fieldValue: any): any {
      const allValuesEmpty = Object.values(this.parent).every((value) => !value);
      if (allValuesEmpty) {
        return true;
      }
      if (this.parent.country === CountryCode.UK) {
        return true;
      }
      if (!fieldValue) {
        return this.createError({ path: 'address.state', message: Messages.StateRequired });
      }

      const stateOptions = getStateOptionsDependingOnCountry(this.parent.country) || [];
      const isStateValueInOptions =
        fieldValue === '' || stateOptions.some((stateOption) => stateOption.value === fieldValue);
      if (!isStateValueInOptions) {
        return this.createError({ path: this.path, message: Messages.StateInvalid });
      } else {
        return true;
      }
    })
  }),
  password: yup.mixed().test('address', function (this: yup.TestContext, password: any): any {
    if (!password) {
      return true;
    }
    if (password?.length === 0) {
      return true;
    }
    try {
      passwordValidator().validateSync(password);
      return true;
    } catch (error) {
      return this.createError({ path: this.path, message: error.message });
    }
  })
});
