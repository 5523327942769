import { apiService } from '@efacity/frontend-shared';
import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
  Typography
} from '@mui/material';
import { Messages, formatDateToMonthDayYearDate } from '@efacity/common';
import React, { useEffect, useState } from 'react';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { tableStyles } from '../../stylesheets/tableStyle';
import MustAgreeCheckedIcon from '../../pages/CustomerAgreements/MustAgreeCheckedIcon';
import MustAgreeUnCheckedIcon from '../../pages/CustomerAgreements/MustAgreeUnCheckedIcon';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CircularProgress from '@mui/material/CircularProgress';
import SaveIcon from '@mui/icons-material/Check';
import Button from '@mui/material/Button';

export const buttonStyle = {
  width: 200,
  height: 40,
  textTransform: 'uppercase',
  marginTop: 5,
  marginBottom: 5
} as React.CSSProperties;

interface AgreementInfo {
  _id: string;
  agreementName: string;
  isViewed?: boolean;
  isSigned: boolean;
  signedOn: string;
  customerMustSignAgreement: boolean;
}

export const StudentAgreementsTable = ({ orgId, studentId }) => {
  const [agreements, setAgreements] = useState([]);
  const [agreementsToSign, setAgreementsToSign] = useState([]);
  const [isSendingAgreementInvitation, setIsSendingAgreementInvitation] = useState(false);

  const theme = useTheme();

  const switchAgreementToSign = (agreementId: string) => {
    setAgreementsToSign((currentAgreementsToSign) => {
      const newAgreementsToSign = currentAgreementsToSign.includes(agreementId)
        ? currentAgreementsToSign.filter((id) => id !== agreementId)
        : [...currentAgreementsToSign, agreementId];
      return newAgreementsToSign;
    });
  };

  useEffect(() => {
    const getStudentWithCustomersById = async (orgId: string) => {
      try {
        const { data } = await apiService.get<any>(`/org/${orgId}/agreements/student/${studentId}`);
        setAgreements(data);
      } catch (error) {
        showNotification(false, error.message || Messages.CannotFetchAgreements, true);
      }
    };
    getStudentWithCustomersById(orgId);
  }, [orgId, studentId]);

  const sendAgreementInvitationToStudent = async () => {
    try {
      setIsSendingAgreementInvitation(true);
      await apiService.post<any>(`/org/${orgId}/agreements/student/${studentId}/invite`, agreementsToSign);
      setAgreementsToSign([]);
      showNotification(true, Messages.AgreementInvitationSent, false);
    } catch (error) {
      showNotification(false, error.message || Messages.FailedSendAgreementInvitation, true);
    } finally {
      setIsSendingAgreementInvitation(false);
    }
  };

  if (!agreements.length) return <div>No data...</div>;

  return (
    <TableContainer>
      <Table size="small" aria-label="agreements for student">
        <TableHead sx={tableStyles.tableHeaderCellSx}>
          <TableRow>
            <TableCell sx={{ ...tableStyles.tableHeaderCellSx, width: '70px' }}></TableCell>
            <TableCell sx={tableStyles.tableHeaderCellSx}>Agreement name</TableCell>
            <TableCell sx={{ ...tableStyles.tableHeaderCellSx, width: '110px' }}>Is Viewed</TableCell>
            <TableCell sx={{ ...tableStyles.tableHeaderCellSx, width: '70px' }}>Status</TableCell>
            <TableCell sx={{ ...tableStyles.tableHeaderCellSx, width: '110' }}>Signed on</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {agreements.map((agreement: AgreementInfo) => {
            return (
              <TableRow>
                <TableCell>
                  <Checkbox
                    checked={agreementsToSign.includes(agreement._id)}
                    onChange={() => switchAgreementToSign(agreement._id)}
                    color="primary"
                    disabled={agreement?.isSigned}
                  />
                </TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    {agreement.customerMustSignAgreement ? (
                      <MustAgreeCheckedIcon color="primary" />
                    ) : (
                      <MustAgreeUnCheckedIcon color="primary" />
                    )}
                    {agreement.agreementName}
                  </Box>
                </TableCell>
                <TableCell>
                  {agreement?.isViewed ? (
                    <CheckCircleOutlineIcon style={{ color: theme.palette.secondary.main }} />
                  ) : (
                    <Box sx={{ color: theme.palette.error.main }}>not viewed</Box>
                  )}
                </TableCell>
                <TableCell>
                  {agreement?.isViewed ? (
                    agreement.isSigned ? (
                      'agreed'
                    ) : (
                      <Box sx={{ color: theme.palette.error.main }}>declined</Box>
                    )
                  ) : (
                    '-'
                  )}
                </TableCell>
                <TableCell style={{ alignContent: 'center' }}>
                  {agreement?.isViewed ? formatDateToMonthDayYearDate(agreement.signedOn) : '-'}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20, marginBottom: 20 }}>
        <Typography>To invite customer to view and sign agreements select them and click the button below</Typography>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          style={buttonStyle}
          color="primary"
          variant="contained"
          data-testid="send-agreemen-invitation-button"
          disabled={agreementsToSign.length === 0 || isSendingAgreementInvitation}
          onClick={sendAgreementInvitationToStudent}
        >
          {isSendingAgreementInvitation ? (
            <CircularProgress
              variant="indeterminate"
              data-testid="loader"
              size={20}
              style={{ marginRight: 15, color: 'white' }}
            />
          ) : (
            <SaveIcon style={{ marginRight: 15 }} />
          )}
          Send Invitation
        </Button>
      </div>
    </TableContainer>
  );
};
