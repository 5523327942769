import type { Theme } from '@mui/material';
import type { FC } from 'react';
import { Box, Typography } from '@mui/material';

export interface VideoPreviewProps {
  src: string | null | undefined;
  frameWidth: number;
  frameHeight: number;
  index?: number | string;
}

const videoPreviewStyles = {
  rootSx: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: (theme: Theme) => theme.palette.grey[200]
  },
  noImageSx: {
    color: (theme: Theme) => theme.palette.primary.main
  }
};

export const VideoPreview: FC<VideoPreviewProps> = ({ src, frameHeight, frameWidth, index }) => {
  return src && src.length > 0 ? (
    <iframe
      style={{
        minHeight: frameHeight,
        minWidth: frameWidth,
        maxHeight: frameHeight,
        maxWidth: frameWidth
      }}
      src={src}
      title={`video-preview-${index}`}
    />
  ) : (
    <Box
      sx={videoPreviewStyles.rootSx}
      data-testid="defaultUploadBg"
      style={{
        minHeight: frameHeight,
        minWidth: frameWidth,
        maxHeight: frameHeight,
        maxWidth: frameWidth
      }}
    >
      <Typography sx={videoPreviewStyles.noImageSx}>No video...</Typography>
    </Box>
  );
};

export default VideoPreview;
