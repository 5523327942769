import { FormMode, emptyAdministratorInfo } from '@efacity/common';
import { PageTitle, useAuth } from '@efacity/react-next-sc';
import { PATHS } from '@efacity/routing';
import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SiteAdminOrganizationForm } from '@efacity/frontend-next/site-admin/schools';
import { WithLoading, getMergedFormValues, ConfirmationModal } from '@efacity/frontend-shared';
import { IdParamTypes } from '@efacity/frontend-next-shared/utils';
import {
  OrganizationSettingsWithAdministratorsFormValues,
  initialOrganizationSettingsFormValues,
  useOrganizationSettingsLoader
} from '@efacity/frontend-next-shared/org-settings';

export interface OrganizationFormContainerProps {
  paramOrgId?: string | null;
  mode: FormMode;
}

export const SiteAdminOrganizationFormContainer: React.FC<OrganizationFormContainerProps> = ({
  paramOrgId = null,
  mode
}) => {
  const { id } = useParams<IdParamTypes>();
  const orgId = FormMode.Edit ? paramOrgId || id : null;
  const navigate = useNavigate();
  const {
    authState: { user }
  } = useAuth();

  const [{ organizationSettingsState }, { createOrganization, updateOrgSettings, deleteOrgAdmin }] =
    useOrganizationSettingsLoader(orgId, `/schools/${orgId}`, `/schools`);

  const onSchoolFormSubmit = async (
    formValues: OrganizationSettingsWithAdministratorsFormValues,
    setError: (name, error, options?) => void,
    logoImage: Blob,
    mobileLogoImage: Blob
  ) => {
    const isActionSuccess =
      mode === FormMode.Add
        ? await createOrganization(formValues, setError, logoImage, mobileLogoImage)
        : await updateOrgSettings(formValues, setError, logoImage, mobileLogoImage);

    if (isActionSuccess) navigate(PATHS.schools);
  };

  const defaultConfirmationModalState = {
    isOpen: false,
    isOperating: false,
    message: '',
    onConfirm: null
  };
  const [confirmationModalState, setConfirmationModalState] = useState(defaultConfirmationModalState);

  const onDeleteClick = (adminId: string) => {
    setConfirmationModalState({
      isOpen: true,
      isOperating: false,
      message:
        '<div style="display:flex;justify-content:center">You are about to delete an Admin.</div><br/>' +
        '<div style="display:flex;justify-content:center">Please, confirm.</div>',
      onConfirm: () => onDeleteOrgAdmin(adminId)
    });
  };

  const onDeleteOrgAdmin = async (adminId: string) => {
    setConfirmationModalState((state) => {
      return { ...state, isOperating: true };
    });
    await deleteOrgAdmin(adminId, `/schools/${orgId}/admins/${adminId}`);
    setConfirmationModalState(defaultConfirmationModalState);
  };

  const handleCancelClick = (reset, initialValues) => {
    if (!paramOrgId) {
      navigate(PATHS.schools);
    } else {
      reset(initialValues);
    }
  };

  const initialFormValues = useMemo(() => {
    if (mode === FormMode.Edit && organizationSettingsState.organizationSettings) {
      return {
        organizationSettings: getMergedFormValues(
          initialOrganizationSettingsFormValues,
          organizationSettingsState.organizationSettings
        ),
        administrators: []
      };
    }

    return {
      organizationSettings: initialOrganizationSettingsFormValues,
      administrators: [emptyAdministratorInfo]
    };
  }, [organizationSettingsState.organizationSettings, mode]);

  return (
    <>
      {!paramOrgId && <PageTitle title={mode === FormMode.Add ? 'Add School' : 'Edit School'} underlined />}
      <WithLoading
        isLoading={organizationSettingsState.isLoading}
        message={'Loading organization data...'}
        style={{ width: '25%' }}
      >
        <SiteAdminOrganizationForm
          authenticatedUser={user}
          mode={mode}
          initialFormValues={initialFormValues}
          administrators={organizationSettingsState.administrators}
          orgId={orgId}
          deletingOrgAdmin={confirmationModalState.isOperating}
          handleSubmit={onSchoolFormSubmit}
          onDeleteOrgAdmin={onDeleteClick}
          onCancel={handleCancelClick}
          loading={organizationSettingsState.isLoading}
        />
      </WithLoading>
      {confirmationModalState.isOpen && (
        <ConfirmationModal
          open={confirmationModalState.isOpen}
          isOperating={confirmationModalState.isOperating}
          message={confirmationModalState.message}
          onClose={() => setConfirmationModalState(defaultConfirmationModalState)}
          onConfirm={() => confirmationModalState.onConfirm(confirmationModalState)}
        />
      )}
    </>
  );
};
