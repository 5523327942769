import React, { useMemo } from 'react';
import { IconButton, Link, Typography } from '@mui/material';
import { smallPublishButtonStyle } from '../../../components/Buttons/SessionPublishButton';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import EmailAddress from '../../../components/EmailAddress/EmailAddress';
import { PhoneNumber } from '@efacity/react-next-sc';
import { AccessorKeyColumnDef, createColumnHelper } from '@tanstack/react-table';
import { UserWithCenters } from '@efacity/common';
import { TextColumnFilter } from '@efacity/table';
import LinkedCentersExpandableList from './LinkedCentersExpandableList';

const columnHelper = createColumnHelper<UserWithCenters>();

const useOrganizationAdminsTableColumns = (
  orgId: string,
  hasSchoolAdminAccess: boolean,
  isCenterAdminsPage: boolean,
  onDeleteAdminClick: (orgId: string, userId: string, userFullName: string) => void,
  onEditAdminClick: (orgId: string, userId: string) => void,
  authenticatedUserId: string
): AccessorKeyColumnDef<UserWithCenters, string>[] => {
  const baseColumns = useMemo(() => {
    return [
      columnHelper.accessor('fullName', {
        header: 'Full Name',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ getValue, row }) => {
          return (
            <Link
              component="button"
              align={'left'}
              onClick={() =>
                onEditAdminClick(
                  isCenterAdminsPage ? row.original?.centers?.[0]?._id || orgId : orgId,
                  row.original._id
                )
              }
              underline={'none'}
            >
              {getValue()}
            </Link>
          );
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      }),
      columnHelper.accessor('email', {
        header: 'Email',
        //accessor: 'email',
        enableColumnFilter: true,
        enableSorting: true,
        cell: ({ getValue }) => {
          return <EmailAddress email={getValue()} />;
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      }),
      columnHelper.accessor('defaultPhone', {
        header: 'Phone',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ getValue }) => {
          return <PhoneNumber phone={getValue()} />;
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      })
    ];
  }, [onEditAdminClick, orgId, isCenterAdminsPage]);

  const deleteAdminColumn: AccessorKeyColumnDef<UserWithCenters, string> = useMemo(() => {
    return {
      header: '',
      id: 'actions-cell',
      accessorKey: '_id',
      enableSorting: false,
      enableColumnFilter: false,
      cell: ({ row }) => {
        if (row.original._id === authenticatedUserId) return <div />;
        return (
          <IconButton
            color="primary"
            aria-label="delete-instructor"
            data-testid="delete-instructor-btn"
            component="span"
            style={smallPublishButtonStyle}
            onClick={() => onDeleteAdminClick(orgId, row.original._id, row.original.fullName)}
          >
            <DeleteIcon />
          </IconButton>
        );
      },
      meta: {
        headerSx: { width: 75, maxWidth: 75 },
        columnSx: { width: 75, maxWidth: 75 }
      }
    };
  }, [onDeleteAdminClick, authenticatedUserId, orgId]);

  const centersNamesColumn: AccessorKeyColumnDef<UserWithCenters, string> = useMemo(() => {
    return {
      header: 'Center',
      accessorKey: 'centers',
      accessorFn: (row) => row?.centers || [],
      cell: ({ getValue, row }) => {
        return getValue() && getValue().length > 0 ? (
          <LinkedCentersExpandableList
            userId={row.original._id}
            userFullName={row.original.fullName}
            centers={row.original?.centers}
            initiallyExpanded={false}
            onDeleteCenterAdminCLick={onDeleteAdminClick}
          />
        ) : (
          <Typography>-</Typography>
        );
      }
    };
  }, [onDeleteAdminClick]);

  return useMemo(() => {
    if (isCenterAdminsPage) {
      if (hasSchoolAdminAccess) baseColumns.push(centersNamesColumn);
      else baseColumns.push(deleteAdminColumn);
    } else {
      baseColumns.push(deleteAdminColumn);
    }
    return baseColumns;
  }, [baseColumns, deleteAdminColumn, hasSchoolAdminAccess, isCenterAdminsPage, centersNamesColumn]);
};

export default useOrganizationAdminsTableColumns;
