import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { EmailFormValues, ResetPasswordFormValues, useAuth } from '@efacity/react-next-sc';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { EmailForm } from '../../components/AuthForm/EmailForm/EmailForm';
import { FormTitle } from '../../components/AuthForm/FormTitle/FormTitle';
import { ResetPasswordForm } from '../../components/AuthForm/ResetPasswordForm/ResetPasswordForm';

export const ResetPassword = () => {
  const { sendResetPasswordEmail, resetPassword } = useAuth();
  const [searchParams] = useSearchParams();
  const uuid = searchParams.get('uuid');
  const email = searchParams.get('email');

  const navigate = useNavigate();

  const onSendResetPasswordEmail = async (values: EmailFormValues) => {
    const { isError, message } = await sendResetPasswordEmail(values);
    showNotification(!isError, message);
    if (!isError) {
      return navigate('/auth/sign-in', { state: {} });
    }
  };

  const onResetPassword = async (values: ResetPasswordFormValues, resetForm) => {
    const { isError, message } = await resetPassword({ ...values, uuid: uuid as string });
    showNotification(!isError, message);
    if (!isError) {
      resetForm();
      return navigate('/auth/sign-in', { state: {} });
    }
  };

  return (
    <>
      <FormTitle withBorder={false} title="Reset Your Password" />
      {uuid ? (
        <ResetPasswordForm onResetPassword={onResetPassword} />
      ) : (
        <EmailForm onSendResetPasswordEmail={onSendResetPasswordEmail} initialEmailValue={email ? email : ''} />
      )}
    </>
  );
};
