import React, { useMemo } from 'react';
import { IconButton, Link } from '@mui/material';
import {
  ApplicationStatus,
  ApplicationStatusConnector,
  convertUtcToZonedTime,
  formatStringToMonthDayYearDate,
  joinNames,
  moneyWithCurrencyCodeFormatter,
  nowUTC
} from '@efacity/common';
import { createColumnHelper } from '@tanstack/react-table';
import { EnrollmentApplicationStatusFilter, TextColumnFilter } from '@efacity/table';
import url from 'url';
import { PATHS, toPath } from '@efacity/routing';
import { NavLink } from 'react-router-dom';
import { tableStyles } from '../../stylesheets/tableStyle';
import { EnrollmentApplicationWithOrgName } from './UserEnrollmentApplications';
import { smallPublishButtonStyle } from '../../components/Buttons/SessionPublishButton';
import { isBefore } from 'date-fns';
import DeleteIcon from '@mui/icons-material/DeleteForever';

const columnHelper = createColumnHelper<EnrollmentApplicationWithOrgName>();

const headerSx = { ...tableStyles.tableHeaderCellSx, ...tableStyles.tableHead };

export const useEnrollmentApplicationsColumns = (
  timeZone: string,
  onDeleteEnrollmentCLick: (enrollmentId: string, userName: string) => void
) => {
  return useMemo(() => {
    const columns = [
      columnHelper.accessor('orgName', {
        header: 'School Name',
        enableColumnFilter: true,
        enableSorting: true,
        meta: {
          filter: (column) => <TextColumnFilter column={column} />,
          headerSx: headerSx
        }
      }),
      columnHelper.accessor('enrollmentForm.student.firstName', {
        header: 'Student Name',
        id: 'enrollmentForm.student.firstName',
        enableColumnFilter: true,
        enableSorting: true,
        cell: ({ row }) => {
          const studentName =
            row.original.enrollmentForm.student?.firstName?.length > 0 ||
            row.original.enrollmentForm.student?.lastName?.length > 0
              ? joinNames(row.original.enrollmentForm.student.firstName, row.original.enrollmentForm.student.lastName)
              : '[no name]';

          const enrollmentUrl = url.format({
            pathname: toPath(PATHS.enrollment, { orgId: row.original.orgId }),
            query: { enrollmentId: row.original._id }
          });
          return (
            <Link component={NavLink} to={enrollmentUrl}>
              {studentName}
            </Link>
          );
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />,
          headerSx: headerSx
        }
      }),
      columnHelper.accessor('submittedOn', {
        header: 'Submitted On',
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ getValue }) =>
          getValue() ? formatStringToMonthDayYearDate(convertUtcToZonedTime(getValue(), timeZone).toISOString()) : '-',
        meta: {
          headerSx: headerSx
        }
      }),
      columnHelper.accessor('approvedAt', {
        header: 'Approved On',
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ getValue }) =>
          getValue() ? formatStringToMonthDayYearDate(convertUtcToZonedTime(getValue(), timeZone).toISOString()) : '-',
        meta: {
          headerSx: headerSx
        }
      }),
      {
        header: 'Price',
        id: 'price',
        accessor: 'price',
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => moneyWithCurrencyCodeFormatter(row.original.price, row.original.currencyCode),
        meta: {
          headerSx: { ...headerSx, textAlign: 'right', paddingRight: '15px' },
          columnSx: { textAlign: 'right', paddingRight: '15px', width: '115px', maxWidth: '115px' }
        }
      },
      columnHelper.accessor('applicationStatus', {
        header: 'Status',
        enableColumnFilter: true,
        enableSorting: true,
        cell: ({ getValue }) => ApplicationStatusConnector[getValue()],
        meta: {
          filter: (column) => <EnrollmentApplicationStatusFilter column={column} />,
          headerSx: { ...headerSx, width: 160, maxWidth: 160 }
        }
      }),
      {
        Header: '',
        id: 'actions-cell',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) =>
          row.original.applicationStatus !== ApplicationStatus.Approved ? (
            <IconButton
              color="primary"
              aria-label="delete application"
              component="span"
              style={smallPublishButtonStyle}
              disabled={isBefore(new Date(row.original.expiresOn), new Date(nowUTC()))}
              onClick={() =>
                onDeleteEnrollmentCLick(
                  row.original._id,
                  joinNames(
                    row.original.enrollmentForm.student.firstName,
                    row.original.enrollmentForm.student.lastName
                  ) || '[no name]'
                )
              }
            >
              <DeleteIcon />
            </IconButton>
          ) : (
            <> </>
          ),
        meta: {
          headerSx: { ...headerSx, width: 75, maxWidth: 75 }
        }
      }
    ];

    return columns;
  }, [timeZone, onDeleteEnrollmentCLick]);
};
