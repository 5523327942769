import { apiService, EnrollmentApplicationFrontend } from '@efacity/frontend-shared';
import { useState } from 'react';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { Messages } from '@efacity/common';

export interface EnrollmentsState {
  enrollments: EnrollmentApplicationFrontend[];
  enrollmentApplication: EnrollmentApplicationFrontend | null;
  total: number;
  timeZone: string;
  isLoading: boolean;
  isUpdating: boolean;
}
export const defaultEnrollmentsState: EnrollmentsState = {
  enrollments: [],
  enrollmentApplication: null,
  total: 0,
  timeZone: 'America/New_York',
  isLoading: false,
  isUpdating: false
};

export const useEnrollmentsLoader = (orgId: string, loadEnrollmentsList = true) => {
  const [enrollmentsState, setEnrollmentsState] = useState<EnrollmentsState>(defaultEnrollmentsState);

  const getEnrollmentApplication = async (enrollmentId: string) => {
    setEnrollmentsState((currentState) => ({
      ...currentState,
      isLoading: true
    }));
    try {
      const { data } = await apiService.get<{ enrollmentApplication: EnrollmentApplicationFrontend; timeZone: string }>(
        `/enrollments/${orgId}/application/${enrollmentId}`
      );
      setEnrollmentsState((currentState) => ({
        ...currentState,
        enrollmentApplication: data.enrollmentApplication,
        timeZone: data.timeZone,
        isLoading: false
      }));
    } catch (error) {
      setEnrollmentsState((currentState) => ({
        ...currentState,
        enrollmentApplication: null,
        isLoading: false
      }));
      showNotification(
        false,
        (error.response?.data?.message as string) || Messages.FailedGetEnrollmentApplication,
        true
      );
    }
  };

  const approveEnrollmentApplication = async (enrollmentId: string) => {
    setEnrollmentsState((currentState) => ({
      ...currentState,
      isUpdating: true
    }));
    try {
      await apiService.patch(`/enrollments/${orgId}/application/${enrollmentId}/approve`, {});
    } catch (error) {
      showNotification(false, (error.response?.data?.message as string) || Messages.FailedApproveEnrollment, true);
    } finally {
      setEnrollmentsState((currentState) => ({
        ...currentState,
        isUpdating: false
      }));
    }
  };

  const deleteEnrollmentApplication = async (enrollmentId: string) => {
    setEnrollmentsState((currentState) => ({
      ...currentState,
      isUpdating: true
    }));
    try {
      await apiService.delete(`/enrollments/${orgId}/enrollment/${enrollmentId}`);
    } catch (error) {
      showNotification(false, (error.response?.data?.message as string) || Messages.FailedDeleteEnrollment, true);
    } finally {
      setEnrollmentsState((currentState) => ({
        ...currentState,
        isUpdating: false
      }));
    }
  };

  const requestChangesToEnrollmentApplication = async (enrollmentId: string, comment: string) => {
    setEnrollmentsState((currentState) => ({
      ...currentState,
      isUpdating: true
    }));
    try {
      const formData = new FormData();
      formData.append('comment', comment);
      await apiService.patch(`/enrollments/${orgId}/application/${enrollmentId}/request-changes`, formData);
    } catch (error) {
      showNotification(
        false,
        (error.response?.data?.message as string) || Messages.FailedRequestChangesToEnrollment,
        true
      );
    } finally {
      setEnrollmentsState((currentState) => ({
        ...currentState,
        isUpdating: false
      }));
    }
  };
  return {
    enrollmentsState,
    getEnrollmentApplication,
    approveEnrollmentApplication,
    deleteEnrollmentApplication,
    requestChangesToEnrollmentApplication
  };
};
