import React, { FC } from 'react';
import { Box, Dialog, DialogContent, DialogProps, Paper } from '@mui/material';
import {
  ModalDialogTitle,
  REDIRECT_ITEM_NAME,
  SignInFormValues,
  SignUpFormValues,
  useLocalStorage
} from '@efacity/react-next-sc';
import { TabPanel } from './TabPanel';
import { ButtonWithIcon } from '../AuthForms/ButtonWithIcon/ButtonWithIcon';
import { customTheme } from '../../theme';
import { SignUpForm } from '../AuthForms/SignUpForm/SignUpForm';
import { SignInForm } from '../AuthForms/SignInForm/SignInForm';
import { PATHS } from '@efacity/routing';
import { apiService } from '../../apiServices/apiService';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { ErrorOption, FieldPath } from 'react-hook-form';
import { addServerErrors } from '@efacity/react-hook-form-mui';

const tabContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  flexGrow: 1
} as React.CSSProperties;
const activeTabStyle = {
  fontSize: 18,
  color: customTheme.palette.primary.dark,
  position: 'relative',
  textTransform: 'uppercase',
  borderBottom: '4px solid',
  borderColor: customTheme.palette.primary.light,
  cursor: 'pointer'
} as React.CSSProperties;
const tabStyle = {
  fontSize: 18,
  textTransform: 'uppercase',
  color: '#C4C4C4',
  cursor: 'pointer'
} as React.CSSProperties;

const hoverSx = {
  '&:hover': {
    color: 'primary.light'
  },
  fontSize: {
    lg: 18,
    md: 18,
    sm: 16,
    xs: 16
  }
};

const dialogContentSx = {
  paddingLeft: '20px',
  paddingRight: '20px',
  paddingBottom: '12px',
  paddingTop: '12px'
};

interface AuthModalProps extends DialogProps {
  redirectPath: string;
  authenticatedUrl: string;
  signIn: (formValues: SignInFormValues) => Promise<Partial<{ loginSuccess: boolean; error: string }>>;
  signUp: (values: SignUpFormValues) => Promise<Partial<{ signUpSuccess: boolean; error: any }>>;
}

export const AuthModal: FC<AuthModalProps> = ({ open, redirectPath, signIn, authenticatedUrl, signUp }) => {
  const [value, setValue] = React.useState(1);
  const { setItem, removeItem } = useLocalStorage();

  const handleStartGoogleSignIn = async () => {
    try {
      const { data } = await apiService.get<{ googleSigninUrl: string }>('/auth/google-signin-url');
      setItem(REDIRECT_ITEM_NAME, redirectPath);
      window.location.href = data.googleSigninUrl;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to get signInUrl: ', error);
    }
  };

  const onSignIn = async (formValues: SignInFormValues, resetForm: () => void) => {
    const { loginSuccess, error } = await signIn(formValues);
    if (loginSuccess) {
      removeItem(REDIRECT_ITEM_NAME);
      resetForm();
    } else {
      showNotification(false, error, true);
    }
  };
  const onSignUp = async (
    values: SignUpFormValues,
    setError: (name: FieldPath<SignUpFormValues>, error: ErrorOption, options?: { shouldFocus: boolean }) => void
  ) => {
    const { signUpSuccess, error } = await signUp(values);

    if (signUpSuccess) {
      removeItem(REDIRECT_ITEM_NAME);
      return;
    }

    if (error && error?.response?.data?.message) {
      if (error.response.status === 303) {
        setItem(REDIRECT_ITEM_NAME, redirectPath);
        return (window.location.href = `${PATHS.checkEmail}/?messageId=1`);
      }

      showNotification(true, error.response.data?.message, true, 'Ok');

      if (error.response.status === 409) {
        if (error.response.data?.googleSigninUrl) {
          setItem(REDIRECT_ITEM_NAME, redirectPath);
          return (window.location.href = error.response.data.googleSigninUrl);
        } else {
          return (window.location.href = `${PATHS.auth}/${PATHS.signIn}?email=${values.email}`);
        }
      }

      return;
    }

    if (error && error?.response?.data?.validationErrors) {
      return addServerErrors<SignUpFormValues>(error.response.data.validationErrors, setError);
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      PaperComponent={(props) => {
        return <Paper style={{ position: 'absolute', top: 0 }} {...props} />;
      }}
    >
      <ModalDialogTitle text="SignUp or LogIn to continue application" />
      <DialogContent sx={dialogContentSx}>
        <Box sx={{ padding: '0 15px 15px 15px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', marginBottom: '15px' }}>
          <Box sx={{ width: '100%' }}>
            <div style={{ display: 'flex' }}>
              <div style={tabContainerStyle}>
                <Box
                  data-testid="signup-link"
                  onClick={() => setValue(1)}
                  sx={value === 1 ? { ...activeTabStyle, ...hoverSx } : { ...tabStyle, ...hoverSx }}
                >
                  Sign Up
                </Box>
              </div>
              <div style={tabContainerStyle}>
                <Box
                  data-testid="signin-link"
                  sx={value === 2 ? { ...activeTabStyle, ...hoverSx } : { ...tabStyle, ...hoverSx }}
                  onClick={() => setValue(2)}
                >
                  Log In
                </Box>
              </div>
            </div>
          </Box>
          <br />
          <ButtonWithIcon
            icon={<img src="/assets/images/GoogleLogo_30_30.svg" alt="Google logo icon" />}
            fullWidth
            onClick={handleStartGoogleSignIn}
          >
            Sign up with Google
          </ButtonWithIcon>
          <hr style={{ width: '100%', marginTop: 20 }} />
          <div style={{ textAlign: 'center' }}>Or</div>
          <TabPanel value={value} index={1}>
            <SignUpForm onSignUp={onSignUp} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <SignInForm onSignIn={onSignIn} email={''} />
          </TabPanel>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
