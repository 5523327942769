import React, { useRef } from 'react';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import { useParams } from 'react-router-dom';
import { PATHS, toPath } from '@efacity/routing';
import { PageTitle } from '@efacity/react-next-sc';
import { Table as TableNew, TableRef, usePaginatedLoaderWithAdditionalInfo } from '@efacity/table';
import useLocationsTableColumns from './useLocationsTableColumns';
import { LocationFrontend, Messages } from '@efacity/common';
import { apiService } from '@efacity/frontend-shared';
import { showNotification } from '@efacity/frontend-next-shared/notifications';

const LocationsContainer: React.FC = () => {
  const { orgId } = useParams<OrgIdParamType>();
  const tableRef = useRef<TableRef<LocationFrontend>>(null);
  const dataFetcherUrl = `/org/${orgId}/locations`;
  const { paginatedDataFetcher } = usePaginatedLoaderWithAdditionalInfo<LocationFrontend>();

  const handleDeleteLocation = async (locationId: string) => {
    try {
      await apiService.delete<{ message: string }>(`/org/${orgId}/locations/${locationId}`);
      tableRef.current?.refetchTableData();
    } catch (error) {
      showNotification(false, error.response.data.message || Messages.FailedDeleteOrganizationAddress, true);
    }
  };

  const columnsNew = useLocationsTableColumns(orgId, handleDeleteLocation);

  return (
    <div style={{ marginTop: '15px' }}>
      <PageTitle title="" addButtonLink={toPath(PATHS.addLocation, { orgId: orgId })} />
      <TableNew<LocationFrontend>
        tableRef={tableRef}
        dataFetcherUrl={dataFetcherUrl}
        columnDefs={columnsNew}
        dataFetcher={paginatedDataFetcher}
        noDataText="No Locations..."
        manualSorting={false}
        manualFiltering={false}
      />
    </div>
  );
};

export default LocationsContainer;
