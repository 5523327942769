import { useCallback, useEffect, useState } from 'react';
import { apiService, getMergedFormValues } from '@efacity/frontend-shared';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { isEmptyObject, Location, LocationType, Messages, FormMode } from '@efacity/common';
import { LocationFormValues } from './LocationForm';
import { addServerErrors } from '@efacity/react-hook-form-mui';
import { ErrorOption, FieldPath } from 'react-hook-form';

export interface LocationsState {
  locations: Location[];
  newlyAddedLocation: string | null;
  isLoading: boolean;
}

export const initialLocationFormValues: LocationFormValues = {
  type: LocationType.Virtual,
  name: '',
  orgId: '',
  capacity: 1,
  addressId: '',
  defaultInstructor: '',
  virtualMeeting: { meetingId: '', link: '', password: '' },
  logoFullUrl: '',
  mobileLogoFullUrl: ''
};

export const useLocationsLoader = (url: string) => {
  const [locationsState, setLocationsState] = useState({
    locations: [],
    newlyAddedLocation: null,
    isLoading: true
  });

  const getLocations = useCallback(() => {
    const setLocations = (locations: Location[], isLoading: boolean) => {
      setLocationsState((state) => ({
        ...state,
        locations,
        isLoading
      }));
    };

    const showErrorNotification = (message: string) => {
      setLocations([], false);
      showNotification(false, message, true);
    };

    setLocationsState((locationsState) => ({
      ...locationsState,
      newlyAddedLocation: null,
      isLoading: true
    }));
    apiService.get<{ data: Location[] }>(url).then(
      (result) => {
        return setLocations(result.data.data, false);
      },
      (error) => {
        setLocationsState((locationsState) => ({
          ...locationsState,
          isLoading: false
        }));
        showErrorNotification((error.response?.data?.message as string) || 'Failed to get locations from ' + url);
      }
    );
  }, [url]);

  const updateLocation = async (
    formValues: LocationFormValues,
    setError: (name: FieldPath<LocationFormValues>, error: ErrorOption, options?: { shouldFocus: boolean }) => void,
    mobileLogoImage: Blob,
    locationId: string
  ): Promise<boolean> => {
    const location = { ...formValues };
    const formDataToSend = new FormData();
    formDataToSend.append('location', JSON.stringify(location));

    if (mobileLogoImage) {
      formDataToSend.append('mobileLogoImage', mobileLogoImage);
    }

    return apiService
      .patch<{ message: string }>(`${url}/${locationId}`, formDataToSend)
      .then(({ data }) => {
        getLocations();
        return true;
      })
      .catch((error) => {
        const errorResponse = error.response.data;
        if (
          typeof errorResponse?.validationErrors?.location === 'object' &&
          !isEmptyObject(errorResponse.validationErrors.location)
        ) {
          addServerErrors<LocationFormValues>(errorResponse.validationErrors.location, setError);
        } else {
          showNotification(false, errorResponse.message || Messages.FailedUpdateOrganizationAddress, true);
        }
        return false;
      });
  };

  const addLocation = async (
    formValues: LocationFormValues,
    setError: (name: FieldPath<LocationFormValues>, error: ErrorOption, options?: { shouldFocus: boolean }) => void,
    mobileLogoImage: Blob
  ): Promise<boolean> => {
    const location = { ...formValues };
    const formDataToSend = new FormData();
    formDataToSend.append('location', JSON.stringify(location));

    if (mobileLogoImage) {
      formDataToSend.append('mobileLogoImage', mobileLogoImage);
    }

    return apiService
      .post<{ location: Location; message: string }>(url, formDataToSend)
      .then(({ data }) => {
        setLocationsState((state) => ({
          locations: [...state.locations, data.location],
          newlyAddedLocation: data.location._id,
          isLoading: false
        }));

        return true;
      })
      .catch((error) => {
        const errorResponse = error.response.data;
        if (
          typeof errorResponse?.validationErrors?.location === 'object' &&
          !isEmptyObject(errorResponse.validationErrors.location)
        ) {
          addServerErrors<LocationFormValues>(errorResponse.validationErrors.location, setError);
        } else {
          showNotification(false, errorResponse.message || Messages.FailedCreateOrganizationAddress, true);
        }
        return false;
      });
  };

  return [
    {
      locationsState,
      setLocationsState
    },
    { getLocations, updateLocation, addLocation }
  ];
};

export const useLocationFormValues = (
  orgId: string,
  locationId: string,
  formMode: FormMode,
  defaultLocationType?: LocationType
) => {
  const [locationFormState, setLocationFormState] = useState({
    locationFormValues: defaultLocationType
      ? { ...initialLocationFormValues, type: defaultLocationType }
      : initialLocationFormValues,
    isLoading: false
  });

  useEffect(() => {
    const getLocationById = async (orgId: string) => {
      setLocationFormState({
        locationFormValues: initialLocationFormValues,
        isLoading: true
      });

      try {
        const { data } = await apiService.get<Location>(`/org/${orgId}/locations/${locationId}`);
        const location = { ...data };
        delete location._id;

        setLocationFormState((locationFormState) => ({
          ...locationFormState,
          locationFormValues:
            location.type === LocationType.InPerson || location.type === LocationType.PublicSchool
              ? { ...getMergedFormValues(initialLocationFormValues, location), addressId: location?.address?._id || '' }
              : getMergedFormValues(initialLocationFormValues, location),
          isLoading: false
        }));
      } catch (error) {
        showNotification(false, error.message || 'Failed to get location info.', true);
        setLocationFormState({
          locationFormValues: initialLocationFormValues,
          isLoading: false
        });
      }
    };

    if (formMode === FormMode.Add) {
      setLocationFormState((locationFormState) => ({
        ...locationFormState,
        locationFormValues: defaultLocationType
          ? { ...initialLocationFormValues, type: defaultLocationType }
          : initialLocationFormValues
      }));
    } else {
      getLocationById(orgId);
    }
  }, [orgId, locationId, formMode, defaultLocationType]);

  return {
    locationFormState: locationFormState
  };
};
