import { formatStringToMonthDayYearDate, OrganizationIdName, User } from '@efacity/common';
import { PageTitle, useAuth } from '@efacity/react-next-sc';
import { PATHS } from '@efacity/routing';
import React, { useRef } from 'react';
import { tableStyles } from '../../stylesheets/tableStyle';
import { createColumnHelper } from '@tanstack/react-table';
import {
  Table,
  TableRef,
  TextColumnFilter,
  DateColumnFilter,
  usePaginatedLoaderWithAdditionalInfo
} from '@efacity/table';
import { Certificate } from '@efacity/frontend-shared';

const headerSx = { ...tableStyles.tableHeaderCellSx, ...tableStyles.tableHead };

interface CertificateWithNames extends Omit<Certificate, 'certificatedBy'> {
  organization: OrganizationIdName;
  certificatedBy: User;
}
const columnHelper = createColumnHelper<CertificateWithNames>();

const UserCertificates = () => {
  const { authState } = useAuth();
  const userId = authState.user._id;
  const tableRef = useRef<TableRef<CertificateWithNames>>(null);
  const fetchDataUrl = `user/${userId}/certificates`;
  const { paginatedDataFetcher } = usePaginatedLoaderWithAdditionalInfo<CertificateWithNames>({
    dateTimeFields: ['expiresOn']
  });

  const certificationLandingLink = PATHS.instructorCertification;

  const columns = [
    columnHelper.accessor('certificationForActivity.name', {
      header: 'Activity Name',
      enableSorting: true,
      enableColumnFilter: true,
      cell: ({ row }) => {
        return row.original.certificationForActivity?.name || '[n/a]';
      },
      meta: {
        filter: (column) => <TextColumnFilter column={column} />,
        headerSx: headerSx
      }
    }),
    columnHelper.accessor('organization.name', {
      header: 'Organization',
      enableSorting: true,
      enableColumnFilter: true,
      cell: ({ row }) => {
        return row.original.organization?.name || '[n/a]';
      },
      meta: {
        filter: (column) => <TextColumnFilter column={column} />,
        headerSx: headerSx
      }
    }),
    columnHelper.accessor('certificatedBy.fullName', {
      header: 'Issued by',
      enableSorting: true,
      enableColumnFilter: true,
      cell: ({ row }) => {
        return row.original.certificatedBy?.fullName || '[n/a]';
      },
      meta: {
        filter: (column) => <TextColumnFilter column={column} />,
        headerSx: headerSx
      }
    }),
    columnHelper.accessor('expiresOn', {
      header: 'Expires on',
      enableSorting: true,
      enableColumnFilter: true,
      cell: ({ row }) => <>{formatStringToMonthDayYearDate(row.original.expiresOn)}</>,
      meta: {
        filter: (column) => <DateColumnFilter column={column} />,
        headerSx: headerSx
      }
    })
  ];

  return (
    <div>
      <PageTitle title="Certificates" addButtonLink={certificationLandingLink} />
      <Table<CertificateWithNames>
        tableRef={tableRef}
        dataFetcherUrl={fetchDataUrl}
        columnDefs={columns}
        dataFetcher={paginatedDataFetcher}
        noDataText="No Certificates..."
        manualFiltering={false}
        manualSorting={false}
      />
    </div>
  );
};

export default UserCertificates;
