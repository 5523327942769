import { Activity, ActivityTypes, CurrencyCode, FormMode, Roles } from '@efacity/common';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import {
  ActivityFormValues,
  AdditionalSessionOptionIdName,
  IOption,
  apiService,
  getFormAsFormData,
  useFetch,
  WithLoading
} from '@efacity/frontend-shared';
import { addServerErrors } from '@efacity/react-hook-form-mui';
import { hasFrontendAccessWithRoleForOrganization, useAuth } from '@efacity/react-next-sc';
import { PATHS, toPath } from '@efacity/routing';
import { Grid } from '@mui/material';
import { cloneDeep } from 'lodash';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IdOrgIdParamTypes } from '@efacity/frontend-next-shared/utils';
import { useActivityOptions } from '../../../hooks/useActivityOptions';
import { useOrganizationRegionsOptions } from '../../../hooks/useOrganizationRegionsOptions';
import ActivityForm from './ActivityForm';

export interface ActivityFormContainerProps {
  mode: FormMode;
  activity: Activity;
  isLoadingActivity: boolean;
  allowHandleActivity: boolean;
  isCloningActivity: boolean;
}

const mapCategoriesForApi = (categories: IOption[]): any => {
  return categories.map((category) => {
    if (!category._id) {
      return { name: category.name };
    }
    return { _id: category._id };
  });
};

const ActivityFormContainer: FC<ActivityFormContainerProps> = ({
  mode,
  activity,
  isLoadingActivity,
  allowHandleActivity,
  isCloningActivity
}) => {
  const { orgId, id: activityId } = useParams<IdOrgIdParamTypes>();
  const {
    organizationRegions: { defaultCurrency: currency, isPaymentsAllowed }
  } = useOrganizationRegionsOptions(orgId);
  const navigate = useNavigate();

  const [activityCategoriesState] = useFetch<IOption[]>(`/org/${orgId}/activity/categories`, {
    initialDataState: []
  });

  const [activityTagsState] = useFetch<IOption[]>(`/org/${orgId}/activity/tags`, {
    initialDataState: []
  });
  const { activityState } = useActivityOptions(orgId);

  const [additionalOptionsState] = useFetch<AdditionalSessionOptionIdName[]>(
    activityId
      ? `/org/${orgId}/additionalOptions/additionalActivityOptions?activityId=${activityId}`
      : `/org/${orgId}/additionalOptions/additionalActivityOptions`,
    { initialDataState: [] }
  );

  const handleSubmit = (
    values: ActivityFormValues,
    setError: (fieldName: keyof ActivityFormValues, error: { type: string; message: string }) => void,
    activityImg: Blob
  ) => {
    const activityActionSuccessful = (message: string) => {
      showNotification(true, message);
      navigate(toPath(PATHS.activities, { orgId: orgId }));
    };
    const valuesWithMappedCategories: Partial<Activity> = {
      ...cloneDeep(values),
      categories: mapCategoriesForApi(values.categories)
    };

    const activityActionFailure = (error) => {
      const { data: errorData } = error.response;
      if (errorData.validationErrors) {
        addServerErrors<ActivityFormValues>(errorData.validationErrors, setError);
      } else {
        showNotification(false, errorData.message as string);
      }
    };

    if (valuesWithMappedCategories.type === ActivityTypes.Enrichment) {
      delete valuesWithMappedCategories.shortDescription;
      valuesWithMappedCategories.descriptionOnSessionSelection = JSON.stringify(
        valuesWithMappedCategories.descriptionOnSessionSelection
      );
    } else {
      delete valuesWithMappedCategories.descriptionOnSessionSelection;
    }

    valuesWithMappedCategories.longDescription = JSON.stringify(valuesWithMappedCategories.longDescription);

    // when cloning activity and there is and imageFullUrl keep it
    // in case new image is selected default one will be overwritten further
    const formDataToSend = getFormAsFormData<Partial<Activity>>(
      valuesWithMappedCategories,
      isCloningActivity ? [] : ['imageFullUrl']
    );
    if (activityImg) {
      formDataToSend.append('imageFullUrl', activityImg);
    }
    if (mode === FormMode.Edit) {
      return apiService
        .patch<{ activity: Activity; message: string }>(`/org/${orgId}/activity/${activityId}`, formDataToSend)
        .then(({ data }) => {
          activityActionSuccessful(data.message);
        })
        .catch((error) => {
          activityActionFailure(error);
        });
    } else {
      return apiService
        .post<{ message: string }>(`/org/${orgId}/activity`, formDataToSend)
        .then(({ data }) => {
          activityActionSuccessful(data.message as string);
        })
        .catch((error) => {
          activityActionFailure(error);
        });
    }
  };

  const handleCancel = () => {
    navigate(toPath(PATHS.activities, { orgId }));
  };

  const {
    authState: { user }
  } = useAuth();
  const isUserIndependentTeacher = hasFrontendAccessWithRoleForOrganization(
    orgId,
    [Roles.IndependentTeacher],
    user.adminAccesses || []
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <WithLoading isLoading={isLoadingActivity} message={'Loading activity data...'} style={{ width: '25%' }}>
          <ActivityForm
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            allowHandleActivity={allowHandleActivity}
            activity={activity}
            mode={mode}
            orgId={orgId}
            activityId={activityId}
            currency={currency as CurrencyCode}
            isCloning={isCloningActivity}
            activityCategoriesState={activityCategoriesState}
            activityTagsState={activityTagsState}
            activityOptionsState={activityState}
            additionalOptionsState={additionalOptionsState}
            priceAllowed={isPaymentsAllowed}
            isUserIndependentTeacher={isUserIndependentTeacher}
          />
        </WithLoading>
      </Grid>
    </Grid>
  );
};

export default ActivityFormContainer;
