import { Messages } from '@efacity/common';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { apiService } from '@efacity/frontend-shared';
import { addServerErrors } from '@efacity/react-hook-form-mui';
import { SignUpFormValues } from '@efacity/react-next-sc';
import { PATHS } from '@efacity/routing';
import { Box, Typography } from '@mui/material';
import { ErrorOption, FieldPath } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { IdOrgIdParamTypes } from '@efacity/frontend-next-shared/utils';
import { SignUpForm } from '@efacity/frontend-shared';
import { LandingWrapper } from '../../components/LandingWrapper/LandingWrapper';
import { CenteredFormContainer } from '@efacity/frontend-next-shared/forms/server';
import { useOrganizationRegionsOptions } from '../../hooks/useOrganizationRegionsOptions';

export const InstructorRegistration = () => {
  const { orgId } = useParams<IdOrgIdParamTypes>();
  const navigate = useNavigate();
  const { organizationRegions } = useOrganizationRegionsOptions(orgId);

  const handleSubmit = async (
    formValues: SignUpFormValues,
    setError: (name: FieldPath<SignUpFormValues>, error: ErrorOption, options?: { shouldFocus: boolean }) => void
  ) => {
    return apiService
      .post<{ message: string }>(`/auth/signup/${orgId}/teacher`, formValues)
      .then(() => {
        return navigate(`${PATHS.checkEmail}?messageId=2&orgId=${orgId}`);
      })
      .catch((error) => {
        const { data: errorData } = error.response;
        if (errorData.validationErrors) {
          addServerErrors<SignUpFormValues>(errorData.validationErrors, setError);
        } else {
          showNotification(false, (error.response.data.message as string) || Messages.FailedToUpdateUser, true);
        }
      });
  };

  return (
    <LandingWrapper
      footer={null}
      logoFullUrl={organizationRegions?.logoFullUrl?.length > 0 ? organizationRegions?.logoFullUrl : null}
      mobileLogoFullUrl={organizationRegions?.logoFullUrl?.length > 0 ? organizationRegions?.logoFullUrl : null}
    >
      <CenteredFormContainer>
        <Box marginBottom={4}>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            Instructor Registration
            <hr style={{ height: '2', width: 'auto', margin: 0, marginTop: 15 }} />
          </Typography>
        </Box>
        <SignUpForm onSignUp={handleSubmit} submitBtnLabel="Submit" />
      </CenteredFormContainer>
    </LandingWrapper>
  );
};
