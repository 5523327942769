import * as yup from 'yup';
import {
  isListOfEmails,
  MailingServer,
  Messages,
  nonNegativeAmountValidator,
  TaxTypes,
  ValidationErrors
} from '@efacity/common';
import { addressFormValidationSchema } from './addressFormValidationSchema';
import { requiredPhoneValidator, orgNameValidationAPI } from '@efacity/frontend-shared';

const organizationInfoValidationObject = (path: string, orgId?: string) => {
  return {
    status: yup.string(),
    name: yup
      .string()
      .required(Messages.OrgNameRequired)
      .test('organization_name_async_validation', Messages.OrgNameNotUnique, function (value) {
        return orgNameValidationAPI(value, orgId)
          .then((res) => {
            return true;
          })
          .catch((e) => {
            return false;
          });
      }),
    description: yup.string(),
    ein: yup.string(),
    defaultCurrency: yup.string(),
    website: yup.string().optional().url(Messages.URLRequired),
    customerLogoutUrl: yup.string().optional().url(Messages.URLRequired),
    schoolContactEmail: yup.string().email(),
    hideNumberOfRegistrations: yup.boolean(),
    validateUserOnRegistration: yup.boolean(),
    phone: requiredPhoneValidator(`${path}.phone`),
    phoneCountry: yup.string().required(),
    timeZone: yup.string().required('Select Timezone'),
    efacityFee: yup.number().min(0, Messages.WrongFee).max(100, Messages.WrongFee),
    connectModel: yup.string(),
    taxType: yup.string(),
    taxPercentage: yup.number().when(['taxType'], (taxType, schema) => {
      if (taxType && taxType !== TaxTypes.None) {
        return schema.min(1, Messages.WrongTaxPercentage).max(99, Messages.WrongTaxPercentage);
      }
    }),
    smtpSettings: yup.object().when('mailingServer', {
      is: MailingServer.smtp,
      then: yup.object().shape({
        server: yup.string().required(ValidationErrors.SmtpServerRequired),
        port: yup.number().required(ValidationErrors.PortRequired),
        user: yup.string().required(ValidationErrors.UserRequired),
        password: yup.string().required(ValidationErrors.PasswordRequired),
        ssl: yup.boolean().required(ValidationErrors.SSLRequired)
      })
    }),
    additionalNotificationEmails: yup
      .string()
      .test('additionalNotificationEmails', null, function (this: yup.TestContext, fieldValue: any): any {
        if (fieldValue?.length > 0) {
          if (isListOfEmails(fieldValue)) {
            return true;
          }
          const { path, createError } = this;

          return createError({
            path: path,
            message: ValidationErrors.InvalidEmailsList
          });
        }
        return true;
      })
  };
};

export const organizationEmailSettingsValidationObject = yup.object().shape({
  smtpSettings: yup.object().when('mailingServer', {
    is: MailingServer.smtp,
    then: yup.object().shape({
      server: yup.string().required(ValidationErrors.SmtpServerRequired),
      port: yup.number().required(ValidationErrors.PortRequired),
      user: yup.string().required(ValidationErrors.UserRequired),
      password: yup.string().required(ValidationErrors.PasswordRequired),
      ssl: yup.boolean().required(ValidationErrors.SSLRequired)
    })
  })
});

export const organizationAdministratorsValidationSchema = yup.array().of(
  yup.object().shape({
    fullName: yup.string().required('Full Name is required'),
    callByName: yup.string(),
    email: yup.string().email(Messages.InvalidEmail).required('Email is required')
  })
);

export const editOrganizationFormValidationSchema = (path: string, orgId: string) =>
  yup.object().shape({
    organizationSettings: yup.object().shape({
      ...organizationInfoValidationObject(path, orgId),
      defaultAddress: yup.object().shape({
        _id: yup.string().required(Messages.DefaultAddressRequired)
      })
    }),
    administrators: organizationAdministratorsValidationSchema
  });

export const siteAdminAddOrganizationFormValidationSchema = (path: string) =>
  yup.object().shape({
    organizationSettings: yup.object().shape({
      ...organizationInfoValidationObject(path),
      enrollmentRequired: yup.boolean(),
      enrollmentPrice: yup.string().test('test-name', ValidationErrors.InvalidPriceRange, nonNegativeAmountValidator),
      efacityFee: yup.number().min(0, Messages.WrongFee).max(100, Messages.WrongFee),
      defaultAddress: addressFormValidationSchema
    }),
    administrators: organizationAdministratorsValidationSchema
  });

export const siteAdminEditOrganizationFormValidationSchema = (path: string, orgId: string) =>
  yup.object().shape({
    organizationSettings: yup.object().shape({
      ...organizationInfoValidationObject(path, orgId),
      enrollmentRequired: yup.boolean(),
      enrollmentPrice: yup.string().test('test-name', ValidationErrors.InvalidPriceRange, nonNegativeAmountValidator),
      efacityFee: yup.number().min(0, Messages.WrongFee).max(100, Messages.WrongFee),
      defaultAddress: yup.object().shape({
        _id: yup.string().required(Messages.DefaultAddressRequired)
      })
    }),
    administrators: organizationAdministratorsValidationSchema
  });
