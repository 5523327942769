import React, { useRef, useState } from 'react';
import { apiService, ConfirmationModal } from '@efacity/frontend-shared';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { Messages, UserWithCenters } from '@efacity/common';
import UserEditModal from '../UserEditModal/UserEditModal';
import { NewButton } from '@efacity/frontend-next-shared/org-settings';
import { Table, TableRef, usePaginatedLoaderWithAdditionalInfo } from '@efacity/table';
import AddNewAdminModal from './AddNewAdminModal';
import useOrganizationAdminsTableColumns from './useOrganizationAdminsTableColumns';

export interface UserUnderEdit {
  orgId: string;
  userId: string;
}
export const emptyUserUnderEdit: UserUnderEdit = {
  orgId: '',
  userId: ''
};

export interface OrganizationAdminsTableProps {
  authenticatedUserId: string;
  orgId: string;
  hasSchoolAdminAccess: boolean;
  isCenterAdminsPage: boolean;
}

const OrganizationAdminsContainer: React.FC<OrganizationAdminsTableProps> = ({
  authenticatedUserId,
  orgId,
  hasSchoolAdminAccess,
  isCenterAdminsPage
}) => {
  const tableRef = useRef<TableRef<UserWithCenters>>(null);
  const dataFetcherUrl = isCenterAdminsPage ? `/org/${orgId}/users/center-admins` : `/org/${orgId}/users/school-admins`;
  const { paginatedDataFetcher } = usePaginatedLoaderWithAdditionalInfo<UserWithCenters>();
  const [addModalOpen, setAddModalOpen] = useState(false);

  const handleAddModalClose = async () => {
    setAddModalOpen(false);
  };

  const deleteAdmin = async (orgId: string, userId: string) => {
    setConfirmationModalState((state) => {
      return { ...state, isOperating: true };
    });
    try {
      await apiService.delete<{ message: string }>(`/org/${orgId}/admins/${userId}`);
      tableRef.current?.refetchTableData();
      setConfirmationModalState(defaultConfirmationModalState);
    } catch {
      showNotification(false, Messages.FailedDeleteAdministrator, true);
      setConfirmationModalState(defaultConfirmationModalState);
    }
  };
  const defaultConfirmationModalState = {
    isOpen: false,
    isOperating: false,
    message: '',
    onConfirm: null
  };
  const [confirmationModalState, setConfirmationModalState] = useState(defaultConfirmationModalState);

  const onDeleteAdminCLick = (orgId: string, userId: string, name: string) => {
    setConfirmationModalState({
      isOpen: true,
      isOperating: false,
      message:
        `<div style="display:flex;justify-content:center">You are about to delete administrator ${name}.</div><br/>` +
        '<div style="display:flex;justify-content:center">Please, confirm.</div>',
      onConfirm: () => deleteAdmin(orgId, userId)
    });
  };

  const [userUnderEdit, setUserUnderEdit] = useState<UserUnderEdit>(emptyUserUnderEdit);
  const onEditAdminClick = (orgId: string, userId: string) => {
    setUserUnderEdit({
      orgId: orgId,
      userId: userId
    });
  };
  const handleUserModalClose = async (reloadData: boolean) => {
    if (reloadData) {
      tableRef.current?.refetchTableData();
    }
    setUserUnderEdit(emptyUserUnderEdit);
  };

  const columns = useOrganizationAdminsTableColumns(
    orgId,
    hasSchoolAdminAccess,
    isCenterAdminsPage,
    onDeleteAdminCLick,
    onEditAdminClick,
    authenticatedUserId
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '15px' }}>
      <NewButton
        style={{ width: '120px', marginBottom: '10px', alignSelf: 'end' }}
        onClick={() => {
          setAddModalOpen(true);
        }}
      >
        New
      </NewButton>
      <Table<UserWithCenters>
        tableRef={tableRef}
        columnDefs={columns}
        dataFetcherUrl={dataFetcherUrl}
        dataFetcher={paginatedDataFetcher}
        noDataText="No Admins..."
        manualFiltering={false}
        manualSorting={false}
      />
      {confirmationModalState.isOpen && (
        <ConfirmationModal
          open={confirmationModalState.isOpen}
          isOperating={confirmationModalState.isOperating}
          message={confirmationModalState.message}
          onClose={() => setConfirmationModalState(defaultConfirmationModalState)}
          onConfirm={() => confirmationModalState.onConfirm(confirmationModalState)}
        />
      )}
      {userUnderEdit.userId && (
        <UserEditModal
          open={!!userUnderEdit.userId}
          orgId={userUnderEdit.orgId}
          userId={userUnderEdit.userId}
          onClose={handleUserModalClose}
        />
      )}
      <AddNewAdminModal orgId={orgId} isOpen={addModalOpen} onClose={handleAddModalClose} />
    </div>
  );
};

export default OrganizationAdminsContainer;
