import { Activity, ActivityTypes, Messages, SessionStatus } from '@efacity/common';
import { apiService } from '@efacity/frontend-shared';
import { useState } from 'react';
import { CancelActions } from './CancellationModal';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { Session as EfacitySession } from '@efacity/react-next-sc';

export interface SessionActionsState {
  isLoadingSession: boolean;
  isUpdating: boolean;
  session: EfacitySession | null;
  requireStudentAge: boolean;
  sessionIdUnderUpdate: string | null;
}
export const defaultSessionActionsState: SessionActionsState = {
  isLoadingSession: false,
  isUpdating: false,
  session: null,
  requireStudentAge: false,
  sessionIdUnderUpdate: null
};

export const useSessionTabActions = (orgId: string) => {
  const [sessionActionsState, setUpdatingSessionState] = useState<SessionActionsState>(defaultSessionActionsState);
  const [cancellationModalOpened, setCancellationModalOpened] = useState<boolean>(false);
  const [modalCallbackAdditionalProps, setModalCallbackAdditionalProps] = useState({ sessionId: null });

  const loadSession = async (sessionId: string) => {
    setUpdatingSessionState((state) => ({
      ...state,
      isLoadingSession: true
    }));
    try {
      const { data } = await apiService.get<{ session: EfacitySession; requireStudentAge: boolean }>(
        `/org/${orgId}/sessions/${sessionId}`
      );

      const requireStudentAgeForSession =
        data.requireStudentAge && data.session.type !== ActivityTypes.InstructorCertification;

      setUpdatingSessionState((state) => ({
        ...state,
        session: data.session,
        requireStudentAge: requireStudentAgeForSession,
        isLoadingSession: false
      }));
    } catch {
      showNotification(false, Messages.FailedGetSession);
    } finally {
      setUpdatingSessionState((state) => ({
        ...state,
        isLoadingSession: false
      }));
    }
  };

  const loadActivityValuesForSession = async (activityId: string) => {
    setUpdatingSessionState((state) => ({
      ...state,
      isLoadingSession: true
    }));
    try {
      const { data } = await apiService.get<Activity>(`/org/${orgId}/activity/${activityId}`);
      const activityAsSessionFormValues = {
        ...(data as unknown as EfacitySession),
        activityId: data._id,
        price: data.defaultSessionPrice,
        tags: []
      };

      setUpdatingSessionState((state) => ({
        ...state,
        session: activityAsSessionFormValues,
        isLoadingSession: false
      }));
    } catch {
      showNotification(false, Messages.FailedGetSession);
    } finally {
      setUpdatingSessionState((state) => ({
        ...state,
        isLoadingSession: false
      }));
    }
  };

  const updateCancellationInSession = (session: EfacitySession, updatedSession: EfacitySession) => ({
    ...session,
    cancelledAt: updatedSession?.cancelledAt,
    cancelledBy: updatedSession?.cancelledBy,
    publishedAt: updatedSession?.publishedAt,
    cancellationReason: updatedSession?.cancellationReason
  });

  const onCancelSession = async (
    sessionId: string,
    cancellationReason: string,
    cancelSessionActions: CancelActions
  ) => {
    setUpdatingSessionState((state) => ({
      ...state,
      isUpdating: true
    }));
    try {
      const result = await apiService.patch<{ session: EfacitySession; message: string }>(
        `/org/${orgId}/sessions/${sessionId}/cancel`,
        { cancellationReason }
      );
      cancelSessionActions.setCancellationReason('');
      if (sessionActionsState.session) {
        setUpdatingSessionState((state) => ({
          ...state,
          session: { ...updateCancellationInSession(state.session, result.data.session) }
        }));
      }
      setCancellationModalOpened(false);
      if (result.status === 202) {
        showNotification(false, result.data?.message, true, 'Ok');
      }
    } catch (error) {
      if (error.response?.data?.validationErrors) {
        cancelSessionActions.setValidationError(
          error.response?.data?.validationErrors?.cancellationReason || Messages.FailedToCancelSession
        );
      } else {
        showNotification(false, Messages.FailedToCancelSession);
      }
    } finally {
      setUpdatingSessionState((state) => ({
        ...state,
        isUpdating: false
      }));
    }
  };

  const onRestoreSession = async (sessionId: string) => {
    setUpdatingSessionState((state) => ({
      ...state,
      isUpdating: true,
      sessionIdUnderUpdate: sessionId
    }));
    try {
      const result = await apiService.patch<{ session: EfacitySession; message: string }>(
        `/org/${orgId}/sessions/${sessionId}/restore`,
        {}
      );
      if (sessionActionsState.session) {
        setUpdatingSessionState((state) => ({
          ...state,
          session: { ...updateCancellationInSession(state.session, result.data.session) }
        }));
      }
      setCancellationModalOpened(false);
      if (result.status === 202) {
        showNotification(false, result.data?.message, true, 'Ok');
      }
    } catch {
      showNotification(false, Messages.FailedRestoreSession);
    } finally {
      setUpdatingSessionState((state) => ({
        ...state,
        isUpdating: false,
        sessionIdUnderUpdate: null
      }));
    }
  };

  const updateStatusInSession = (session: EfacitySession, newStatus: SessionStatus) => ({
    ...session,
    status: newStatus
  });

  const onChangeSessionStatus = async (sessionId: string, sessionStatus: SessionStatus) => {
    setUpdatingSessionState((state) => ({
      ...state,
      isUpdating: true,
      sessionIdUnderUpdate: sessionId
    }));

    try {
      const result = await apiService.patch<{ session: EfacitySession; message: string }>(
        `/org/${orgId}/sessions/${sessionId}/status`,
        {
          sessionStatus
        }
      );

      if (sessionActionsState.session) {
        setUpdatingSessionState((state) => ({
          ...state,
          session: { ...updateStatusInSession(state.session, result.data.session?.status) },
          sessionIdUnderUpdate: null
        }));
      }
      if (result.status === 202) {
        showNotification(true, result.data?.message, true, 'Ok');
      }
    } catch (error) {
      showNotification(false, error?.message || Messages.FailedChangeSessionStatus);
    } finally {
      setUpdatingSessionState((state) => ({
        ...state,
        isUpdating: false,
        sessionIdUnderUpdate: null
      }));
    }
  };

  const onOpenCancellationModal = () => {
    setCancellationModalOpened(true);
  };

  const onCloseCancellationModal = () => {
    setCancellationModalOpened(false);
  };

  return [
    { sessionActionsState, cancellationModalOpened, modalCallbackAdditionalProps },
    {
      loadSession,
      loadActivityValuesForSession,
      setCancellationModalOpened,
      onOpenCancellationModal,
      onCloseCancellationModal,
      onCancelSession,
      onRestoreSession,
      onChangeSessionStatus,
      setModalCallbackAdditionalProps,
      setUpdatingSessionState
    }
  ] as const;
};
