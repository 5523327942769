import React, { useRef } from 'react';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import { useParams } from 'react-router-dom';
import { Table, TableRef, usePaginatedLoaderWithAdditionalInfo } from '@efacity/table';
import { useProductPurchasesColumns } from './useProductPurchasesColumns';
import { Row } from 'react-table';
import { Box } from '@mui/material';
import SmallInvoiceDataTable from '../../components/SmallInvoiceDataTable/SmallInvoiceDataTable';
import { Messages, ProductPurchaseWithOrganization } from '@efacity/common';
import { PageTitle } from '@efacity/react-next-sc';
import { useOrganizationRegionsOptions } from '../../hooks/useOrganizationRegionsOptions';

const ProductPurchasesContainer: React.FC = () => {
  const { orgId } = useParams<OrgIdParamType>();
  const tableRef = useRef<TableRef<ProductPurchaseWithOrganization>>(null);
  const { paginatedDataFetcher } = usePaginatedLoaderWithAdditionalInfo<ProductPurchaseWithOrganization>({
    dateTimeFields: ['updatedAt']
  });
  const { organizationRegions } = useOrganizationRegionsOptions(orgId);
  const columns = useProductPurchasesColumns(orgId, organizationRegions.timeZone);

  const renderInvoiceBalanceTable = (row: Row<ProductPurchaseWithOrganization>) => {
    return (
      <td colSpan={columns.length - 1}>
        <Box margin={1}>
          <SmallInvoiceDataTable
            invoiceBalance={row.original?.invoiceBalance}
            message={Messages.NoInvoiceForPurchase}
          />
        </Box>
      </td>
    );
  };

  return (
    <div>
      <PageTitle title="Product Purchases" />
      <Table<ProductPurchaseWithOrganization>
        tableRef={tableRef}
        dataFetcherUrl={`/org/${orgId}/product-purchases`}
        columnDefs={columns}
        dataFetcher={paginatedDataFetcher}
        renderRowSubComponent={renderInvoiceBalanceTable}
        noDataText="No purchased products..."
      />
    </div>
  );
};

export default ProductPurchasesContainer;
