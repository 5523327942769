import React, { useRef, useState } from 'react';
import { NewButton } from '@efacity/frontend-next-shared/org-settings';
import { useParams } from 'react-router-dom';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import { Agreement, Messages } from '@efacity/common';
import AgreementModal from './AgreementModal/AgreementModal';
import { apiService } from '@efacity/frontend-shared';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { PageTitle } from '@efacity/react-next-sc';
import { nonPaginatedDataFetcher, Table, TableRef } from '@efacity/table';
import useCustomerAgreementsTableColumns from './useCustomerAgreementsTableColumns';

const CustomerAgreementsContainer = () => {
  const { orgId } = useParams<OrgIdParamType>();
  const tableRef = useRef<TableRef<Agreement>>(null);
  const dataFetcherUrl = `/org/${orgId}/agreements`;

  const [agreementModalOpen, setAgreementModalOpen] = useState(false);
  const [agreementToEdit, setAgreementToEdit] = useState(null);

  const handleShowAgreementModal = (agreementId?: string) => {
    setAgreementModalOpen(true);
    if (agreementId) {
      setAgreementToEdit(agreementId);
    }
  };
  const handleModalClose = (needReload: boolean) => {
    setAgreementModalOpen(false);
    setAgreementToEdit(null);

    if (needReload) {
      tableRef.current?.refetchTableData();
    }
  };

  const handleDeleteAgreement = async (agreementId: string) => {
    try {
      await apiService.delete(`/org/${orgId}/agreements/${agreementId}`);
      tableRef.current?.refetchTableData();
    } catch (e) {
      showNotification(false, e?.response?.data?.message || Messages.UnableDeleteAgreement);
    }
  };

  const columns = useCustomerAgreementsTableColumns(handleShowAgreementModal, handleDeleteAgreement);

  return (
    <div style={{ marginTop: '15px' }}>
      <PageTitle
        title=""
        button={
          <NewButton
            onClick={() => handleShowAgreementModal()}
            data-testid="add-agreement"
            style={{ width: 120, height: 36, textTransform: 'uppercase' }}
          >
            New
          </NewButton>
        }
      />
      <Table<Agreement>
        tableRef={tableRef}
        dataFetcherUrl={dataFetcherUrl}
        columnDefs={columns}
        dataFetcher={nonPaginatedDataFetcher<Agreement>}
        noDataText="No agreements..."
        manualSorting={false}
        manualFiltering={false}
      />
      {agreementModalOpen && (
        <AgreementModal
          agreementId={agreementToEdit}
          orgId={orgId}
          open={agreementModalOpen}
          onClose={handleModalClose}
        />
      )}
    </div>
  );
};

export default CustomerAgreementsContainer;
