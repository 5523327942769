import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { Messages } from '@efacity/common';

export const handleSaveFile = (request) => {
  try {
    const contentHeader = request.headers['content-type'];
    const cd = request.headers['content-disposition'];

    if (contentHeader.includes('text/html')) {
      const newWindow = window.open('', '_blank');
      if (newWindow) {
        newWindow.document.write(request.responseText);
        newWindow.focus();
      }
    } else {
      // get the file name with regex
      const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const match = regex.exec(cd);
      let fileName = match[1] || 'data';
      // replace leading and trailing slashes
      fileName = fileName.replace(/"/g, '');
      const downloadLink = document.createElement('a');
      const blob = new Blob([request.data], { type: contentHeader });
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = fileName;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  } catch {
    showNotification(false, Messages.FailedExportRegistrations, true);
  }
};
