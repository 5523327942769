import React, { useMemo, useRef } from 'react';
import { getRolesView, rolesSelectOptions, User, formatStringToMonthDayYearDate } from '@efacity/common';
import { NavLink } from 'react-router-dom';
import { PATHS, toPath } from '@efacity/routing';
import { Link } from '@mui/material';
import EmailAddress from '../../components/EmailAddress/EmailAddress';
import { PageTitle, PhoneNumber } from '@efacity/react-next-sc';
import { createColumnHelper } from '@tanstack/react-table';
import { Message } from '@efacity/frontend-shared';
import {
  Table,
  TableRef,
  TextColumnFilter,
  SelectColumnFilter as SelectColumnFilterNew,
  usePaginatedLoaderWithAdditionalInfo,
  DateColumnFilter
} from '@efacity/table';

const columnHelper = createColumnHelper<User>();

const SiteAdminUsers: React.FC = () => {
  const tableRef = useRef<TableRef<Message>>(null);
  const dataFetcherUrl = '/users';
  const { paginatedDataFetcher } = usePaginatedLoaderWithAdditionalInfo<User>({
    dateTimeFields: ['createdAt']
  });

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('fullName', {
        header: 'Name',
        cell: ({ getValue, row }) => {
          return (
            <Link
              component={NavLink}
              to={toPath(PATHS.siteAdminEditUser, { userId: row.original._id })}
              underline={'none'}
            >
              {getValue()}
            </Link>
          );
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      }),
      columnHelper.accessor('email', {
        header: 'Email',
        cell: ({ getValue }) => {
          return <EmailAddress email={getValue()} />;
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      }),
      columnHelper.accessor('defaultPhone', {
        header: 'Phone',
        cell: ({ getValue }) => {
          return <PhoneNumber phone={getValue()} />;
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      }),
      {
        header: 'Roles',
        id: 'roles',
        accessorFn: (row) => row.roles,
        enableSorting: false,
        cell: ({ row }) =>
          row.original.roles.length > 0 ? <>{getRolesView(row.original.roles).join(', ')}</> : <>-</>,
        meta: {
          filter: (column) => <SelectColumnFilterNew column={column} options={rolesSelectOptions} />
        }
      },
      columnHelper.accessor('createdAt', {
        header: 'Date joined',
        id: 'createdAt',
        enableSorting: true,
        enableColumnFilter: false,
        cell: ({ row }) => (
          <div>{row.original.createdAt ? formatStringToMonthDayYearDate(row.original.createdAt) : ''}</div>
        ),
        meta: {
          filter: (column) => <DateColumnFilter column={column} />
        }
      })
    ];
  }, []);

  const addUserLink = toPath(PATHS.siteAdminAddUser);

  return (
    <div>
      <PageTitle title="All Users" addButtonLink={addUserLink} />
      <Table<User>
        tableRef={tableRef}
        dataFetcherUrl={dataFetcherUrl}
        columnDefs={columns}
        dataFetcher={paginatedDataFetcher}
        noDataText="No Users..."
        manualSorting={true}
        manualFiltering={true}
      />
    </div>
  );
};

export default SiteAdminUsers;
