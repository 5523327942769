import { ActivityIdName, apiService, getMergedFormValues } from '@efacity/frontend-shared';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { AdministratorInfo, FormMode, Organization } from '@efacity/common';
import { initialOrganizationSettingsFormValues } from '@efacity/frontend-next-shared/org-settings';
import { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';

export interface CenterFormValues {
  centerSettings: Omit<Organization, 'orgType' | 'sendFromEmail' | 'mailingServer' | 'smtpSettings'>;
  administrators: AdministratorInfo[];
  allowedActivities: string[];
}

export interface CenterFormState {
  centerFormValues: CenterFormValues;
  allActivities: ActivityIdName[];
  isLoading: boolean;
}

export const defaultCenterFormValues: CenterFormValues = {
  centerSettings: initialOrganizationSettingsFormValues,
  administrators: [],
  allowedActivities: []
};

export const defaultCenterFormState: CenterFormState = {
  centerFormValues: defaultCenterFormValues,
  allActivities: [],
  isLoading: true
};

export const useCenterFormValues = (orgId: string, centerId: string, formMode: FormMode) => {
  const [centerFormState, setCenterFormState] = useState(defaultCenterFormState);

  useEffect(() => {
    const getCenterById = async () => {
      try {
        setCenterFormState((state) => ({ ...state, isLoading: true }));
        const { data } = await apiService.get<any>(`/org/${orgId}/schools/centers/${centerId}`);

        setCenterFormState(() => ({
          centerFormValues: {
            centerSettings: getMergedFormValues(initialOrganizationSettingsFormValues, data.centerSettings),
            administrators: [...cloneDeep(data.administrators)],
            allowedActivities: [...cloneDeep(data.allowedActivities)]
          },
          allActivities: data.allActivities,
          isLoading: false
        }));
      } catch (error) {
        showNotification(false, error.message || 'Failed to get Center info.', true);
        setCenterFormState(defaultCenterFormState);
      }
    };

    const getActivityOptions = async () => {
      try {
        setCenterFormState((state) => ({ ...state, isLoading: true }));
        const { data } = await apiService.get<ActivityIdName[]>(`/org/${orgId}/schools/activity-options`);

        setCenterFormState((centerFormState) => ({
          centerFormValues: {
            ...centerFormState.centerFormValues,
            administrators: []
          },
          allActivities: data,
          isLoading: false
        }));
      } catch (error) {
        showNotification(false, error.message || 'Failed to get Activity options info.', true);
        setCenterFormState((centerFormState) => ({
          ...centerFormState,
          allActivities: [],
          isLoading: false
        }));
      }
    };

    if (formMode === FormMode.Add) {
      getActivityOptions();
      setCenterFormState(defaultCenterFormState);
    } else {
      getCenterById();
    }
  }, [orgId, centerId, formMode]);

  return [centerFormState];
};
