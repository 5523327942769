import React, { FC, useRef } from 'react';
import { apiService } from '@efacity/frontend-shared';
import { AdditionalSessionOption, InstanceTemporality, Messages } from '@efacity/common';
import { IdOrgIdParamTypes } from '@efacity/frontend-next-shared/utils';
import { useParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import { smallPublishButtonStyle } from '../../../components/Buttons/SessionPublishButton';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { nonPaginatedDataFetcher, Table as TableNew, TableRef } from '@efacity/table';
import { useSessionOptionsTableColumns } from './useAdditionalSessionsOptionsColumns';

export interface SessionOptionsTableProps {
  temporality?: InstanceTemporality;
}

const AdditionalSessionOptionsTable: FC<SessionOptionsTableProps> = ({ temporality = InstanceTemporality.Current }) => {
  const isActionsAllowed = temporality === InstanceTemporality.Current;
  const { orgId } = useParams<IdOrgIdParamTypes>();
  const tableRef = useRef<TableRef<AdditionalSessionOption>>(null);
  const dataFetchUrl = `/org/${orgId}/additionalOptions/${temporality}`;

  const columns = useSessionOptionsTableColumns(
    orgId,
    isActionsAllowed
      ? [
          {
            header: '',
            id: 'delete-additional-option',
            cell: ({ row }) => {
              return (
                <IconButton
                  color="primary"
                  aria-label="delete additional option"
                  data-testid="delete-session-option"
                  component="span"
                  disabled={new Date(row.original.expiresOn) < new Date()}
                  style={smallPublishButtonStyle}
                  onClick={() => handleDeleteAdditionalOption(row.original._id)}
                >
                  <DeleteIcon />
                </IconButton>
              );
            },
            meta: {
              headerSx: { width: 75, maxWidth: 75 },
              columnSx: { width: 75, maxWidth: 75 }
            }
          }
        ]
      : []
  );

  function handleDeleteAdditionalOption(additionalOptionId) {
    apiService.delete(`/org/${orgId}/additionalOption/${additionalOptionId}`).then(
      (result: any) => {
        showNotification(true, result.data.message as string, false);
        tableRef.current?.refetchTableData();
      },
      (error) => {
        showNotification(false, (error.response.data.message as string) || Messages.FailedDeleteAdditionalOption, true);
      }
    );
  }

  return (
    <TableNew<AdditionalSessionOption>
      tableRef={tableRef}
      columnDefs={columns}
      dataFetcherUrl={dataFetchUrl}
      dataFetcher={nonPaginatedDataFetcher<AdditionalSessionOption>}
      manualSorting={false}
      manualFiltering={false}
    />
  );
};

export default AdditionalSessionOptionsTable;
