import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { AutocompleteProps } from '@mui/material';
import { CountryCode, getCountryNameByCode, getCountryTimezonesByCode, plainTimeZones } from '@efacity/common';
import { Autocomplete } from '@mui/material';
import { FormInputTextProps, FormTextInput } from './FormTextInput';
import { useFormContext } from 'react-hook-form';

interface FormTimeZoneSelectorProps {
  country: CountryCode;
}

interface TimeZoneOptionEnhanced {
  value: string;
  groupBy: string;
}

/*
 * this component wraps Autocomplete
 * it fires onChange event with selected option.value as a string
 * by default MUI Autocomplete will store object as selected value e.g. {value: 'America/New_York', groupBy: ...}
 * but we only need the value as string
 * taking only a value and providing it to onChange will also help to make it more compatible with formik
 *
 * it takes value from formik, but because it works with objects, it transforms simple string value like 'America/New_York'
 * into {value: 'America/New_York', groupBy: ...} internally, so Autocomplete could use it
 *
 * in general the wrapper make Autocomplete component behave like normal <Select> would.
 * i.e. when select option is selected the value is just option.value, not entire option
 * */
export const FormTimeZoneSelector: FC<
  FormTimeZoneSelectorProps & FormInputTextProps & Partial<AutocompleteProps<any, any, any, any>>
> = ({ name, country, ...props }) => {
  const { watch, setValue } = useFormContext();
  const value = watch(name);

  const timeZonesOptions = useMemo<TimeZoneOptionEnhanced[]>(() => {
    if (getCountryNameByCode(country)) {
      const timeZonesForSelectedCountry = getCountryTimezonesByCode(country).map((timeZone) => {
        const split = timeZone.split('/');
        const groupBy = `${split[0]}/${split[1][0].toUpperCase()}`;
        return {
          value: timeZone,
          groupBy: groupBy
        };
      });
      return [...timeZonesForSelectedCountry];
    } else {
      const allTimeZones = plainTimeZones
        .sort((a, b) => a.localeCompare(b))
        .map((timeZone) => {
          const split = timeZone.split('/');
          const groupBy = `${split[0]}/${split[1][0].toUpperCase()}`;
          return {
            value: timeZone,
            groupBy: groupBy
          };
        });
      return [...allTimeZones];
    }
  }, [country]);

  const getSelectValue = useCallback(() => {
    const foundOption = timeZonesOptions.find((option) => option.value === value);
    return foundOption || timeZonesOptions[0];
  }, [value, timeZonesOptions]);

  const [selectValue, setSelectValue] = useState(getSelectValue);

  // TODO: Fix per https://stackoverflow.com/questions/58866796/understanding-the-react-hooks-exhaustive-deps-lint-rule
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setValue(name, getSelectValue().value, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
    setSelectValue(getSelectValue());
  }, [getSelectValue, value, country]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const getOptionSelected = (option: TimeZoneOptionEnhanced) => {
    return option.value === value;
  };

  const getOptionLabel = (option: TimeZoneOptionEnhanced) => {
    return option?.value || 'None';
  };

  return (
    <Autocomplete
      id="time-zone-select"
      value={selectValue}
      disableClearable
      onChange={(_, selectedOption) => {
        setValue(name, selectedOption.value, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
      }}
      options={timeZonesOptions}
      isOptionEqualToValue={getOptionSelected}
      getOptionLabel={getOptionLabel}
      groupBy={(option) => {
        return option.groupBy;
      }}
      fullWidth={true}
      {...props}
      renderInput={(params) => (
        <FormTextInput
          name={name}
          {...params}
          label="Timezone"
          variant="outlined"
          size="small"
          {...props}
          InputProps={{
            ...params.InputProps
          }}
          // override low-level event to prevent self-changing
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onChange={() => {}}
        />
      )}
    />
  );
};
