import React, { useMemo, useState } from 'react';
import { Button, IconButton, Link, Tooltip } from '@mui/material';
import {
  getInvoiceFileName,
  getPaymentReceiptFileName,
  moneyWithCurrencyCodeFormatter,
  ProductPurchaseWithOrganization,
  PurchaseStatus,
  purchaseStatusViewConnector
} from '@efacity/common';
import { PATHS, toPath } from '@efacity/routing';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ColoredPurchaseStatusDate from '../ProductPurchases/ColoredPurchaseStatusDate';
import { createColumnHelper } from '@tanstack/react-table';
import { PurchaseStatusFilter, DateColumnFilter, TextColumnFilter } from '@efacity/table';

const columnHelper = createColumnHelper<ProductPurchaseWithOrganization>();

export const useCustomerProductPurchasesColumns = () => {
  return useMemo(() => {
    const columns = [
      columnHelper.accessor('productName', {
        header: 'Product',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ getValue, row }) => {
          return row.original?.orgId && row.original?.productId ? (
            <Link
              component={Button}
              onClick={() => {
                window.location.href = toPath(PATHS.publicProductDescription, {
                  orgId: row.original.orgId,
                  productId: row.original.productId
                });
              }}
              underline="none"
              style={{ textTransform: 'none' }}
            >
              {getValue()}
            </Link>
          ) : (
            <div>{getValue()}</div>
          );
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      }),
      columnHelper.accessor('organizationName', {
        header: 'Seller',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ getValue }) => {
          return <div>{getValue()}</div>;
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      }),
      columnHelper.accessor('status', {
        header: 'Status',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ getValue, row }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const [isExpanded, setIsExpanded] = useState(false);
          return (
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              {isExpanded ? (
                <div style={{ width: '100%' }}>
                  {row.original?.orderedAt && (
                    <ColoredPurchaseStatusDate
                      dateTime={row.original?.orderedAt}
                      timeZone={row.original.organization?.timeZone}
                      purchaseStatus={PurchaseStatus.Ordered}
                    />
                  )}
                  {row.original?.paidAt && (
                    <ColoredPurchaseStatusDate
                      dateTime={row.original?.paidAt}
                      timeZone={row.original.organization?.timeZone}
                      purchaseStatus={PurchaseStatus.Paid}
                    />
                  )}
                  {row.original?.shippedAt && (
                    <ColoredPurchaseStatusDate
                      dateTime={row.original?.shippedAt}
                      timeZone={row.original.organization?.timeZone}
                      purchaseStatus={PurchaseStatus.Shipped}
                    />
                  )}
                  {(row.original?.deliveryConfirmedByCustomerAt || row.original?.deliveryConfirmedByAdminAt) && (
                    <ColoredPurchaseStatusDate
                      dateTime={row.original?.deliveryConfirmedByCustomerAt || row.original?.deliveryConfirmedByAdminAt}
                      timeZone={row.original.organization?.timeZone}
                      purchaseStatus={PurchaseStatus.Delivered}
                    />
                  )}
                  {row.original?.cancelledAt && (
                    <ColoredPurchaseStatusDate
                      dateTime={row.original?.cancelledAt}
                      timeZone={row.original.organization?.timeZone}
                      purchaseStatus={PurchaseStatus.Cancelled}
                    />
                  )}
                </div>
              ) : (
                <div style={{ width: '100%' }}>{purchaseStatusViewConnector[getValue()]}</div>
              )}
              <div>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setIsExpanded(!isExpanded)}
                  data-testid="collapseButton"
                  style={{ marginTop: -10 }}
                >
                  {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </div>
            </div>
          );
        },
        meta: {
          filter: (column) => <PurchaseStatusFilter column={column} />
        }
      }),
      columnHelper.accessor('updatedAt', {
        header: 'Status Updated',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ getValue, row }) => (
          <div>
            <ColoredPurchaseStatusDate
              dateTime={getValue()}
              timeZone={row.original.organization?.timeZone}
              purchaseStatus={row.original.status}
              showStatus={false}
            />
          </div>
        ),
        meta: {
          filter: (column) => <DateColumnFilter column={column} />,
          headerSx: { width: 190, maxWidth: 190 },
          columnSx: { width: 190, maxWidth: 190 }
        }
      }),
      {
        header: 'Balance',
        id: 'purchase-balance',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => {
          return (
            <div>
              {moneyWithCurrencyCodeFormatter(
                row.original.invoiceBalance?.balance ? row.original?.invoiceBalance?.balance : 0,
                row.original?.invoiceBalance?.currencyCode
              )}
              <span onClick={() => row.toggleExpanded()}>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  data-testid="collapseButton"
                  style={{ marginTop: -10 }}
                >
                  {row.getIsExpanded() ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </span>
            </div>
          );
        },
        meta: {
          headerSx: { textAlign: 'right', width: '115px', maxWidth: '115px', paddingRight: '35px' },
          columnSx: { textAlign: 'right', width: '115px', maxWidth: '115px' }
        }
      },
      {
        header: 'Invoice',
        id: 'invoice',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => {
          return (
            <div>
              {row.original.invoiceBalance ? (
                row.original.invoiceBalance?.isPaid ? (
                  <Tooltip title="Download Payment Receipt">
                    <Link href={getPaymentReceiptFileName(row.original.invoiceBalance?._id)}>Receipt</Link>
                  </Tooltip>
                ) : (
                  <Tooltip title="Download Invoice">
                    <Link href={getInvoiceFileName(row.original.invoiceBalance?._id)}>Invoice</Link>
                  </Tooltip>
                )
              ) : (
                ''
              )}
            </div>
          );
        },
        meta: {
          headerSx: { width: '65px', maxWidth: '65px' },
          columnSx: { width: '65px', maxWidth: '65px' }
        }
      }
    ];

    return columns;
  }, []);
};
