import React, { useMemo, useState } from 'react';
import { ReviewInfo, ReviewStatus, formatDateToMonthDayYearInLocale } from '@efacity/common';
import { IconButton } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ReviewStatusButton from '../../components/ReviewStatusButton/ReviewStatusButton';
import { createColumnHelper } from '@tanstack/react-table';
import { DateColumnFilter, ReviewStatusColumnFilter } from '@efacity/table';

const expandableStyle = (isExpanded: boolean) => {
  if (isExpanded) return {};
  return {
    overflow: 'hidden',
    maxHeight: '3em' //2 lines of text
  };
};

const columnHelper = createColumnHelper<ReviewInfo>();

const useSiteAdminReviewsColumns = (
  changeReviewStatus: (reviewId: string, newStatus: ReviewStatus) => void,
  processingReviewId: string,
  processingApprovePending: boolean
) => {
  return useMemo(() => {
    return [
      columnHelper.accessor('reviewItemName', {
        header: 'Session',
        id: 'reviewItemName',
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ getValue }) => {
          return <div>{getValue()}</div>;
        }
      }),
      columnHelper.accessor('organizationName', {
        header: 'Organization',
        id: 'organizationName',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ getValue }) => {
          return <div>{getValue()}</div>;
        }
      }),
      columnHelper.accessor('reviewerDisplayName', {
        header: 'Reviewer',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ getValue }) => {
          return <div>{getValue()}</div>;
        }
      }),
      {
        header: 'Stars',
        id: 'stars',
        accessorFn: (row) => row.stars,
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ row }) => {
          return <div>{row.original.stars}</div>;
        }
      },
      columnHelper.accessor('comment', {
        header: 'Comment',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ getValue }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const [isExpanded, setIsExpanded] = useState(false);
          return (
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <div style={expandableStyle(isExpanded)}> {getValue()}</div>
              <div>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setIsExpanded(!isExpanded)}
                  data-testid="collapseButton"
                >
                  {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </div>
            </div>
          );
        },
        meta: {
          headerSx: { textAlign: 'right', paddingRight: '35px' }
        }
      }),
      {
        header: 'Created on (in UTC)',
        id: 'reviewedOn',
        accessorFn: (row) => row.reviewedOn,
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ row }) => {
          return <div>{formatDateToMonthDayYearInLocale(row.original.reviewedOn)}</div>;
        },
        meta: {
          filter: (column) => <DateColumnFilter column={column} />,
          headerSx: { width: '170px', maxWidth: '170px' },
          columnSx: { width: '170px', maxWidth: '170px' }
        }
      },
      {
        header: 'Status',
        id: 'status',
        accessorFn: (row) => row.status,
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ row }) => {
          return <div>{row.original.status}</div>;
        },
        meta: {
          filter: (column) => <ReviewStatusColumnFilter column={column} />,
          headerSx: { width: '120px', maxWidth: '120px' },
          columnSx: { width: '120px', maxWidth: '120px' }
        }
      },
      {
        header: '',
        id: 'actions-cell',
        accessor: '',
        cell: ({ row }) => {
          return (
            <ReviewStatusButton
              reviewId={row.original._id}
              currentStatus={row.original.status}
              changeReviewStatus={changeReviewStatus}
              progress={processingReviewId === row.original._id || processingApprovePending === true}
            />
          );
        }
      }
    ];
  }, [changeReviewStatus, processingReviewId, processingApprovePending]);
};

export default useSiteAdminReviewsColumns;
