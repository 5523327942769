import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import { CreditWithCustomer } from '@efacity/common';
import { PageTitle } from '@efacity/react-next-sc';
import { useOrganizationCreditsColumns } from './useOrganizationCreditsColumns';
import { Table, TableRef, usePaginatedLoaderWithAdditionalInfo } from '@efacity/table';

const OrganizationCredits: React.FC = () => {
  const { orgId } = useParams<OrgIdParamType>();
  const tableRef = useRef<TableRef<CreditWithCustomer>>(null);
  const dataFetcherUrl = `/org/${orgId}/credits`;
  const { paginatedDataFetcher } = usePaginatedLoaderWithAdditionalInfo<CreditWithCustomer>({});

  const columnsNew = useOrganizationCreditsColumns(orgId);

  return (
    <div>
      <PageTitle title="Credits" />
      <Table<CreditWithCustomer>
        tableRef={tableRef}
        dataFetcherUrl={dataFetcherUrl}
        columnDefs={columnsNew}
        dataFetcher={paginatedDataFetcher}
        noDataText="No Credits..."
      />
    </div>
  );
};

export default OrganizationCredits;
