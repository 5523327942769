'use client';

import { createContext } from 'react';
import { initialAuthState } from '../hooks/auth/authDefaultStates';
import type { AuthState } from '@efacity/common';

interface AuthContext {
  authState: AuthState;
  setAuthState: (authState: AuthState) => void;
}

export const AuthContext = createContext<AuthContext>({
  authState: initialAuthState,
  setAuthState: (state: AuthState) => state
});
