import { Messages, SendStatuses } from '@efacity/common';
import { useEffect, useState } from 'react';
import { apiService, Message } from '@efacity/frontend-shared';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { usePaginatedLoaderWithAdditionalInfo } from '@efacity/table';

export interface MessagesState {
  data: Message[];
  total: number;
  timeZone: string;
  processingMessageId: string | null;
}
export const defaultMessagesState: MessagesState = {
  data: [],
  total: 0,
  timeZone: '',
  processingMessageId: null
};
export const useMessages = (orgId: string) => {
  const [messagesState, setMessagesState] = useState<MessagesState>(defaultMessagesState);

  const { dataState, paginatedDataFetcher } = usePaginatedLoaderWithAdditionalInfo<Message>({
    dateTimeFields: ['createdOn', 'sentOn'],
    additionalFields: ['timezone']
  });

  useEffect(() => {
    setMessagesState((prevState) => ({
      ...prevState,
      ...dataState,
      isLoading: dataState.isLoading
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataState.data, dataState.total, dataState.isLoading]);

  const resendMessage = async (messageId: string): Promise<void> => {
    setMessagesState((prevState) => ({ ...prevState, processingMessageId: messageId }));

    try {
      await apiService.post<{ status: SendStatuses }>(`/org/${orgId}/message/${messageId}/resend`, {});
    } catch (error) {
      showNotification(false, error.response?.data?.message || Messages.FailedResendMessage);
    } finally {
      setMessagesState((prevState) => ({ ...prevState, processingMessageId: null }));
    }
  };

  return { messagesState, getMessages: paginatedDataFetcher, resendMessage };
};
