import React, { useRef } from 'react';
import { useTheme } from '@mui/material';
import { PageTitle } from '@efacity/react-next-sc';
import { useLoginAuditColumns } from './useLoginAuditColumns';
import { Table as TableNew, TableRef, usePaginatedLoaderWithAdditionalInfo } from '@efacity/table';
import { LoginAudit } from '@efacity/frontend-shared';

const LoginAuditContainer: React.FC = () => {
  const theme = useTheme();
  const tableRef = useRef<TableRef<LoginAudit>>(null);
  const dataFetcherUrl = '/login-audit';
  const { dataState, paginatedDataFetcher } = usePaginatedLoaderWithAdditionalInfo<LoginAudit>({
    dateTimeFields: ['loginTimestamp']
  });
  const columns = useLoginAuditColumns();

  return (
    <div>
      <PageTitle title="Login Audit" />
      <div style={{ fontSize: 20, marginBottom: 15, color: theme.palette.primary.main }}>
        Active users in last 30 days: {dataState['activeUsers']}
      </div>
      <TableNew<LoginAudit>
        tableRef={tableRef}
        dataFetcherUrl={dataFetcherUrl}
        columnDefs={columns}
        dataFetcher={paginatedDataFetcher}
        noDataText="No login audit data..."
        initialSortBy={[{ id: 'loginTimestamp', desc: true }]}
      />
    </div>
  );
};

export default LoginAuditContainer;
