import React, { useMemo } from 'react';
import { IconButton, Link, useMediaQuery, useTheme } from '@mui/material';
import DateCell from '../../components/DateCell';
import { adminSectionTableFontSize } from '@efacity/frontend-shared';
import { PATHS, toPath } from '@efacity/routing';
import {
  convertUtcToZonedTime,
  getAmountForRegistration,
  getBalanceForRegistration,
  getDiscountsAmountForRegistration,
  moneyWithCurrencyCodeFormatter,
  RegistrationForOrganizationAdmin,
  RegistrationStatus
} from '@efacity/common';
import EmailAddress from '../../components/EmailAddress/EmailAddress';
import RegistrationActionsButton from './RegistrationActionsButton/RegistrationActionsButton';
import { NameLinkWithNoteIcon } from '../../components/NameLinkWithNoteIcon/NameLinkWithNoteIcon';
import { StudentWithCustomerInfoTabs } from '../../components/EditStudentWithCustomerInfoModal/EditStudentWithCustomerInfoModal';
import MustAgreeUnCheckedIcon from '../CustomerAgreements/MustAgreeUnCheckedIcon';
import { createColumnHelper } from '@tanstack/react-table';
import { TextColumnFilter, DateColumnFilter } from '@efacity/table';

const columnHelper = createColumnHelper<RegistrationForOrganizationAdmin>();

export const useOrganizationRegistrationsColumns = (
  orgId: string,
  onOpenCancellationModal: (
    registrationId: string,
    studentId: string,
    sessionName: string,
    switchRegistration: boolean,
    cancellationAmount: string
  ) => void,
  onOpenNotesModal: (customerId: string, name: string, url: string) => void,
  registrationCancelling: boolean,
  setStudentIdUnderEdit: ({
    studentId,
    studentName,
    tab
  }: {
    studentId: string;
    studentName: string;
    tab: StudentWithCustomerInfoTabs;
  }) => void,
  setCustomerUnderEdit: ({ customerId }: { customerId: string }) => void
) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));

  return useMemo(() => {
    return [
      columnHelper.accessor('efacitySessionId.name', {
        header: 'Session',
        enableSorting: false,
        enableColumnFilter: true,
        cell: ({ row }) => {
          return (
            <>
              {row.original.status === RegistrationStatus.Unregistered && (
                <div style={{ color: 'red' }}>(cancelled)</div>
              )}
              {row.original.efacitySessionId.name}
            </>
          );
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      }),
      columnHelper.accessor('efacitySessionId.schedule.startDateTime', {
        header: 'Starts On',
        id: 'efacitySessionId.schedule.startDateTime',
        enableSorting: false,
        enableColumnFilter: true,
        cell: ({ row, getValue }) => {
          if (row.original.efacitySessionId?.schedule?.startDateTime) {
            return (
              <DateCell
                dateTime={
                  getValue()
                    ? convertUtcToZonedTime(
                        row.original.efacitySessionId?.schedule?.startDateTime,
                        row.original.efacitySessionId?.timeZone
                      )
                    : undefined
                }
                yearOnNewLine={false}
                showTime={false}
                wrap={true}
                style={{ ...adminSectionTableFontSize }}
              />
            );
          }
          return <>-</>;
        },
        meta: {
          filter: (column) => <DateColumnFilter column={column} />
        }
      }),
      columnHelper.accessor('efacitySessionId.schedule.endDateTime', {
        header: 'Ends On',
        id: 'efacitySessionId.schedule.endDateTime',
        enableSorting: false,
        enableColumnFilter: true,
        cell: ({ row }) => {
          if (row.original.efacitySessionId?.schedule?.endDateTime) {
            return (
              <DateCell
                dateTime={convertUtcToZonedTime(
                  row.original.efacitySessionId?.schedule?.endDateTime,
                  row.original.efacitySessionId?.timeZone
                )}
                yearOnNewLine={false}
                showTime={false}
                wrap={true}
                style={{ ...adminSectionTableFontSize }}
              />
            );
          }
          return <>-</>;
        },
        meta: {
          filter: (column) => <DateColumnFilter column={column} />
        }
      }),
      columnHelper.accessor('registeredStudentFullName', {
        header: 'Student',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ row }) => {
          return (
            <NameLinkWithNoteIcon
              name={row.original.registeredStudentFullName}
              onNameClick={() =>
                setStudentIdUnderEdit({
                  studentId: row.original.registeredStudent.toString(),
                  studentName: row.original.registeredStudentFullName,
                  tab: StudentWithCustomerInfoTabs.MAIN
                })
              }
              onNoteClick={() =>
                onOpenNotesModal(
                  row.original.registeredStudent.toString(),
                  row.original.registeredStudentFullName,
                  `/org/${orgId}/users/students/${row.original.registeredStudent}/notes`
                )
              }
            />
          );
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />
        }
      }),
      {
        header: 'Agreements',
        id: 'agreements-column',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => {
          return (
            <IconButton
              onClick={() => {
                setStudentIdUnderEdit({
                  studentId: row.original.registeredStudent,
                  studentName: row.original.registeredStudentFullName,
                  tab: StudentWithCustomerInfoTabs.AGREEMENTS
                });
              }}
            >
              <MustAgreeUnCheckedIcon color="primary" />
            </IconButton>
          );
        }
      },
      columnHelper.accessor('customerFullName', {
        header: 'Customer',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ getValue, row }) => {
          return (
            <Link
              component="button"
              align={'left'}
              underline={'none'}
              onClick={() =>
                setCustomerUnderEdit({
                  customerId: row.original.customerId
                })
              }
            >
              {getValue()}
            </Link>
          );
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />,
          columnSx: { ...adminSectionTableFontSize }
        }
      }),
      columnHelper.accessor('customerEmail', {
        header: 'Email',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ getValue }) => {
          if (getValue()) {
            return <EmailAddress email={getValue()} mobileView={mobileView} />;
          }
          return <>-</>;
        },
        meta: {
          filter: (column) => <TextColumnFilter column={column} />,
          columnSx: { ...adminSectionTableFontSize }
        }
      }),
      columnHelper.accessor('registeredAt', {
        header: 'Registered On',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ row, getValue }) => {
          if (getValue()) {
            return (
              <DateCell
                dateTime={convertUtcToZonedTime(getValue(), row.original.efacitySessionId?.timeZone)}
                yearOnNewLine={false}
                wrap={true}
                style={{ ...adminSectionTableFontSize }}
              />
            );
          }
          return <>-</>;
        },
        meta: {
          filter: (column) => <DateColumnFilter column={column} />
        }
      }),
      columnHelper.accessor('price', {
        header: 'Amount',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => {
          return (
            <>
              {moneyWithCurrencyCodeFormatter(
                getAmountForRegistration(row.original._id, row.original?.invoiceBalance?.invoiceItems || []),
                row.original.currencyCode
              )}
            </>
          );
        }
      }),
      {
        header: 'Discount',
        id: 'discount-column',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => {
          return (
            <>
              {moneyWithCurrencyCodeFormatter(
                getDiscountsAmountForRegistration(row.original._id, row.original?.invoiceBalance?.invoiceItems || []),
                row.original.currencyCode
              )}
            </>
          );
        },
        meta: {
          headerSx: { textAlign: 'right' },
          columnSx: { textAlign: 'right' }
        }
      },
      {
        header: 'Balance',
        id: 'balance-column',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => {
          return (
            <>
              {moneyWithCurrencyCodeFormatter(
                row.original.status === RegistrationStatus.RegisteredAndPaid
                  ? 0
                  : getBalanceForRegistration(row.original._id, row.original?.invoiceBalance?.invoiceItems || []),
                row.original.currencyCode
              )}
            </>
          );
        },
        meta: {
          headerSx: { textAlign: 'right' },
          columnSx: { textAlign: 'right' }
        }
      },
      {
        id: 'registration-actions-option',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => {
          return (
            <RegistrationActionsButton
              payPath={
                row.original?.invoiceBalance?._id &&
                !(
                  row.original.status === RegistrationStatus.RegisteredAndPaid ||
                  row.original.status === RegistrationStatus.Unregistered
                )
                  ? toPath(PATHS.invoice, { orgId: orgId, invoiceId: row.original?.invoiceBalance._id })
                  : null
              }
              onOpenNotesModal={onOpenNotesModal}
              onCancel={() =>
                onOpenCancellationModal(
                  row.original._id,
                  row.original.registeredStudent,
                  row.original.efacitySessionId.name,
                  false,
                  ''
                )
              }
              cancelDisabled={row.original.status === RegistrationStatus.Unregistered}
              onSwitch={() =>
                onOpenCancellationModal(
                  row.original._id,
                  row.original.registeredStudent,
                  row.original.efacitySessionId.name,
                  true,
                  moneyWithCurrencyCodeFormatter(
                    row.original.cancellationBalance,
                    row.original.invoiceBalance?.currencyCode
                  )
                )
              }
              disableActionButtons={registrationCancelling}
              invoiceId={row.original?.invoiceBalance._id}
              isPaid={row.original?.invoiceBalance.isPaid}
              customerId={row.original.customerId}
              name={row.original?.customerFullName || '[no name]'}
              orgId={orgId}
            />
          );
        },
        meta: {
          headerSx: { width: 110, maxWidth: 110 },
          columnSx: { width: 110, maxWidth: 110 }
        }
      }
    ];
  }, [
    orgId,
    onOpenCancellationModal,
    registrationCancelling,
    mobileView,
    setStudentIdUnderEdit,
    setCustomerUnderEdit,
    onOpenNotesModal
  ]);
};
